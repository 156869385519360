import { useState } from 'react';

const BooksRead = () => {
  const Book2 = "/books/Ramdevra_Final_Report.pdf";
  return (
    <>
      <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
      <div>
        <iframe src={Book2} style={{ width: '100%', height: '100rem' }} />
      </div>

    </>
  );
};
export default BooksRead;
