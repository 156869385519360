import React, { useState, useRef } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import './about.css';
import AudioPlayer from "../Audio/AudioPlayer";

import emailjs from "emailjs-com";
// import emailjs from '@emailjs/browser';

const Contact = (props) => {
    const img5 = "/assets/wp-content/uploads/2018/12/contact-icon-1 (1).png";
    const img6 = "/assets/wp-content/uploads/2018/12/contact-icon-2 (1).png";
    const form = useRef();
    const [formData, setformData] = useState({
        fullname: '',
        email: '',
        message: '',
    });
    const [errors, seterrors] = useState({});
    const [emailSent, setEmailSent] = useState(false);
    const HandleChange = (e) => {
        const { name, value } = e.target;
        setformData({ ...formData, [name]: value });
    }

    const ValidateForm = () => {
        const errors = {};
        const usernameRegex = /^[a-zA-z]+([\s][a-zA-Z]+)*$/;
        if (!usernameRegex.test(formData.fullname)) {
            errors.fullname = 'Special Characters & digits Are Not Allowed, Spaces are only allowed between two words Only one space is allowed between two words  Spaces at the start or at the end are consider to be invalid.'
        }
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(formData.email)) {
            errors.email = 'Enter valid email address'
        }
        if (formData.message.length < 6) {
            errors.message = 'Message must be at least 6 characters';
        }
        seterrors(errors);
        return Object.keys(errors).length === 0;
    }
    const HandleSubmit = (e) => {
        e.preventDefault();
        if (ValidateForm()) {
            sendEmail();
        }
    }

    const sendEmail = () => {
        emailjs.sendForm('service_xle6nx3', 'template_de12hca', form.current, 'e1Kj8npXKfJADCCv7')
            .then((result) => {
                setEmailSent(true);
                setformData({  // Resetting form fields to initial values after successful submission
                    fullname: '',
                    email: '',
                    message: '',
                });
            })
            .catch((error) => {
                alert(error.text);
            });
    };

    // const sendEmail = (e) => {
    //     e.preventDefault();
    //     emailjs.sendForm('service_xle6nx3', 'template_de12hca', form.current, 'e1Kj8npXKfJADCCv7')
    //         .then((result) => {
    //             alert('Email has sent successfully...', result);
    //         }, (error) => {
    //             alert(error.text)
    //         });
    // };


    return (
        <>
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}
            <Helmet>
                <title>Ramapir | Contact Us</title>
                <meta name="keywords" content="Bhagwan Ramdevji Maharaj said Gatganga Participants of this Communion are believed..." />
            </Helmet>
            <Header />


            <Header />

            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}

                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">

                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div>
                <h5 className="contactus-title">Contact Us</h5>
            </div>

            <div className="page_content_wrap">
                <div className="content_wrap">
                    <div className="container">
                        <div className="form-container">
                            <form ref={form} onSubmit={HandleSubmit} method="post">
                                <div className="mb-5">
                                    <label htmlFor="exampleInputEmail1" className="form-label">
                                        Full Name
                                    </label>
                                    <input
                                        type="text"
                                        name='fullname'
                                        value={formData.fullname}
                                        className="form-control"
                                        autoComplete='off'
                                        onChange={HandleChange}
                                    />
                                    {errors.fullname && (
                                        <p className="error" style={{ color: "red" }}>
                                            {errors.fullname}
                                        </p>
                                    )}
                                </div>
                                <div className="mb-5">
                                    <label htmlFor="exampleInputEmail1" className="form-label">
                                        Email address
                                    </label>
                                    <input
                                        type="email"
                                        name='email'
                                        value={formData.email}
                                        onChange={HandleChange}
                                        autoComplete='off'
                                        className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                    />
                                    <div id="emailHelp" className="form-text">
                                        We'll never share your email with anyone else.
                                    </div>
                                    {errors.email && (
                                        <p className="error" style={{ color: "red" }}>
                                            {errors.email}
                                        </p>
                                    )}
                                </div>
                                <div className="mb-5">
                                    <label htmlFor="exampleInputPassword1" className="form-label">
                                        Message
                                    </label>
                                    <textarea
                                        name='message'
                                        value={formData.message}
                                        onChange={HandleChange}
                                        rows='3'
                                        autoComplete='off'
                                        className="form-control"
                                        id="exampleInputPassword1"
                                    />
                                    {errors.message && (
                                        <p className="error" style={{ color: "red" }}>
                                            {errors.message}
                                        </p>
                                    )}
                                </div>

                                <button type="submit" id="sc_button_212540216" className="sc_button sc_button_default sc_item_button sc_item_button_default sc_title_button sc_button_size_normal sc_button_icon_left">
                                    <span className="sc_button_text">
                                        <span className="sc_button_title">
                                            Submit
                                        </span>
                                    </span>
                                </button>
                                {emailSent && (
                                    <p style={{ color: 'green' }}>Email has been sent successfully!</p>
                                )}

                            </form>
                        </div>

                        {/* <script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments)}
  gtag('js', new Date());

  gtag('config', 'UA-23581568-13');
</script>

	<script src="js/main.js"></script> */}

                        {/* <div className="trx_addons_tabs services_page_tabs">
                            <section
                                id="services_page_tab_comments"
                                className="services_page_section services_page_section_comments"
                            >
                                {" "}
                                <section className="comments_wrap">
                                    <div className="comments_form_wrap">
                                        <div className="comments_form">
                                            <div id="respond" className="comment-respond">
                                                <h3
                                                    id="reply-title"
                                                    className="section_title comments_form_title"
                                                >
                                                    Leave a comment{" "}
                                                    <small>
                                                        <a
                                                            rel="nofollow"
                                                            id="cancel-comment-reply-link"
                                                            href="/services/the-happiness-program/#respond"
                                                        >
                                                            Cancel reply
                                                        </a>
                                                    </small>
                                                </h3>
                                                <form
                                                    method="post"
                                                    id="commentform"
                                                    className="comment-form"
                                                    onClick={sendEmail}
                                                >
                                                    <div className="comments_field comments_author">
                                                        <label htmlFor="author" className="required">
                                                            Name
                                                        </label>
                                                        <span className="sc_form_field_wrap">
                                                            <input
                                                                // id="author"
                                                                name="fullname"
                                                                value={formData.fullname}
                                                                onChange={HandleChange}
                                                                type="text"
                                                                placeholder="Your Name *"
                                                            />
                                                        </span>

                                                    </div>
                                                    {errors.fullname && (
                                                        <p className="error" style={{ color: "red" }}>
                                                            {errors.fullname}
                                                        </p>
                                                    )}
                                                    <div className="comments_field comments_email">
                                                        <label htmlFor="email" className="required">
                                                            E-mail
                                                        </label>
                                                        <span className="sc_form_field_wrap">
                                                            <input
                                                                id="email"
                                                                name="email"
                                                                type="email"
                                                                value={formData.email}
                                                                onChange={HandleChange}
                                                                placeholder="Your E-mail *"
                                                                defaultValue=""
                                                                aria-required="true"
                                                            />
                                                        </span>
                                                    </div>
                                                    {errors.email && (
                                                        <p className="error" style={{ color: "red" }}>
                                                            {errors.email}
                                                        </p>
                                                    )}
                                                    <div className="comments_field comments_comment">
                                                        <label htmlFor="comment" className="required">
                                                            Comment
                                                        </label>
                                                        <span className="sc_form_field_wrap">
                                                            <textarea
                                                                id="comment"
                                                                name="message"
                                                                value={formData.message}
                                                                onChange={HandleChange}
                                                                placeholder="Your Comment *"
                                                                aria-required="true"
                                                                // defaultValue={""}
                                                            />
                                                        </span>

                                                    </div>
                                                    {errors.message && (
                                                        <p className="error" style={{ color: "red" }}>
                                                            {errors.message}
                                                        </p>
                                                    )}
                                                    <p
                                                        className="wpgdprc-checkbox
comment-form-wpgdprc"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            name="wpgdprc"
                                                            id="wpgdprc"
                                                            defaultValue={1}
                                                        />
                                                        <label htmlFor="wpgdprc">
                                                            By using this form you agree with the storage and
                                                            handling of your data by this website.
                                                            <abbr
                                                                className="wpgdprc-required"
                                                                title="You need to accept this checkbox."
                                                            >
                                                                *
                                                            </abbr>
                                                        </label>
                                                    </p>
                                                    <p className="form-submit">
                                                        <input
                                                            name="submit"
                                                            type="submit"
                                                            id="send_comment"
                                                            className="submit"
                                                            defaultValue="Leave a Comment"
                                                        />{" "}
                                                        <input
                                                            type="hidden"
                                                            name="comment_post_ID"
                                                            defaultValue={327}
                                                            id="comment_post_ID"
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name="comment_parent"
                                                            id="comment_parent"
                                                            defaultValue={0}
                                                        />
                                                    </p>
                                                </form>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </section>
                            <section
                                id="services_page_tab_contacts"
                                className="services_page_section services_page_section_contacts"
                            >
                                <div
                                    className="wpcf7 no-js"
                                    id="wpcf7-f82-p327-o1"
                                    lang="en-US"
                                    dir="ltr"
                                >
                                    <div className="screen-reader-response">
                                        <p role="status" aria-live="polite" aria-atomic="true" /> <ul />
                                    </div>
                                    <form
                                        action="/services/the-happiness-program/#wpcf7-f82-p327-o1"
                                        method="post"
                                        className="wpcf7-form init"
                                        aria-label="Contact form"
                                        noValidate="novalidate"
                                        data-status="init"
                                    >
                                        <div>
                                            <input type="hidden" name="_wpcf7" defaultValue={82} />
                                            <input
                                                type="hidden"
                                                name="_wpcf7_version"
                                                defaultValue="5.7.7"
                                            />
                                            <input
                                                type="hidden"
                                                name="_wpcf7_locale"
                                                defaultValue="en_US"
                                            />
                                            <input
                                                type="hidden"
                                                name="_wpcf7_unit_tag"
                                                defaultValue="wpcf7-f82-p327-o1"
                                            />
                                            <input
                                                type="hidden"
                                                name="_wpcf7_container_post"
                                                defaultValue={327}
                                            />
                                            <input
                                                type="hidden"
                                                name="_wpcf7_posted_data_hash"
                                                defaultValue=""
                                            />
                                        </div>
                                        <div className="elementor-column-gap-extended">
                                            <div className="elementor-row">
                                                <div className="elementor-column elementor-col-50">
                                                    <div className="elementor-column-wrap elementor-element-populated">
                                                        <p>
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-name"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    placeholder="Name*"
                                                                    defaultValue=""
                                                                    type="text"
                                                                    name="your-name"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-50">
                                                    <div className="elementor-column-wrap elementor-element-populated">
                                                        <p>
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-email"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    placeholder="Email*"
                                                                    defaultValue=""
                                                                    type="email"
                                                                    name="your-email"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="elementor-column-gap-extended">
                                            <div className="elementor-row">
                                                <div className="elementor-column elementor-col-100">
                                                    <div className="elementor-column-wrap elementor-element-populated">
                                                        <p>
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-message"
                                                            >
                                                                <textarea
                                                                    cols={40}
                                                                    rows={10}
                                                                    className="wpcf7-form-control wpcf7-textarea"
                                                                    aria-invalid="false"
                                                                    name='message'
                                                                    value={formData.message}
                                                                    onChange={HandleChange}
                                                                    placeholder="Message"
                                                                    defaultValue={
                                                                        "Hi.\nI'd like this service 'The Happiness Program'.\nPlease, get in touch with me."
                                                                    }
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text">
                                            <p>
                                                <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="acceptance-486"
                                                >
                                                    <span className="wpcf7-form-control wpcf7-acceptance">
                                                        <span className="wpcf7-list-item">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    name="acceptance-486"
                                                                    defaultValue={1}
                                                                    aria-invalid="false"
                                                                />
                                                                <span className="wpcf7-list-item-label">
                                                                    I agree that my submitted data is being collected
                                                                    and stored.
                                                                </span>
                                                            </label>
                                                        </span>
                                                    </span>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="text">
                                            <p>
                                                <button
                                                    className="wpcf7-form-control has-spinner wpcf7-submit"
                                                    type="submit"
                                                    defaultValue="Send message"
                                                >
                                                    </button>
                                            </p>
                                        </div>
                                        <div className="wpcf7-response-output" aria-hidden="true" />
                                    </form>
                                </div>
                            </section>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="elementor-widget-container">
                    <div
                        id="trx_sc_icons_528506280"
                        className="sc_icons sc_icons_default sc_icons_size_medium sc_align_center"
                    >
                        <div className="sc_icons_columns_wrap sc_item_columns trx_addons_columns_wrap columns_padding_bottom">
                            <div className="trx_addons_column-1_2">
                                <div className="sc_icons_item">
                                    <div className="sc_icons_image">
                                        <img
                                            decoding="async"
                                            loading="lazy"
                                            src={img5}
                                            alt="Icon"
                                            width="116"
                                            height="116"
                                        />
                                    </div>
                                    <div className="sc_icons_item_details">
                                        <h4 className="sc_icons_item_title">
                                            <span>Address</span>
                                        </h4>
                                        <div className="sc_icons_item_description">
                                            <span>B-816, Stratum @Venus Ground, Opp. Jhansi ki rani BRTS bus stand, Nehrunagar, Ahmedabad, Gujarat, India</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="trx_addons_column-1_2">
                                <div className="sc_icons_item">
                                    <div className="sc_icons_image">
                                        <img
                                            decoding="async"
                                            loading="lazy"
                                            src={img6}
                                            alt="Icon"
                                            width="116"
                                            height="116"
                                        />
                                    </div>
                                    <div className="sc_icons_item_details">
                                        <h4 className="sc_icons_item_title">
                                            <span>Mail</span>
                                        </h4>
                                        <div className="sc_icons_item_description">
                                            <a href="mailto:kartik@stalwartitsolution.com">
                                                <span>kartik@stalwartitsolution.com</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>{" "}
                </div>
            </div>

            {/* <div>
                <footer className="bg-body-tertiary text-center text-lg-start">
                    <div
                        className="text-center p-3 footer-section"
                    >
                        © 2023 Copyright:
                        <a className="text-body" href="" style={{ textDecoration: 'none' }}>
                            Stalwart IT Solution
                        </a>
                        All Rights Reserved.
                    </div>
                </footer>
            </div> */}


            <Footer />
            {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay }) } /> */}
        </>
    );
};

export default Contact;
