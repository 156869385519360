import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails18 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery20.jpg";
    return (
        <>
         <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}


            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                   
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            સગુણાનો પુકાર

                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                    
                                                >
                                                    <span className="income">સગુણાનો પુકાર

                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content">
                                                    સગુલ્લાએ આખી રાત અજંપામાં વિતાવી, પઢિયારરાયને થયેલા ચમત્કાર અને કેન્દ્રની યુક્તિથી તે સાવ અજાણી હતી. તે વહેલી સવારમાં સાસુ પાસે આવી જ લાગી : “તમે કાલે વચન આપ્યું હતું કે, આવતી કાલે રણુંજા મોકલીશ, તો
                                                    વેલું તૈયાર થાઉં છું.
                                                    ‘સગુણા ! તને મોકલવાની મારી ના નથી પણ આ તારા પાંચ વર્ષના કુંવરને અહીં મૂકીને જા. મુસાફરીથી એ હેરાન થશે. વળી આ આટલો નાનો બાળક લગ્નમાં
                                                    મજે પણ શું ?”
                                                    પણ એક માતા પોતાના બાળકને લીધા વિના લગ્નનો આનંદ શી રીતે માણી કે? તેથી તેણે ટૂંકો જવાબ આપ્યો કે, મારે દીકરાને મૂકીને નથી જવું. સગુણાની સાસુએ રાતે થયેલા ચમત્કારથી તેને રોકવાનો વિચાર માંડી વાળ્યો હતો, એટલે વિદાય કર્યે જ છૂટકો. એમ માની તૈયાર થવા કહ્યું.
                                                    રતનો રાયકો સગુણાને તેડીને પીંગલગઢના પાદરમાં આવ્યો. સાથે સગુણાની સાસુ પણ તેને પાદર સુધી વળાવવા આવી હતી. તે સારા શુકન જોવા લાગ્યું. ડાબા ને જમણા તેતર બોલે જી રે,
                                                    અને બોલે રે ભૈરવ ભેંકાર, જાવ છો ભર્યા ને તમે આવશો ઠાલાંજી રે,
                                                    અને ઠરશો નહિ
                                                    રૈ ઠરકાય... આજ
                                                    ‘વહુજી ! અપશુકન થાય છે, ડાબા જમણા તેતર બોલે છે અને ભૈરવ ભેંકારે છે તે સાંભળો, ખોટી મમત છોડી જવાનું માંડી વાળો. જો દીકરાને કંઈ ખીલીખટકો થયો તો યાદ રાખજો પીંગલગઢમાં તમારું સ્થાન નહીં હોય.'
                                                    સાસુનાં આ વચનો સાંભળી સગુણા કહેવા લાગી :
                                                    માતા મિણલદે ને પિતા અજમલજી રે, અને મારે રામદેવ વીરમદેવ ભાઈ રે. પરગટ પીરાણું આજ રણુંજ પ્રગટાયજી રે, હોય રે... આજ
                                                    અને મદા સવન
                                                    ‘સાસુજી ! તમે ફીકર કરશો નહિ. રણુંજામાં મારા ભાઈ રામદેવ એવા પ્રગટ પીર છે કે, જેના નામથી મડદાં બેઠાં થાય છે. ત્યાં મંગળ શુકનનો અવાજ સાંભળી

                                                </p>
                                                <p className="paragraph-content">
                                                    સાસુનું એ તરફ ધ્યાન ખેંચતાં તેણે કહ્યું,
                                                    હરિયાળા વનમાં કોયલ બોલે જી રે, અને રે શુકન સવાયા હોય રે, સગુણાએ સાંઢિયો શણગારીયો જી રે, અને કોર્ટે બાંધ્યો ઘુઘરમાળ ... આજ

                                                    'સાસુજી ! હરિયાળા ખેતરમાં કોયલ બોલે છે, તે ઘણા સારા શુકન છે.’ · કહી સગુન્નાએ ખજાનો લાદી સાંઢિયો શણગારી, તેને કેડે ઘુઘરમાળા પોતાના પુત્રને લઈ સાંઢિયા ઉપર બેઠાં અને રતનાના સાંઢિયા સાથે ધન
                                                    સાંઢિયો રણુંજાના માર્ગે હંકારી મૂક્યો.
                                                    દિલ્હીના બાદશાહની કચેરીમાં એક મીર કવિ રામદેવની પ્રશંસા કરતો કહેર લાગ્યો કે, બાવન ઓલિયાની કરામત જોવી હોય તો રણુંજાના રામદેવ પાસે છે
                                                    મક્કાથી આવેલા પીરોનો પણ ગર્વ ઉતારી દીધો હતો.
                                                    બાદશાહ કહે : ‘એમ હોય તો રામદેવનાં લગ્ન છે, તેથી આજે એની બહે પીંગલગઢથી રણુંજા જવા નીકળશે.'
                                                    બાદશાહે તરત જ ચાર માણસોને હુકમ કર્યો કે, હમણાં જ જીવ અને રામદેવની બહેનને લૂંટી જલદી પાછા આવો. એની બહેન લૂંટાશે એટલે એ એ
                                                    આવશે. પછી કેવી કરામત કરે છે તે જણાશે.'
                                                    બાદશાહનો હુકમ થતાં ચાર માણસો તરત રવાના થયા. પીંગલગઢથી ર જતાં રસ્તામાં ઓડો બાંધીને બેઠા. ત્યાં તો સગુણા ચાલી આવે છે. સાંઢિયો મેલ્યો રે બેનીએ ઝૂલતો જી રે,
                                                    અને બેની ચાલ્યાં માઝમરાત, દિલ્હીના ચોરે ઓડા બાંઘીયા રે
                                                    અને બેની ઘૂંટાણાં વગડાની માંય... આજ૦
                                                    મધરાત જામી છે. સગુણાનો સાંઢિયો ધમધમાટ ચાલ્યો જાય છે; ત્યાં ઓડે બાંધી બેઠેલા ચાર લૂંટારા ઊભા થઈ પડકાર કરી સાંઢિયો થોભાવી ઘેરી વળ્યા. રતનો કરગરવા લાગ્યો પણ લૂંટારાઓએ ધમકી આપી. સગુણાના સાંઢિયા ર ભરેલો માલ, દાગીના, કપડાં લઈ લીધાં અને સગુણાના શરીર પરથી દાગીન ઉતારી લીધા. લૂંટારા માલ લઈ દિલ્હી તરફ રવાના થયા. રતનો વિમાસંમ પડ્યો. સગુણા કલ્પાંત કરતાં રામદેવજીને પ્રાર્થના કરવા લાગ્યાં.
                                                    બેનીએ વિખડાં

                                                </p>
                                                <p className="paragraph-content">
                                                    મેનાનો
                                                    વિખડાં
                                                    રે અને
                                                    સગુણાનો પુકાર
                                                    ઘોળિયાં
                                                    દે,
                                                    પાડવા પોતાનો
                                                    હમણાં રામદેરે વહાણલાં વહી જશે જી રે.
                                                    ને દેખશે દુરીજન લોક રે ...આજ

                                                    ' ભાઈ ! રામદે’ વીરા ! મારા મનના મનોરથ મનમાં જ રહી ગયા. મને
                                                    ા શુકનનું ફળ મળ્યું; વીર ! તમારા વિવાહમાં આવતાં હું અધવચમાં લૂંટાણી. ઉપર પહેરવા કર્ણય ન રહ્યું. સવાર પડતાં લોકો મને આવા વેશે દેખીને શું કરતા સગુ મરવાના નિશ્ચય પર આવ્યાં. રતનો ઘણી રીતે સમજાવે છે. પણ હતો ? હું જીવીને શું કરું . મારા મનની મનમાં જ રહી ગઈ !' આવી રીતે કલ્પાંત રામદેવજીનું સ્મરણ કરતાં સંકટ સમયે ઉપયોગ કરવા માટે ગુપ્ત રીતે પોતાની પાસે ખેલા ઝેરનો કટોરો તૈયાર કરી રતનાને કહ્યું : ‘હું તો અહીં જ દેહ પાડીશ, પણ મારા કુંવરને તું રણુંજા પહોંચતો કરજે ભાઈ !' સગુલ્લાનાં વચન સાંભળતાં રતનો
                                                    પોક મૂકીને રડવા લાગ્યો.
                                                    રામદેવ સૂતા ૨ે રંગ મહેલમાંજી રે, અને રે સ્વપ્ન આવ્યું માઝમરાત, મિણલદે તમને વિનવું
                                                    માતા
                                                    જી રે,
                                                    અને રે બેની લૂંટાણાં વગડાની માંય ...આજ
                                                    બેન સગુણાનો પુકાર રામદેવજીએ મધરાતે સાંભળ્યો. તેઓ તુરત માતા ઊલદે પાસે જઈ કહેવા લાગ્યા કે, બહેન સગુણા જંગલમાં લૂંટાણાં છે એવું મને
                                                    સ્વપ્નું આવ્યું છે માટે હું તેમની વહારે જાઉં છું.'
                                                    મિણલદે કહેવા લાગ્યા ઃ ‘ભાઈ ! પહેલાં રતનાને કેદ પકડ્યાનું સ્વપ્નું આવ્યું
                                                    તું. આવાં સપનાં તો આવે જાય તે બધાં સાચાં ન હોય.'
                                                    કાં તો રે . રામદેવ તમને સ્વપ્નું આવ્યું જીરે,
                                                    કાં તો બેની ચાલ્યાં
                                                    માઝમરાત.
                                                    'બેટા, કાં તો બેન માઝમરાતે તમને યાદ કરતાં આવતાં હશે તેના ભણકાર તમને વાગે છે, માટે આટલી મોડી રાતે સૂઈ જાવ.’
                                                    માતાજીના વચનોની આ વખતે રામદેવજીને કંઈ અસર ન થઈ અને તુરત તેમણે લીલડા ઘોડા પર પલાણ નાખ્યું.
                                                </p>
                                                <p className="paragraph-content">
                                                    ભલો રે ભલો લીલુડો ઘોડલોજી રે,
                                                    અને રે ભલી રણમાં તલવાર. તુરત ઘોડલો પલાણિયો 20 રે,
                                                    અને રે રામે દીધો રે પડકાર ...આજ
                                                    રામદેવજી લીલુડા ઘોડે સવાર થઈ જ્યાં સગુણાબહેન ઝેરનો ભરેલો ક પીવા જતાં હતાં, ત્યાં આવ્યા અને ઝેરનો કટોરો ઝૂંટવી લીધો. તેઓ
                                                    પૂછવા લાગ્યા :
                                                    સાચું રે બોલો રે મારી બેનડી જી રે કેટલોક લૂંટાયો છે માલ ! નવખંડમાં ચોર નવ રહી શકે જી રે,

                                                    દુન
                                                    જીરે લાવું પકડી હું હાલ ...આજ૰ તેઓ જોચો૨ના પાછળ પડશે તો ભારે ધીંગાણું થશે અને કદાચ ચોર ભાઇને ઘાલ સગુણા બોલતાં અચકાયાં. મનમાં વિચાર કરવા લાગ્યાં કે ભાઈનાં લગ્નરે કરી નાખશે તો મહાઅનર્થ થશે અને હવે લૂંટાયેલું પાછું આવવાનું નથી. આ
                                                    વિચારી બોલ્યાં :
                                                    ઘરડો બૂઢો રે મારો સાંઢિયો જી રે,
                                                    ઉપર જૂનો પુરાણો રે માલ. રામદેવજી બોલ્યા : ‘ખોટી વાત, બહેન ! સાચું બોલો.' સાચું બોલોને મારી બેનડી જી રે,
                                                    જૂઠે નીકળશે કાળો રે
                                                    કોઢ.
                                                    ‘બેન સગુણા ! જો જૂઠું બોલશો તો કાળો કોઢ નીકળશે. ખરું કહો કેટલો માલ
                                                    ગયો ?’
                                                    રામદેવજી કોઈ રીતે સમજે તેમ નથી, એમ માની સગુણાએ સાચું કહેશ
                                                    માંડ્યું ઃ
                                                    મીનલ પગો રે મારો સાંઢિયો જી રે,
                                                    અને ૨ે ઉપર ભર્યું કાચું હેમ. રામદેવજીએ વાત સાંભળતાં ઘોડો પવન વેગે દોડાવ્યો. થોડી વારમાં તેમાં
                                                    લૂંટારાઓની નજીક પહોંચી ગયા.

                                                </p>
                                                <p className="paragraph-content">
                                                    જાવ જાવ ચોરટાઓ કેટલેક જાશો જી રે, લૂંટ કેરો તમે માલ કેમ કરી ખાવ?
                                                    ‘ખબરદાર ! ચોરટાઓ, જો ભાગ્યા છો તો ભાલે વીંધાઈ જશો.’ આમ કહી હાથ લંબાવી તેમણે ચોરોને પકડી લીધા.
                                                    ઝડપ દઈને રે ત્રણને ઝાલિયા જી રે, અને એકને મેલ્યો કરવાને જાણ, નવસે નગારાં તારાં ઊંધાં પાડું જી રે
                                                    અને રામો એકલા અસરાણ ...આજ.
                                                    લૂંટારાઓને પકડી ત્રણને ઠાર કર્યા અને ચોથાને કહ્યું કે, જા, તારા બાદશાહને જઈને ખબર આપ કે, લાવ લશ્કર લઈને આવે અને જોઈ લે કે રામાપીર કેવા છે ? તે બિચારો લૂંટારો તો જીવ લઈને નાઠો.
                                                    રામદેવે કરને લંબાવીયા જી રે,
                                                    વસ્ત્રો આપ્યાં બેનીને હાય. બેની રે ત્યાં અચરજ પામીયાં જી રે,
                                                    આવ્યા વ્હારે રણુંજાના નાથ ...આજ
                                                    રામદેવજીએ બધો માલ પડાવી લાવી, કર લંબાવી સગુણાને વસ્ત્રો આપતાં દૃઢ અવાજે કહ્યું : ‘તમે ચાલતાં થાવ, હું આવી પહોંચું છું.’
                                                    જીવ બચાવીને લૂંટારાએ બાદશાહને ખબર આપી કે, એક ઘોડેસ્વારે મારા ત્રણ સાથીદારોને ઠાર કર્યા છે અને મને આપને ખબર આપવા છોડી મૂક્યો છે. તેણે કહ્યું છે કે;
                                                    નવસે નગારાં તારાં ઊંધાં પાડું જી રે,
                                                    અને એવી કરવા મેલ્યો જાણ ...આજ
                                                    વાત સાંભળી બાદશાહ ગુસ્સે થયો અને હુકમ આપતાં...
                                                    નવસે નગારાં રે નોબત ગડગડી જી રે,
                                                    અને મોગલ ચડ્યા રે નવ લાખ.
                                                    તેજીના તંગ રામે તાણીઆ જી રે,
                                                    અને રેવાળ્યો. મોગલોનો ઘાટ ...આજ૦
                                                    નવલાખ મોગલોની ફોજને ધમધમાટ આવતી જોઈ રામદેવજીએ લીલુડો ઘોડો

                                                </p>
                                                <p className="paragraph-content">
                                                    સામે દોડાવ્યો અને તલવારના ઝાટકાથી લાખો મોગલોનો દાટ વાળી . એકેક મોગલ સામે એકેક રામદેવજીને જોતાં મોગલ સરદાર જીવ લઈને
                                                    ******
                                                    રામદેવજીએ તેને પાછળથી પકડયો અને એક જ ઝટકે તેનું માથું ઘી કે મ
                                                    આમ કંઈક મરતાં કંઈક નાસતાં મોગલ સૈન્યમાં ધમશાણ મચી રહ્યું. દાસદાસીઓ ચમર ઢોળે છે, ત્યાં ભીષણ ગર્જના સંભળાણીઃ ‘હોશિયાર કે જ દિલ્હીનો બાદશાહ ઢોલિયા પર બેઠો બેઠો હુકો પીએ છે. માનીતી
                                                    બાદશાહ, તૈયાર થઈ જા.'
                                                    * તમ
                                                    ગેબી અવાજ સાંભળતાં તો બાદશાહ ધ્રૂજવા લાગ્યો. તેના હાથમાંથી હ નળી પડી ગઈ. રામદેવજીએ બાદશાહને પકડી જમીન પર પાડી, ઢોલિયાન
                                                    ઊંધો વાળી બાદશાહને દબાવ્યો.
                                                    હેઠે બાદશાહ ઉપર ઢોલિયો, તે પર હિન્દવો રમણ કરે, ઊભી હુરમો અરજ કરે ને, બાદશાહને બહુ માર પડે. બાદશાહ બચાવવાની બૂમો પાડવા લાગ્યો. રામદેવજી ઢોલિયા ઉપર કૂદન બોલ્યા : ‘બાદશાહ, જોઈ લે રામાપીરને.’
                                                    બેગમ કહેવા લાગી : ‘ભાઈ રામદેવજી ! તમે મારા ભાઈ છો. હું તમારી ધમન બાદશાહની હુરમો કરગરવા લાગી. બાદશાહને બચાવાની વિનંતી કરતી બહેન છું.’ બાદશાહ પણ દયા યાચતો કહેવા લાગ્યો : ‘પીરજી દયા કરો ! માનવ નહીં પણ દેવ છો. હું તમારે પગે પડું છું. મને માફ કરો’
                                                    ‘બાદશાહ હવેથી જો કોઈની માદીકરી ઉપર અત્યાચાર કે લૂંટારા નીતિ રાખીશ તો યાદ રાખજે તારા મોત સિવાય તારી મુક્તિ નથી.’ કે
                                                    બાદશાહ અને હુરમ બંને પગમાં પડી કહેવા લાગ્યાં :
                                                    દિલ્હીના ચોકમાં દેવળ ચણાવું જી રે, અને માનતાએ આવે નર ને નાર; સવા લાખનું છતર ચઢાવું જી રે, રે જાપ... આજ
                                                    અને જપીએ તમારા
                                                    થયાં અને બાદશાહે દિલ્હીમાં રામાપીરનું દેવળ ચણાવી સવા લાખનું સોનાનું બાદશાહ હુરમો તથા દાસદાસીઓ આ પ્રગટ પરચો દેખવાથી રામદેવનાં ભ
                                                    રત્નજડિત છત્તર ચડાવ્યું.
                                                    રામદેવજીથી છૂટાં પડેલાં સગુણા ને રતનો આગળ ચાલ્યાં જાય છે. રાત થય વીતી ગઈ છે. વળી કુંવરનું શરીર પણ સારું ન હતું અને ત્રણ દિવસના લાંબા પંથ
                                                </p>
                                                <p className="paragraph-content">
                                                    સાંઢિયો ઘણો થાકી ગયો હતો; તેથી થાક ઉતારતા ત્યાં જ બેઠાં અને થાક - પરિશ્રમથી ત્રણે જણાં નિદ્રાધિન થયાં.
                                                    સવારના ચાર વાગવાના સુમારે ઢોલ, નગારાં, શરણાઈઓનો નાદ રતનાને કાને પડતાં તે જાગ્યો અને સહુને રામા સરોવરને કાંઠે આવેલા જોઈને આશ્ચર્યથી ‘જય રામાપીર’ એમ બોલી ઊઠ્યો, સગુણા પણ ઘોષણા સાંભળતાં જાગી. પોતાને રામા સરોવરને કાંઠે જોતાં તે હર્ષઘેલી થઈ ગઈ અને હરખઘેલો રતનો ઝડપથી બધાંને દરબારગઢમાં લઈ ગયો.
                                                    માતા મિણલદે, લાસાંબહેન, લક્ષ્મીબહેન વગેરે સગુણાને આવેલાં જોઈ હર્ષથી ભેટી પડચાં. બધાને ઘણો આનંદ થયો.
                                                    રામદેવજી શણગાર સજતા હતા, ત્યાં સગુણા આવી પહોંચ્યાં. તે બોલે તે પહેલાં જ ‘બહેન ! તું વખતસર આવી પહોંચી તેથી મને ઘણો આનંદ થયો.’ રામદેવજીએ કહ્યું.
                                                    ‘ભાઈ ! આ બધો તમારો પ્રતાપ છે. વનવગડે બહેનનો પોકાર સાંભળી તમે ન આવ્યા હોત તો મારો દેહ ત્યાં જ પડત.'
                                                    વાત સાંભળી મિણલદે સમજી ગયાં કે, રામદેવજીને આવેલું સ્વપ્ન સાચું હતું છતાં આશ્ચર્ય સાથે પૂછ્યું :
                                                    ‘બેટા સગુણા ! રામદેવજીએ વહાર કરી, એ તું શું બોલી ?'
                                                    ‘માતાજી ! ભાઈએ તો તુંવરાકુળની લાજ રાખી. મારા સસરાને પરચો બતાવ્યો, રતનાને કેદમાંથી છોડાવ્યો. રણવગડે લૂંટારાઓને ભાઈએ ઠાર મારી મને બધો માલ પાછો લાવી આપ્યો, તેથી હું બોલી.' સગુણાએ આખી વાત વિગતથી કહી સંભળાવી.
                                                    ‘બહેન ! રામદેવ તો અહીં જ છે, ક્યાંય ગયા જ નથી. માતાજીનાં વચન સાંભળી રામદેવજી ખડખડાટ હસી પડ્યા અને માતાજીની શંકા દૂર કરવા તલવાર કાઢીને તલવાર બતાવતાં બોલ્યા :
                                                    ‘માતાજી ! આ તલવાર વડે જ બહેનના લૂંટનારાઓને માર્યા. લૂંટારાઓને વહારે આવેલી દિલ્હીની ફોજને પણ આ જ તલવાર વડે પરાજીત કરી અને બાદશાહને નમાવ્યો. જોઈ લો ! આ મોગલોના લોહીની તરસી તલવાર.' માતાજી તલવારને લોહીવાળી જોઈ આશ્ચર્ય પામ્યાં. ત્યારબાદ સગુણાના કુંવરને બેચેની થતી હોવાથી સગુણા રણુંજામાં રહી અને જાન ઉમરકોટ જવા તૈયાર થઈ.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails18;
