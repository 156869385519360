import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails37 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdevpir15.jpeg";
  return (
    <>
      {/* <div className="body_wrap">
        <div className="page_wrap"> */}

<meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
      <Header />
      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id="46"
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="52ebb12"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          style={{ height: "30rem", marginBottom: "5rem" }}
        >
          <div className="elementor-background-overlay"></div>
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
              data-id="ae9d67e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fcf91b3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                      data-id="93dfaf4"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                          data-id="847c2c5"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                          style={{ height: "8rem" }}
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                          data-id="8fe49e3"
                          data-element_type="widget"
                          data-widget_type="trx_sc_layouts_title.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              id="trx_sc_layouts_title_515615674"
                              className="sc_layouts_title sc_align_center with_content without_image without_tint"
                            >
                              <div className="sc_layouts_title_content">
                                <div className="sc_layouts_title_title">
                                  {" "}
                                 
                                </div>
                                <div className="sc_layouts_title_breadcrumbs">
                                  <div className="breadcrumbs">
                                    
                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



      <div className="page_content_wrap blog-details">
        <div className="content_wrap" >
          <div className="content">
            <div id="container" className="give-wrap container">
              <div id="content" role="main">
                <div
                  id="give-form-288-content"
                  className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                >
                  <div
                    id="give-sidebar-left"
                    className="give-sidebar give-single-form-sidebar-left"
                  >
                    <div className="images">
                      <img
                        width="1024"
                        height="576"
                        src={img1}
                        className="attachment-large size-large wp-post-image blog-img"
                        alt=""
                        decoding="async"
                        sizes="(max-width: 1024px) 100vw, 1024px"
                      />
                    </div>
                  </div>
                  <div className="summary entry-summary">
                    <h2 itemProp="name" className="give-form-title entry-title">
                      મહાવીર ફક્કડાનાથ !

                    </h2>{" "}
                    <div
                      id="give-form-288-wrap"
                      className="give-form-wrap give-display-onpage"
                    >
                      <div className="give-goal-progress">
                        <div
                          className="raised blog-title"
                          
                        >
                          <span className="income">મહાવીર ફક્કડાનાથ !


                          </span>
                        </div>
                      </div>

                      <div
                        id="give-form-content-288"
                        className="give-form-content-wrap give_pre_form-content"
                      >
                        <p className="paragraph-content" >
                          દોહા
                          ગુરુ રામગર મૂઠિયા, માથે મૂક્યો હાથ, સેવામાં હનુમાન તું, જય હો ! ફક્કડાનાથ !
                          સવા, ગીગા, વસતા એ ત્રણે શિષ્યોને રામગરજીએ પીરાઈ-સિદ્ધિ આપી ધર્મધજાધારી કર્યા. એમના પટશિષ્ય ફક્કડાનાથે મંદિર તૈયાર કરવામાં રાતદિવસ ઉજાગરા વેઠી સખત પરિશ્રમ કર્યો.
                          રામગરજીએ તેમનો ત્યાગ, સેવાભાવ અને અથાગ પરિશ્રમ જોઈ તેમને મહાવીર હનુમાનની ઉપમા આપી હતી. જ્યારે તે હનુમાનજી કહી બોલાવતા ત્યારે ફક્કડાનાથ કહેતા : ‘બાપુ ! હું તો હનુમાનજીનો આસ્તિક છું, હનુમાનજી નથી !'
                          ‘તારી સેવાથી હું ખૂબ પ્રસન્ન થયો છું. ખરેખર તું રામસેવક હનુમાન જેટલો જ સેવાભાવી છે.' રામગરજીએ કહ્યું. ‘બાપુ ! ગુરુદેવ ! આપે મને હનુમાનજી જેવો કહ્યો ? હનુમાન તરીકે મારું નામ જાહેર થાય અને કોઈ તેલ ચડાવવા આવે તો મારે શું કહેવું ?' ફક્કડાનાથે પૂછ્યું. દોહા
                          જો કોઈ લે પારખાં, થઈશ મા વેણ ફરેલ, પચાવજે પીરાઈથી, ચડે માથડે તેલ.
                          કોઈ દ્વેષભાવથી પરીક્ષા ક૨વા ધારે તો મેં તને હનુમાન કહ્યો છે, એ વેણથી ફરીશ નહીં અને તેલ ચડાવવા આવે તો માથું ધરજે.
                          છંદ
                          s
                          રહી સમાઈ ગંગ, સદાશિવ તણી જટામાં, ચડે શીર પર તેલ, સમાશે વાળ ઘટામાં, સુગરા પડશે પાય, નુગરા જશે ડઘાઈ ! હાજરિયા હનુમાન, પ્રગટ થાશે પીરાઈ, ધર્મધજા ફરકાવજે, સ્મરણ કરી અભંગ, સેવકને ઉપદેશ દે, ચડે ભક્તિનો રંગ.

                        </p>
                        <p className="paragraph-content">
                          ફક્કડા ! ભગવાન રામદેવજી મહારાજનું સ્મરણ એ મહાશક્તિ સંચારક છે. તારે જે જોઈએ તે મળી રહેશે એવી મેં તને સંકલ્પસિદ્ધિ આપી છે, પરંતુ તું કોઈની પાસે યાચના કરીશ તો તારી સિદ્ધિ નષ્ટ થશે.
                          મોહની માયાજાળમાં ત્યાગી, વૈરાગી, તપસી, ઋષિ, મુનિ કસાયાના દાખલા યાદ રાખજે ! માયાની ઝપટે ચડીશ તો એ જાળમાં લપેટી લેશે, ધનધાનની લાલચ, દંભ અને પાખંડ, કપટ અને છળ તને કર્મ, ધર્મ, ભક્તિ ભુલાવી દેશે, માટે યાદ રાખજે... લે, આ ઘજા !' કહી તેમણે પોતાની કફની ફાડીને તેનો ટુકડો આપ્યો.
                          ગુરુઆદેશ સાંભળી, એ ટુકડો દંડી પર વીંટાળી ધજા કરી, ગુરુને વંદન કરી, ઝમરાળા નામના ગામ તરફ ફક્કડાનાથે પ્રયાણ કર્યું. ઝમરાળા નજીક પહોંચતાં સંધ્યાકાળનો સમય થયો એ વખતે...
                          દોહા
                          કરમાંથી સરકી ધજા, સૂકી સરીતા માંય,
                          બેઠા ફક્કડાનાથજી, ધજા રોપતા ત્યાંય.
                          સૂકી નદી વળોટતાં વચ્ચે ધજાઊખળીને સરકી પડી. એ જોઈ ધજાને એ જ સ્થળે રોપી ફક્કડાનાથ ત્યાં જ બેઠા. એ જોઈ ગામનાં આવતાં- જતાં માણસોને આશ્ચર્ય થયું. આ વાત ગામમાં વાયુવેગે પહોંચતાં સ્ત્રી-પુરુષોનાં ટોળે ટોળાં ત્યાં દોડી આવ્યાં અને કહેવા લાગ્યાં :
                          ‘મહાત્મા ! ભક્તિમાં ભાન ભૂલ્યા ! આ તો નદી છે, નદી ! મહાત્મા ! આ સંધ્યાટાણું છે, હમણાં રાત પડશે, નદીમાંથી ઊઠીને કાંઠે બેસો. જોગીરાજ ! જેઠ મહિનો ઊતરવા આવ્યો છે. અષાઢી ગાજવાની તૈયારી જેવાં વાદળાં જુઓ ! વરસાદની હેલીમાં નદી બે કાંઠે છલકાય છે. ઊઠો કાંઠે બેસો બાવાજી !' આ પ્રમાણે ત્યાં ઊભેલા લોકો કહેવા લાગ્યાં, છતાં તેઓ મૌન ધારણ કરી બેસી રહ્યા.
                          કોઈ કહે, એ હઠીલો સાધુ પાણીનું વહેણ આવશે ત્યારે ધજા ઉપાડી કાંઠે બેસશે કાં તો ગામમાં આવશે. નદી એની દયા નહીં રાખે.
                          અષાઢી બીજની વહેલી સવારે ફક્કડાનાથે અદ્ભુત દૃશ્ય જોયું. લીલુડા ઘોડે ચડી, હાથમાં ભમ્મરિયો ભાલો ધારણ કરી રામદેવજી મહારાજ ત્યાં આવ્યા. આ દૃશ્ય જોઈ ફક્કડાનાથ ‘જય જય કૃપાનિધાન !' કહી સામે દોડડ્યા. એટલામાં તો દૃશ્ય અદૃશ્ય થઈ ગયું. ફકકડાનાથે એ સ્થળને નમન કરી ત્યાંથી મૂઠી ભરી રેણું લઈ પાછા આવીને આસન પર બેઠા. હૃદયમાં રામદેવજીનું નિજસ્વરૂપ વસી ગયું

                        </p>
                        <p className="paragraph-content">
                          અને તેનું ધ્યાન ધરી બેસી ગયા. સવારે આકાશ ઘટાટોપ વાદળાંઓથી છવાઈ ગયું. ઝડઝપટાથી વાયુ ફૂંકાયો અને જોતજોતામાં મૂશળધાર વરસાદ વરસવા લાગ્યો. ઘડીકમાં નદી છલકાવા લાગી.
                          ચોપાઈ
                          ધન વરસે સ્થળ છાંટે ફોરાં, રહ્યા સિદ્ધનાં આસન કોરાં,
                          અડગ ધ્યાનમાં રહ્યા સુધીરા, સ્થળ તજી નદી વહે બે તીરા.
                          ધોધમાર વરસાદ અને પવનના સુસવાટા ઝપાટાથી ગામ અને ખોર ખળભળી ગયાં. શેરીઓમાં પાણી ઊભરાવા લાગ્યું. ગામલોકોએ આખી રાત અજંપામાં વિતાવી. પરોઢિયે વરસાદ બંધ થતાં ગામલોકો નદીએ ગયા, તો મહાત્માનું આસન અને તેને ફરતા ભાગમાં વરસાદનો છાંટોય પડ્યો ન હતો. નદી બે કાંઠે વહી રહી હોવા છતાં વચ્ચે જોગી ધ્યાનમાં બેઠા હતા. આ આશ્ચર્યકારક બનાવ જોવા લોકોનાં ટોળેટોળાં નદી કાંઠે દોડી આવ્યાં.
                          ચોપાઈ
                          વહે નીર બે ભાગમાં, વચ્ચે નેજા સ્થાન, અચરજ સર્વે પામતા, ફરકે ધજા નિશાન. ઝમરાળાને પાદરે, વહે નદીએ નીર, નહીં સ્થાનને સ્પર્શતું, વચ્ચે ફક્કડાપીર, ધન્ય ઝમરાળા ભોમને, આવ્યા સંત સુધીર, જન કરતા જય ઘોષણા, જય જય રામાપીર. દાના બાપુની ડેલીએ, મળ્યો લોક સમુદાય, શ્રીફળ સાકર લઈ સહુ, દર્શન કરવા જાય.
                          આ વાત દૂર દૂરનાં ગામો સુધી પ્રસરી ગઈ. કોઈ ઘોડાસ્વારી કરી, કોઈ ઊંટસ્વારી કરી, કોઈ ગાડાં જોડીને અને કોઈ પગપાળા એમ અનેક માણસો ફક્કડાનાથને જોવા આવ્યા.
                          નદીના કાંઠે માનવ મેદની ઊભરાવા લાગી અને ‘ફક્કડાનાથની જય ! રામાપીરની જય !' થી વાતાવરણ ગાજી ઊઠયું . (1)
                          ઝમરાળામાં ફક્કડાનાથના ધર્મનેજા રોપણને ત્રણ વરસ પૂરાં થતાં હોવાથી મહોત્સવમાં ધર્મનેજાધારી મહાપુરુષોને તેડાવવા સવરામંડપ રોપવો એવી ઇચ્છા થઈ. તેથી અષાઢી પૂર્ણિમાએ સવરામંડપમાં સહુને તેડાવવા વાયક બીડાં મોકલ્યા.

                        </p>
                        <p className="paragraph-content">
                          ગિરનારથી ગોરખમઢીના ધુણાવાળા કડાનાળ અને મહાત્માં રામતવન, તેમના શિષ્ય ભોજો ભગત, કુશળસંગ, સતી ગંગાબાઈ, સાંસતિયા, તોરાનો શિષ્ય દીપકીયો, ધના ભગત તથા ગેમલ ભગત તથા પાટણથી જેમલ રાઠોડ, નિરલબાઈ, ઉગમશી, મેઘધારુઓ તથા સતી લોયણ, સુરજારાણી રંગપરથી રામગરજી અને તેમનાં શિષ્યો, સવો ભગત, ગીગો ભગત, વસતો ભગત અને દરબાર સુરસંગજી આવ્યા. નસીદપુરથી દાઠા ભગત સાથે ઘણા ભાવિક ભક્તો
                          આવ્યા.
                          મોણપુરમાં દેવાયત પંડિતના બે આહિર ગેલા, હાલો કુલો હતા. એ રામદેવજીના ઉપાસક હતા, સાતો ભગત સો ભેંસો લઈ વાંઢ નીકળ્યો હતો. એમણે સવરામંડપ અને પાટપૂજાની વાત સાંભળી ત્યાં દર્શને આવતાં વિચાર આવ્યો કે ભેંસ વળો૨ છે. તો મંદિરમાં પાણી પખાલ સારવા આપવી; એમ ધારીને ભેંસને સાથે લાવ્યો.
                          ફક્કડાનાથ અને મહાત્માઓ પાટમાં મંગલાચરણના સમયની રાહ જોઈ બેઠા છે. સાતાએ મંડપ બહાર ભેંસ ઊભી રાખી. મહાત્માઓનાં દર્શન કરી રામગરજીને વિનયપૂર્વક દંડવત પ્રમાણ કરી કહ્યું : ‘બાપુ ! હું એક ‘વળોર’ ભેંસ લાવ્યો છું. આ જગ્યામાં પાણી પખાલ સારવામાં કામ આવે એમ ધારીને ભેટ મૂકવાની મારી ઇચ્છા છે.'
                          ‘ભગત ! ભેંસની પીઠે પાણી પખાલ ના હોય, એ તો દૂધદાતા છે. ધૃત આપનારી નારાયણી છે.' રામગરજીએ કહ્યું, ‘બાપા ! આ ભેંસ તો દૂધ દે એવી નારાયણી નથી. એ વાંઝણી હોવાથી એ ‘વળો૨' ગણાય છે.' સાતાએ જણાવ્યું. રામગરજીએ ધ્યાન ધારણમાં જોયું તો એ જોઘપુરની દરબાર સભામાં પોતાનાથી શાપિત થયેલી નર્તકી પરમાનંદી હતી. એનો ઉદ્ધાર કરવાનું વચન પૂરું કરવાનો પ્રસંગ સમજી ગયા. રામગરજી અને સાતાને સાંભળવા માણસો ટોળે વળી ઊભા. રામગરજીએ કહ્યું ‘વળોર' ગાય પણ હોય છે. એ કાનકુંવર પણ કહેવાય છે. ગૌધણમાં જઈ રોજ જે ઠેકાણે નિયમિત બેસતી હોય એ ધરતીમાં સંપત્તિ હોય છે એવી દંતકથા છે.
                          ‘જે મહિષી ‘વળોર' હોય એ ‘જળપદ્મણી’ કહેવાય છે. એ જે ઠેકાણે રોજ બેસે એ સ્થળે ભૂગર્ભમાં મીઠા પાણીની સેર વહેતી હોય છે.' રામગરજીએ સમજાવ્યું અને ગુરુપૂજન વિધિનો મંગલ આરંભ કર્યો. દરેક નિજ ગુરુનું પૂજન કર્યું. સાતાએ

                        </p>
                        <p className="paragraph-content">
                          પણ સર્વ સંતોનું પૂજન કર્યું. રામગરજીએ સાતાને જળકુંભ આપતાં કહ્યું : ‘સાતા ! લે આ જળકુંભ, ભગવાન શ્રી રામદેવ મહારાજનું સ્મરણ કરી અને સવરામંડપની પરકમ્મા કરી પાટ ફરતી સાત પ્રદક્ષિણા ફરી એ કુંભનું જળ ભેંસના મુખમાં અંજલિ ભરી રેડ અને તેને છાંટી પવિત્ર કર અને દોહવા બેસ. એ દૂધની ખીર કરી પાટ વધાવતી વખતે પ્રસાદી તરીકે વહેંચાશે.' રામગરજીએ કહ્યું,
                          ‘સાતાએ સૂચન પ્રમાણે ભેંસ દોહી ! તો સૌના આશ્ચર્ય વચ્ચે અર્ધો મણ દૂધની તાંબડી ભરાઈ ! ફક્કડાનાથની શિષ્યા ઉમાબાઈ અને સાતા ભગત બંને જણે ખીર
                          બનાવી.
                          “પાટપૂજા જમાજાગરણ પછી પ્રભાત થતાં પાટ વધાવી ખીરનો પ્રસાદ વહેંચ્યો. તે પછી ભજનકીર્તન થવા લાગ્યાં. ભજન પૂરાં થયેથી ગિરનારના ગોરખધૂણેથી આવેલા ગુરુ ફક્કડાનાથ સભાને સંબોધવા લાગ્યા.
                          ‘ગુરુ ગોરખનાથે મારું નામાભિધાન ‘ફક્કડાનાથ' રાખીને તરુવર અને માનવોનો સંબંધ જીવતો રાખ્યો છે, તરુવર માનવની કેટલી સેવા કરે છે ! તે ફળ, ફૂલ પાન, છાલ, રસ-કસ આપે છે. માનવને ઉપયોગી બને એવા પરહિતના સેવાકાર્ય માટે એણે માનવનો સંગ કર્યો અને અંત સુધી નિભાવી રાખ્યો.
                          તરુએ માનવને સાથ આપી છેક સુધી સેવા કરી. છતાં માનવ સ્વાર્થી બન્યો ! એનો સ્વભાવ પરહિતમાંથી પલટાઈ પોતાના હિત તરફ વળ્યો ! છતાં વૃક્ષે પોતાનો નીતિનિયમ છોડ્યો નહીં.
                          ચોપાઈ
                          વૃક્ષ ને વેલી છોડ-સુગંધી, માનવ સાથે સ્નેહની સંધી,
                          માનવની સેવામાં ભળતા, અંતિમ માનવ સાથે બળતાં.
                          માનવી સાથે સ્નેહસંબંધ સાચવી રાખતાં વૃક્ષ, વેલ કે સુગંધી પુષ્પ માનવના મૃત્યુ વખતે પણ સાથે બળે છે. કાયા ન્યોછાવર કરવા મિત્ર તરીકે વૃક્ષે આપેલો કોલ માનવે અવગણ્યો અને દુશ્મન જેવું કામ કરવા લાગ્યો.
                          ચોપાઈ
                          તરુવરને કાપે કે મોડે, ઝૂડે ઝાપટે ફળને તોડે,
                          છાલ પાન રસકસ દે કોઠે, સાથે બળે પણ સંગ ન છોડે. અવધૂતની સંગપ્રસંગ વાણી ! વાકછટાથી સભાજનો પ્રભાવિત થઈ ગયા ! લાકડું અને સેવાભાવને વર્ણવતાં ફક્કડાનાથે લલકાર કર્યો :

                        </p>
                        <p className="paragraph-content">
                          લલકાર
                          કહેતા હું, સુનલો ફક્કડાનાથ,
                          સેવા સંગી, કભી ન છોડું સાથ, સુનલો અવધૂત ફક્કડાનાથ...મેં હું લકડા બાલક કી સેવા કરને મેં, હરદમ રહું સંગાથ, ટુક, ટુક્ર, કટાઉ તનકો, પહું સુથારી હાથ, સુનલો અવધૂત ફક્કડાનાથ... મેં હું લકડાo માંચી, ઝોલા, ખાટ ખાટૌલા, ખીલૌના બન જાતા, ચુસા, ઘુઘર, ગેંદ ખેલ મેં, બાલક ઉર હરખાતા, સુનલો અવધૂત ફક્કડાનાથ...મેં હું લકડા વૃદ્ધો કી બનતા લકડી, ટેકની રખતે હાથ, મરે તભી ઉસ ચિતામે મેં, જલતા ઉનકી સાથ; સુનલો અવધૂત ફક્કડાના....મેં હું લકડા સ્મરણ સંગી પવનપાવડી, આપ હી રખતે હાથ, ચરન પાવડી નેજા ઠંડી, ધર્મનિશાન ધરાત, સુનલો અવધૂત ફક્કડાનાથ...મેં હું લકડા દૂત આપ હો, નામ દૂજો હનુમાન, ‘કેશવ’ કો ભક્તિ કી શક્તિ, દે દો કૃપાનિધાન,
                          રામદેવકા
                          સુનલો અવધૂત ફક્કડાના....મેં હું લકડા૦
                          ૨૦૧
                          ઝમરાળાની સંતસભામાં આવેલા શ્રોતાજનો પરોપકારી વૃક્ષોનું મહત્ત્વ સમજી ગયા. અને તે દિવસે બધાંએ બને તેટલાં વૃક્ષો રોપી ઉછે૨વાની પ્રતિજ્ઞા લીધી. ‘ફક્કડાનાથની જય !' બોલાવી સભા વિસર્જન થઈ.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
      {/* </div>
      </div> */}
      <div>
        <Footer />
        {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
      </div>
    </>
  );
};

export default BlogDetails37;
