import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails30 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery32.jpg";
    return (
        <>
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}
 <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      

            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                   
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                           
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            ધનોભગત-રામબાણવાળો
                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                   
                                                >
                                                    <span className="income">ધનોભગત-રામબાણવાળો

                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content" >
                                                    ચોપાઈ
                                                    સોરઠ ભોમે ‘ધોળા' ગામે, કણબી ભગત ધનજી નામે, રામદેવપીરનો ભગત કહાવે, યાત્રા ધામ રણુંજા જાવે, સોરઠના મધ્ય ભાગમાં આવેલું ‘ધોળા' નામનું ગામ. ત્યાં સરવાળિયા શાખાનો કણબી પટેલ ધનજી (ધનો) નામે હતો. વરસમાં એક વાર તે ગિરનારની યાત્રાએ જતો અને રામદેવપીરના મંદિરમાં દર્શન કરી ભજન ધૂનમાં રાત્રિ ગાળતો.
                                                    ધનાનો ભક્તિભાવ જોઈ ગિરનારની મુચુકુન્દ ગુફામાં રહેતા સિદ્ધ મહાત્મા રામેતવને એને શિષ્ય કર્યો. મહા વદી અગિયારસથી ચૌદશ શિવરાત્રી સુધી સંતમેળો થતો હોવાથી એ ગિરનારની યાત્રાએ આવતો અને રામતવનના શિષ્યો, ભગત ગેમલ, ભોજલરામ, ભગત કુશળસંગ, સતી ગંગાબા વગેરે સાથે ભજન સત્સંગમાં આનંદ ઓચ્છવ કરતાં હતાં.
                                                    ધનો ભોળો, ભલો, દયાવાન સ્વભાવનો હતો. એક પડાળિયું ઘર, એક ગાય, એક જોડ બળદ અને ખેતર ! આ એની મિલકત ! એક સ્ત્રી અને બે નાનાં છોકરાઓ એનો પરિવાર !
                                                    ધના ભગતની સ્ત્રીને પણ ભક્તિરંગ લાગ્યો હતો. ભગત રામદેવજીના સ્થાપન પાસે બેસે, ધૂપ દીપ કરે, કંકુ ચોખાથી વધાવે, કપાળમાં ધૂપ ભસ્મનો ચાંલ્લો કરે, ધરમાં જે કાંઈ રાંધ્યું હોય તેને રામભોજન માનીને ભગવાનને ધરાવે ને પછી પોતે જમે, પતિ- પત્ની બંને ભાવિક હતાં. કોઈ વાર પોતાના પૂરતું રાંધેલું હોય અને કોઈ ભૂખ્યો આંગણે આવે તો એને પ્રેમપૂર્વક ખવડાવે અને પોતે ભૂખ્યાં રહે. એક વાર-
                                                    દોહા
                                                    જુનાગઢનો સંઘવી, ગામ રણુંજા જાય,
                                                    ધોળા' ગામને ગોંદરે, રાત્રિવાસો થાય.
                                                    રણુંજાના યાત્રાળુઓએ ધોળા ગામને પાદરે પડાવ નાખ્યો અને વિશ્રાંતિ લીધી. સંઘ બે દિવસ રોકવાનો હતો; યાત્રાળુ સંઘના ઉતારાની વાત ધના ભગતે સાંભળી અને ભોજનની ચિંતામાં પડ્યો. ઘરમાં જોયું તો કશું અનાજ ન હતું. થોડાક ઘઉં વાવણી માટે રાખેલા હતા, ઘડીકમાં ધના ભગતે વિચાર કરી લીધો અને વાવણી
                                                </p>
                                                <p className="paragraph-content">
                                                    માટે રાખેલા ઘઉંને દળીને સંધને ભોજન આપ્યું અને તેઓ પણ સંઘ સાથે ગયા.
                                                    દોહા
                                                    ગયો વાવણી સમય ને, ગયો અષાઢી માસ,
                                                    શ્રાવણમાં આવે ધનો, ઘઉં બી ન મળે પાસ.
                                                    અષાઢી બીજના સમયે મેઘરાજાએ મહેર કરી. વરસાદ વરસાવ્યો અને ધરતીને તૃપ્ત કરી. ચાત્રાનો સંઘ દોઢ મહિને જુનાગઢ આવ્યો ને ઘનો ભગત નિત્ય નિયમ પ્રમાણે ગિરનારમાં ગુરુદર્શને ગયા.
                                                    ‘ઘના ! ચોમાસું વીતવા આવ્યું, ખેતરમાં વાવેતર ?' રામતવને મોંઘમ વાતથી પૂછ્યું.
                                                    ‘બાપુ ! ખેડ્યા વિનાનું ખેતર રહ્યું અને વાવે નો સમય તો જોતજોતામાં જાતમાં વીત્યો !' ધનાએ કહ્યું. ‘ભગત ! હજી વખત છે. વાવણી કરી ને !' ગુરુએ સૂચવ્યું.
                                                    દોહા
                                                    ચોમાસું અર્ધું ગયું, રહ્યું બાજરિયું અન્ન,
                                                    ઘઉં વપરાયા સંઘમાં, મૂંઝાયે છે. મન.
                                                    ઘરમાં ઘઉં સાવ ન હતા. માત્ર થોડીક બાજરી હતી. તેથી તે ગુરુને જણાવવામાં મૂંઝાતો હતો, પણ એના મનને હતાશ થયેલું જોઈ ગુરુ તેના મનની બધી અકળામણ
                                                    સમજી ગયા.
                                                    દોહા
                                                    રામેતવન આદેશ દે, નવ થા ધના હતાશ,
                                                    પ્રભુ રામદેવપીર પર, રાખ પૂરો વિશ્વાસ.
                                                    ધના ! તું તો ભગવાન રામદેવજીનો પૂર્ણ ભગત છું. તારા શુદ્ધ ભક્તિભાવને જાણીને મેં તને ગુરુદીક્ષા આપી ચેલો કર્યો છે. તારા મનમાં જે મૂંઝવણ હોય તે કહે ?' રામતવને કહ્યું.

                                                    ચોપાઈ
                                                    કારણ મૂંઝવણનું સમજાવ્યું, ઘઉં બીજ ન મળે શું હું વાવું ?
                                                    ને કહી ભગતે વિગત જ્યારે, ગુરુ દેતા આશ્વાસન ત્યારે.
                                                    ભગતે અંત૨ની વાત ગુરુને કહી એટલે ગુરુએ કહ્યું :

                                                </p>
                                                <p className="paragraph-content">
                                                    રેણું રામદે ચરણની, દઉં તુજને આ વાર,
                                                    કર
                                                    વાવેતર ખંતથી, ખેતરની મોઝાર,
                                                    શ્રી રામદેવ બાળપણમાં દરબારગઢના ચોકમાં રમત કરતાં હતા. એમના ચરાની રેણું તને આપું એ લઈ જા, બીજી રેતીમાં ભેળવી દે અને રામદેવજીનું ડો
                                                    પગલે સ્મરણ કરી ઓરણીમાં એ રેતી ઓરજે.
                                                    ચોપાઈ
                                                    રેણું ઝોળી ગુરુએ લીધી, પ્રેમથી ધના ભગતને દીધી, મુજ વચનો નિશ્ચયે પળાશે, વેળું ઊગીને ઘઉંલા થાશે. આવ્યા. બીજે દિવસે વહેલા ઊઠી રામદેવજીની પૂજા કરીને પટલાણીએ પ્રસાદ ગુરુએ પ્રેમપૂર્વક રેણુંની ઝોળી આપી. ધનો ભગત ખુશખુશાલ થતા ઘેર બળદને ખેતર લઈ જવા જોતર્યા. ખેતર તરફ જતાં ગામના ચોરે બેઠેલા માણસો. ધરાવ્યો, બળદને ચાંદલા કર્યા; ગાયને ચાંદલો કરી પગે લાગી અને ઓરણી જેથ આશ્ચર્ય પામી બોલ્યા : ‘ભગત ! આ શું ? રહી રહીને જાગ્યા ?’
                                                    એક માણસે પડખે ચડી ખડિયો તપાસતાં વેળું દીઠી અને હસતાં હસતાં ચોરે બેઠેલા માણસોને ઊંચા અવાજે કહેવા લાગ્યો ઃ ‘ખેતરમાં ધુળ ઢેફાં છે, એમાં વેળુંનો ઉમેરો કરવા જાય છે ! ખડિયામાં વેળું છે, ઘઉં નથી !' ચોરે બેઠેલા બોલ્યા : ‘ડાગળી ખસી લાગે છે ? ભગતી ભારે પડી ! ઓરણી શરમાય છે. પાછા વળો, રેતી વાવીને પથરા ઉગાડશો ?’ બધા ખડખડાટ હસી પડ્યા પણ ભગત નામસ્મરણની ધૂનમાં ને ધૂનમાં કાંઈ પણ જવાબ આપ્યા સિવાય ખેતરે ગયા અને શ્વાસોચ્છ્વાસે રામદેવજીનું રટણ કરતા વિશ્વાસથી વાવણી કરીને ઘેર આવ્યા.
                                                    લોકો શું કહે છે ? તે પ્રત્યે તેમનું જરાકે ધ્યાન ન હતું.
                                                    ‘ધના ભગતે વેળું વાળી !' ગામમાં વાત ફેલાઈ ગઈ. કોઈ કહે ઃ ખસ્યું ! કોઈ કહે ભગતીએ ભાન ભુલાવ્યું. કોઈ કહે : ધનો ભગત ગાય છે કે રામબાણ વાગ્યાં હોય એ જાણે, ભગતને બાણ વાગ્યું અને ચસક્યું. આવી વાતો ઠેર ઠેર થવા લાગી.
                                                    થોડાક દિવસ વિત્યા ને ખેતરમાં ઘઉં, જુવાર અને બાજરીના છોડ ઊગી નીકળ્યા. જોતજોતામાં વાવેતર ફાલવા ફૂલવા લાગ્યું અને સૌથી સવાઈ ખેતી થઈ. ધના ભગતે ઘઉંનાં ગાડાં ભરી ભરીને ઘેર લાવવા માંડ્યાં, ત્યારે ગામના લોકો મોઢામાં આંગળા નાખી ગયા ! અને ધના ભગતને પૂજ્ય માની સૌ એમને વંદન
                                                    કરવા લાગ્યા.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails30;
