import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails29 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery31.jpg";
    return (
        <>
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}
 <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      

            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                   
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                          
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            ધાનદાતા ધીંગારામ
                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                    
                                                >
                                                    <span className="income">ધાનદાતા ધીંગારામ

                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content">
                                                    લખો આહીર નેકટેક અને નિમાધારી બની દરેક માસની પૂનમે રણુંજાતીર્થધામ જતો. ત્યાં ધૂપ-દીપ કરી ધજા ચડાવી અને રામદેવજીની સમાધિને પૂજી ધૂપભસ્મ લઈ પાછો આવતો. નિયમમાં એ અડગ હતો એટલે એ પૂનમ ભરવા ગયો. લખાની પત્ની નવેગામ હટાણે ચીજવસ્તુઓ ખરીદવા ગઈ.
                                                    ધીંગાની ઉંમર સોળ વરસની થઈ. એ રામદેજીનો પરમ ઉપાસક અજપા જાપવાળો હોવાથી એને ઘીંગારામના નામથી સહુ સંબોધતા હતા. તેની માતાએ બપોરે ખાવા માટે ઘડી રાખેલો રોટલો અને દૂધ તથા દહીંનો વાટકો લઈને તેઓ ખાવા ઊઠ્યા એ વખતે...
                                                    ચોપાઈ
                                                    વૃદ્ધા સ્ત્રી થર થરતા દેહે, આવી લખા ભગતના નેહે,
                                                    આંખે ઝાંખું ઝાંખું દેખે, ધ્યાનેથી ધીંગાને પેખે.
                                                    એક અતિ વયોવૃદ્ધ ડોસી ઝાંખું ઝાંખું દેખતી હાથમાં લાકડીના ટેકે નેસ પાસે આવી. એણે ધીંગાને પૂછ્યું :
                                                    ‘દીકરા ! બેટા ! લખા ભગતના નેસે જવું છે. એ નેસ કેટલે છેટે છે ?'
                                                    વૃદ્ધાનો અવાજ સાંભળી ધીંગો બહાર આવીને બોલ્યો :
                                                    *મા ! માડી ! આજ નેસ લખા ભગતનો ! આવો બેસો.' ધીંગાએ લાકડી ઝાલી
                                                    વૃદ્ધાને નેસમાં લઈ જઈ પાથરણા પર બેસાડ્યાં અને પાણી ધર્યું.
                                                    ‘બેટા ! તારું નામ શું ?’ ડોશીએ પૂછ્યું.
                                                    ‘ઘીંગો.’
                                                    ‘દીકરા ! હું ઘડપણથી ઘેરાણી છું. સગાંસંબંધી કોઈ નથી. શરીરે અશક્ત છું.
                                                    હું ત્રણ દિવસથી ભૂખી છું. તારા ઘરમાં કોઈ ટાઢો શીળો રોટલો હોય તો મને લાવી
                                                    દે. લખા ભગતના નેહે ટુકડો રોટલો મળશે એ આશાએ હું અહીં આવી છું.' વૃદ્ધાએ
                                                    કહ્યું .
                                                    વૃદ્ધાની દીનવાણી સાંભળતાં ઘીંગાનું હૃદય દ્રવી ગયું અને પોતાને માટે ઢાંકી

                                                </p>
                                                <p className="paragraph-content">
                                                    રાખેલો, રોટલો દૂધ, દહીં વગેરે લાવી વૃદ્ધા પાસે થર્યું.
                                                    ‘મા ! ઘી અને ગોળ નાખી રોટલો ચોળી દઉં ? શાંતિથી ખાવ.' ધીંગાએ ક ‘દીકરા ! ઘી-ગોળની જરૂર નથી. પેટ પોષણ માટે જ્યાં ત્યાં કરીને જીવનનિર્વાહ કરું છું. ભાણામાંથી અર્ધો રોટલો લઈ લે.' વૃદ્ધાએ કહ્યું. ‘મા ! ભાવે એટલું ધરાઈને ખાવ, પછી હું તમને તમારા ઠેકાણે મૂકી જમ
                                                    અને રોજ ખાવાનું આપી જઈશ.' ધીંગે કહ્યું.
                                                    “બેટા ! મારે કોઈ ઠેકાણું નથી. જ્યાં ત્યાં પડી રહું છું.' વૃદ્ધાએ કહ્યું. ‘માડી ! હવે અહીં જ રહો. તમને મનભાવતું ખાવાનું કરી દઈશું. માર
                                                    મા આવશે. તમને જોઈને રાજી થશે. તમારી સેવાનો અમને લાભ મળશે. આ નેસમાં જુઓ બે દૂઝણી ગાયો છે, સામે ભગવાન રામદેવજી મહારાજનું સ્થાનક છે. સવાર- સાંજ જ્યોત, ધૂપ આરતી કરીએ છીએ.
                                                    તુલસીક્યારો છે, પડાળીમાં સાધુ, સંત, ભક્તોનો ઉતારો છે. વાડામાં ઘેટાં- બકરાં છે. અહીં તમે ગમે તે ઠેકાણે રહો. તમારી જીવનભર સેવા કરવા હું હરદમ તૈયાર રહીશ.’ ધીંગાએ આગ્રહપૂર્વક જણાવ્યું. વૃદ્ધાએ ભાણામાંથી રોટલાનું બટકું લઈ મોંમાં મૂક્યું અને દૂધ પીધું. બીજું બટકું લઈ દહીંમાં બોળી ખાઈને પાણી પીધું. ધરાઈને તૃપ્ત થવાના ઓડકાર આવવા લાગ્યા. લાકડીનાં ટેકે ઊભાં થઈ ગયાં અને ઘીંગાના માથે હાથ મૂકી બોલ્યાં :
                                                    ‘બેટા ઘીંગારામ ! તું રામદેવજી મહારાજનો કૃપાપાત્ર ભક્ત છું. મને ભગવાન રામદેવજીની આજ્ઞા થતાં હું અહીં આવી છું.' એમ ઉચ્ચારી પોતાનું દિવ્ય સ્વરૂપ ધારણ કર્યું.
                                                    ચોપાઈ
                                                    દઉં વરદાન જાય નહીં ખાલી, છું મા અન્નપૂર્ણા મતવાલી,
                                                    અન્ન આપજે ઉદાર થઈને, ભરજે બીજ રણુંજા જઈને. ‘બેટા ! ઘીંગારામ ! તારા વિચાર, વિનય, વિવેક, વાણી અને પૂર્ણભાવ ભરી ભક્તિથી હું પ્રસન્ન થઈ વરદાન આપું છું કે, તું દર માસની સુદી બીજને દિવસે રણુંજા જજે અને ભગવાન રામદેવજીની સમાધિનાં દર્શન કરજે. તારે કોઈ સ્થળે
                                                    ભીડ પડે તો ભગવાન તારી સહાયે આવશે.
                                                    પણ તારી સહાયે રહીશ.'

                                                </p>
                                                <p className="paragraph-content">
                                                    ધાન્ય તણા ભંડાર ભરાશે, દીનનો અન્નદાતા કહેવાશે,
                                                    ધૂપ ધજા લઈ તું જ્યાં જાયે, પીર રામદે' રહેશે સહાયે. બેટા ! મારા વચન પર વિશ્વાસ રાખી, તું ધર્મધજા લઈ ધર્મોપદેશ માટે ગામોગામ પ્રયત્ન કરજે અને સવાર- સાંજ ધૂપ કરી ધજાની પૂજા કરજે.
                                                    દોહા
                                                    અન્નપૂર્ણા મા જોઈને, ઘીંગો પડતો પાય,
                                                    રૂપ બતાવી નિજનું, માતા અદૃશ્ય થાય.
                                                    ઘીંગો માતાજીનાં ચરણે નમ્યો. માતા અલોપ થયાં. માતાને ભાણામાં પીરસેલો રોટલો તેણે ભાંગ્યા વિનાનો અખંડીત દીઠો, દૂધ- દહીંનાં વાડકા પણ જેમ ને તેમ હતાં એવાં ભરેલાં દીઠાં. ઘીંગો રોટલાનું ભાણું ઢાંકીને ઊંડા વિચારમાં પડ્યો. માતાજીનું વૃદ્ધારૂપ અને દિવ્ય સ્વરૂપ એની સામે તરવરવા લાગ્યું.
                                                    નવેગામથી ચીજવસ્તુ વહોરીને ધીંગાની મા આવી. રણુંજાથી લખો ભગત આવ્યા. ઘીંગાએ સવિસ્તાર વાત કરી. રોટલાનું ભાથું બતાવ્યું. ધાબળો સંકેલીને પાથરણું રાખેલું બતાવ્યું. પતિપત્ની વાત સાંભળી આશ્ચર્ય પામ્યાં અને રોટલાને, પાથરણાંને સ્પર્શ કરી નમન કરવા લાગ્યાં. પોતે દર્શનનો લાભ મેળવી ના શકવાથી સહજ શ્રદ્ધાથી માતાજીને બેસવાના પાથરણાં કામળીને પૂજામાં રાખી. રોટલો અને દહીંને પ્રસાદ ગણી ત્રણે જણાએ પ્રેમપૂર્વક ખાધો.
                                                    ઘીંગાએ જાગામીટા ઊંઘમાં રાતે ભણકાર સાંભળ્યોઃ ‘રણુંજા સમાધિને પૂજી, ધૂપ-ભસ્મ લઈ હ૨ભુજી પાસે જા !'

                                                    ધીંગાએ સાંભળેલા ભણકારમાં થયેલી આજ્ઞાની વાત કરી. માતા-પિતાને નમન કરી રણુંજા જઈ ધૂપદીપ કરી સમાધિની પૂજા કરી ધૂપની ભસ્મ પોતાની અલખઝોળીમાં લીધી અને ત્યાંથી તેઓ હરભુજી પાસે આવ્યા.
                                                    ધીંગારામે હરભુજીને દેવી અન્નપૂર્ણાના પ્રાગટ્ય અને વરદાનની વાત કરી. ઘીંગારામ પર શ્રી રામદેવજીની કૃપા હોવાથી અન્નપૂર્ણાને એમણે જ મોકલેલ છે. એમ જણાવ્યું અને ધીંગારામને કહ્યું કે હવે તમે પ્રવાસી બની લોકોને ધર્મની મહત્તા સમજાવો, સ્થળે સ્થળે ધર્મધજા ફરકાવો. એમ આશા કરી, આદેશ આપી ઘીંગારામને ધજા, ધૂપ, અલખઝોળી અને ભસ્મ આપી.

                                                </p>
                                                <p className="paragraph-content">
                                                    ચોપાઈ
                                                    લખો ઉઘથી જાગૃત થાયે, પરોઢિયે નહાવાને જાય, પાંચ ગુણ અન્ન પડેલ દ્વારે, દેખીને થયું અચરજ ભારે નેસનો ઝાંપો ઉધાડતા ઘઉં, બાજરી, જુવાર, ચોખા અને મગથી ભરેલા પાંચ પાંગ નિત્યનિયમ મુજબ પરોઢિયે ઊઠી દાતણ લઈ તળાવ નહાવા જતા લેખો બને મંણના પાંચ થેલા પડેલાં દીઠા. લખો આશ્ચર્યમુગ્ધ થયો. પત્નીને ઊંચા અવાજે
                                                    બોલાવી.
                                                    ચોપાઈ
                                                    અનાજના જોઈને થેલા, પતિ પત્ની થયાં હરખે ઘેલાં,
                                                    જય રામદેવપીર આલમરાજા, આપ કૃપાએ મળ્યા અનાજા હે ભગવાન ! રામદેવજી મહારાજ ! આલમરાજા નકળંગનાથ ! આપની કૃપા અમારી ઉપર ઊતરી, આપે અન્નપૂર્ણામાતાને આશા કરાથી અનાજ મોલ્યું
                                                    અન્નદાતા ! એમ કહી હર્ષઘેલાં બની ગયાં.
                                                    ધીંગારામને સામાં આવતાં માતાપિતાએ પાંચે થેલા બતાવ્યા. દેવીનું વરદાન, ગુરુઆદેશ, નિઃસંકોચ અન્નદાન કરવાની આાથી પોતાના પર શ્રી રામદેવજી મહારાજની પૂર્ણ કૃપા થઈ છે. એવું મનમાં દૃઢ થઈ ગયું અને બોલ્યા : ચોપાઈ
                                                    રામદેવજી ભક્તોના ત્રાતા, આજ્ઞાંકિત અન્નપૂર્ણા માતા, નિષ્કામે જે ભક્તિ કરતા, અલખ ધણી છે અભરણ ભરતા. ઘીંગારામ કહે પિતા માતા, અલખ ધણી ઇચ્છીત ફળદાતા,
                                                    પાંચે થેલા અવંગ રાખો, કોઠારી થઈ શ્રદ્ધા રાખો.
                                                    ‘પિતાજી ! હવે ઇચ્છા પ્રમાણે સહાય કરવા અને નિષ્કામ કર્મને સફળ કરવા અલખ ધણી શ્રી રામદેવજીએ આપણા પર પૂર્ણ કૃપા કરી છે. એટલે એમની પ્રસન્નતાની પ્રસાદી એ પાંચે થેલા છે. તે અવંગ રાખી એનું ગુરુઆજ્ઞા પ્રમાણે કરીશું. એમ જણાવી પાંચે થેલા ઉપર દેવી અન્નપૂર્ણાને બેસવા દીધેલા પાથરણાં
                                                    વાળી દઈ ધાબળી ઢાંકી.
                                                    દોહા
                                                    1981 પાંચે થેલા પૂજીયા, મૂક્યા ઘરની માંય, અવંગ થેલા રાખવા, ઘાબળિયે ઢંકાય.

                                                </p>
                                                <p className="paragraph-content">
                                                    પાંચે થેલાને પૂજીને ઘરમાં મૂક્યા. તેના ઉપર ધાબળી ઢાંકી ત્રણે જણાએ પ્રભુનો પાડ માન્યો. લખા ભગતનો નેશ રામદરબાર'થી જાણીતો થયો. શ્રી રામદેવજીનું મંદિર હોવાથી એ ‘રામદરબારમાં’ રોજ સવાર સાંજ માણસો
                                                    આવતાં જતાં.
                                                    હરભુજીના નવાગામના પ્રયાણ અને ગાયના પુનર્જીવનના પરચાથી પ્રભાવિત થઈ ગયેલો વેદો ગામોટ રોજ દર્શને આવતો હતો. એણે ધીંગારામના પ્રયાણની વાત સાંભળી પોતાને સેવક તરીકે સાથે લેવા આગ્રહ ભરી વિનંતી કરી. ઘીંગારામ સાથે પ્રવાસમાં નવાગામવાળા ચાર સેવકો આવ્યા. તેઓ એકાગાડી – એક બળદની ગાડી તૈયાર કરી ચાલી નીકળ્યા અને નવાગામમાં આવ્યાં.
                                                    ઘીંગાને દે ધાન, શંકુથી રૂઠ્યો કરે ?
                                                    તુજ પાસે નથી પ્રધાન, કોણ સમજાવે શામળા !
                                                    ઘીંગારામની ઘેર ઘેર પધરામણી થવા લાગી. સમસ્ત ગામની વસ્તી શ્રી રામદેવજીની ભક્તિમાં વળી.
                                                    ‘ભાઈ ! રાજમલ ભગતનું ઘર બતાવશો ?' વહેલી સવારમાં ગામને દરવાજે આવી દરવાનને એક અજાણ્યા ગાડીવાને પૂછ્યું.
                                                    ‘સામા ચોકમાં ઘોળી ધજા ફરકે છે. એ રાજમલ ભગતનું ઘર.' દરવાને જણાવ્યું.
                                                    ગાડીવાન ગાડું હંકારી ત્યાં આવ્યો. ડેલીનું બારણું બંધ હોવાથી બહારની સાંકળ ખખડાવી, બારણું ઊઘડતાં ગાડીવાને પૂછ્યું : 'આ ઘર રાજમલ ભગતનું છે ? અહીં ધીંગા ભગત આવ્યા છે ?'
                                                    ‘હા ! આવો અંદર !' રાજમલે ડેલી ઉઘાડી. ગાડું અંદર આવ્યું. ગાડીવાન બોલ્યો :
                                                    ‘આ ગાડામાં અનાજની ગુણો છે. તે ઘીંગા ભગતની છે.’ એમ કહી ગુણો ઉતારી ગાડું ડેલી બહાર લીધું. ધીંગારામ ત્યાં આવ્યા અને ગુણો દીઠી. ગાડાવાળાને પૂછતાં એણે જણાવ્યું કે મેવાસાના ગણેશ૨ામે ગુણો મોકલી છે અને કહ્યું છે કે, જેવા ઉદાર છો તેવા રહેજો.' આટલું કહી ગાડીવાન ત્યાંથી ગાડું હાંકી વિદાય થયો. ગાડીવાનના ગયા પછી વિચાર કરી ધીંગા ભગત બોલ્યા : ‘આ અનાજ નબળી સ્થિતિવાળાને વહેંચી દો.'
                                                    વેદો ગામોટ અને રાજમલ ભગતે પોતાના ગામની બહાર રહેતા લોકોને કે

                                                </p>
                                                <p className="paragraph-content">
                                                    જેમને અશના સાંસાં હતા. તેમને ઘેર બોલાવી અનાજ આપ્યું. મા અનાજની વહેચણી થઈ પણ અનાજના થેલા હતા તેવા જ ભરેલા જો જન્મ ને રાજમલ આશ્ચર્યમાં ગરક થઈ ગયા ! કુતૂહલથી તેમણે ધીંગારામને પૂછ્યું : 'બાપુ ! આપની આજ્ઞાથી અનાજ વહેંચ્યું. ઘણાં માણસો લઈ ગયાં છતાં ધંગ થેલામાં હજી હતું એટલું જ અનાજ છે. આ પરચો કે ચમત્કાર ?' 'અનાજ નામ હાથે જ વહેંચ્યું છે. એ ચમત્કાર કે પરચો જે ગણો તે. તાગ લેવા માંડશો તો તળિયું નહીં મેળવી શકો. આવેલો માલ મારો નથી. ગણેશરામે મોકલ્યો છે. જે
                                                    સાગનું
                                                    પરહિત માટેનો છે. થેલા ભરાય કે ઠલવાય એની ચિંતા રાખશો નહીં. જેસલમેરના સીમાડા પર સેલાણીશા નામે ફકીરનો તકિયો હતો. એ પીરબાવા નામે ઓળખાતો હતો. એને બે સેવકો હતા. એકનું નામ ચાંપો અને બીજાનું નામ કુંપો. નાતે સબર, વગડામાં વટેમાર્ગુને લાગ આવતાં લૂંટવામાં એ પાકા થઇ ગયા
                                                    હતા.
                                                    ચોપાઈ
                                                    ચાંપો કંપો સબરા જાતી, વગડે સગડે ફરે દિન રાતી, વાટવડાને લૂંટી જાયે, દયા લેશ નહીં દિલ માંગે, જાતે હિંદુ પણ પરધરમી, લૂંટારા એ કુડા કરમી, કૂંચે ફરતા ચારે દિશા, માલ સંઘરે સેલાણીશા. સેલાણીશાના બે સેવક હિન્દુ હોવા છતાં એ પરધર્મ તરફ વળ્યા. સેલાણીશા નામના ફકીરને પીરબાવા નામથી ઓળખાવતા. વગડે વટેમાર્ગુને લૂંટીને માલ પડાવી લે. અને સેલાણીશાને સાચવવા આપી જાય. લૂંટનો માલ સંઘરવા અને જેસલમેરથી જતાં માણસોની ખબર પહોંચાડવા સેલાણીશાએ પોતાના માણસોને ગામમાં ઘેર ઘેર ભિક્ષા માગવાના બહાને રોકયા હતા. એ ફકીરો બાતમી મેળવી ખબર આપતા હતા.
                                                    1
                                                    જેસલમેરની પ્રજા બે મતમાં વહેંચાઈ હતી.
                                                    ચોપાઈ
                                                    જેસલમેર નગર મુકામે, રાજાજી બલરાજને નામે,
                                                    ધરે ધર્મના ભેદને ભ્રાંતિ, શંકાશીલ હૃદયે નહીં શાંતિ.
                                                    જેસલમે૨નો બલરાજ નામનો રાજાધર્મ-અધર્મની ખરીખોટી બાબત શોધવામાં પડ્યો હતો. એને ધર્મમાં સત્યાસત્યની શોધ કરવામાં રસ હતો. એનું હૃદય

                                                </p>
                                                <p className="paragraph-content">
                                                    શંકાશીલ હતું. મન પર શાંતિ ન હતી. સેલાણીશા દર શુક્રવારે રોજ કચેરીમાં આવતા અને ગામમાં ચોરે, મંદિર કે ધર્મશાળામાં ઊતરેલા સાધુ, સંત, ભક્તને કચેરીમાં લાવીને અને સેલાણીશા સામે ધર્મવાદમાં ઉતારતા.
                                                    ચોપાઈ
                                                    થાય સભામાં ધર્મવિવાદા, વડછડ વાદ વધે વિખવાદા,
                                                    રાજા પરમ ઘરમા ઘરમી, ભ્રાન્તિ ભેદ ચલાચલ ભરમી. સભામાં ધર્મચર્ચા થાય, સેલાણીશા અને પ્રતિપક્ષ વાદવિવાદમાં ઊતરે વછ થાય અને વિખવાદ વધે ત્યાં સુધી ચર્ચા ચાલે, એવું ઘણાં વરસથી ચાલતું. એમાં રાજાને ખબર મળી કે મારવાડથી ધીંગારામ ભગત જે ધાનદાતા ઘીંગારામ નામથી પ્રસિદ્ધ છે, તે જેસલમેર ગામથી અહીં આવવા નીકળ્યા છે.
                                                    ‘ધીંગારામ ! ઘાનદાતા ! અહીં આવે છે ? એ કોણ છે ?' રાજાએ પૂછ્યું.
                                                    ‘મહારાજ ! એ તો ધીંગારામ છે. અન્ન કોઠારી, ભંડારી, દાતાર, પરચાધારી એવા સિદ્ધિવાળા ભગત છે. ઠેર ઠેર અનાજ વહેંચતા આવે છે. કોઈ ખાવ, કોઈ લઈ જાવ, ભૂખ્યા રહેશો નહીં. એવી રીતે ઉચ્ચાર કરતા હોય છે.' ખબર આપનારે સ્પષ્ટ વાત કરી. આ સાંભળી રાજાને આશ્ચર્ય થયું.
                                                    આ વાત સેલાણીશાએ સાંભળી. તીએ આવી ચાંપા-કુંપાને વાત કરીને સૂચના આપી કે માર્ગ રૂંધો અને ઘીંગારામ નામના ભગતને લૂંટી લો ! એના ગાડામાંથી માલ મેળવી અહીં મૂકી જાવ. સેલાણીશાની સૂચનાથી ચાંપો- કુંપો માર્ગમાં અડ્ડો જમાવી બેઠા.
                                                    ગાડાને વાંસની ખપાટો વાળી શણ મઢીને કરેલો ઘુમટ ઉપર માથે ધોળી ધજા ફરકે છે. ગાડામાં અનાજના ઘેલા છે. ધીંગારામ અને સાથીદારો પગપાળા ચાલતા આવે છે. દૂરથી જેસલમેરનો રાજમહેલ જણાયો. માર્ગ રૂંધી બેઠેલા ચાંપો, કુંપો અને સાથીદારો ઊંટ સવારી કરી આવ્યા અને પડકાર કર્યો :
                                                    ‘ક્યાંથી આવો છો ! ક્યાં જવું છે ?' ચાંપો બોલ્યો. ‘અમે મારવાડ રહીએ છીએ અને વાગડપ્રદેશમાં જવા નીકળ્યા છીએ. જેસલમેરમાં બે દિવસ રોકાવાના છીએ.' વેદા ગામોટે કહ્યું.

                                                </p>
                                                <p className="paragraph-content">
                                                    ‘ઉપાડી લો થેલા !’ ચાંપાએ કહેતાં પાંચે ઊંટસવારોએ ગાડામાંથી ઘેલા કે
                                                    ઊંટ પર મૂક્યા.
                                                    ‘ભાઈ ! તમારું નામ શું ?' ધીંગારામે પૂછ્યું.
                                                    ‘ચાંપો અને કુંપો !’ ચાંપે કહ્યું,
                                                    ‘ચાંપા ભગત ! વધુ અનાજની જરૂર પડે તો બે દિવસ જેસલમેરમાં અમારો ઉતારો રહેશે, ત્યાં આવીને લઈ જજો. જય રામદેવજી !' કહી ધીંગારામે હંકારવા માંડ્યું અને જેસલમેરના દરવાજે આવેલા શંકરના દેવળમાં ઉતારો કર્યો.
                                                    ‘બાપુ ! એ કોઈ ભક્ત નહોતા. લૂંટારા હતા.' રાજમલે કહ્યું.
                                                    ગા
                                                    ‘ભાઈ ! લૂંટારા હશે પણ અશ તો ખાશે ને ? એમને ભગવાન રામદેવજીએ આપણી પરીક્ષા કરવા મોકલ્યા હોય એમ ગો !' ધીંગારામે મોટા મનથી કહ્યું. ચાંપો, કંપો અને સાથીદારો સેલાશીશા પાસે આવ્યા. ઊંટ પરથી પાંચે થેલ નીચે મૂકતાં ચાંપો બોલ્યો : ‘બાપુ ! આજે ભગતડો ભંગાણો ! ઘઉં ભરેલા થેલ ઉપાડી લાવ્યા. જુઓ !' થેલામાં હાથ નાખી ઘઉંની મૂઠી ભરતાં ચાંપો ચોંકી ગયો.
                                                    વિસ્મય થયો !'
                                                    ચોપાઈ
                                                    ભગતડો આજે ભંગાણો, રેવા ન દીધો અન્નનો દાણો,
                                                    ગુણો ઉતારી નીચે મેલે, ધૂળનાં ઢેફાં દીઠાં થેલે. પાંચે થેલામાં ધૂળ અને ઢેફાં જોઈ બધા હેરત પામી ગયા ! સેલાણીશા દિગ્મૂઢ બની ગયો. લૂંટારા ખુશ થતા હતા એ ખસિયાણા પડી ગયા. બીજે દિવસે શુક્રવાર હોવાથી નિયમ પ્રમાણે સેલાણીશા અને ચાંપો- કુંપો રાજકચેરીમાં આવ્યા. ઘીંગારામ પીર ગણાતા હોવાથી એમની સાથે ધર્મચર્ચા કરવા ધીંગારામને રાજાએ તેડાવ્યા. ધીંગારામનું પુષ્ટ શરીર, ભરાવદાર મૂછો, તેજસ્વી આંખો, બેવડબાંધો, ઊંચાઈવાળું અંગ, ડોકમાં માળા, કાનમાં બૂટિયાં, કુન્ડલ, કડીઓ અને માથે કેસરી પટો બાંધેલા ઘીંગારામને જોતાં રાજા પ્રભાવિત થયો.
                                                    ‘તમે પી૨ છો કે ભગત છો ?' રાજાએ પૂછ્યું. ‘રાજન્, હું માનવસેવક છું.’ ઘીંગારામે કહ્યું, “શા કારણે ફરવા નીકળ્યા છો ?' સેલાણીશાએ પૂછ્યું. ‘ધર્મ ભૂલેલાને સ્વધર્મ સમજાવવા.' ધીંગારામે સ્પષ્ટતા કરી.
                                                    ‘હે રાજા ! આ શરીર ચર્મનું છે. તેની કથા એટલે ગુદરીનો આશ્રમ લઈ આત્મા બિરાજે છે અને નિમિત્ત પૂરું થતાં ચાલ્યો જાય છે. એવી ઘટમાળમાં પરિભ્રમણ

                                                </p>
                                                <p className="paragraph-content">
                                                    કરનાર આપણે અને દરેક માનવોનો જન્મ-મરણનો એક જ પંથ છે. એમાં ધર્મ, અધર્મ, ભ્રાંતિ અને ભેદ એ તો વિકાર છે. ભવરોગ છે. એટલે માનવ માનવ પ્રત્યે સમાનતા રાખે ત્યારે સુખશાન્તિ મળે છે.
                                                    હે રાજા ! આજે તમે બધા શાંતિથી સાંભળો છો એથી મને આનંદ થાય છે. ધર્માધર્મની ભાંજગડમાં જીવન વિતાવવાથી સ્વધર્મનો કેડો જડે નહીં. વાદ, વિવાદ એ તો વિખવાદ વધારનારા હોય છે. વારીમાં વલોણું મૂકવા જેવું હોવાથી મિથ્યાવાદે વખત ગુમાવશો નહીં.
                                                    નયનો બે છે પણ સમયે દૃષ્ટિમાં દૃષ્ટિ એક થાય છે. બે આંખોની બે જુદી જુદી દૃષ્ટિ હોય તો બે વસ્તુ ગોચર થાત. જીવાત્મા શિવાત્માની એક જ સૃષ્ટિ છે. જીવાત્મા કર્મબંધનથી અવતાર લઈ ભવમાં ભોગવટાવાળો બને છે. એ સંસારના સાર-અસારમાં અથડાય છે.
                                                    ચોપાઈ
                                                    કણ, ફળ, પર્ણ વિવિધ વનરાઈ, જીવાત્મા કાજે સર્જાઈ,
                                                    પાંચ તત્ત્વ જે સ્થૂળ સ્થળવંતા, જુગતી જાણે સાધુ સંતા.
                                                    'વાહ ! ધન્ય છે ! ઘીંગારામ ! તમારા જ્ઞાન, બોધ, ઉપદેશ અને ધર્મઅધર્મની સત્ય સમજૂતીભરી અમૃતવાણી સાંભળી મને ખરું માર્ગદર્શન થયું છે.' રાજાએ બે હાથજોડી વંદન કર્યાં.
                                                    ચોપાઈ
                                                    વિનય, વચન, વિવેક ભરી વાણી, સ્વધર્મની સમશ્યા સમજાણી,
                                                    કરી વંદન નમાવે શીશા, ક્રોધિત થાતો સેલાણીશા. રાજા અને પ્રજાએ ધીંગારામની દિવ્યવાણી સાંભળી સર્વે મુગ્ધ બની ગયા ! રાજા બે હાથ જોડી વંદન કરી ધન્યવાદ આપીને ધીંગારામને શીશ નમાવતાં પાસે બેઠેલા સેલાણીશાને તેજોદ્વેષ થયો અને રાજાનો હ્રદયપલટો થવાથી હવે વર્ચસ્વ નહીં રહે એમ સમજી ધીંગારામ પર ક્રોધિત થયો અને બોલ્યો ઃ રાજાધિરાજ ! આ તો હજી જ્ઞાન-અજ્ઞાનમાં ઊછરતો બાલક છે. બોલવામાં ચતુર છે પણ ધર્મતત્ત્વને એ શું જાણે ?
                                                    દોહા
                                                    બાળબુદ્ધિથી બોલતો, એને શું હોય શાન, નિરાકાર સાકારમાં, કોણ દેવ છે મહાન

                                                </p>
                                                <p className="paragraph-content">
                                                    ‘પીરબાવા ! સેલાણીશા પાસે હું તો બાલક છું. એમની કક્ષાએ હું નથી પણ...
                                                    ચોપાઈ
                                                    સેલાણીશા પીર મનાવે, ધર્મ અધર્મ એ સમજાવે, પાંચે ગુણોને સંઘરતા, ગુરુ થઈ જ્ઞાન બોધ કરતા.
                                                    પાંચે ગુણો સંઘરતા.' આ શબ્દો સાંભળતાં સેલાણીશા ચોંકી ગયા ! ચાંપ કુંપે લૂંટેલી ઘઉંની ગુણોને પોતે સંઘરી છે, એ વાત ધીંગારામે કોઈ પાસેથી જાણી લીધી. એમ ધારીને પૂરકીને બોલ્યો ઃ
                                                    ‘મેં પાંચ ગુણો સંધરી છે ? તેમને કોણે કહ્યું ? એ ગુણોમાં ઘઉં હતાં ?” સેલાણીશા બોલવામાં ભૂલ કરવા લાગ્યા. આ જોઈ રાજા અને સભાજનો
                                                    દિગ્મૂઢ બની ગયા !
                                                    જ્યાં નિરવ શાંતિથી ધર્મની વાત થતી હતી ત્યાં અશાંતિ, ભ્રાન્તિ અને ખળભળાટ થયો. રાજાએ વાતનો નીવેડો લેવા, વાતનું ખરું રહસ્ય મેળવવા સેલાન્નીશાના સામે જોઈ પૂછ્યું : ‘બાપુ ! ગાભરા કેમ બન્યા ? પાંચે ગુણ એ શું ! સેલાણીશા સમજ્યા કે રાજા હઠીલો છે. ભ્રાન્તિવાળો છે. ખરી વાત નહીં કહું
                                                    તો ધીંગારામ કહેશે. એમ ધારીને કહ્યું :
                                                    ‘મહારાજ ! પાંચ ગુણો ધૂળ- ઢેફાં ભરેલી છે. એ ગુણો ચાંપો અને કંપો મૂકી ગયા છે. ધીંગારામની પાંચે ગુણોની ધૂળનો સંઘરો હું શા માટે કરું ? ચાંપો- કંપો બેઠા છે તેમને પૂછો.' રાજાએ તરત માણસો મોકલી પાંચ ગુણો મંગાવી. માણસો ગુણો લઈને હાજર થયા.
                                                    ‘ચાંપા ! આ ગુણો તારી છે ?' રાજાએ પૂછ્યું. ‘ના મહારાજ ! ચાંપાએ ધ્રૂજતા અને રૂંધાતા અવાજે કહ્યું .
                                                    ‘રાજન્ ! એ ગુણો ‘દીનદેવ' ઘઉં ભરેલી હતી.' ઘીંગારામે કહ્યું. ‘ચાંપાભાઈ ! તમે ઘઉં ભરેલી ગુણો ગાડામાંથી લઈ ઊંટે સવાર થઈ લઈ ગયા છો અને આવી ધૂળની ગુણી ભરી ?’ વેદો બોલ્યો.
                                                    ‘રાજન્ ! આજે તમારી ભ્રાન્તિ ભાગે છે અને શાન્તિ જાગે છે. માટે મનને સ્થિર કરો.' ધીંગારામે રાજાનો ક્રોધ પારખી અને વચનો કહ્યાં પણ રાજા માને ? ‘ચાંપા, તારી સાથે બીજો કોણ હતો ?' ‘કુંપો !' ચાંપાએ કહ્યું.

                                                </p>
                                                <p className="paragraph-content">
                                                    'તમે મારી હકુમતની સીમામાં આવી લૂંટ કરો છો ? પીરબાવા તમે એમનો માલ સાચવો છો. એવો કયો ધર્મ શીખવો છો ?
                                                    સેલાણીશા સાવ ઢીલો પડી ગયો. તે કંઈ પણ બોલી શક્યો નહીં.
                                                    ‘રાજન્ ! ત્રણેની ભૂલ માટે ક્ષમા કરો. ભાઈ ચાંપા, કુંપા, પીરબાવા ! લો આ ઘજા ‘જય પીર રામદે !' જય નકળંગદેવ ! અન્નની ગુણો કરી અવંગ ધર્મધુરંધર ધર્યો અવતાર, રામાનો હો જય જયકાર એમ ઉચ્ચારતા પાંચ થેલાની પરકમ્મા કરતાં ધીંગારામ, સેલાણીશા, ચાંપો અને કુંપો આ પ્રમાણે ઘોષણા કરવા લાગ્યા.
                                                    સાત વખત પ્રદક્ષિણા કરવાથી ઘઉંના પાંચ થેલા ઉભરાવવા લાગ્યા અને ઘઉંના નીચે ઢગલા થવા લાગ્યા. રાજા અને સભાજનો આ જોઈ હેરત પામી ગયા.
                                                    પીરબાવા, ભાઈ ચાંપા ! કુંપા ! હવે આ પાંચ થેલા તમે લઈ જાવ અને ગરીબજનોમાં વહેંચજો. બીજા જોઈએ તો સાંભરગઢના અમારા ઉતારે આવજો.' ધીંગારામે કહ્યું.
                                                    રાજાએ ‘જય રામાપીર !' કહી ઘીંગારામના ચરણમાં માથું ઝુકાવ્યું અને ગુરુદિક્ષા લીધી. ઘીંગારામે ધજા આપી. ગુગળનો ધૂપ કરી, ઝોળીમાંથી ભસ્મની ચપટી લઈ રાજાને તિલક કર્યું. તે પછી રાજા નિયમિત રણુંજા દર્શન કરવા જવા
                                                    લાગ્યા.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails29;
