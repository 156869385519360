import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails20 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery22.jpg";
    return (
        <>
         <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}


            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                    
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                          
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            સગુણાના પુત્રને જીવતદાન


                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                    
                                                >
                                                    <span className="income">સગુણાના પુત્રને જીવતદાન


                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content">
                                                    શ્રી રામદેવજીની જાન રણુંજાને દરવાજે આવીને ઊભી રહી. લાસા અને લક્ષ્મી દેવજીને પોંખવા આવ્યાં. તે જોઈ રામદેવજીએ પૂછ્યું : ‘સગુણાબહેન કેમ નથી
                                                    મા ક
                                                    ‘ભાઈ ’સગુણાનો કુંવર બીમાર છે એટલે તે તેના બાળકની સારવારમાં રોકાઈ છે.' લાસાએ કહ્યું.
                                                    “સગુણાબહેનને તેડી લાવો. મારે એમના હાથે જ મંગલતિલક કરાવવું છે. તમે તો આઠ દિવસ લગ્નમાં મહાલ્યાં છો, પરંતુ સગુણાબહેન તો જાન જવાના દિવસે જ આવ્યા છે. તેઓ જાનમાં સાથે પણ આવી શક્યાં નથી, માટે આ લ્હાવો તેમને લેવા દો. ’
                                                    રામદેવજીના આગ્રહથી લાસા સગુણાને તેડી લાવી.
                                                    ‘બહેન ! આ આનંદના પ્રસંગમાં આવાં જૂનાં વસ્ત્ર કેમ પહેર્યાં છે ? શું તમને માનંદ નથી થતો ?' રામદેવજીએ કહ્યું. સગુણા રામદેવજીના પ્રશ્નનો ઉત્તર આપી કી નહીં. તેની આંખોમાં ઝળઝળિયાં આવ્યાં. તે જોઈ રામદેવજી બોલ્યા :
                                                    સાચું રે બોલો ને મારી બેનડી જી રે, અને મનડાં શાને રે. ઉદાસ ? જૂનાં રે વસ્ત્ર શીદ ધારિયાં રે,
                                                    નથી
                                                    તમ
                                                    અંતરે ઉલ્લાસ...સાચું રે
                                                    ઘણીના અને વસ્ત્રોની પેટીની કૂંચી હું પીંગલગઢમાં ભૂલી ગઈ છું. એટલે મેં આ ચમદેવજીને સમજાવવા સગુણાએ બહાનું કાઢયું કે, ‘ભાઈ ! મારા શણગાર,
                                                    જૂનાં વસ્ત્રો પહેર્યાં છે.’
                                                    | મઠ લઈ તમને આપતી વખતે મેં જ પેટીઓ ખોલીને તમારા વસ્ત્રો જોયાં હતાં. ‘બહેન સગુણા ! જૂદું ના બોલો, તમે વગડામાં લૂંટાયાં, ત્યારે લૂંટારા પાસેથી આ માટે જાવ મહેલમાં જઈ શણગાર સજીને આવો.'
                                                    - દેવને મંગલતિલક કરવા જતાં સગુણાની આંખોમાંથી આંસુની ધારા વહેવા ગુણા ખિન્ન હૃદયે રાજમહેલમાં જઈ નવાં વસ્ત્રો ધારણ કરી આવી.

                                                </p>
                                                <p className="paragraph-content">
                                                    લાગી. રામદેવજીએ તેને તિલક કરતાં રોકીને પૂછ્યું :
                                                    કોણે રે દુભાવ્યાં બહેની દિલડાં જી,
                                                    અને શાને આંસુડાની ધાર. કોણે રે વચનો કડવાં કાઢિયાં જી રે,
                                                    અને જૂઠું વદશો નહિ રે લગાર ..કોણે ક
                                                    જીવ ચાલતો ન હતો, પરંતુ ભાઈ કોઈ રીતે માને તેમ ન હતા તેથી તે રુદન કરા સગુણા જવાબ આપતાં ચોંધાર આંસુડે રડી પડ્યાં. ખરી વાત કહેતાં તેમને
                                                    બોલ્યાં :
                                                    ગઢ રે પીંગળ કેરા પાદરે જી રે, અને સાંભરે સમાજનાં વેશ. આજ સૂરજ મારો આથમ્યો જી રે,
                                                    અને સાચાં પડ્યાં એનાં કહેણ ...ગઢ ૨૦ “બહેન ! તારી સાસુનાં કહેશને સાંભરીશ નહીં. હું એને સમજાવીશ. મૂંઝાઈંગ નહિ બહેન ! અરે હા ! પણ ભાણિયો કેમ નથી આવ્યો ?’ રામદેવજીએ પૂછ્યું, “ભાઈ ! એ તો સૂઈ રહ્યો છે. એની તબિયત સારી નથી.'
                                                    ‘લાસા ! જા, જઈને ભાણિયાને તેડી લાવ. એ પણ મારી સાથે વરવેલમાં બેસી ગામમાં આવશે . એને પણ આનંદ થશે.' રામદેવજીએ લાસાને આજ્ઞા કરી. ‘ભાઈ ! પહેલાં મને મંગલતિલક કરી લેવા દો. ભાણિયો ભરનિદ્રામાં સૂતો છે. એને સૂઈ રહેવા દો,’
                                                    ‘ના, બહેન ! ભાશા વિના હું તિલક નહીં કરવા દઉં. તારા કહેવામાં મને કંઈ શંકા પડે છે. તું ઉદાસ છે એનું કારણ પણ મને સમજાતું નથી.
                                                    સગુણાનું હૈયું હાથ ન રહ્યું. તે ધ્રુસકે ધ્રુસકે રડતી બોલી :
                                                    ભાણેજે લીધી ભવની નિંદરા જી રે,
                                                    અને તેમાં આવ્યાં
                                                    માઝમરાત.
                                                    હીરલો ગુમાવ્યો. મેં તો હાથથી જી રે,
                                                    અને મારું માણેક મેલું થાય. ભાણેજે
                                                    કેમ થઈ ? ભાણેજ ભરનિદ્રામાં જ સૂતો છે.
                                                    બહેન ! સિદ્ધ પિતા અને સતી માતાની પુત્રી હોવા છતાં તને આવી ભ્રમ


                                                </p>
                                                <p className="paragraph-content">
                                                    જૂઠ્ઠું ૨ે નવ બોલો મારી બેનડી જી રે, ભાણેજ તો સૂતો નિંદર માંય, મંગળ પ્રસંગે તમે આવિયા જીરે, અને એમાં વિઘન કેમ થાય? ...જૂઠું
                                                    
                                                    બહેન ! ભાણિયો તો ભરનિદ્રામાં સૂતો છે, ગભરાઈશ નહીં. એને જગાડું આમ કહી રામદેવજીએ ભાણેજને સાદ દીધો !
                                                    ઊઠો ! ઊઠો ! લાડીલા ભાણેજડા જી રે, ભાઈ તમે ત્યાગોને નિંદરાય, આવો તમે મામા પાસે દોડતા જી રે,
                                                    અને સૌના દિલડાં હરખાય . ઊઠો ! ઊઠો
                                                    શ્રી રામદેવજીનો સાદ મૃત્યુ પામેલા ભાણેજે સાંભળ્યો. તે આંખો ઉઘાડી આળસ મરડી બેઠો થયો અને રામદેવજી પાસે દોડી જવા તૈયાર થયો. માતા મિણલદે મને દાસદાસીઓ આ અજાયબીભર્યું દૃશ્ય જોઈ ઘેલાં બની ગયાં. પગમાં ઝાંઝર રૂમઝૂમ વાગે,
                                                    અને હસતો દોડ્યો જાય ૨ ...પગમાં
                                                    મામા મામા કહેતો ભાણેજ આવ્યો,
                                                    લાગ્યો પીરજીને પાય ૨ ...પગમાં
                                                    ખોટા હીરાને ખરો કર્યો હિન્દવે,
                                                    પરગટ પરચો
                                                    જણાય ૨ ...પગમાં
                                                    ‘કેશવ' આવ્યા દ્વારામતીથી
                                                    રણુંજામાં રણછોડરાય ૨ ...પગમાં પૂરે પરચા રામદેવ,
                                                    પરગટ
                                                    પીર
                                                    બેન
                                                    થઈને પૂજાય ૨ ...પગમાં ઉતારે આરતી, સગુણા
                                                    હઈડે હરખ ન માય રે ...પગમાં
                                                    ગામ રણુંજામાં ઉજવે
                                                    રામદેવ પીરનો વિવાહ
                                                    સરવું,
                                                    ૨ ...પગમાં
                                                    રી સદાને માટે રણુંજામાં રહેવાનું નક્કી કર્યું.
                                                    રણુંજમાં આનંદ- ઉત્સવ થઈ ગયો. સગુલ્લાએ પીંગલગઢમાં જવાનું માંડી
                                                </p>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails20;
