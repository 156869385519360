import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";
const BlogDetails3 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery4.jpg";
  return (
    <>
     <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
      <Header />

      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id="46"
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="52ebb12"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          style={{ height: "30rem", marginBottom: "5rem" }}
        >
          <div className="elementor-background-overlay"></div>
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
              data-id="ae9d67e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fcf91b3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                      data-id="93dfaf4"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                          data-id="847c2c5"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                          style={{ height: "8rem" }}
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                          data-id="8fe49e3"
                          data-element_type="widget"
                          data-widget_type="trx_sc_layouts_title.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              id="trx_sc_layouts_title_515615674"
                              className="sc_layouts_title sc_align_center with_content without_image without_tint"
                            >
                              <div className="sc_layouts_title_content">
                                <div className="sc_layouts_title_title">
                                  {" "}
                                 
                                </div>
                                <div className="sc_layouts_title_breadcrumbs">
                                  <div className="breadcrumbs">
                                   
                                   
                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="page_content_wrap blog-details">
        <div className="content_wrap" >
          <div className="content">
            <div id="container" className="give-wrap container">
              <div id="content" role="main">
                <div
                  id="give-form-288-content"
                  className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                >
                  <div
                    id="give-sidebar-left"
                    className="give-sidebar give-single-form-sidebar-left"
                  >
                    <div className="images">
                      <img
                        width="1024"
                        height="576"
                        src={img1}
                        className="attachment-large size-large wp-post-image blog-img"
                        alt=""
                        decoding="async"
                        sizes="(max-width: 1024px) 100vw, 1024px"
                      />
                    </div>
                  </div>
                  <div className="summary entry-summary">
                    <h2 itemProp="name" className="give-form-title entry-title">
                      શ્રી રામદેવજીનું પ્રાગટય
                    </h2>{" "}
                    <div
                      id="give-form-288-wrap"
                      className="give-form-wrap give-display-onpage"
                    >
                      <div className="give-goal-progress">
                        <div
                          className="raised blog-title"
                          
                        >
                          <span className="income">શ્રી રામદેવજીનું પ્રાગટય</span>
                        </div>
                      </div>
                      <div
                        id="give-form-content-288"
                        className="give-form-content-wrap give_pre_form-content"
                      >
                        <p className="paragraph-content">
                          સતી સ્વપ્નમાં દેખતાં, મહાતેજ પરકાશ! અશ્વ તણા અસવારનો, થયો દર્શન આભાસ. ભાદરવા સુદી દશમની મધરાત પછી સતી મિણલદેને સ્વપ્ન આવ્યું. મહેલમાં તેજનો મહાપ્રકાશ છવાયો હતો અને એમને શ્વેતાંગી રૈવત પર અસવાર થયેલા, કેસરી જામો, માથે મુકુટ અને હાથમાં ભાલાનું આયુધ ઘારણ કરેલી દિવ્ય વિભૂતિ દેવનાં દર્શન થયાં. થોડી વારે તેમનું સ્વપ્ન સરી ગયું. તેઓ જાગૃત થયા અને તરત જ અજમલજીને સ્વપ્નની વાત કરી. સતીના સ્વપ્નની વાત સાંભળી એ વખતે એમને પણ આભાસમાં જે સમસ્યા થતી જણાઈ કે જે શ્રી દ્વારિકાધિશના આગમનન આગાહી રૂપે હતી.
                          પરોઢિયે મિણલદે ગૌશાળામાં ગયાં. દૂધ દોહી દેગડી ભરી લાવ્યાં. આજે મંગાળાના-મોટા પાષાણના ચૂલામાં અગ્નિ સળગાવી દૂધને ગરમ કરવા મંગાળા પર દેગડી મૂકી, ગાયોને નીરણ પાણીની માવજત કરવા ગયાં.
                          ચોપાઈ સંવત ચૌદસો એકસઠ ચાલે, બાળરૂપ થયું દીનદયાલે, ભાદ્રની એકાદશી સવારે, શુક્લપક્ષના શુભ ગુરુવારે, આંગણે કુમકુમ પગલાં છાપા, પ્રાદુર્ભાવ પરચા પ્રતાપા, પારણે પોઢી કરને વધારી, દૂધ ઊકળતી દેગ ઉતારી.
                          ગાયોને નીરણ પાણીની વ્યવસ્થા કરી મિણલદેને દૂધની દેગને ચૂલે મૂકેલી છે તે ઊભરાઈ જશે, એમ યાદ આવવાથી ઉતાવળાં ઉતાવળાં ત્યાં આવ્યાં અને જુએ છે તો ચૂલા ઉપરથી દેગને નીચે ઉતારેલી દીઠી તેથી તેમના આશ્ચર્યનો પાર રહ્યો નહીં.
                          દાસ - દાસી કોઈ હાજર નથી. અજમલજી પોતાના આરામ ભવનમાં
                        </p>
                        <p className="paragraph-content" >
                          પ્રભુસ્મરણની માળાના રટલમાં છે અને આ દેગ ચૂલેથી નીચે કોળે ઉતારી ? પુરુષ આશ્ચર્ય પામ્યાં. ત્યાં એમની નજર પરશાળમાં પડી. ત્યાં તેમણે ઠેર પગલાંની છાપો પડેલી દીઠી તેથી પોતે આશ્ચર્યમુગ્ધ ભની ગયા. ધારણા પાર આવતાં એમણે વીરમદેવ પાસે બીજા તેજસ્વી ભાળકને પારણામાં મૂતેલો જય તેથી તે હેરત પામી ગયાં અને તેમણે હરખભેર જઈ અજમલજીને આ રટવામાં ખબર આપી.
                          નિત્ય-નિયમથી પરવારી અજમલજી આવ્યા. એમણે કુમકુમ પગલાં જોવાં, દૂર ઊકળતી દેગડીને નીચે ઉતારી લીધેલી દેખી. પારણા પાસે આવીને જોયું તો પારણ પોઢેલો બાળક ચપળ અને તેજસ્વી નેત્રોથી અજમલજી સામે જોઈ મરક મરક મુખ કરી સ્મિત કરતો હતો. વળી એના પગને દૂધ સ્પર્શેલું જોઈ પોતે સમજી ગયા કે દેગડીને બાળકુંવરે નીચે ઉતારેલી છે. ભગવાન શ્રી દ્વારિકાધીશે જે વરદાન આપ્યું હતું, તેનું સ્મરણ તેમને તાજું થઈ ગયું અને દંડવત્ પ્રણામ કરી આનંદમગ્ન બને ગયા. ત્યાર બાદ તેમણે મેળવેલાં વરદાનની મિણલદેને વાત કરી.
                          ચોપાઈ
                          શ્રી હરિ અજમલ ઘર અવતારી, મિણલદેને હરખ અપારી, હરખે પ્રજાજનો નરનારી, સ્વરૂપ જોઈ ઉપર અચરજ ભારી.
                          અજલમજીએ માંગલિક પ્રસંગે પ્રજાજનો દિવ્ય સમસ્યારૂપી કુંકુમ પદરેણુની છાપ અને બાલસ્વરૂપનાં દર્શન કરી શકે, એમ ધારીને ગામમાં ઢોલ પીટાવી પ્રજાજનોને ખબર આપી. દરબારગઢના દ્વારે મંગલ નોબત વાગવા લાગી. શરણાઈઓ ગહેકવા લાગી. ગામમાં વાયુવેગે વાત ફેલાઈ કે દરબાર ગઢના રાજભવનમાં ઠેર ઠેર કંકુનાં પગલાં પડયાં છે. પારણામાં ભાળકુંવર પોઢયો છે. એવું પારલામાં સૂતા સૂતા હાથ લંબાવીને ચૂલા પર ઊકળતા ઊભરાતા દૂધની દેગડી નીચે ઉતારી છે, આવી નવાઈ પમાડે તેવી વાત પ્રસરવાથી નરનારીઓમાં આશ્ચર્ય છવાયું અને સ્ત્રીપુરુષોનાં ટોળેટોળાં આવવા લાગ્યાં. રાજભવનમાં દિવ્ય તેજ નિહાળી આશ્ચર્યચકિત થઈ ગયાં, પારણે પોઢેલાં બાળકને જોઈ સૌ મોહમુગ્ધ થવા લાગ્યાં. તેમના હૃદયમાં સાક્ષાત્ પ્રભુદર્શન કર્યાં હોય તેટલો આનંદ ઉભરાવા લાગ્યો.
                        </p>
                        <p className="paragraph-content">
                          કમલનયન ગૌરાંગ વિશેષા, ભાલે વિષ્ણુતિલકની રેખા, સ્મિત વદન તેજસ્વી સોહે, નિરખી નારીજન રૂપથી મોહે. સ્ત્રીઓ કુંકુમ ચરણરજ લઈ ચાંલ્લા કરવા લાગી અને પોતાનાં ભાળકોને કપાળે તિલક કરવા લાગી. પુરુષો પારણા પાસે આવી બાળકને થડી થડી નિરખવા લાગ્યા. બાળકુંવરનું દિવ્ય સ્વરૂપ, ગોરું અંગ, કમલ સમાન નેત્રો, કપાળમાં વિષ્ણુતિલકની ઉપસેલી રેખા, મંદમંદ હાસ્ય કરતી મુખમુદ્રા જોઈ સહુ આશ્ચર્યમુગ્ધ બન્યાં, બાળકુંવરને વધાવતાં સર્વ સ્ત્રીનાં હ્રદય આનંદથી ઊભરાતાં હતાં. તેઓ બાળકને નિરખતાં ધરાતાં નહીં.
                          સોરઠો
                          તરવરાટ તને જોઈ ચપળતા, થયાં મુગ્ધ નરનારી જોતાં, સોડમ ગુગળ ધૂપની આવે, મથ મથ ભવન હવા પ્રસરાવે.
                          છંદ
                          દ્વારિકાના નાથ પ્રગટ્યા, રાય પોકરણના ઘરે, કંકુતણાં પગલાં પડ્યાં, ને પારણે પોઢણ કરે. દૂધડે ઊકળતી દેગને, લંબાવી કર હેઠે ઘરે, અજમલ અને રાણી મિણલનાં, હ્રદય આનંદે ભરે. નોબત ગડગડે રાજદ્વારે, ઢોલ ઢમ ઢમ વાગતા, હરખે ભર્યા નરનારીઓ, કુંવર નિરખવા આવતાં. બાળક તણાં રૂપ તેજથી, મોહિત સર્વે થઈ જતાં, વહેંચાય સાકર ગામમાં આનંદ ઓચ્છવ ઊજવતાં. અજમલ પિતા-માતા મિણલ ને શાખ ક્ષત્રી તુંવરા, 'કેશવ' તણા પ્રભુ આજ પ્રગટયા, રામદેવજી કુંવરા…    </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
      <div>
        <Footer />
        {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
      </div>
    </>
  );
};
export default BlogDetails3;