import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails38 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramadevpir24.jpg";
  return (
    <>
      {/* <div className="body_wrap">
        <div className="page_wrap"> */}
 <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      

      <Header />
      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id="46"
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="52ebb12"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          style={{ height: "30rem", marginBottom: "5rem" }}
        >
          <div className="elementor-background-overlay"></div>
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
              data-id="ae9d67e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fcf91b3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                      data-id="93dfaf4"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                          data-id="847c2c5"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                          style={{ height: "8rem" }}
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                          data-id="8fe49e3"
                          data-element_type="widget"
                          data-widget_type="trx_sc_layouts_title.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              id="trx_sc_layouts_title_515615674"
                              className="sc_layouts_title sc_align_center with_content without_image without_tint"
                            >
                              <div className="sc_layouts_title_content">
                                <div className="sc_layouts_title_title">
                                  {" "}
                                 
                                </div>
                                <div className="sc_layouts_title_breadcrumbs">
                                  <div className="breadcrumbs">
                                    
                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



      <div className="page_content_wrap blog-details">
        <div className="content_wrap" >
          <div className="content">
            <div id="container" className="give-wrap container">
              <div id="content" role="main">
                <div
                  id="give-form-288-content"
                  className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                >
                  <div
                    id="give-sidebar-left"
                    className="give-sidebar give-single-form-sidebar-left"
                  >
                    <div className="images">
                      <img
                        width="1024"
                        height="576"
                        src={img1}
                        className="attachment-large size-large wp-post-image blog-img"
                        alt=""
                        decoding="async"
                        sizes="(max-width: 1024px) 100vw, 1024px"
                      />
                    </div>
                  </div>
                  <div className="summary entry-summary">
                    <h2 itemProp="name" className="give-form-title entry-title">
                      વેણ ફગો વિપ્ર

                    </h2>{" "}
                    <div
                      id="give-form-288-wrap"
                      className="give-form-wrap give-display-onpage"
                    >
                      <div className="give-goal-progress">
                        <div
                          className="raised blog-title"
                         
                        >
                          <span className="income">વેણ ફગો વિપ્ર


                          </span>
                        </div>
                      </div>

                      <div
                        id="give-form-content-288"
                        className="give-form-content-wrap give_pre_form-content"
                      >
                        <p className="paragraph-content" >
                          ચોપાઈ
                          સોરઠ ભોમે શિહોર ગામે, ગોર વિષ્ઠ આણંદજી નામે, ક્કડાએ દીધાં વરદાના, તુજ ઘર સાત થશે સંતાના.
                          ફક્કડાનાથના સેવક શિહોર ગામના રાજપુરોહિત આણંદજીની સેવાભક્તિ જાણીતી હતી. તે ફક્કડાનાથની આજ્ઞા પ્રમાણે વર્તતો હતો અને રાજદરબારમાં પણ તે ફક્કડાનાથને પણ ઝમરાળાનો જાગતો દેવ કહી પ્રસંશા કરતો હતો. સંસાર- સુખ માટે એની એક જ માગણી હતી કે, હે નાથ ! હે ગુરુદેવ ! હું નિઃસંતાન છું. મને દીકરો આપો ! રાજગોર હોવાથી ધન, અન્ન, વસ્ત્રનો ટૂટો નથી. માત્ર એક સંતાનની ખામી છે.
                          આવી એની પ્રાર્થનાથી ફક્કડાનાથે એને કહ્યું કે, તું મારા આદેશ પ્રમાણે વર્તે છે તેથી હું તારા સેવાભાવથી પ્રસન્ન થઈ તને વરદાન આપું છું કે, તારે સાત પુત્રો થશે. છ છોકરાં રાખી સાતમો દીકરો તું અહીં મૂકી જજે. મોટો થતાં તેને હું ધર્માચાર્ય પદ આપીશ. તે ધર્મોપદેશ કરી લોકોને ભગવાન શ્રી રામદેવજી મહારાજની ભક્તિ તરફ વાળશે. તારો એ દીકરો સમર્થ અને પરચાધારી બનશે.
                          દોહા
                          વચન સફળ થયું સંતનું, આણંદજી હરખાય,
                          નિજસૂત બાવો નહીં બને, થવું હોય થાય.
                          વરદાન પ્રમાણે ગોરાણીને દીકરા જન્મવા લાગ્યા. આણંદજીને હરખનો પાર ન રહ્યો. ગુરુને એક દીકરો સેવા માટે અર્પણ કરવાની વાત ગોરાણીને કરી હતી પણ ગોરાણીએ ચોખ્ખું સંભળાવી દીધું હતું કે મને દીકરો ભગવાને આપ્યો છે, બાવાએ નથી આપ્યો. મારો દીકરો રાજગોર થશે. મારે તેને બાવો બનાવવો નથી. દીકરો નહીં મળે. ગોરાણીએ પુરકીને સંભળાવી દીધું.
                          ‘આપણે વિપ્ર છીએ ! રાજગોર છીએ !' આમ ગોરાણીના કહેવાથી આણંદજી ઘડીભર વિચાર કરી નિશ્ચય પર આવ્યો. ગોરાણીની વાત સાંભળી એનું મન ઢચુપચુ થઈ ગયું. દીકરાને બાવો બનાવાય નહીં એ ગોરાણીના શબ્દો અંતરમાં વસી ગયા.

                        </p>
                        <p className="paragraph-content">
                          આથી તે ફક્કડાનાથનાં દર્શને જવું નહીં અને છોકરો આપવો નહીં, આવા દૃઢ નિશ્ચય પર આવ્યો.
                          ચોપાઈ
                          કબુલ થયેલો સંતની આગે, આણંદજી નિજ વચનો ત્યાગે,
                          એક છોકરો ગાંડો થાયે, ઘર છોડીને નાસી જાયે. સાતમાંથી એક છોકરો ગાંડો બન્યો, એવો તો ગાંડો કે અંગ ૫૨ વસ્ત્ર ન રાખે. શિહોરની ભરબજારમાં કૂદકા મારતો નીકળે. લોકોમાં ત્રાસ ફેલાયો. છેવટે એ ગાંડો ક્યાં ચાલ્યો ગયો તેનો પત્તો લાગ્યો નહીં. સંતને વચનથી છેતરવા અને ઠગવાનું ગોરને ફળ મળ્યું અને ગોરપદું પણ ગયું. છેવટે તે ફક્કડાનાથ પાસે આવ્યો. ફક્કડાનાથે તેને કહ્યું કે તારા મનોરથ પ્રમાણે તને મળી ગયું છે. હવે નવી માંગણી
                          કરીશ નહીં.
                          અત્યારે પણ આણંદજીના કુળમાં બે છોકરામાં એક ગાંડો હોય છે અને એક કાને બૂચો હોય છે. આજ પ્રમાણે તેમનો ગાંડા અને બૂચાનો આજે પણ વંશ ચાલ્યા કરે છે.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
      {/* </div>
      </div> */}
      <div>
        <Footer />
        {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
      </div>
    </>
  );
};

export default BlogDetails38;
