import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import MetaTags from 'react-meta-tags';
import Gallery from "../Gallery";
import Blog from "../Blog/Blog";

import 'bootstrap/dist/css/bootstrap.min.css';
import "../Audio/Audio.css";
// import "../Audio/bootstrap.css"


import AudioPlayer from "../Audio/AudioPlayer";
import "./index.css";
import '../../App.js';
import { useParams } from "react-router-dom";
import { useAudio } from "../Audio/AudioPlayer";
import Farman from "../Farman/Index.js";
import Slider from "../Slider/Slider.js";
import SantVani from "../Farman/Santvani.js";
import { Helmet } from "react-helmet";


const Dashboard = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdevpir-vision.jpg";
  const cardimg = "/assets/wp-content/uploads/2018/12/cover.jpg";
  const cardimg1 = "/assets/wp-content/uploads/2018/12/bhagwat gita.jpg";
  const cardimg2 = "/assets/wp-content/uploads/2018/12/om img.png";
  const img = "/assets/wp-content/uploads/2018/12/ramdev-gallery4.jpg";
  return (
    <>
      <Helmet>
        <title>Ramapir</title>
        <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      </Helmet>

      <Header />
      <Slider />

      <div className="page_content_wrap_3">
        <div className="content_wrap">
          <div className="content">
            <article
              id="post-407"
              className="post_item_single post_type_page post-407 page type-page status-publish hentry"
            >
              <div className="post_content entry-content">
                <div
                  data-elementor-type="wp-post"
                  data-elementor-id="407"
                  className="elementor elementor-407"
                >
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-159258c elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="159258c"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-extended">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fe5f5b1 sc_inner_width_none sc_layouts_column_icons_position_left"
                        data-id="fe5f5b1"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-d8adc01 sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="d8adc01"
                            data-element_type="widget"
                            data-widget_type="spacer.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-a9d1228 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="a9d1228"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-extended">
                      <div
                        className="elementor-column elementor-top-column elementor-col-50 elementor-element elementor-element-676adb1 sc_inner_width_none sc_layouts_column_icons_position_left"
                        data-id="676adb1"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-671ccdc sc_fly_static elementor-widget elementor-widget-image"
                            data-id="671ccdc"
                            data-element_type="widget"
                            data-widget_type="image.default"
                          >
                            <div className="elementor-widget-container">
                              <img
                                decoding="async"
                                style={{ height: '500px' }}
                                width="568"
                                height="496"
                                src={img1}
                                className="attachment-full size-full wp-image-409"
                                alt=""
                                loading="lazy"
                                sizes="(max-width: 568px) 100vw, 568px"
                              />{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-top-column elementor-col-50 elementor-element elementor-element-78b092d sc_inner_width_none sc_layouts_column_icons_position_left our_core_mission_section"
                        data-id="78b092d"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-c289823 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                            data-id="c289823"
                            data-element_type="widget"
                            data-widget_type="trx_sc_title.default"
                          >
                            <div className="elementor-widget-container">
                              <div
                                className="sc_title sc_title_default"
                              >
                                <span className="sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default about-title1">
                                  શ્રી રામાપીર વિશે
                                </span>
                                <h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                  <span className="sc_item_title_text">
                                    બાબા રામદેવ અથવા રામદેવજી, અથવા રામદેવ પીર, રામશા પીર (1352-1385 એડી; .S. 1409-1442)
                                  </span>
                                </h1>
                              </div>{" "}
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-814de7a sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="814de7a"
                            data-element_type="widget"
                            data-widget_type="spacer.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-57702e5 sc_fly_static elementor-widget elementor-widget-heading"
                            data-id="57702e5"
                            data-element_type="widget"
                            data-widget_type="heading.default"
                          >
                            <div className="elementor-widget-container">
                              <h4 className="elementor-heading-title elementor-size-default r-heading">
                                જન્મનો ઇતિહાસ
                              </h4>{" "}
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-7c4b363 sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="7c4b363"
                            data-element_type="widget"
                            data-widget_type="spacer.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-a296948 sc_fly_static elementor-widget elementor-widget-text-editor"
                            data-id="a296948"
                            data-element_type="widget"
                            data-widget_type="text-editor.default"
                          >
                            <div className="elementor-widget-container">
                              <p>
                                રાજા અજમલ (અજમલ તંવર) એ છહાન બારુ ગામના પમજી ભાટીની પુત્રી રાણી મીનાલદેવી સાથે લગ્ન કર્યાં. પુત્રહીન રાજા દ્વારકા ગયા અને શ્રી કૃષ્ણને તેમના જેવા સંતાનની ઈચ્છા વિશે વિનંતી કરી. શ્રી કૃષ્ણના આશીર્વાદથી તેઓને બે પુત્રો થયા, મોટા વિરમદેવ અને નાના રામદેવ. રામદેવજીનો જન્મ 1409માં ચૈત્ર સુદી પંચમીના રોજ જેસલમેર જિલ્લાના રામદેવરામાં એક રાજપૂત પરિવારમાં થયો હતો.

                              </p>{" "}
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-64de630 sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="64de630"
                            data-element_type="widget"
                            data-widget_type="spacer.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-17d05f5 sc_fly_static elementor-widget elementor-widget-heading"
                            data-id="17d05f5"
                            data-element_type="widget"
                            data-widget_type="heading.default"
                          >
                            <div className="elementor-widget-container">
                              <h4 className="elementor-heading-title elementor-size-default r-heading">
                                શ્રી રામદેવજીની દ્રષ્ટિ
                              </h4>{" "}
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-ddc2f39 sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="ddc2f39"
                            data-element_type="widget"
                            data-widget_type="spacer.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-096ee3d sc_fly_static elementor-widget elementor-widget-text-editor"
                            data-id="096ee3d"
                            data-element_type="widget"
                            data-widget_type="text-editor.default"
                          >
                            <div className="elementor-widget-container">
                              <p>
                                શ્રી રામદેવજી બધા મનુષ્યોની સમાનતામાં માનતા હતા, પછી તે ઉચ્ચ હોય કે નીચ, અમીર હોય કે ગરીબ. તેમણે પીડિતોને તેમની ઇચ્છાઓ પૂરી કરીને મદદ કરી. તેને ઘણીવાર ઘોડા પર દર્શાવવામાં આવે છે. તેમના અનુયાયીઓ રાજસ્થાન, હરિયાણા, પંજાબ, ગુજરાત અને મધ્ય પ્રદેશ, મુંબઈ, દિલ્હી અને પાકિસ્તાનના
                                સિંધમાં ફેલાયેલા છે. તેમની યાદમાં રાજસ્થાનમાં અનેક મેળા ભરાય છે. તેમના નામના મંદિરો ભારતના ઘણા રાજ્યોમાં જોવા મળે છે.
                              </p>{" "}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </section>
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-4ef42c5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="4ef42c5"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-extended">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c25fb82 sc_inner_width_none sc_layouts_column_icons_position_left"
                        data-id="c25fb82"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-560fb92 sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="560fb92"
                            data-element_type="widget"
                            data-widget_type="spacer.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-4ef42c5 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="4ef42c5"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-extended">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c25fb82 sc_inner_width_none sc_layouts_column_icons_position_left"
                        data-id="c25fb82"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">

                          <div
                            className="elementor-element elementor-element-560fb92 sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="560fb92"
                            data-element_type="widget"
                            data-widget_type="spacer.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>


      <div className="page_content_wrap_4">
        <div className="content_wrap">
          <div className="content">
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-40b303f4 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
              data-id="40b303f4"
              data-element_type="section"
              data-settings='{"stretch_section":"section-stretched"}'
              style={{ width: "100%", marginTop: "5rem" }}
            >
              <div className="elementor-container elementor-column-gap-extended">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-791b29ea sc_inner_width_none sc_layouts_column_icons_position_left"
                  data-id="791b29ea"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-44ef2128 sc_fly_static elementor-widget elementor-widget-trx_sc_icons animated fadeInUp"
                      data-id="44ef2128"
                      data-element_type="widget"
                      data-settings='{"_animation":"fadeInUp"}'
                      data-widget_type="trx_sc_icons.default"
                    >
                      <div className="elementor-widget-container">
                        <div
                          id="trx_sc_icons_358526507"
                          className="sc_icons sc_icons_default sc_icons_size_medium sc_align_center"
                        >
                          <div className=" trx_addons_columns_wrap columns_padding_bottom">
                            <div className="trx_addons_column-1_4">
                              <div className="sc_icons_item sc_icons_item_linked">
                                <div className="sc_icons_image">
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={cardimg}
                                    alt="Icon"
                                    width={116}
                                    height={117}
                                  />
                                </div>
                                <div className="sc_icons_item_details">
                                  <h4 className="sc_icons_item_title">
                                    <span>Shree Ramdevji Maharaj Bhajans</span>
                                  </h4>

                                  <Link to='/Audio'
                                    className="sc_icons_item_link"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="trx_addons_column-1_4">
                              <div className="sc_icons_item sc_icons_item_linked">
                                <div className="sc_icons_image" style={{ marginBottom: '20px' }}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={cardimg2}
                                    alt="Icon"
                                    width={116}
                                    height={117}
                                  />
                                </div>
                                <div className="sc_icons_item_details">
                                  <h4 className="sc_icons_item_title" style={{ marginBottom: '20px' }}>
                                    <span>Shree Ramdevji Maharaj Videos</span>
                                  </h4>

                                  <Link to='/Video'
                                    className="sc_icons_item_link"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="trx_addons_column-1_4">
                              <div className="sc_icons_item sc_icons_item_linked">
                                <div className="sc_icons_image">
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={cardimg1}
                                    alt="Icon"
                                    width={116}
                                    height={117}
                                  />
                                </div>
                                <div className="sc_icons_item_details">
                                  <h4 className="sc_icons_item_title">
                                    <span>Shree Ramdevji Maharaj Books</span>
                                  </h4>
                                  <div className="sc_icons_item_description">

                                  </div>
                                  <Link to='/Books'
                                    className="sc_icons_item_link"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="trx_addons_column-1_4">
                              <div className="sc_icons_item sc_icons_item_linked">
                                <div className="sc_icons_image">
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={img}
                                    alt="Icon"
                                    width={116}
                                    height={117}
                                  />
                                </div>
                                <div className="sc_icons_item_details">
                                  <h4 className="sc_icons_item_title">
                                    <span>Shree Ramdevji Maharaj Stories</span>
                                  </h4>

                                  <Link to='/BlogMain'
                                    className="sc_icons_item_link"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <div >
        <Farman />
      </div>
      <div>
        <SantVani />
      </div>
      <div>
        <Gallery />
      </div>

      <div>
        <Blog />
      </div>

      <div className="footer">
        <Footer />
        {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay }) } /> */}
      </div>

    </>
  );
};


export default Dashboard;