import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails23 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery25.jpg";
  return (
    <>
      {/* <div className="body_wrap">
        <div className="page_wrap"> */}
      <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />


      <Header />
      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id="46"
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="52ebb12"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          style={{ height: "30rem", marginBottom: "5rem" }}
        >
          <div className="elementor-background-overlay"></div>
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
              data-id="ae9d67e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fcf91b3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                      data-id="93dfaf4"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                          data-id="847c2c5"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                          style={{ height: "8rem" }}
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                          data-id="8fe49e3"
                          data-element_type="widget"
                          data-widget_type="trx_sc_layouts_title.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              id="trx_sc_layouts_title_515615674"
                              className="sc_layouts_title sc_align_center with_content without_image without_tint"
                            >
                              <div className="sc_layouts_title_content">
                                <div className="sc_layouts_title_title">
                                  {" "}

                                </div>
                                <div className="sc_layouts_title_breadcrumbs">
                                  <div className="breadcrumbs">

                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



      <div className="page_content_wrap blog-details">
        <div className="content_wrap" >
          <div className="content">
            <div id="container" className="give-wrap container">
              <div id="content" role="main">
                <div
                  id="give-form-288-content"
                  className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                >
                  <div
                    id="give-sidebar-left"
                    className="give-sidebar give-single-form-sidebar-left"
                  >
                    <div className="images">
                      <img
                        width="1024"
                        height="576"
                        src={img1}
                        className="attachment-large size-large wp-post-image blog-img"
                        alt=""
                        decoding="async"
                        sizes="(max-width: 1024px) 100vw, 1024px"
                      />
                    </div>
                  </div>
                  <div className="summary entry-summary">
                    <h2 itemProp="name" className="give-form-title entry-title">
                      નેતલદેને પરચો
                    </h2>{" "}
                    <div
                      id="give-form-288-wrap"
                      className="give-form-wrap give-display-onpage"
                    >
                      <div className="give-goal-progress">
                        <div
                          className="raised blog-title"

                        >
                          <span className="income">નેતલદેને પરચો


                          </span>
                        </div>
                      </div>

                      <div
                        id="give-form-content-288"
                        className="give-form-content-wrap give_pre_form-content"
                      >
                        <p className="paragraph-content" >
                          સવારના પહોરમાં રામદેવ દાતણ કરતા હતા, ત્યાં તેમનાં ભાભીએ આપી કે, 'મહારાજ ! આપણી ગાયમાતા મરી ગઈ છે.' ‘ભાભી ! આપણી ગાય મરે જ નહીં.' રામદેવજીએ કહ્યું. ‘ચાલો બતાવું, હું જોઈને આવી છું.'
                          'બર
                          રામદેવજી ભાભી સાથે ગાયવાડામાં ગયા ને ગાયને દાતણ અડકાડી કહ્યું
                          *ઊઠો ઊઠો ગાયમાતા ! ઊઠો ઊઠો !'
                          ગાય તુરત ઊઠી ઊભી થઈ ગઈ. રામદેવજી ભાભીને કહેવા લાગ્યા : ‘કેમ ભાભી ગાય મરેલી કહેતાં હતાં ને ?' ભાભી શરમાઈ અચરજ પામ્યાં અને ગાયને
                          જીવતી જોઈ આનંદ પામ્યાં.
                          ગાય જીવતી કર્યાની વાત દાસીઓએ રાણી નેતલદેને કહી. વાત સાંભળી નેતલદેએ કહ્યું : ‘અન્નદાતા ! આપ તો સમર્થ છો. અનેક પરચા પૂરો છો ! આજ મને એક પ્રશ્ન પૂછવાનું મન થયું છે, આશા છે ભગવાન ?'
                          ‘કહો ! કહો ! તમારે એવો કયો પ્રશ્ન પૂછવો છે ?’
                          ‘દેવ ! મારા પેટમાં ગર્ભ છે. એ પુત્ર છે કે પુત્રી ?'
                          રામદેવજીએ કોઠામાં રહેલા ગર્ભને પૂછ્યું : ‘બેટા ?'
                          ‘હાં બાપુ ! બહાર આવું ?’
                          ‘ના, હમણાં કોઠામાં જ રહો.’
                          ગર્ભ અદૃશ્ય રહીને બોલ્યો તે નેતલદેએ સાંભળ્યું ને શરમાઈ રામદેવજીના પગમાં પડ્યાં. રામદેવજીની ક્ષમા માગી. રામદેવજીએ હસતાં હસતાં ક્ષમા આપી. નેતલદેએ આ અદ્ભુત પરચો અનુભવ્યો.
                          થોડો સમય વીતી ગયો અને રામદેવજી જીવનલીલા સંકેલવાની તૈયારી કરવા
                          લાગ્યા.
                          નેતલદેને પો ભજન
                          જી રે ગળતી માઝમરાત, એ જી રે... ગળતી માઝમરાત.
                          સપનાં

                        </p>
                        <p className="paragraph-content">
                          જી રે ગળતી માઝમરાત, એ જી રે... ગળતી માઝમરાત. સપનાં આવ્યાં. અજમલરાયને... એ જી ...હોજી. પંદરસો ને પંદરની સાલ, એજી રે. પંદરસો પંદરની સાલ, અને ભાદરવી સાતમ કેરી રાતડી રે... એ જી...હોજી ઊભા અજમલ દ્વારિકામાંય, એ જી અજમલજી દ્વારિકામાંય, અને રામદે' સમાયા રણછોડરાયમાં... એ જી...હોજી ઘોડલો થયો ગરુડને રૂપ, એ જી..., રે ઘોડલો ગરુડને રૂપ, અને સેવામાં દીઠા રે ડાલીબાઈને... એ જી ...હોજી. સંવત પંદરસોને પંદરની સાલમાં ભાદરવા સુદી સાતમની મધરાતે અજમલજીને રામદેવે સ્વપ્નું આપ્યું કે પોતે દ્વારિકામાં શ્રી રણછોડરાયનાં દર્શન કરતા ઊભા છે. ત્યાં લીલુડે ઘોડે રામદેવજી આવ્યા ને રણછોડરાયમાં સમાયા ! ઘોડો પણ ગરુડજીનું રૂપ ધરી નમન કરતો ઊભો રહ્યો ને ડાલીબાઈને ચમર ઢોળતાં ઊભેલાં જોયાં. આથી અજમલજીએ બૂલંદ અવાજે ‘જય રણછોડરાય ! જય રામાપીર !' એવો જયનાદ કર્યો.
                          અજમલરાયના જયનાદના પડઘા રંગમહેલમાં ગુંજી ઊઠ્યા. દાસદાસીઓ અને સતી મિણલદે પણ ઝબકી ઊઠ્યાં અને સૌ રાજાના ઢોલિયા પાસે દોડી આવ્યાં. જુવે છે તો રાજા ભરનિદ્રામાં ઊંઘતા હતા. ઊંઘમાં જ રાજાએ ફરી વાર જયનાદ કર્યો. જય રણછોડ ! જય રામાપીર !'
                          સતી મિણલદેએ આશ્ચર્ય પામતાં રાયને ઢંઢોળી જાગૃત કર્યા, ત્યાં ખબર પડતાં રામદેવજી અને વીરમદેવજી પણ દોડી આવ્યા. અજમલજી જાગૃત થતા બધાને ઊભેલા જોઈ સમજી ગયા ને પોતાને આવેલા સપનાની અથથી ઇતિ વાત કહી, તે સાંભળી મિણલદે બોલ્યાં : ‘નાથ ! આપણે ઘણા વરસે દ્વારિકા ગયેલાની યાદીનું સ્વપ્નું આવ્યું. પણ જુઓ દેવ ! રામદેવજી તો આપની સામે જ બેઠા છે.'
                          ‘માતાજી ! આવેલ સ્વપ્ન સાવ સાચું છે, અવધ પૂરી થઈ છે અને પિતાજી ! હવે હું અઠવાડિયામાં સમાધિ લઈ સ્વધામ દ્વારિકાપુરી જવાનો છું. માટે આઠમે સૌ સગાં સંબંધીઓને છેલ્લો મળી લઉં એ માટે ખબર પહોંચાડો.' શ્રી રામદેવજીએ ગંભીરતાથી કહ્યું.


                        </p>
                        <p className="paragraph-content">
                          દ્વારિકામાં આપેલો કોલ એજી રે...અમે આપેલો કોલ, હવે અવધુ વધાવો અજમલરાયજી... એ જી...હોજી પૂરાં કર્યાં ધારેલાં કામ, એજી રે...કર્યાં ધારેલાં કામ, અને ભક્તિનાં ફળ તમને આપીયાં... એ જી ... હોજી
                          રામદેવની અંતીમ ઇચ્છા...સમાધિ ! એ વાત સાંભળતાં અજમલજી અને મિણલદે તથા તમામ જનો ધ્રૂજી ગયાં. વીજળી સરખો આંચકો અનુભવતાં ભારે ગમગીન બની સૌ વિખરાયાં. વાત વીજળી વેગે ગામોગામ ફેલાઈ,
                          આઠમને દિવસે દરબાર ભરાયો. તુંવરા શાખના પોતાના કુટુંબીઓ સૌ
                          સગાંસંબંધીઓ એકઠા મળ્યા.
                          શ્રી રામદેવજીએ સૌ સાથે અફીણ કસુંબા પીધા-પાયા ને નોમને દિવસે સમાધિ લેવાની હોવાથી સમાધિ ખોદાવવા સૌને સાથે લઈ રામદેવ નેજો રોપવા સ્થળ બતાવવા ચાલ્યા. ઢોલ- નગારાં વાગવા લાગ્યાં. નગરજનો ટોળાંબંધ શ્રીફળ- કંકુ
                          લઈ વધાવવા આવવા લાગ્યાં.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
      {/* </div>
      </div> */}
      <div>
        <Footer />
        {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
      </div>
    </>
  );
};

export default BlogDetails23;
