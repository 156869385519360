import './App.css';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Components/Dashboard';
import Footer from './Components/Footer';
import Gallery from './Components/Gallery';
import About from './Components/About';
import PlanVisit from './Components/About/PlanVisit';
import About2 from './Components/About/About2';
import BlogMain from './Components/Blog';
import BlogDetails from './Components/Blog/BlogDetails';
import Books from './Components/E-Book/Books';
import BooksRead from './Components/E-Book/BooksRead';
import BooksRead1 from './Components/E-Book/BooksRead1';
import BooksRead2 from './Components/E-Book/BooksRead2';
import Audio from "./Components/Audio"
import { AudioDetail } from './Components/Audio/AudioDetail';
import BlogDetails2 from './Components/Blog/BlogDetails2';
import BlogDetails3 from './Components/Blog/BlogDetail3';
import BlogDetails4 from './Components/Blog/BlogDetail4';
import BlogDetails5 from './Components/Blog/BlogDetail5';
import BlogDetails6 from './Components/Blog/BlogDetail6';
import Gallery2 from './Components/Gallery/Gallery2';
import Video from './Components/Video/video';
import Contact from './Components/About/Contact';
import { Link } from 'react-router-dom';
import BlogDetails7 from './Components/Blog/BlogDetail7';
import BlogDetails8 from './Components/Blog/BlogDetail8';
import BlogDetails9 from './Components/Blog/BlogDetail9';
import BlogDetails10 from './Components/Blog/BlogDetail10';
import BlogDetails11 from './Components/Blog/BlogDetail11';
import BlogDetails12 from './Components/Blog/BlogDetail12';
import BlogDetails13 from './Components/Blog/BlogDetail13';
import BlogDetails14 from './Components/Blog/BlogDetail14';
import BlogDetails15 from './Components/Blog/BlogDetail15';
import BlogDetails16 from './Components/Blog/BlogDetail16';
import BlogDetails17 from './Components/Blog/BlogDetail17';
import BlogDetails18 from './Components/Blog/BlogDetail18';
import BlogDetails19 from './Components/Blog/BlogDetail19';
import BlogDetails20 from './Components/Blog/BlogDetail20';
import BlogDetails21 from './Components/Blog/BlogDetail21';
import BlogDetails22 from './Components/Blog/BlogDetail22';
import BlogDetails23 from './Components/Blog/BlogDetail23';
import BlogDetails24 from './Components/Blog/BlogDetail24';
import BlogDetails25 from './Components/Blog/BlogDetail25';
import BlogDetails26 from './Components/Blog/BlogDetail26';
import BlogDetails27 from './Components/Blog/BlogDetail27';
import BlogDetails28 from './Components/Blog/BlogDetail28';
import BlogDetails29 from './Components/Blog/BlogDetail29';
import BlogDetails30 from './Components/Blog/BlogDetail30';
import BlogDetails31 from './Components/Blog/BlogDetail31';
import BlogDetails32 from './Components/Blog/BlogDetail32';
import BlogDetails33 from './Components/Blog/BlogDetail33';
import BlogDetails34 from './Components/Blog/BlogDetail34';
import BlogDetails35 from './Components/Blog/BlogDetail35';
import BlogDetails36 from './Components/Blog/BlogDetail36';
import BlogDetails37 from './Components/Blog/BlogDetail37';
import BlogDetails38 from './Components/Blog/BlogDetail38';
import BlogDetails39 from './Components/Blog/BlogDetail39';
import ScrollTop from './Components/Header/scrollTop';
import AllFarmaan from './Components/Farman/AllFarmaan';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlay: false,
      playing: ''
    };
  }



  render() {
    // console.log('app',this.state.isPlay);
    return (
      <>
        <Router>
          <ScrollTop />
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/Gallery" element={<Gallery />} />
            <Route exact path="/Gallery2" element={<Gallery2 />} />
            <Route exact path='/AllFarmaan' element={<AllFarmaan />} />
            <Route exact path="/About" element={<About />} />
            <Route exact path='/About2' element={<About2 />} />
            <Route exact path="/plan-visit" element={<PlanVisit />} />
            <Route exact path="/BlogMain" element={<BlogMain />} />
            <Route exact path='/BlogDetails' element={<BlogDetails />} />
            <Route exact path="/BlogDetails2" element={<BlogDetails2 />} />
            <Route exact path="/BlogDetails3" element={<BlogDetails3 />} />
            <Route exact path="/BlogDetails4" element={<BlogDetails4 />} />
            <Route exact path="/BlogDetails5" element={<BlogDetails5 />} />
            <Route exact path="/BlogDetails6" element={<BlogDetails6 />} />
            <Route exact path='/BlogDetails7' element={<BlogDetails7 />} />
            <Route exact path="/BlogDetails8" element={<BlogDetails8 />} />
            <Route exact path="/BlogDetails9" element={<BlogDetails9 />} />
            <Route exact path="/BlogDetails10" element={<BlogDetails10 />} />
            <Route exact path="/BlogDetails11" element={<BlogDetails11 />} />
            <Route exact path="/BlogDetails12" element={<BlogDetails12 />} />
            <Route exact path="/BlogDetails13" element={<BlogDetails13 />} />
            <Route exact path='/BlogDetails14' element={<BlogDetails14 />} />
            <Route exact path="/BlogDetails15" element={<BlogDetails15 />} />
            <Route exact path="/BlogDetails16" element={<BlogDetails16 />} />
            <Route exact path="/BlogDetails17" element={<BlogDetails17 />} />
            <Route exact path="/BlogDetails18" element={<BlogDetails18 />} />
            <Route exact path="/BlogDetails19" element={<BlogDetails19 />} />
            <Route exact path='/BlogDetails20' element={<BlogDetails20 />} />
            <Route exact path="/BlogDetails21" element={<BlogDetails21 />} />
            <Route exact path="/BlogDetails22" element={<BlogDetails22 />} />
            <Route exact path="/BlogDetails23" element={<BlogDetails23 />} />
            <Route exact path="/BlogDetails24" element={<BlogDetails24 />} />
            <Route exact path="/BlogDetails25" element={<BlogDetails25 />} />
            <Route exact path='/BlogDetails26' element={<BlogDetails26 />} />
            <Route exact path="/BlogDetails27" element={<BlogDetails27 />} />
            <Route exact path="/BlogDetails28" element={<BlogDetails28 />} />
            <Route exact path="/BlogDetails29" element={<BlogDetails29 />} />
            <Route exact path="/BlogDetails30" element={<BlogDetails30 />} />
            <Route exact path="/BlogDetails31" element={<BlogDetails31 />} />
            <Route exact path="/BlogDetails32" element={<BlogDetails32 />} />
            <Route exact path="/BlogDetails33" element={<BlogDetails33 />} />
            <Route exact path='/BlogDetails34' element={<BlogDetails34 />} />
            <Route exact path="/BlogDetails35" element={<BlogDetails35 />} />
            <Route exact path="/BlogDetails36" element={<BlogDetails36 />} />
            <Route exact path="/BlogDetails37" element={<BlogDetails37 />} />
            <Route exact path="/BlogDetails38" element={<BlogDetails38 />} />
            <Route exact path="/BlogDetails39" element={<BlogDetails39 />} />
            <Route exact path='/Books' element={<Books />} />
            <Route exact path='/BooksRead' element={<BooksRead />} />
            <Route exact path='/BooksRead1' element={<BooksRead1 />} />
            <Route exact path='/BooksRead2' element={<BooksRead2 />} />
            <Route exact path='/Audio' element={<Audio state={this.state} />} onPlay={(isPlay) => this.setState({ isPlay })} />
            <Route exact path="/Video" element={<Video state={this.state} />} onPlay={(isPlay) => this.setState({ isPlay })} />
            <Route exact path='/AudioDetail' element={<AudioDetail />} />
            <Route exact path="/Contact" element={<Contact />} />
            <Route exact path="/footer" element={<Footer />} />
          </Routes>
        </Router>
      </>
    )
  }
}

// function App() {
// const isPlay = false;
// console.log('isplay',isPlay);

//   return (
//     <Router>
//       <Routes>
//         <Route  exact path='/' element={<Dashboard />} />
//         <Route  exact path="/ashram" element={<Ashram />} />
//         <Route  exact path="/programs" element={<Programs />} />
//         <Route  exact path="/Gallery" element={<Gallery />} />
//         <Route  exact path="/Gallery2" element={<Gallery2 />} />
//         <Route  exact path="/About" element={<About />} />
//         <Route  exact path='/About2' element={<About2 />} />
//         <Route  exact path="/plan-visit" element={<PlanVisit />} />
//         <Route  exact path="/BlogMain" element={<BlogMain />} />
//         <Route  exact path='/BlogDetails' element={<BlogDetails />} />
//         <Route  exact path="/BlogDetails2" element={<BlogDetails2 />} />
//         <Route  exact path="/BlogDetails3" element={<BlogDetails3 />} />
//         <Route  exact path="/BlogDetails4" element={<BlogDetails4 />} />
//         <Route  exact path="/BlogDetails5" element={<BlogDetails5 />} />
//         <Route  exact path="/BlogDetails6" element={<BlogDetails6 />} />
//         <Route  exact path='/Books' element={<Books />} />
//         <Route  exact path='/BooksRead' element={<BooksRead />} />
//         <Route  exact path='/Audio' element={<Audio />} />
//         <Route  exact path="/Video" element={<Video />} />
//         <Route  exact path='/AudioDetail' element={<AudioDetail />} />
//         <Route  exact path="/footer" element={<Footer />} />
//       </Routes>
//     </Router>

//   );
// }

export default App;

