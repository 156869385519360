import { Link } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";

import './books.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import React from "react";
import AudioPlayer from "../Audio/AudioPlayer";
import Slider from "../Slider/Slider";
import { Helmet } from "react-helmet";

const Books = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdevpir-book.jpeg";
  const img2 = '/assets/wp-content/uploads/2018/12/book2.jpg';
  const img3 = '/assets/wp-content/uploads/2018/12/ramdev-book1.jpg';
  return (
    <>
      <Helmet>
        <title>Ramapir | Books</title>
        <meta name="keywords" content=" બાબા રામદેવજીએ ભાદવા સુદી દુજથી ભાદવા સુદી અષ્ટમી સુધીના સાત દિવસીય મેળાની જાહેરાત કરી હતી, જે દરમિયાન વિવિધ ધર્મના સંતોને એકતાની ભાવનામાં બોલાવવા આમંત્રણ આપવામાં આવ્યું હતું. નિર્ધારિત અષ્ટમીની તારીખ નજીક આવી, બાબાએ સમાધિ લેવાનો ઈરાદો વ્યક્ત કર્યો. સાથોસાથ, ભક્તમતી ડાલીબાઈએ પ્રથમ સમાધિ લેવાનો પોતાનો ઈરાદો જાહેર કર્યો. પરિણામે, બાબાએ ત્રણ દિવસ પછી, ખાસ કરીને એકાદશી પર સમાધિ લેવાનું નક્કી કર્યું. આ નિર્ણયથી સ્નેહમિલન અને ભક્તિમય ભજન સત્સંગના કાર્યક્રમમાં સ્વયંભૂ વધારો થયો, જે એકાદશી સુધી ચાલુ રહ્યો.
" />
      </Helmet>
      {/* <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" /> */}

      <Header />

      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id="46"
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="52ebb12"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          style={{ height: "30rem", marginBottom: "5rem" }}
        >
          <div className="elementor-background-overlay"></div>
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
              data-id="ae9d67e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fcf91b3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                      data-id="93dfaf4"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                          data-id="847c2c5"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                          style={{ height: "8rem" }}
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                          data-id="8fe49e3"
                          data-element_type="widget"
                          data-widget_type="trx_sc_layouts_title.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              id="trx_sc_layouts_title_515615674"
                              className="sc_layouts_title sc_align_center with_content without_image without_tint"
                            >
                              <div className="sc_layouts_title_content">
                                <div className="sc_layouts_title_title">
                                  {" "}
                                  {/* <h1 className="sc_layouts_title_caption">
                                    Books
                                  </h1> */}
                                </div>
                                <div className="sc_layouts_title_breadcrumbs">
                                  <div className="breadcrumbs">
                                    {/* <Link
                                      to="/"
                                      className="breadcrumbs_item home"
                                    >
                                      Home
                                    </Link> */}

                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <h5 className="Books">Books</h5>
      </div>
      <div className="page_content_wrap">
        <div className="content_wrap">
          <div className="content">

            <div className="list_products shop_mode_list">
              <div className="list_products_header">
                <div className="woocommerce-notices-wrapper"></div>{" "}

                <div className="woocommerce-page">
                  <div className="shop_mode_list">
                    <ul className="products columns-2">
                      <li className="product type-product post-247 status-publish first instock product_cat-fiction product_cat-science product_tag-book product_tag-item product_tag-store has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                        <div className="post_item post_layout_list">
                          <div className="post_featured hover_none">
                            <Link to="/BooksRead" target="_blank" >
                              <img
                                width="370"
                                height="463"
                                src={img1}
                                className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail "
                                style={{ marginTop: '5rem' }}
                                alt=""
                                decoding="async"
                                loading="lazy"
                              />{" "}
                            </Link>
                          </div>
                          <div className="post_data">
                            <div className="post_data_inner">
                              <div className="post_header entry-header">
                                <div className="post_tags product_tags"></div>
                                <h2 className="woocommerce-loop-product__title books-title1" id="test">
                                  {/* The Buddha's Way of Happiness */}

                                  <Link to="/BooksRead" target="_blank" className="books-title" >
                                    Ramdev Ramayan
                                  </Link>
                                </h2>{" "}
                              </div>
                              <div className="post_content entry-content">
                                {/* Baba Ramdevji announced a seven-day fair, spanning from Bhadva Sudi Duj to Bhadva Sudi Ashtami, during which saints of diverse faiths were invited to convene in a spirit of unity. As the designated Ashtami date approached, Baba expressed his intention for Samadhi. Concurrently, Bhaktamati Dali Bai announced her own intention to undertake Samadhi first. Consequently, Baba decided to undergo Samadhi three days later, specifically on Ekadashi. This decision led to a spontaneous increase in the program of affectionate gatherings and devotional bhajan satsangs, which persisted until Ekadashi. */}
                                બાબા રામદેવજીએ ભાદવા સુદી દુજથી ભાદવા સુદી અષ્ટમી સુધીના સાત દિવસીય મેળાની જાહેરાત કરી હતી, જે દરમિયાન વિવિધ ધર્મના સંતોને એકતાની ભાવનામાં બોલાવવા આમંત્રણ આપવામાં આવ્યું હતું. નિર્ધારિત અષ્ટમીની તારીખ નજીક આવી, બાબાએ સમાધિ લેવાનો ઈરાદો વ્યક્ત કર્યો. સાથોસાથ, ભક્તમતી ડાલીબાઈએ પ્રથમ સમાધિ લેવાનો પોતાનો ઈરાદો જાહેર કર્યો. પરિણામે, બાબાએ ત્રણ દિવસ પછી, ખાસ કરીને એકાદશી પર સમાધિ લેવાનું નક્કી કર્યું. આ નિર્ણયથી સ્નેહમિલન અને ભક્તિમય ભજન સત્સંગના કાર્યક્રમમાં સ્વયંભૂ વધારો થયો, જે એકાદશી સુધી ચાલુ રહ્યો.
                              </div>
                            </div>

                            {/*  //post_data_inner  */}
                          </div>
                          {/*  //post_data  */}
                        </div>
                        {/* post_item */}
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>




            <div className="list_products shop_mode_list">
              <div className="list_products_header">
                <div className="woocommerce-notices-wrapper"></div>{" "}
                <div className="woocommerce-page">
                  <div className="shop_mode_list">
                    <ul className="products columns-2">
                      <li className="product type-product post-247 status-publish first instock product_cat-fiction product_cat-science product_tag-book product_tag-item product_tag-store has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                        <div className="post_item post_layout_list">
                          <div className="post_featured hover_none">
                            <Link to="/BooksRead" target="_blank" >
                              <img
                                width="370"
                                height="463"
                                src={img2}
                                className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail "
                                style={{ marginTop: '5rem' }}
                                alt=""
                                decoding="async"
                                loading="lazy"
                              />{" "}
                            </Link>
                          </div>
                          <div className="post_data">
                            <div className="post_data_inner">
                              <div className="post_header entry-header">
                                <div className="post_tags product_tags"></div>
                                <h2 className="woocommerce-loop-product__title books-title1" id="test">
                                  {/* The Buddha's Way of Happiness */}

                                  <Link to="/BooksRead1" target="_blank" className="books-title" >
                                    Ramdevra_Final_Report
                                  </Link>
                                </h2>{" "}
                              </div>
                              <div className="post_content entry-content">
                                Development and Management Plan of
                                Temple Town
                                {/* Baba Ramdevji announced a seven-day fair, spanning from Bhadva Sudi Duj to Bhadva Sudi Ashtami, during which saints of diverse faiths were invited to convene in a spirit of unity. As the designated Ashtami date approached, Baba expressed his intention for Samadhi. Concurrently, Bhaktamati Dali Bai announced her own intention to undertake Samadhi first. Consequently, Baba decided to undergo Samadhi three days later, specifically on Ekadashi. This decision led to a spontaneous increase in the program of affectionate gatherings and devotional bhajan satsangs, which persisted until Ekadashi. */}
                              </div>
                            </div>

                            {/*  //post_data_inner  */}
                          </div>
                          {/*  //post_data  */}
                        </div>
                        {/* post_item */}
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="list_products shop_mode_list">
              <div className="list_products_header">
                <div className="woocommerce-notices-wrapper"></div>{" "}
                <div className="woocommerce-page">
                  <div className="shop_mode_list">
                    <ul className="products columns-2">
                      <li className="product type-product post-247 status-publish first instock product_cat-fiction product_cat-science product_tag-book product_tag-item product_tag-store has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                        <div className="post_item post_layout_list">
                          <div className="post_featured hover_none">
                            <Link to="/BooksRead" target="_blank" >
                              <img
                                width="370"
                                height="463"
                                src={img3}
                                className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail "
                                style={{ marginTop: '5rem' }}
                                alt=""
                                decoding="async"
                                loading="lazy"
                              />{" "}
                            </Link>
                          </div>
                          <div className="post_data">
                            <div className="post_data_inner">
                              <div className="post_header entry-header">
                                <div className="post_tags product_tags"></div>
                                <h2 className="woocommerce-loop-product__title books-title1" id="test">
                                  {/* The Buddha's Way of Happiness */}

                                  <Link to="/BooksRead2" target="_blank" className="books-title" >
                                    Ramdevra
                                  </Link>
                                </h2>{" "}
                              </div>
                              <div className="post_content entry-content">

                                {/* Baba Ramdevji announced a seven-day fair, spanning from Bhadva Sudi Duj to Bhadva Sudi Ashtami, during which saints of diverse faiths were invited to convene in a spirit of unity. As the designated Ashtami date approached, Baba expressed his intention for Samadhi. Concurrently, Bhaktamati Dali Bai announced her own intention to undertake Samadhi first. Consequently, Baba decided to undergo Samadhi three days later, specifically on Ekadashi. This decision led to a spontaneous increase in the program of affectionate gatherings and devotional bhajan satsangs, which persisted until Ekadashi. */}
                                સમગ્ર બ્રહ્માંડનાં તમામ જીવોની ઉત્પત્તિ અને સંચાલન કરનાર એક પોતાને સુખ અને શાંતિ મળે તેવી ઇચ્છા રાખતો હોય છે. જન્મ થી મુખ્ય જ પરમતત્વ છે. એક જ પરમાત્માના આપણે સંતાનો છીયે. દરેક માનવી સુધી માનવ સુખ અને શાંતિ માટે સંઘર્ષ કરતો રહે છે.
                              </div>
                            </div>

                            {/*  //post_data_inner  */}
                          </div>
                          {/*  //post_data  */}
                        </div>
                        {/* post_item */}
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
      <div>
        <Footer />
        {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay }) } /> */}
      </div>
    </>
  );
};

export default Books;
