import React from "react";
import { Link } from "react-router-dom";
import './blog.css';
const Blog = () => {
	return (
		<>
      
			<div className="body_wrap">
				<div>
					<h5 className="stories">Stories</h5>
				</div>
				<section className="elementor-section elementor-top-section elementor-element elementor-element-3b0d64cb elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="3b0d64cb" data-element_type="section" data-settings="{&quot;stretch_section&quot;:&quot;section-stretched&quot;}">
					<div className="elementor-container elementor-column-gap-no">
						<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-35e6ff10 sc_inner_width_none sc_layouts_column_icons_position_left" data-id="35e6ff10" data-element_type="column">
							<div className="elementor-widget-wrap elementor-element-populated">
								<div className="elementor-element elementor-element-27c4e9e1 sc_fly_static elementor-widget elementor-widget-trx_sc_recent_news" data-id="27c4e9e1" data-element_type="widget" data-widget_type="trx_sc_recent_news.default">
									<div className="elementor-widget-container">
										<div id="trx_sc_recent_news_1927735559" className="sc_recent_news sc_recent_news_style_news-announce sc_recent_news_with_accented"><article
											className="post_item post_layout_news-announce post_format_standard post_size_big post-120 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"		>
											<div className="post_featured with_thumb hover_icon post_featured_bg vihara_inline_95389143">						<div className="mask"></div>
												<div className="icons">
													<Link to={'/BlogDetails'} aria-hidden="true" className="icon-add"></Link>
												</div>
												<div className="post_info">
													<h5 className="post_title entry-title">
														<Link to={'/BlogDetails'} aria-hidden="true" className="icon-add">શિવ-પાર્વતી સંવાદ</Link>
													</h5>
													<div className="post_footer">
														<Link to={'/BlogDetails'} aria-hidden="true" className="more-link blog-button">Read more</Link>
													</div></div></div></article><article
														className="post_item post_layout_news-announce post_format_standard post_size_medium post-144 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"		>
												<div className="post_featured with_thumb hover_icon post_featured_bg vihara_inline_912454032">						<div className="mask"></div>
													<div className="icons">
														<Link to={'/BlogDetails2'} aria-hidden="true" className="icon-add"></Link>
													</div>
													<div className="post_info">
														<h5 className="post_title entry-title">
															<Link to={'/BlogDetails2'} aria-hidden="true" rel="bookmark">નંદનો અવતાર-અજમલજી</Link>
														</h5>
														<div className="post_footer">
															<Link to={'/BlogDetails2'} aria-hidden="true" className='more-link blog-button'>Read More</Link>
														</div></div></div></article><article
															className="post_item post_layout_news-announce post_format_standard post_size_medium post-686 post type-post status-publish format-standard has-post-thumbnail hentry category-news">
												<div className="post_featured with_thumb hover_icon post_featured_bg vihara_inline_1392605594">						<div className="mask"></div>
													<div className="icons">
														<Link to={'/BlogDetails3'} aria-hidden="true" className="icon-add"></Link>
													</div>
													<div className="post_info">
														<h5 className="post_title entry-title">
															<Link to={'/BlogDetails3'} aria-hidden="true" rel="bookmark">શ્રી રામદેવજીનું પ્રાગટય</Link>
														</h5>
														<div className="post_footer">
															<Link to={'/BlogDetails3'} aria-hidden="true" className='more-link blog-button'>Read More</Link>
														</div>
														 {/* <aside id="search-4">
                                        <form
                                            role="search"
                                            method="get"
                                            className="search-form"
                                            action="/"
                                        >
                                            <Link to={'/'}>
                                                <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
                                            </Link>
                                            <label>

                                                <input
                                                    type="text"
                                                    className="search-field"
                                                    placeholder="Search &hellip;"
                                                    autoComplete="off"
                                                    style={{ width: '100%' }}
                                                />
                                            </label>
                                        </form>
                                    </aside> */}
													</div></div></article></div>	</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};
export default Blog;
