import React, { useState, useRef, useEffect } from "react";
import styles from "./AudioPlayer.module.css";
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Audio.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import "../Farman/index.css";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Link } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import AudioPlayer from './AudioPlayer';
import { Helmet } from "react-helmet";
const AudioList = (props) => {
  const img1 = "../../assets/wp-content/uploads/2018/12/ramdev-gallery1.jpg";
  const img2 = "../../assets/wp-content/uploads/2018/12/ramdev-gallery2.jpg";
  const img3 = "../../assets/wp-content/uploads/2018/12/ramdev-gallery3.jpg";
  const img4 = "../../assets/wp-content/uploads/2018/12/ramdev-gallery4.jpg";
  const img5 = "../../assets/wp-content/uploads/2018/12/ramdev-gallery5.jpg";
  const img6 = "../../assets/wp-content/uploads/2018/12/ramdev-gallery6.jpg";
  // console.log(props.state.isPlay);
  // const options = {
  //   loop: true,
  //   items: 4,
  //   margin: 10,
  //   autoplay: true,
  //   dots: true,
  //   smartSpeed: 500,
  //   nav: false,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     600: {
  //       items: 3,
  //     },
  //     1000: {
  //       items: 4,
  //     },
  //   },
  // };

  return (
    <>
    <Helmet>
        <title>Ramapir | Bhajans</title>
        <meta name="keywords" content="  આ સંકુલમાં ડાલીબાઈ જેવા તેમના શિષ્યો અને તેમના કેટલાક મુખ્ય શિષ્યોની સમાધિઓ પણ છે. સંકુલમાં મક્કાના પાંચ મુસ્લિમ પીરની કબરો પણ છે,
                                તેમાં પાણીના પગથિયાના કૂવા પણ છે, જેનું પાણી ભક્તો માને છે કે તે ઉપચાર શક્તિ ધરાવે છે
" />
      </Helmet>
      
      <Header />

      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id="46"
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="52ebb12"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          style={{ height: "30rem", marginBottom: "5rem" }}
        >
          <div className="elementor-background-overlay"></div>
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
              data-id="ae9d67e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fcf91b3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                      data-id="93dfaf4"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                          data-id="847c2c5"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                          style={{ height: '8rem' }}
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                          data-id="8fe49e3"
                          data-element_type="widget"
                          data-widget_type="trx_sc_layouts_title.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              id="trx_sc_layouts_title_515615674"
                              className="sc_layouts_title sc_align_center with_content without_image without_tint"
                            >
                              <div className="sc_layouts_title_content">
                                <div className="sc_layouts_title_title">
                                  {" "}
                                  <h1 className="sc_layouts_title_caption">

                                  </h1>
                                </div>
                                <div className="sc_layouts_title_breadcrumbs">
                                  <div className="breadcrumbs">
                                    <Link to='/' className="breadcrumbs_item home"></Link>

                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <div className="page_content_wrap ">
        <div className="content_wrap">
          <div className="content">
            <div className="posts_container columns_wrap columns_padding_bottom">
              <div className="column-1_2">
                <article
                  id="post-686"
                  className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-686 post type-post status-publish format-standard has-post-thumbnail hentry category-news"
                >
                  <div className="post_featured with_thumb hover_icon">
                    <img width="300" height="169" src={img1} className="attachment-medium size-medium audio-image"
                      alt="" decoding="async" loading="lazy"
                      sizes="(max-width: 300px) 100vw, 300px" />
                    <div className="mask" />
                  </div>{" "}
                  <div className="post_header entry-header">
                    <h4 className="post_title entry-title">
                      <Link to={'/AudioDetail'} rel="bookmark" target="_blank" className="v-title"> RamDev pir</Link>
                    </h4>
                  </div>

                  <div className="sc_services_item_button sc_item_button more_info">
                    <a href='/AudioDetail'
                      className="sc_button sc_button_default sc_button_size_small"
                    >
                      Play Now
                    </a>
                  </div>
                </article>
              </div>
              <div className="column-1_2">
                <article
                  id="post-120"
                  className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-120 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                >
                  <div className="post_featured with_thumb hover_icon">
                    <img width="300" height="169"
                      src={img2} className="attachment-medium size-medium audio-image" alt="" decoding="async" loading="lazy"
                      sizes="(max-width: 300px) 100vw, 300px" />

                    <div className="mask" />
                  </div>{" "}
                  <div className="post_header entry-header">
                    <h4 className="post_title entry-title">
                      <Link to={'/AudioDetail'} rel="bookmark" className="v-title" target="_blank">Shree Krishna</Link>
                    </h4>{" "}

                  </div>

                  <div className="sc_services_item_button sc_item_button more_info">
                    <a href='/AudioDetail'
                      className="sc_button sc_button_default sc_button_size_small"
                    >
                      Play Now
                    </a>
                  </div>
                </article>
              </div>
              <div className="column-1_2">
                <article
                  id="post-144"
                  className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-144 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                >
                  <div className="post_featured with_thumb hover_icon">
                    <img width="300" height="169" src={img3}
                      className="attachment-medium size-medium audio-image" alt="" decoding="async" loading="lazy"
                      sizes="(max-width: 300px) 100vw, 300px" />
                    <div className="mask" />
                  </div>{" "}
                  <div className="post_header entry-header">
                    <h4 className="post_title entry-title">
                      <Link to={'/AudioDetail'} rel="bookmark" target="_blank" className="v-title">Ramdev Pir </Link>
                    </h4>{" "}
                  </div>

                  <div className="sc_services_item_button sc_item_button more_info">
                    <a href='/AudioDetail'
                      className="sc_button sc_button_default sc_button_size_small"
                    >
                      Play Now
                    </a>
                  </div>
                </article>
              </div>
              <div className="column-1_2">
                <article
                  id="post-146"
                  className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-146 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                >
                  <div className="post_featured with_thumb hover_icon">
                    <img width="300" height="169"
                      src={img4} className="attachment-medium size-medium audio-image" alt="" decoding="async" loading="lazy"
                      sizes="(max-width: 300px) 100vw, 300px" />
                    <div className="mask" />
                  </div>{" "}
                  <div className="post_header entry-header">
                    <h4 className="post_title entry-title">
                      <Link to={'/AudioDetail'} rel="bookmark" target="_blank" className="v-title">Shree Krishna</Link>
                    </h4>{" "}

                  </div>

                  <div className="sc_services_item_button sc_item_button more_info">
                    <a href='/AudioDetail'
                      className="sc_button sc_button_default sc_button_size_small"
                    >
                      Play Now
                    </a>
                  </div>
                </article>
              </div>
              <div
                className="column-1_2">
                <article
                  id="post-142"
                  className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-142 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                >
                  <div className="post_featured with_thumb hover_icon">
                    <img width="300" height="169"
                      src={img5}
                      className="attachment-medium size-medium audio-image" alt="" decoding="async" loading="lazy"
                      sizes="(max-width: 300px) 100vw, 300px" />
                    <div className="mask" />
                  </div>{" "}
                  <div className="post_header entry-header">
                    <h4 className="post_title entry-title">
                      <Link to={'/AudioDetail'} rel="bookmark" target="_blank" className="v-title">RamDev pir</Link>
                    </h4>{" "}
                  </div>

                  <div className="sc_services_item_button sc_item_button more_info">
                    <a href='/AudioDetail'
                      className="sc_button sc_button_default sc_button_size_small"
                    >
                      Play Now
                    </a>
                  </div>
                </article>
              </div>
              <div
                className="column-1_2"
              >
                <article
                  id="post-148"
                  className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-148 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                >
                  <div className="post_featured with_thumb hover_icon">
                    <img width="300" height="169"
                      src={img6}
                      className="attachment-medium size-medium audio-image" alt="" decoding="async" loading="lazy"
                      sizes="(max-width: 300px) 100vw, 300px" />

                    <div className="mask" />
                  </div>{" "}
                  <div className="post_header entry-header">
                    <h4 className="post_title entry-title">
                      <Link to={'/AudioDetail'} rel="bookmark" target="_blank" className="v-title">Shree Krishna</Link>

                    </h4>{" "}

                  </div>

                  <div className="sc_services_item_button sc_item_button more_info">
                    <a href='/AudioDetail'
                      className="sc_button sc_button_default sc_button_size_small"
                    >
                      Play Now
                    </a>
                  </div>
                </article>
              </div>


            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div> */}
      <div>
        <h5 className="audio">Bhajans</h5>
      </div>
      <div className="page_content_wrap ">
        <div className="content_wrap">
          <div className="content">
            <div className="posts_container columns_wrap columns_padding_bottom">
              <div className="column-1_2">
                <article
                  id="post-686"
                  className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-686 post type-post status-publish format-standard has-post-thumbnail hentry category-news"
                >
                  <div className="post_featured with_thumb hover_icon">
                    <iframe
                      width={560}
                      height={315}
                      src="https://www.youtube.com/embed/HbMfUGibUa4?si=P3WC3fzP1I15XIZW"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    />
                    <div className="mask" />
                  </div>{" "}
                  <div className="post_header entry-header">
                    <h4 className="post_title entry-title">
                      <Link to={'https://www.youtube.com/embed/HbMfUGibUa4?si=P3WC3fzP1I15XIZW'} rel="bookmark" target="_blank" className="v-title">Ramdev pir ni Aarti</Link>
                    </h4>
                  </div>
                  <div className="post_content entry-content">
                    {/* <div className="post_content_inner">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing
                      elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat. Ut wisi enim ad
                      minim veniam, quis nostrud exerci…
                    </div>{" "} */}
                  </div>
                </article>
              </div>
              <div className="column-1_2">
                <article
                  id="post-120"
                  className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-120 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                >
                  <div className="post_featured with_thumb hover_icon">
                    <iframe
                      width={560}
                      height={315}
                      src="https://www.youtube.com/embed/oIdI9WF92Yc?si=QNBXlH0Uil5UG_6B"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    />

                    <div className="mask" />
                  </div>{" "}
                  <div className="post_header entry-header">
                    <h4 className="post_title entry-title">
                      <Link to={'https://www.youtube.com/embed/oIdI9WF92Yc?si=QNBXlH0Uil5UG_6B'} rel="bookmark" className="v-title" target="_blank">Ramdev pir nonstop bhajan</Link>
                    </h4>{" "}

                  </div>
                  <div className="post_content entry-content">
                    {/* <div className="post_content_inner">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing
                      elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat. Ut wisi enim ad
                      minim veniam, quis nostrud exerci…
                    </div>{" "} */}
                  </div>
                </article>
              </div>
              <div className="column-1_2">
                <article
                  id="post-144"
                  className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-144 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                >
                  <div className="post_featured with_thumb hover_icon">
                    <iframe
                      className="video-content"
                      width={370}
                      height={208}
                      src="https://www.youtube.com/embed/b3u90OC8jOk?si=g_ufjVcQT73FLHLD"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    />
                    <div className="mask" />
                  </div>{" "}
                  <div className="post_header entry-header">
                    <h4 className="post_title entry-title">
                      <Link to={'https://www.youtube.com/embed/b3u90OC8jOk?si=g_ufjVcQT73FLHLD'} rel="bookmark" target="_blank" className="v-title">Ramdevpir No Rankar </Link>
                    </h4>{" "}
                  </div>
                  <div className="post_content entry-content">
                    {/* <div className="post_content_inner">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing
                      elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat. Ut wisi enim ad
                      minim veniam, quis nostrud exerci…
                    </div>{" "} */}
                  </div>
                </article>
              </div>
              <div className="column-1_2">
                <article
                  id="post-146"
                  className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-146 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                >
                  <div className="post_featured with_thumb hover_icon">
                    <iframe
                      width={560}
                      height={315}
                      src="https://www.youtube.com/embed/1q0S1gnzKu4?si=j7AaUeeOAYYy6OzU"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    />
                    <div className="mask" />
                  </div>{" "}
                  <div className="post_header entry-header">
                    <h4 className="post_title entry-title">
                      <Link to={'https://www.youtube.com/embed/1q0S1gnzKu4?si=j7AaUeeOAYYy6OzU'} rel="bookmark" target="_blank" className="v-title">Lila Pila Neja Farke</Link>
                    </h4>{" "}

                  </div>
                  <div className="post_content entry-content">
                    {/* <div className="post_content_inner">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing
                      elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat. Ut wisi enim ad
                      minim veniam, quis nostrud exerci…
                    </div>{" "} */}
                  </div>
                </article>
              </div>
              <div
                className="column-1_2">
                <article
                  id="post-142"
                  className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-142 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                >
                  <div className="post_featured with_thumb hover_icon">
                    <iframe
                      width={560}
                      height={315}
                      src="https://www.youtube.com/embed/uXVbUcZ4I7o?si=HrQyBQ5sB_WCdj4m"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    />

                    <div className="mask" />
                  </div>{" "}
                  <div className="post_header entry-header">
                    <h4 className="post_title entry-title">
                      <Link to={'https://www.youtube.com/embed/uXVbUcZ4I7o?si=HrQyBQ5sB_WCdj4m'} rel="bookmark" target="_blank" className="v-title">Ramapir Movie</Link>
                    </h4>{" "}
                  </div>
                  <div className="post_content entry-content">
                    {/* <div className="post_content_inner">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing
                      elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat. Ut wisi enim ad
                      minim veniam, quis nostrud exerci…
                    </div>{" "} */}
                  </div>
                </article>
              </div>
              <div
                className="column-1_2"
              >
                <article
                  id="post-148"
                  className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-148 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                >
                  <div className="post_featured with_thumb hover_icon">
                    <iframe
                      width={560}
                      height={315}
                      src="https://www.youtube.com/embed/5Q3JJfQVG0s?si=7yrWOHcBskCjKen_"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    />

                    <div className="mask" />
                  </div>{" "}
                  <div className="post_header entry-header">
                    <h4 className="post_title entry-title">
                      <Link to={'https://www.youtube.com/embed/5Q3JJfQVG0s?si=7yrWOHcBskCjKen'} rel="bookmark" target="_blank" className="v-title">Dhup Ne Dhumade Vela Aavjo</Link>

                    </h4>{" "}

                  </div>
                  <div className="post_content entry-content">
                    {/* <div className="post_content_inner">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing
                      elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat. Ut wisi enim ad
                      minim veniam, quis nostrud exerci…
                    </div>{" "} */}
                  </div>
                </article>
              </div>
              <div
                className="
column-1_2"
              >
                <article
                  id="post-151"
                  className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-151 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                >
                  <div className="post_featured with_thumb hover_icon">
                    <iframe
                      className="video-content"
                      width={370}
                      height={208}
                      src="https://www.youtube.com/embed/rGulqk34O1Y?si=6lLSfPbehO6Hb_H-"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    />
                    <div className="mask" />
                  </div>{" "}
                  <div className="post_header entry-header">
                    <h4 className="post_title entry-title">
                      <Link to={'https://www.youtube.com/embed/rGulqk34O1Y?si=6lLSfPbehO6Hb_H-'} rel="bookmark" target="_blank" className="v-title">Ramdev Ni Kankotri</Link>
                    </h4>{" "}
                  </div>
                  <div className="post_content entry-content">
                    {/* <div className="post_content_inner">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing
                      elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat. Ut wisi enim ad
                      minim veniam, quis nostrud exerci…
                    </div>{" "} */}
                  </div>
                </article>
              </div>
              <div
                className="
column-1_2"
              >
                <article
                  id="post-154"
                  className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-154 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                >
                  <div className="post_featured with_thumb hover_icon">
                    <iframe
                      className="video-content"
                      width={370}
                      height={208}
                      src="https://www.youtube.com/embed/EgUqk5bqREU?si=RI5J7Zbu8lQPNPL9"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    />
                    <div className="mask" />
                  </div>{" "}
                  <div className="post_header entry-header">
                    <h4 className="post_title entry-title">
                      <Link to={'https://www.youtube.com/embed/EgUqk5bqREU?si=RI5J7Zbu8lQPNPL9'} rel="bookmark" target="_blank" className="v-title">Ramdevpir Dayro</Link>
                    </h4>{" "}

                  </div>
                  <div className="post_content entry-content">
                    {/* <div className="post_content_inner">
                      Lorem ipsum dolor sit amet, consectetuer adipiscing
                      elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat. Ut wisi enim ad
                      minim veniam, quis nostrud exerci…
                    </div>{" "} */}
                  </div>
                </article>
              </div>{" "}
            </div>
          </div>
          <div className="clearfix" />
        </div>
      </div>


      <div >
        <Footer />
        {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay }) } /> */}
      </div>
    </>
  );
};
export default AudioList;
