import React from "react";
import Carousel from "react-bootstrap/Carousel";

import "./index.css";
// import React from 'react';
// import OwlCarousel from 'react-owl-carousel2';
// import 'react-owl-carousel2/style.css';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { faBedPulse } from "@fortawesome/free-solid-svg-icons";

const SantVani = () => {
    const options = {
        loop: true,
        items: 2,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 8000,
        // dots: true,
        smartSpeed: 3000,
        nav: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 2,
            },
        },
    };

    return (
        <>
      
            <div>
                <h5 className="farman">SantVani</h5>
            </div>
            <div className="container">
                <OwlCarousel className="owl-theme" {...options}>
                    <div className="item">
                        <div className="d-flex justify-content-center ">
                            <div className="card ">
                                <div className="card-body">
                                    <h5 className="card-title custom-title"> પ્રથમ પહેલા કોને સમરિયે
                                        કોના લઈએ નામ
                                        માત પિતા ગુરુ આપણા લીજીયે અલખ પુરુષ ના નામ
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="d-flex justify-content-center ">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title custom-title">
                                        ધજા દીઠે ધણી સાંભળે
                                        એ દેવળ દીઠે દુઃખ જાય દર્શન કરતા રામો પીરના
                                        સઘળા પ્રાયશ્ચિત થાય
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="d-flex justify-content-center">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title custom-title">રામા તમારા દેવળે
                                        બળે ગૂગળ ના ધૂપ નરનારી તમને નમનું કરે. તમને નમે મોટા મોટા લૂપ
                                    </h5>
                                </div>
                            </div>
                        </div>{" "}
                    </div>
                    <div className="item">
                        <div className="d-flex justify-content-center">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title custom-title">કળશ માં કળા બિરાજે અને નેજામાં વર્ષે નુર
                                        દેવળ માં પીર પોતે બિરાજે
                                        પીર મારો બેઠા છે જ્યોત સ્વરૂપ
                                    </h5>
                                </div>
                            </div>
                        </div>{" "}
                    </div>
                    <div className="item">
                        <div className="d-flex justify-content-center">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title"> રામા કહુકે રામદેવ
                                        હીરા કહુકે નાથ
                                        નર ને રણુજા વાળો ભેટિયા
                                        ન
                                        તો નર થઈ ગયા ન્યાલ
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="d-flex justify-content-center">
                            <div className="card">
                                {/* <img src={farmaan6} className="card-img-top" alt="Card 2 Image" /> */}
                                <div className="card-body">
                                    <h5 className="card-title">હરજી હાલો દેવળે પૂજવા રામા પીર
                                        કોડીયા ના કોડ મટાડિયા બાપ સાજા કરે શરીર
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="d-flex justify-content-center">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">રામદેવ રક્ષા કરો
                                        અને હોં સંકટ સંતાપ
                                        સુખ કરતા સમરથ ધર્મે
                                        જપુ નિરંતર જાપ
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </OwlCarousel>
            </div>
        </>
    );
};

export default SantVani;
