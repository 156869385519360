import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import "./Gallery.css";

import Header from "../Header";
import { Link } from "react-router-dom";
import "./Gallery.css";
import Slider from "../Slider/Slider";

const Gallery = () => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery1.jpg";
  const img2 = "/assets/wp-content/uploads/2018/12/ramdev-gallery9.jpg";
  const img3 = "/assets/wp-content/uploads/2018/12/ramdev-gallery3.jpg";
  const img4 = "/assets/wp-content/uploads/2018/12/ramdev-gallery13.jpg";
  const img5 = "/assets/wp-content/uploads/2018/12/ramdev-gallery14.jpg";
  const img6 = "/assets/wp-content/uploads/2018/12/ramdev-gallery21.jpg";
  const img7 = "/assets/wp-content/uploads/2018/12/ramdev-gallery20.jpg";
  const img8 = "/assets/wp-content/uploads/2018/12/ramdev-gallery22.jpg";
  const img9 = "/assets/wp-content/uploads/2018/12/ramdev-gallery4.jpg";
  const img10 = "/assets/wp-content/uploads/2018/12/ramdev-gallery2.jpg";
  const itemData = [
    {
      img: img1,
      // title: "Bed",
      height: 460,
    },
    {
      img: img2,
      // title: "Books",
      height: 300,
    },
    {
      img: img3,
      // title: "Sink",
      height: 407,
    },
    {
      img: img4,
      // title: "Kitchen",
      height: 285,
    },
    {
      img: img5,
      // title: "Blinds",
      height: 275,
    },
    {
      img: img6,
      // title: "Chairs",
      height: 275,
    },
    {
      img: img7,
      // title: "Laptop",
      height: 325,
    },
    {
      img: img8,
      // title: "Doors",
      height: 375,
    },
    {
      img: img9,
      // title: "Coffee",
      height: 275,
    },
    {
      img: img10,
      // title: "Storage",
      height: 520,
    },
  ];




  return (
    <>

      {/* <Header /> */}
      <div>
        <h5 className="gallery" >Gallery</h5>
        <span className="gallery2"> View the Gallery of Our Temple</span>
      </div>
      <br />
      <div className="container">
        <ImageList variant="masonry" cols={3} gap={8} className="img-section">
          {itemData.map((item) => (
            <ImageListItem key={item.img} className="ImageListItem">
              <a href={`${item.img}`} data-lightbox="image-1" data-title={item.title} >
                <img
                  className="Image"
                  srcSet={`${item.img}`}
                  src={`${item.img}`}
                  style={{ height: item.height, width: item.width }}
                  alt={item.title}
                  loading="lazy"
                />
                <div className="middle">
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
                </div>
              </a>
            </ImageListItem>
          ))}
        </ImageList>
      </div>
      {/* <div className="body_wrap">
        <div className="esg-center eg-gallery-element-8 esg-none esg-clear esg-line-break"></div>
        <div className="esg-center eg-gallery-element-9 esg-none esg-clear esg-line-break"></div>

        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-fec1830 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="fec1830"
          data-element_type="section"
        >
          <div>
            <ImageList variant="masonry" cols={3} gap={8} className="img-section">
              {itemData.map((item) => (
                <ImageListItem key={item.img} className="ImageListItem">
                  <a href={`${item.img}`} data-lightbox="image-1" data-title={item.title} >
                    <img
                      className="Image"
                      srcSet={`${item.img}`}
                      src={`${item.img}`}
                      style={{ height: item.height, width: item.width }}
                      alt={item.title}
                      loading="lazy"
                    />
                    <div className="middle">
                      <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
                    </div>
                  </a>
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        </section >
      </div > */}
      <br />
      <div className="sc_services_item_button sc_item_button " style={{ display: 'block', textAlign: 'center', marginLeft: 'auto' }}>
        <Link to="/Gallery2" className="sc_button sc_button_default sc_button_size_small">See More</Link>
      </div>
      {/* <Footer /> */}
    </>
  );
};
export default Gallery;