import { useState } from 'react';

const BooksRead2 = () => {
  const Book3 = "/books/Ramdevra.pdf";
  return (
    <>
      <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
      <div>
        <iframe src={Book3} style={{ width: '100%', height: '100rem' }} />
      </div>

    </>
  );
};
export default BooksRead2;
