import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails36 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery38.jpg";
  return (
    <>
      {/* <div className="body_wrap">
        <div className="page_wrap"> */}

<meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
      <Header />
      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id="46"
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="52ebb12"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          style={{ height: "30rem", marginBottom: "5rem" }}
        >
          <div className="elementor-background-overlay"></div>
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
              data-id="ae9d67e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fcf91b3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                      data-id="93dfaf4"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                          data-id="847c2c5"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                          style={{ height: "8rem" }}
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                          data-id="8fe49e3"
                          data-element_type="widget"
                          data-widget_type="trx_sc_layouts_title.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              id="trx_sc_layouts_title_515615674"
                              className="sc_layouts_title sc_align_center with_content without_image without_tint"
                            >
                              <div className="sc_layouts_title_content">
                                <div className="sc_layouts_title_title">
                                  {" "}
                                 
                                </div>
                                <div className="sc_layouts_title_breadcrumbs">
                                  <div className="breadcrumbs">
                                     
                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



      <div className="page_content_wrap blog-details">
        <div className="content_wrap" >
          <div className="content">
            <div id="container" className="give-wrap container">
              <div id="content" role="main">
                <div
                  id="give-form-288-content"
                  className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                >
                  <div
                    id="give-sidebar-left"
                    className="give-sidebar give-single-form-sidebar-left"
                  >
                    <div className="images">
                      <img
                        width="1024"
                        height="576"
                        src={img1}
                        className="attachment-large size-large wp-post-image blog-img"
                        alt=""
                        decoding="async"
                        sizes="(max-width: 1024px) 100vw, 1024px"
                      />
                    </div>
                  </div>
                  <div className="summary entry-summary">
                    <h2 itemProp="name" className="give-form-title entry-title">
                    ફક્કડાનાથ
                    </h2>{" "}
                    <div
                      id="give-form-288-wrap"
                      className="give-form-wrap give-display-onpage"
                    >
                      <div className="give-goal-progress">
                        <div
                          className="raised blog-title"
                         
                        >
                          <span className="income">ફક્કડાનાથ

                          </span>
                        </div>
                      </div>

                      <div
                        id="give-form-content-288"
                        className="give-form-content-wrap give_pre_form-content"
                      >
                        <p className="paragraph-content" >
                          ક્ષત્રી કુળ શીશોદિયા, સરદારસંગજી નામ, સેવક રામદેપીરના, રહેતા પાલી
                          ગામ.
                          શિષ્ય રામગરજી તણા, ભજન ભક્તિ નિષ્કામ,
                          બીજ પૂનમે દર્શને, જતા રણુંજા ધામ.
                          મેવાડમાં ચિત્તોડગઢના શીશોદિયા કુળનાં સૈન્ય દિલ્હીના અકબર બાદશાહના સૈન્ય સાથે ઘણી વખત ટકરાતાં હતાં. રાણો કુંભો અને રાણા પ્રતાપે ચિત્તોડ છોડવું પડ્યું, છતાં દુશ્મનાવટની વેલી પાંગરતી ગઈ.
                          એ શાખાના ઘણા ‘ઘરઘોડિયા' જમીનદાર ક્ષત્રીઓ જુદાં જુદાં કામે વળ્યા. સરાણિયા નાના મોટા ગામમાં વસાવટ કરી, ક્ષત્રીઓનાં શસ્ત્રો, તલવાર, ભાલા, બરછી, કટાર, ચપ્પાં, કાતર, કીરપાણ અને કુકરી જેવાં હથિયારો બનાવીને જીવન ગુજારો કરવા લાગ્યા.
                          બ્રાહ્મણો, વાણિયા, સુથાર, લુહાર ઇત્યાદિ અનેક લોકોએ ચિત્તોડને છેલ્લું વંદન કરી અરવલ્લીની ગિરિમાળામાં ઈડરની આસપાસ આશ્રય મેળવી લીધો અને સદાને માટે ત્યાં વસ્યા. અત્યારે પણ તે મેવાડા બ્રાહ્મણો અને મેવાડા સુથારો કહેવાય છે.
                          ઘણાંએ સ્વદેશ-ભારતમાંથી બાદશાહીની જડ ના ઊખડે ત્યાં સુધી કોઈ ગામમાં વાસ ન કરવો અને વનવગડામાં જ જીવન વિતાવવું એમ નિશ્ચય કરીને લોઢામાંથી જાતજાતનાં વાસણ, ઝારા, તાવેથા, ચીપિયા, તગારાં, તાવડી બનાવવાનો ધંધો કરવા માંડ્યો. એ ગાડરિયા લુહાર નામે ઓળખાતા અને વગડામાં જ રહેતા હતા. માલ બનાવી ગામમાં આવી વેચી જતા. ઘર અને ઘરવખરી ગાડામાં હોવાથી એ ગાડરિયાથી ઓળખાતા હતા.
                          શ્રી રામદેવજીની ક્ષત્રિયો પર કૃપાદૃષ્ટિ થઈ અને પોતાના અનન્ય સેવક ભાઠી હરજીને આજ્ઞા કરી કે, શીશોદિયા શાખાના ! ક્ષત્રિયો શહેર, ગામડું કે વનવગડામાં વાસ કરતા હોય, તેમને વિધર્મીઓ કોઈ પ્રકારની લાલચ આપી ધર્મ ભ્રષ્ટ કરે નહીં.

                        </p>
                        <p className="paragraph-content">
                          તે માટે તમારે એમને બોધ-ઉપદેશ આપી ભજનભાવ તરફ વાળવા. ભાઠી હરજીએ શહેર-ગામડાં અને વગડામાં પરિભ્રમણ કરી લીલુડે ધોડે અસ્વારીવાળાં ધાતુનાં પાન સહુને આપ્યાં અને યાત્રાધામ રણુંજામાં દર વર્ષે જવા સમજાવ્યા. એ સમયે પાલીગામમાં એક ક્ષત્રિય રાજપૂત રહેતા હતા. એમનું નામ રાણા સામંતસંગ. એ ઊંટના સોદાગર હતા. ભીંડરમાં રહેતા દલુ શેઠ સાથે એમને લેવડદેવડ હતી.
                          દલુશેઠે ભેખ લીધો અને પોતાની માલમિલકત દયાદાનમાં વાપરી. લેણદારનાં ખાતાં માંડી વાળ્યાં અને કોઈ સાથે વેપારધંધાની લેવડદેવડ બાકી ના રાખી. એ ખાતામાં રાણા સામંતસંગ પણ દેવાથી મુક્ત થયા હતા.
                          સામંતસંગ દર પૂનમ અને બીજને દિવસે રણુંજા જતા હતા. એ વખતે પોતાના પુત્રને સાથે લઈ જતા હતા. એમનું નામ સરદારસંગ હતું, નાનપણથી માતાપિતાના ભક્તિ સંસ્કારથી સરદારસંગ ભક્તિમાં રંગાતો જતો હતો.
                          રામગરનો સરદારસંગ પર પ્રેમ હતો. એ રાતદિવસ ગુરુસેવા કરી ગુરુઆદેશ પ્રમાણે વર્તતો હતો.
                          એક દિવસ રામગરજી સરદારસંગ પર પ્રસન્ન થયા અને નિષ્કામ કર્મ કરી ભક્તિ કરવાનો તેને ઉપદેશ આપ્યો. એ જ સમયે સરદારસંગે ચાદર જેવા કપડાને વચ્ચેથી ફાડીને પોતાના માથામાં પહેરી લીધું અને રામગરજીનાં ચરણ પકડી માથું નમાવી વંદન કર્યાં.
                          એ વખતે રામજીએ ઝોળી અને ધૂપની ભસ્મ આપી. ‘જય ફક્કડાનાથ !’ કહી તેમને બિરદાવ્યા. સરદારસંગ ફક્કડાનાથનું બિરુદ પામ્યા ત્યાંથી સહુ તેમને ફક્કડાનાથના નામથી સંબોધવા લાગ્યા.
                          ફક્કડાનાથે પોતાના પાલીગામ જઈ જમીન પિત્રાઈઓને આપી. દરદાગીના બહેન, દીકરીઓ, ભાણેજોને વહેંચી આપ્યા અને રોકડ મૂડી હતી તે બ્રાહ્મણ, સાધુ, અભ્યાગત, ભાટ, ચારણોને દાનદક્ષિણામાં આપી દીધી અને પાલીગામને છેલ્લાં પ્રણામ કરી રણુંજા આવ્યા.
                          રણુંજાથી ગુરુ રામગર ધર્મપ્રચારના પ્રવાસે મેવાડ તરફ ગયાના સમાચાર ફક્કડાનાથને મળ્યા. મેવાડમાં ઘણાં ધર્મસ્થાનોમાં પૂછપરછ કરવા લાગ્યા પણ માહિતી મળી નહીં. પોતે જાણતા હતા કે ગુરુ અવધૂત છે ! મસ્ત જીવનવાળા છે. એમના મનનું માપ કોઈ કાઢી શકે એવો નથી.
                          છેવટે સમાચાર મળ્યા કે રામગરજી જોધપુરમાં છે, તેથી તેઓ જોધપુરમાં

                        </p>
                        <p className="paragraph-content">
                          આવ્યા અને રામજીમંદિરમાં ઊતર્યા. પૂજારીએ જણાવ્યું કે તેઓ અહીંથી ચાલ્યા ગયા છે. અને સોરઠ પ્રદેશમાં જવાનું કહેતા હતા.
                          જોધપુરથી વાત સાંભળી ફક્કડાનાથ-સરદારસંગ સોરઠભૂમિમાં આવ્યા. પરંતુ તેમને ગુરુજી મળ્યા નહીં. છતાં એમની ગુરુદર્શનની ધૂન ઓસરી નહીં. ફક્કડાનાથને ગુરુદર્શન વિના તૃપ્તિ થતી ન હોવાથી એ વનવગડા ખૂંદવા લાગ્યા.
                          ફરતાં ફરતાં ફક્કડાનાથ ગિરનારમાં આવ્યા. ત્યાં તેમને ભોજા ભગત, કહળસંગ, ગંગાસતી, જેમલ રાઠોડ, નીરલબાઈના ગુરુ રામેતવન મળ્યા. એમણે જણાવ્યું કે રામગરજી તો રંગપર ગયા છે, તેથી તેમણે રંગપરનો માર્ગ લીધો.
                          દોહા
                          સેવકગણના સાથમાં, દીઠા ગુરુને ત્યાંય,
                          શીશ નમાવ્યું ચરણમાં, હૈયામાં હરખાય.
                          રામગરજી મંડપમાં માનવધર્મ વિષે, અમૃતવાણી વહાવતા હતા. તે વેળા ‘જય ગુરુદેવ’ કહી ફક્કડાનાથે સભામાં પ્રવેશ કરી રામગરજીનાં ચરણમાં માથું નમાવ્યું. ગુરુદર્શનથી એમના હૈયામાં હરખનો પાર ન રહ્યો. એ વખતે ગુરુશિષ્ય કોટિ વિષે પ્રવચન થતું હતું. રામગરજીએ પોતાના વાણીપ્રવાહની સરવાણીને રોકતા કહ્યું :
                          દોહા
                          કંચન કદી કટાય નહીં, કટાય પિત્તળ લોહ,
                          શિષ્ય કરે જો કપટછળ, અક્ષમ્ય એ ગુરુદ્રોહ.
                          ‘ગુરુના બોધ, ઉપદેશને, આદેશને પૂરી રીતે પાળનાર શિષ્યનું મન કંચન જેવું હોય છે. ગુરુસેવા, આધિનતા, આસ્થા અને શ્રદ્ધાથી એનું મન રંગાયેલું હોય છે. નિર્મળ હોય છે. કંચન કોટિના શિષ્યને દ્વેષ કે ઈર્ષ્યાનો કાટ લાગતો નથી અને તે ગુરુઆજ્ઞાપાલક હોય છે
                          ગુરુદીક્ષા લીધા પછી શિષ્યવૃત્તિમાં વિકૃતી થાય, તેજોદ્વેષનો વિકાર થાય, અને પોતાની પ્રકૃતિ ગુરુ વિરોધી, છળકપટવાળી બને. ગુરુ સાથે રહીને પોતે ગુરુ થવા પ્રયત્ન કરે. ગુરુ કરતાં પોતે વધુ જ્ઞાન ધરાવે છે, એવું સેવકોના મનમાં ઠસાવવા પ્રયત્ન કરે એ શિષ્ય પિત્તળ અને લોહ ધાતુ જેવા હોય છે. એ કપટથી કટાતો હોવાથી ગુરુદ્રોહી ગણાય છે, તેવા શિષ્યોને કાટની અસર હોય છે.

                        </p>
                        <p className="paragraph-content">
                          ગુરુ તણી નિંદા કરે, ચર્ડ લોહ સમ કાટ,
                          ભવસાગરમાં ડૂબતો ! મળે ન બચવા ઘાટ.
                          ૨
                          જે શિષ્યો નિજ ગુરુ પ્રત્યેની આસ્થા ઓસરે એ પ્રકારની નિન્દા કરે એ લોહપુર કાટની જેમ કપટના કાટનો ભોક્તા બને છે, એ ભવસાગરમાં ડૂબે છે. એને સાગરનો કિનારો મળતો નથી.
                          દોહા
                          ગુરુ દિક્ષા શિક્ષા અને, પળાય જો આદેશ,
                          ભવસાગર સહેજે તરે, શંકા નવ લવલેશ.
                          શિષ્ય બન્યા પછી ગુરુશિક્ષા-જ્ઞાન-શિખામણ અને આદેશ પળાય તો એ શિષ્ય ભવસાગર તરીને પાર ઊતરે છે, એમાં લવલેશ શંકા નથી, ગુરુ કરતાં પહેલાં ખૂબ મનોમંથન થવું જોઈએ. એ મંથનમાંથી આત્મ અવાજનો સંકેત થાય છે અને પોતાની ભાવના પ્રમાણે એને ગુરુ મળે છે. જે ગુરુ દોંગો હોય, દંભી હોય, ભક્તિમાર્ગ ચડેલો ના હોય. એ ગુરુ થવાને લાયક નથી હોતો.
                          ચોપાઈ
                          શ્વેત વસ્ત્ર ગેરુથી રંગે, પાખંડે ધરે ભગવાં અંગે,
                          ભક્તિ ભાવ નવ જ્ઞાન બતાવે, પાકો નવ એ રંગ ચડાવે.
                          ‘ઘોળા વસ્ત્રને ગેરુ માટીથી રંગીને પહેરવાથી કે ઓઢવાથી જ્ઞાન પ્રાપ્ત થતું નથી. વ્યંઢળ કેસરી વસ્ત્રો પહેરવાથી શૌર્યવાન થતો નથી. પશુઓમાં ભગવારંગી વર્ણવાળાં ઘણાં પશુઓ હોય છે. એ ભક્તિજ્ઞાનને વરેલાં હોતાં નથી. એ તો રંગ માટીનો છે. એમાં સર્વસ્વ જ્ઞાન ભરેલું નથી. પ્રભુ ભજન, ધ્યાન, ધારણા, ભક્તિભાવની શુદ્ધ ભાવનાથી જ સાધક બને છે. સાધક-સાધુ ગેરુ રંગને અગ્નિવર્ણ માનીને ધારણ કરે છે અને સંકલ્પ કરે છે કે, હે અગ્નિદેવ ! તમારા વર્ણનું (રંગનું) હું વસ્ત્ર ધારણ કરું છું મને મોહમાયાથી બચાવજો. આ સ્તુતિ કરનારા હોય તે સાધુ ગણાય છે અને તે ગુરુ થવાને લાયક હોય છે. અજ્ઞાની ગુરુ કેવા હોય છે તે
                          સાંભળો :
                          ચોપાઈ
                          ગુરુ ચેલા વૃંદ બેઠાં નાવે, પડી તરડ જલ સરતું આવે, અજ્ઞાની ગુરુ છિદ્ર પડાવે, ડૂબ્યું નાવ સહુ તળિયે જાવે.

                        </p>
                        <p className="paragraph-content">
                          ગળામાં મોટી માળા ! માથે પાઘડી ભગવી, કફની ભગવી, પંચિયું ભગવું, ચાદય ભગવી એવો સ્વાંગ ધરી ધણાંનો ગુરુ થયો અને ચેલાઓને લઈને તે યાત્રા
                          કરવા સાગરકિનારે આવ્યો.
                          'નાંગરેલા નાવને બંધનમુક્ત કરી ચેલા, સેવકો અને ગુરુ બેઠા. નાવ જળવાટ કાપતું જતું હતું.
                          નાવને ખૂણા પર જીણી તિરાડ પડવાથી પાણી અંદરના ભાગમાં પ્રવેશવા ઝમવા લાગ્યું. ચેલાઓ જોઈને ગભરાયા ! અને ગુરુજી હમણાં માર્ગ બતાવશે એ વિશ્વાસે રહ્યા. પાણી વધુ આવવા લાગ્યું ત્યારે ગુરુનું ધ્યાન દોરતાં ચેલાઓ કહેવા લાગ્યા :
                          ‘ગુરુજી ! બાપુ ! નાવડી ફૂટી ! પાણી અંદર આવવા લાગ્યું. હમણાં પાણી ભરાશે. હવે...? ચેલાઓએ ગભરાતા ગભરાતાં કહ્યું.
                          ‘ગભરાવ નહીં ! મૂંઝાશો નહીં ! હું તમારો ગુરુ છું. તમને ડૂબવા નહીં દઉં. તમને તારું નહીં તો હું ગુરુ શાનો ? મારી બાજુ મોટું કાણું પાડો એટલે એ ખાળ વાટે પાણી ચાલ્યું જશે.' ગુરુએ કહ્યું. તરત ચેલાઓ મોટા મોટા ચીપિયા લઈને હોડીમાં કાણું પાડી ખાળ બનાવવા મચી પડ્યા.
                          નાવડીને બાકોરું પાડ્યું ત્યાં તો પાણીનો નાવડીમાં એટલો ધસારો થયો કે ગુરુ અને ચેલાને લઈ નાવડી સાગરને તળિયે ગઈ.
                          આવા દંભી, પાખંડી, અજ્ઞાની માણસો શુદ્ધ, પવિત્ર, ભક્તિશીલ, સાધુસંતોમાં અંતરાયરૂપ બની ગુરુ થઈ બેસે છે. અને...
                          ચોપાઈ
                          અનાચાર અધર્મ અપરાધુ, સ્વાંગ ધરે પણ હોય ન સાધુ,
                          ધરી ભગવા કરે બોધ અગાધુ, પ્રપંચ પાખંડી તકસાધુ.
                          ‘સાધુ જેવો સ્વાંગ ધરે પણ સાધુ જેવા આચાર ન હોય, ભક્તિ પર લક્ષ આપ્યા સિવાય સ્વધર્મની શાન-સમશ્યા, ગુરુબોધ અને જ્ઞાનમાં સાવ અજ્ઞાત, પાખંડી, પ્રપંચી, કૌભાંડી, ભગવાં વસ્ત્રો ધારણ કરે છે. એ તકસાધુઓ હોય છે, માનવીને છેતરનારા અપરાધીજન ગણાય છે, કોઈ કોઈ તો...
                          ચોપાઈ
                          સ્વાંગ નિજ ધરે અવધૂતા, પાખંડ બતલાવે પ્રભુતા, ધનહર એ પ્રપંચી ધુત્તા, નહીં સાધુ પણ દંભી કૂત્તા.

                        </p>
                        <p className="paragraph-content">
                          ‘અવધૂત જેવો વેશ લઈ પાખંડ કરી ચમત્કાર બતાવી પોતાની પ્રભુતા બતાવે અને ધનમાલ પડાવવા પ્રયત્ન કરે એ ધુતારો, દંભી, કૂતરાની કોટિનો હોય છે. એ સાધુ હોતો નથી, કારણ કે વાચાળ હોવાથી ભોળાભલા માણસો એના સેવક બની અંધશ્રદ્ધાથી એને ગુરુ કરે છે. એમને ભક્તિમાર્ગનું ખરું રહસ્ય સાંપડતું નથી. સાચા સાધુઓ શીલવંત હોય છે. પ્રભુભજનમાં વિક્ષેપ ના પડે એ કારણે ચેલા કરતા પહેલાં એનું માનસ તપાસી લે છે કારણ કે -
                          ચોપાઈ
                          મુખ મન સાદ સ્વભાવ ન સરખા, રાગવિરાગ ઉચ્ચારે પરખા, વિષમ વેણ કોઈ અમી વાણી, સાધુ જન લેતા પરમાણી.
                          ‘મનુષ્યની મુખાકૃતિ મન, અવાજ, સ્વભાવ, રાગવિરાગ એના ઉચ્ચાર ઉપરથી પરખાય છે. મનુષ્ય માત્રના ચહેરા વગેરે જુદા જ હોય છે. કોઈની વાણીમાં કટુતા, વ્યંગ, કટાક્ષ, મેણાંટોણાં અને ઝેર જેવી વાણી હોય છે. કોઈની વાણીમાંથી અમૃત સ્રવતું હોય છે. આવાનું માનસ-ચારિત્ર્ય સાધુજનો પારખી પ્રમાણીને પૂછીને શિષ્ય કરે છે. એ સાધુ દંભી નથી હોતા. સાંભળો :
                          દોહા
                          સાધુતા સસ્તી નથી, જપ તપ પાકટ થાય, સાધક સાધુની કોટિના, વર્તનથી પરખાય. ‘પ્રભુભજન-નામસ્મરણ, જપતપમાં એમનું પોત પ્રકટ થાય છે અને સાધક-સાધુની કોટિ એમના વર્તનથી પરખાય છે.
                          ચોપાઈ
                          વર્તન, વાણી વચન વિવેકા, નિર્મળ નિશ્ચલ, નિશ્ચય નેકા,
                          અષ્ટ ગુણવંતા જે થાવે, એ શીલ સિદ્ધ સાધુ કહાવે. રામગરજીનો વાણીપ્રવાહ અને આનંદરંગમાં બોધ આપવાની રીત ફક્કડાનાથે પહેલવહેલાં જ સાંભળ્યા અને ધન્યગુરુ ! ધન્ય જ્ઞાનદેવનો ઉચ્ચાર કરી ઊભા થઈ ‘રામદેવપીરનો જય ! રામગરજીનો જય !' નો જયકાર લલકારવા લાગ્યા.
                          ફક્કડાનાથની શોધખોળ સફળ થવાથી તેમને ખૂબ જ આનંદ થયો, સભાજનોએ પણ જયઘોષણા કરી.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
      {/* </div>
      </div> */}
      <div>
        <Footer />
        {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
      </div>
    </>
  );
};

export default BlogDetails36;
