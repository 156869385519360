import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails16 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery18.jpg";
    return (
        <>
         <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}


            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                   
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                          
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            રામાપીર અને જામાપીર
                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                   
                                                >
                                                    <span className="income">રામાપીર અને જામાપીર

                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content">
                                                    પૂજાવાને પીર થઈ, ઈચ્છા દઈ મનમાંય, જામાજી મમતે ચડયો, જાંબેસરનો રાય,
                                                    સંઘવીઓ રામદેવજીનાં દર્શને આવતા જતા હતા. માર્ગમાં જાંબુસર નામનું રણુંજા તીર્થઘામ હોવાથી સતી, સેવકો, ભક્તો, સાધુ-સંતો અને મહંતો ગામને પાદર પીપળિયો કૂવો અને બાજુમાં ઘટાટોપ વડની છાયા હતી, તેથી આ જતા યાત્રાળુઓ ત્યાં વિશ્રાંતિ લેવા બેસતા. ભજનિકો ઢોલક, ઝાંઝ પખાજ મંજીરા, રામસાગર જેવા સાજથી ભજન ધૂનની રમઝટ બોલાવતા હતા. કોઈ કો યાત્રાળુ-સંઘ રાતવાસો પણ ત્યાં રહેતો હતો. યાત્રાળુઓ આ વડ નીચે જમા-જાગરણ કરી, રાત વિતાવી પ્રભાતે ચાલ્યા જતા.
                                                    બેસર ગામનો જામાજી નામનો રાજા હતો. એને બે રાણીઓ હતી. સુરસ અને ચંદા. એમાં સુરજા રાણી રામદેવજીની ઉપાસક હોવાથી યાત્રાળુ બાઈઓને પોતાને મહેલે બોલાવી શ્રીફળ, મીઠાઈ વગેરે રામદેવજીને ધરાવવા એમની સાથે મોકલતી હતી. આ બધું ચંદારાણીને ગમતું નહીં એટલે ઈર્ષાથી સુરજા વિરુદ્ધ એ જામાજીના કાન ભંભેરતી. એક વાર તેણે નવો તુક્કો ઊભો કરી રાજાને કહ્યું ઃ
                                                    ‘મહારાજ ! મારા મનમાં એક વાત ઘણા સમયથી ઘોળાયા કરે છે. એ વાત આપના હિતની છે અને માનવકલ્યાણની પણ છે.’ ચંદારાણીએ જણાવ્યું. ‘ચંદા ! એવી કઈ વાત છે ? જે હોય તે કહો.' જામાજીએ પૂછ્યું. ‘આપણા પાદરમાં રામાપીરનાં દર્શને રણુંજા તરફ જતાં યાત્રાળુઓ રાતદિવસ આ કૂવા પાસેના વડ તળે વિસામો લેતા હોય છે. રામદેવ તો રાજકુમાર છે. એ પીર કેવી રીતે કહેવાય ? એ રામાપીર કહેવાય છે, તો આપ જામાપીર કેમ કહેવાવ ? ખરો ધર્મ તો આશરાનો ગણાય. આપણા પાદરમાં વડ અને કૂવો છે રાજાને પાનો ચડાવવા તોળી તોળીને શબ્દો કહ્યા. રાણીની વાતમાંથી જામાજીને એના આશરે યાત્રાળુઓ શાંતિ મેળવે છે. એ જામાપીરના પ્રતાપથી ને ? રાણીએ | ‘જામાપીર' નામ જડી ગયું. તેણે રામાપીર સામે જામાપીરનો નવો તુક્કો ઊભો કર્યો અને અમલમાં મૂક્યો. સભામાં હાજી હા કરવા આવનારા સભાજનો તો હવે

                                                </p>
                                                <p className="paragraph-content">
                                                    જરના જય ભોલાવા લાગ્યા. ગામના સમજુ લોકોને આ નૂત ગમતું નહીં
                                                    આ રન કોણ સમજાવે ?
                                                    સુરજરાા મનમાં સમજી ગઈ કે ચંદારાણીએ રાજાને રમકડું બનાવ્યા છે. રાજા ! હોવાથી મમતે ચડેલા છે. રામદેવની સામે તરકટનાં નૂતનું ભૂત તેમના
                                                    નિજ ભરાયું છે. ચળભાની
                                                    હવે નીકળે તેમ નથી | ચંદા ચપળ અને ચકોર હોવાથી
                                                    આટાપાટા
                                                    ખેલવામાં પાવરધી છે. વળી રાજાની માનતી છે એટલે
                                                    જેનો વિરોધ કરવાથી આપણું કાંઈ ઉપજવાનું નથી એટલે ચૂપ રહી જોયા કરવું એ
                                                    વધારે સારું છે.
                                                    ચોપાઈ
                                                    કાનમાં કુન્ડલ કરમાં ભાલો, મતિહીન બન્યો મદવાલો,
                                                    રૂડે ઘોડલે કરી સવારી, આવ્યો એ પાદર મોજારી.
                                                    રાજાએ રામદેવજી પેઠે કાન કોચવી કુન્ડલ પહેર્યાં, હાથમાં ભાલો લીધો અને ોડો શત્રુગારી સવાર- સાંજ ઘોડેસવાર થઈ પાદરમાં સહેલ કરવા નીકળે અને પરિયા “જામાપીરની જય’ની ઘોષણા કરે.
                                                    દોહા
                                                    પીર થવા પોરહ વધ્યો, નહિ જ્ઞાન કે ભાન,
                                                    ભાંડ દાંડ સેવક બન્યા, પૂરે તાનમાં તાન.
                                                    રાજાને પીર થવા પોરહ વધ્યો છે, એવી વાત જેસલમેર ગામના ભગત મંડળમાં ચર્ચાવા લાગી.
                                                    આ વાત બહુરૂપી બનીને જીવનગુજારો કરતાં બે ભાંડોના જાણવામાં આવી, બકરા ભાંડ લોલમાં લોલ કરી તકનો લાભ લેવા બેસરના પાદરમાં આવી પહોંચ્યા.
                                                    દોહા
                                                    જેસલમેરથી આવીયા, દુદી ફુદો ભાંડ,
                                                    રામાપીરની જે કહી, આદરતા કૌભાંડ.
                                                    મૂછો વાત ! બંને ભાંડો ઘોડેસ્વારને જોતાં “જામાપીરની જય !' બોલી નમી પડ્યા હઁદો અને કુદો વેશ બદલવામાં, ભાષા બદલવામાં એવા તકસાધુ હતા કે ન અને કહ્યું :

                                                </p>
                                                <p className="paragraph-content">
                                                    દોહા
                                                    જામાપીરને પૂજતા, ભગતો ઠેર ઠેર,
                                                    કરે ભજન નરનારીઓ, નગરી જેસલમેર. ઘણી ખમ્મા જામાપીરને ! અમારા જેસલમેર નગરમાં સ્ત્રી અને ામાપીરની પૂજા કરે છે. ભજન લલકાર કરે છે. ઠેકાણે ઠેકાણે જામાપીરની જ થયા કરે છે. અમે પણ આ બધું સાંભળી આપનાં દર્શને આવ્યા ! આપ અંતરયામી છો. અમારો ભક્તિભાવ જાણીને આપ ઘોડલે સ્વારી કરી સામા સહ સેવક ગણી અમને સેવાનું કામ સોંપો અમારા નાથ !' ભાંડે બે હાથ જોડી ગ આવ્યા એટલાં ધનભાગ્ય ! ઘણી ખમ્મા મારા ઘણી ! અમારી સેવા સ્વીકારો
                                                    કંઠે કહ્યું.
                                                    દોહા
                                                    રણુંજા નામે ગામમાં, વસતા રામાપીર,
                                                    તીરથ જાંબેસર મહીં, મોટા જામાપીર.
                                                    ''
                                                    અ
                                                    રણુંજામાં ‘રામાપીર’ પ્રસિદ્ધ છે. પરચા પીરાઈવાળા છે એવું અમે સાંભળ્યું હતું. હવે એમનાથી પીરાઈમાં ચડે એવા જાંબેસર ગામના રાજા જામાપીર છે એવું અમે સાંભળ્યું.
                                                    દોહા
                                                    જામાપીરની જાતરા, પ્રસિદ્ધ ગામે ગામ,
                                                    દર્શનથી પાવન કરે, જાંબેસરનું ધામ.
                                                    આવી ભાંડની ચાલાકી ભરી વાણીથી રાજા જાંબોજી અંજાઈ ગયો.
                                                    ચોપાઈ
                                                    નમણ કરે નુગરા પાખંડા, પળપળ પલટ રંગ કાચંડા,
                                                    વાણીમાં વાતે વિતંડા, છળિયા શઠ જન ફંડ મુદંડા,
                                                    નુગરો, શઠ, છલનાર અને શંડા મુશંડા જેવો વિતંડાવાદમાં શૂરો જ્યારે નમન કરે, ત્યારે સમજવું એ જાળ પાથરી રહ્યો છે. કાચંડો પોતાના રંગ ક્ષણે ક્ષણે બદલે છે અને માથું હલાવતો હોય છે, પરંતુ તેથી તે પોતાનો કરડવાનો સ્વભાવ છોડી દેતો નથી. દુદો અને ફુદો તો ભાંડ બહુરૂપી, બહુરંગી કપટવાળા હતા. ભલભલા કાચંડાને પણ ઝડપી લે એવા એ ભાંડી પાસે રાજા રમકડું બની ગયો.
                                                </p>
                                                <p className="paragraph-content">
                                                    દોહા
                                                    પૂજવા પીર થઈ કૂમતે, કરે ભાંડ કૌભાંડ
                                                    જળો જેમ છૂટે નહીં, મળ્યા ભાંડ બેદાંડ,
                                                    113
                                                    કુમતિયા રાજાને પીર થઈ પૂજાવાની લગની લાગી હતી. તેને બે દાંડભાંડ મળ્યા
                                                    1 AM? એ
                                                    ચોંટ્યા. દુદાફુદાની પકડમાંથી એ છટકે તેમ રહ્યું નહીં.
                                                    ચોપાઈ
                                                    થઈ જમાવટ છળમાં પૂરી, જામાપીરના થયા હજુરી, સાંજે સવારે પૂજા થાયે, જામાપીરની આરતી ગાયે. પાખંડમાં બેયે એવી જમાવટ કરી કે એ પીરના પૂજારી તરીકે ઓળખાવા , જે વડની પાસે તેઓ ઊતર્યા હતા તે વડની નીચે રાજાએ રહેવા માટે ઝૂંપડી કરાવી આપી અને તેનું નામ ‘જામમઢી’ રાખ્યું. આ બે ભાંડો જામમઢીમાં બેઠા બેઠા રધું જતા યાત્રાળુઓને રોકી જામાપીરનાં દર્શને જવાની ભલામણ કરતા
                                                    sel,
                                                    4.
                                                    . તેઓ કહેતા હતા કે રામાપીર તો હમણાં થયા. જામાપીર તો તેમનાથી મોટા
                                                    અને પરચાધારી છે. લોકો ભાંડોની વાતથી ભોળવાઈને જામાપીરનાં દર્શન કરવા
                                                    રતા પી. . .
                                                    દોહા
                                                    યાત્રાળુ સમજી જતા, રાયનું તરકટ તૂત,
                                                    મૂરખને વળગાડિયું, પીર થવાનું ભૂત.
                                                    રાજા મૂર્ખ બન્યો છે. કોઈએ તેને પીર થવાનું ભૂત વળગાડ્યું છે; એવું યાત્રાળુઓ તેમનું તરકટ નૂતને જાણી જતા અને એની મૂર્ખાઈ પર હસતા હતા. રાજાએ ગામની પાસે નાની તલાવડી હતી, એને ખોદાવી મોટું તળાવ કર્યું અને એનું નામ જામાસરોવ૨ રાખ્યું.
                                                    ચોપાઈ
                                                    દુદો ફુદો સેવક જાણી, પ્રસન્ન રહેતી ચંદારાણી,
                                                    થયો જનાને પગપેસારો, પાટ ઠાઠના કર્યા વિચારો.
                                                    કુંદા- ફુદાનું વર્ચસ્વ રાજા અને રાણી પ્રત્યે એવું જામ્યું કે તેમણે જનાના સુધી
                                                    | ગપેસારો કરી દીધો. એમને વફાદાર સેવક જાણી ચંદારાણી તેમના ઉપર પ્રસન્ન | દેતી. પોતાના પાસા પોબાર પડતા હોવાથી ભાંડોએ એક નવો તુક્કો ઊભો કરી આ રાજારાણીને જણાવ્યું કે, ‘પીરજી ! આપણો પીરાઈ પંથ છે. રણુંજામાં રામાપીર

                                                </p>
                                                <p className="paragraph-content">
                                                    સવરામંડપમાં .
                                                    મહા મહિનામાં સુદી બીજને દિવસે પાટોત્સવ કરે છે. એ જાત્રાળુઓ અને સતી સેવકો આવે છે. પાટપૂજા સત્યુગથી થતી આવે
                                                    પર
                                                    પ્રહ્લાદથી ચાલુ છે. સાંભળો... દે પાટનું માહાત્મ્ય સંભળાવા કહેવા માં
                                                    પેલા પેલા જુગમાં, સોનાના પાટ રે હાં...
                                                    1 સિંહાસન
                                                    .
                                                    સોનાને સિંહાસન બેઠા, નકળંગીરાય, નકળંગરાય ભગવાનને પધરાવ્યા હતા. પ્રહ્લાદ પાટપૂજા કરી અને સતયુગમાં પ્રહ્લાદ રાજાએ પાટોત્સવ કર્યો હતો. એમાં સોનાના વીજાવલીએ આરતી ઉતારી હતી. એવી રીતે આપણે સોનાનું નાનું સિં બનાવી નકળંગરૂપે આપની મૂર્તિ પધરાવીએ અને મહારાણી આપની આરતી એવો પાટોત્સવ કરીએ તો રણુંજાના પાટોત્સવમાં જતા સતી સેવકો મ જાત્રાળુઓને આપણે આપણા ઉત્સવમાં વાળી શકીએ અને રામાપીરના ઉત્તર
                                                    કોઈ જાય નહીં.'
                                                    ‘વાહ ! ધન્ય છે ! તમારી વાતો અમને ખૂબ ગમી ગઈ.’ વાત સાંભળી આનંદમાં આવી ગયો. તેણે ચંદાને પૂછ્યું :
                                                    ‘ચંદા ! આપણા સેવકોની વાત તને ગમી ?’
                                                    ‘કૃપાનાથ ! આપ તો પીર છો ! નકળંગ અવતાર છો ! પાટોત્સવમાં સોનાન સિંહાસન પર આપની સોનાની નકળંગ મૂર્તિ પધરાવી, હું આરતી ઉતારું એવું મારું ધન્ય ભાગ્ય ક્યાંથી !' રાણીએ પોતાની સંમતિ આપી.
                                                    રાણી ચંદાની સંમતિ પણ મળી ચૂકી. હવે પૂછવાની વાત જ શી ? તેમણે તુરંત કારભારીને હુકમ કર્યો : ‘કારભારી ! આ આપણા સેવાભાવી સંત દુદાજી અને ફુદાજીને જોઈએ તેટલું સોનું આપો; જેથી તેઓ મૂર્તિ અને સિંહાસન ઘડાવે. થા આરતી અને દીપક માટે રૂપું અને પૈસા પણ આપજો.' ‘જી અન્નદાતા !' ક. કારભારી રાજાની આજ્ઞા પાળવા માટે તૈયાર થયો. ત્યાં ફુદાજી બોલી ઊઠ્યો
                                                    “અન્નદાતા ! પહેલાં તો ગામોગામ વાયક બીડાં મોકલવા સારા ઘોડા જોઈએ. ઘોડા હોય તો આપણું શુભકાર્ય જલદી પતે.’
                                                    ‘ભલે, ઘોડારમાંથી બે સારા ઘોડા લઈ જાવ અને એ ઘોડા હવેથી તમાર જામમઢીએ જ રાખજો.' રાજાએ કહ્યું.
                                                    સોનુંરૂપું અને ખર્ચ માટે રોકડ રકમ તથા બે ઘોડા લઈને દુદો- ફુંદો જામમઢીએ આવ્યા અને પ્રચારનો આરંભ કર્યો. યાત્રાળુઓને રોકીને તેઓ જામાપીરની
                                                    કરવા લાગ્યા.

                                                </p>
                                                <p className="paragraph-content">
                                                    ચોપાઈ
                                                    વાતો કરવા લાગ્યા. જાત્રાળુને ટોકે, બેસરની પાસે રોકે, જામાપીર છે સહુથી મોટા, જગમાં જેનાન મળે બેટા. જઇને પીરનાં કરજો દર્શન, પરચો આપે થઈને પરસન, રણુંજા રામાપીર કહાવે, જામાપીરની ટોડે ન આવે. વાવડીને જળથી ઉભરાવી, પરચાની પીરાઇ બતાવી, તળાવ ‘જામાસરોવર' ભારી, પ્રગટ પીર છે પરચાધારી. આવી રીતે યાત્રાળુઓને આકર્ષવા, જામાપીરની પીરાઈ પરચાની વાતો કરતા હતા પણ યાત્રાળુઓ જામાજીને જોઈ તોલ બાંધી લેતા કે, આ પીરે નથી અને ફકીરે તુ નથી. રામદેવજી મહારાજની વાદે ચડેલો બુદ્ધિગુમાવી બેઠેલો મંદ અક્કલ રાજા છે. એને વખાણનારા પૂજારીઓ ભક્તિમાં રંગાયેલા નથી પણ તકસાધુ ધુતારા છે.
                                                    એવું સમજી જતા હતા.
                                                    શ્રી રામદેવની ઉપાસક સુરજારાણીને રાજાની આવી વર્તણૂક ચંદારાણીની લાહ અને પૂજારીઓની જનાના સુધીની અવરજવરથી આધાત થતો. ખોટી પ્રશંસા અને પીર થવાનું પાખંડ જોઈને રાણીનું મન ઘવાતું હતું. પોતાની મનોવેદના રૈદાસદાસીઓ પાસે ઠાલવતાં કે, મહારાજાને સત્બુદ્ધિ કોણ આપે.'
                                                    ચોપાઈ
                                                    મન માંહે સમજે છે. રાણી, મતિ ભૂપતિની હેરાણી, પીર રામદે' પ્રભુ અવતારી, મમતે રાય ચડ્યા છે ભારી. સુરજારાણી સમજતાં હતાં કે, રામદેવજી એ તો પ્રભુ છે, દ્વારિકાધીશનો અવતાર છે. એમની નકલ કરવા રાજા મમતે ચડી ગયા છે ! એમની મતિ જો કોઈએ કરવી હોય તો એ ચંદારાણીનું કામ છે. આજે તો ગમે તે રીતે મહારાજને મળીને સમજવું. એમ વિચાર કરીને રાણી રાજા પાસે ગયાં અને કહ્યું : ‘મહારાજ ! ઘણા | સમયથી હું મૌન રહી છું. આપ પીર છો. પરચાધારી છો એવું બધાં કહે છે. પણ |આપનું મન પીરાઈથી રંગાયેલું છે ? એવા યોગક્રિયાના માર્ગે આપે કોઈ સિદ્ધિ | મેળવી છે ? જામાસરોવરમાં રામદેવજીની કૃપા ના થઈ હોત તો સરોવરનું પાણી દામાંથી ઉજળું ના થાત. આ એમના ઉપકારનો આપણે અનાદર કરી પ્રતિકાર કરવા માંડ્યો છે એમ આપને નથી લાગતું ?' સુરરાજારાણીએ શિખામણના બે

                                                </p>
                                                <p className="paragraph-content">
                                                    ચોપાઈ
                                                    બહુ રીતે સમજાવે રાણી, આપે મતિ ક્યાં થકી આણી. રામદેપીરની છેડ ન કરતા, ઈર્ષાળુ પગલાં ના ભરતાં. 'મહારાજ ! આપ હવેથી રામદેવજી સામે કોઈ ઈર્ષાપૂર્વકનું પગલું લાગ્યો ઃ ‘રાણી ! હું પીર છું. રામદેવના ગુરુ બાળનાથ છે એટલે એ પી ન નહીં.’ રાણીના આ શબ્દો રાજાને ગમ્યા નહીં. પરંતુ તે ઊલટા તકે કરીને ગન્નાય. પીરને વળી ગુરુ કેવા ? પીરથી ગુરુ વધે જ નહીં.' રાજાએ કહ્યું. સુરજારાણીને લાગ્યું કે રાજા કોઈ કાળે માને તેમ નથી એટલે તેણે હવ
                                                    શિખામણ દેવાનું માંડી વાળ્યું.
                                                    ભરાડી ગણાતા ભક્ત ચાંપો અને કુંપો શ્રીરામદેવજીનાં દર્શને રણુંજામાં આવ્ય હતા. એમણે વાત સાંભળી કે, જાંબેસરનો રાજા જામાપીર તરીકે પોતાને ઓળખ છે અને આવતા મહા માસની સુદી બીજના દિવસે સવરામંડપ રોપી પાટ
                                                    કરવાના છે. સંતમેળો થવાનો છે.
                                                    ચાંપા-કુંપાને ‘જામાપીર’ નામ સાંભળી આશ્ચર્ય થયું. નવા પીર થયા ! એમને પીર પ્રમાણે કોણે સંબોધ્યા ! એ જાણવા બંને ભાઈબંધ શ્રીરામદેવજીનાં દર્શન કરી જાંબુસરના વડ પાસે જામમઢીએ આવ્યા અને દુદા અને ફુદાને દીઠા.
                                                    દુદો અને ફુદો જાતજાતના વેશધારી બની, ભોળાંભલાં માણસોને ફસાવી જીવનનિર્વાહ ચલાવતા હતા. ચાંપો અને કુંપો આ ભાંડોને ઓળખતા હતા. એમના ધૂર્ત ધંધામાં એમને ઘણી વાર પકડી અને ઠપકો પણ આપ્યો હતો. બંન્ને ભાંડ જાણતા હતા કે ચાંપો-કુંપો માથાભારે છે. શક્તિશાળી છે. એમની સામે થવું એ જીવના જોખમ જેવું છે.
                                                    ચાંપા-કુંપાને જામમઢીએ આવેલાં જોઈ દૂદો-ફુદો ગભરાયા અને દહેશત ખાઈ ગયા કે, આ કરાફાતિયા સખણા રહેશે નહીં. આપણો ભાંડો ફોડી મુશ્કેલીમાં મૂક
                                                    દેશે. ત્યાં તો ચાંપો બોલી ઉઠ્યો :
                                                    ‘અલ્યા ભાંડ ! ભાઈબંધો ! તમે અહીં ક્યાંથી ?’
                                                    ‘દુદા ! આંબાની ડાળ ખરી પકડી પાડી !' કુંપે કહ્યું .
                                                    ‘ભાઈબંધો ! તમે તો કાંઈ રાજા જેવા ઠાઠમાં છો ! અહીં ક્યારથી હસ્યા છો ?" |
                                                    ચાંપે પૂછ્યું.

                                                </p>
                                                <p className="paragraph-content">
                                                    જુદા ! જેસલમેરમાં સુધા શેઠને ત્યાં ધાપડ દઈને સોનાનાં કડાં લઈ આવ્યો ' એ તને ગોતે છે.' ચાંપે કહ્યું.
                                                    અને આ
                                                    હા ! વાલનું
                                                    વેળિયુંયે
                                                    • દુદો ક્યાં સખણો મર્યો'તો ! એણે ગવરા ગોરની ગોરાણીને દિ’ .ના છોડ્યું મારે દીકરે !' કુંપે વાતમાં ઉમેરો કર્યો,
                                                    ભાઈબંધો ! તમે જામાપીરનો પરચો જોવા કે પરચો દેવા પધાર્યા છો ?' પાએ પૂછ્યું અને કુંપો હસી પડ્યો . વળતો જવાબ શું આપે ? બંને ભાંડો વિચાર કરતા હતા કે, જો રાત્રે આરતી સમયે આ બે ભરાડી સાથે આવશે તો તો ઊંધું માર્યા વિના નહી રહે. રાજા અક્કલના બૂઠા છે. આપણે ઉઘાડા પડીએ તો કદાચ જેલમાં ( ા પૂરી દે. આમ વિચારી બંને જણે નક્કી કર્યું કે, હવે જે મળે તે લઈને અહીંથી તે ઝટ પલાયન થઈ જવું એ જ સારું છે. દુદો બોલ્યો : ‘ચાંપાભાઈ, આ પાથરણું છે. | તેના ઉપર બે ઘડી આડા પડો, અમે સૂવાના પાગરણની અને ભોજનની વ્યવસ્થા કરી આવીએ.' આમ કહી બંને ભાંડો ઘોડે રાંગવાળી, મરચી-મશાલો અને ગોળ- ચણા ખાધેલા મદમસ્ત ઘોડાઓને પૂરપાટ દોડાવી ગયા. અજવાળી રાત હોવાથી તેમણે ઘોડાની ઝડપ વધારી અને ઘડીકમાં મેવાડની હદ પણ વટાવી દીધી.
                                                    અહીં જામાપીરની આરતી સમય થયો, પરંતુ બંને પૂજારીઓ હાજર ન હતા. જામાપીરે ઘણી રાહ જોઈ હજુરીને તેડવા જામમઢીએ મોકલ્યો. જામમઢીમાં ચાંપો અને કુંપો આડે પડખે પડેલા હતા, ત્યાં હજુરી આવ્યો અને પૂછ્યું : “પૂજારીજી ક્યાં ગયા ?’
                                                    ‘અહીં કોઈ પૂજારી આવ્યા નથી.' ચાંપે કહ્યું.
                                                    ‘એ તો અહીં જ રહે છે. પીરજીની આરતીનો સમય થયો પણ હજુ આવ્યા નથી એટલે મને તેડવા મોકલ્યો છે.' હજુરીએ કહ્યું .
                                                    ‘આ મઢીમાં તો દુદો અને ફુદો રહે છે. એ ક્યાં પૂજારી છે કે આરતી ઉતારે ?' કુંપે કહ્યું .
                                                    ‘એ જ અહીંના પૂજારી છે. જામાપીરની આરતી ઉતારીને પૂજા કરે છે એટલે તેમને પૂજારી પદ આપેલું છે.' હજુરીએ ચોખવટ કરી. હજુરીની વાત સાંભળી ચાંપો અને કંપો હસવા લાગ્યા. હસતાં હસતાં તેઓ બોલ્યા : “તમારા પૂજારી અમારા માટે પાગરણ અને ભોજનની વ્યવસ્થા કરવા ગામમાં ગયા છે.’
                                                    ચાંપા-કુંપાની વાત સાંભળી હજુરી આવ્યો અને રાજાને વાત કરી. રાજાએ મધરાત સુધી વાટ જોઈ છતાં તેમના પૂજારીઓ આવ્યા જ નહીં. રાજાએ સવારે

                                                </p>
                                                <p className="paragraph-content">
                                                    વહેલા ઊઠી પીરનો સ્વાંગ સજ્યો. હાથમાં ભાલો લઈ ધોડેસ્વાર થઇ ખત આવ્યો. રાજાને આવેલા જોઈ ચાંપે તેમને નમન કર્યું. વેશ પરથી તેઓ 4/
                                                    ગયા કે આજ જામાપીર !
                                                    ‘પૂજારીઓ બહાર ગયા છે ?' રાજાએ ચાંપાને પૂછ્યું.
                                                    ‘પીરજી ! ગઈ કાલ સાંજે અમારા સૂવા માટે પાગરણ અને ભોજનની વ્યવ કરવા ગયા છે, તે હજુ સુધી પાછા ફર્યા નથી. અમે પણ તેમની વાટ જો ભૂ બેસી રહ્યા છીએ. ચાંપાએ આપવીતી કહી. ચાંપાની વાત સાંભળી રાજા હેતુ ચમક્યા. પોતાના પાણીદાર અશ્વો જોવા તેમણે આમ તેમ નજર કરી પણ
                                                    ક્યાંય જોવામાં ન આવ્યા. તેમણે પૂછ્યું :
                                                    ‘ઘોડા ક્યાં રાખ્યા છે ?'
                                                    ‘ઘોડા તો સાથે લઈને ગયા છે.’ ચાંપાની વાત સાંભળી રાજા ઊંડા વિચારમ પડી ગયા.
                                                    પીરજી ! આ બંને પૂજારીઓ ઘણા જાણીતા છે. આપને ખંખેર્યા વિના કોર જાય તેવા નથી, તો આપ પ્રગટ-પરચો બતાવો. આપના ચમત્કારથી તેમને આંધળ કરો કે વાટ જ સૂજે નહીં અને આપોઆપ દોરડે બંધાઈને અહીં આવે.’ ચાંપાને વાત રાજા જાંબોજી મનોમન સાંભળી રહ્યા અને રાત્રે મહેલમાં જઈ ચંદારાણીને વાત કરી. ચંદારાણી વાત સાંભળી વલોપાત કરવા લાગ્યાં. તેમણે કહ્યું ‘મહારાજ! મઢીએ જે બે જાત્રાળુઓ છે તેમને અહીં તેડાવો.' રાણીની સૂચના પ્રમાણે હજુરી જામમઢીએ ગયો અને ચાંપા તથા કુંપાને તેડી લાવ્યો. રાણીએ તેમને પૂછ્યું : ‘તમે કયા ગામ રહો છો ?’
                                                    ‘માતા ! અમે જેસલમેર ગામના છીએ, જે પૂજારી બની તમને ચેલા બનાવી ગયા એ પીરના ગામના છીએ.' ચાંપાએ મર્મવચનો કહ્યાં.
                                                    ‘શું એ પૂજારીઓ પીર હતા ?'
                                                    ‘હા માતા, એમના પીરાઈ-પરચા ઘણા ગામોમાં જાણીતા છે. અહીં પણ એ પરચો બતાવ્યા સિવાય જાય તેવા નથી. અમે સાંભળ્યું છે કે, તેઓ અહીંથી પદ્મ પાણીદાર ઘોડા, સોનું, રૂપું અને રૂપિયા લઈ ગયા છે. પરંતુ માતા ! આવા પર તો તેમણે ગામેગામ બતાવેલા છે. તેઓ જાતના ભાંડ છે, વેશ બદલવામાં કુમ હોવાથી ભલભલાને છેતરે છે.' ચાંપાએ તેમની ખરી ઓળખાણ આપી.
                                                    માતાજીએ પૂછ્યું : ‘ભાઈ ! એમનો પત્તો ક્યાં લાગે ?'

                                                </p>
                                                <p className="paragraph-content">
                                                    'માતા ! જામાપીર પરચાધારી છે. એમના પંજામાંથી એ છટકીને ક્યાં જવાના છે ? બેયને આંધળા કરી બાવડા બાંધીને અહીં બોલાવશે. જુઓને ! રામદેવજી બહેન સગુા ભર જંગલમાં લૂંટાણાં તે વેળા લૂંટારાઓને આંધળા બનાવી નહોતા લાગ્યા ! તેઓએ બહેનની વારે ધાઈને તેમનો માલ પાછો અપાવ્યો હતો. જામાપીર પણ આવો પરચો બતાવી તેમની આદત સુધારે અને નુગરામાંથી સુગરા બનાવે તો
                                                    તેમનું જીવન
                                                    સુધરે.' ચાંપાએ ગંભીરતાથી કહ્યું.
                                                    ચંદારાણી મહારાજની નકલી પીરાઈ સમજતાં હતાં, એ પીરાઈ નહીં પણ એક તરકટ હતું એટલે એ કશું બોલ્યાં નહીં. હવે જાંબાજીને પણ મનમાં પસ્તાવો થવા લાગ્યો હતો. સુરજારાણીની કહેલી વાત હવે તેમને ગળે ઊતરી અને ચાંપા-કુંપાની સલાહ પ્રમાણે તેઓ રણુંજા ગયા. જાંબાજી અને તેમની બે રાણીઓ શ્રીરામદેવજી આગળ હાથ જોડી ઊભાં રહ્યાં અને પોતાની આપવીતી કહી સંભળાવી. પોતાના તરકટ અને ઘમંડની વાત કબૂલ કરી. અપાર પસ્તાવો કરવા લાગ્યા. તેમણે શ્રી રામદેવજીનું શરણું સ્વીકાર્યું. શ્રી રામદેવજીએ તેમની ભૂલોની ક્ષમા આપી અને ગુરુદિક્ષા આપી.
                                                    બે દિવસમાં અંધ બનેલી હાલતમાં દુદો અને ફૂંદો બંને ભાંડો આપમેળે દરબારમાં આવ્યા અને જાંબાજીના બે ઘોડા, સોનું- રૂપું અને રૂપિયા જાંબાજીને પાછા આપ્યા. તેમણે જીવનમાં કરેલાં અનેક વિશ્વાસઘાતનાં કામો અને કૌભાંડોની શિક્ષારૂપે તેઓ મૃત્યુ સુધી અંધ જ રહ્યા.

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails16;
