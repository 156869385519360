import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails10 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery12.jpg";
  return (
    <>
      {/* <div className="body_wrap">
        <div className="page_wrap"> */}
 <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      

      <Header />
      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id="46"
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="52ebb12"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          style={{ height: "30rem", marginBottom: "5rem" }}
        >
          <div className="elementor-background-overlay"></div>
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
              data-id="ae9d67e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fcf91b3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                      data-id="93dfaf4"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                          data-id="847c2c5"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                          style={{ height: "8rem" }}
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                          data-id="8fe49e3"
                          data-element_type="widget"
                          data-widget_type="trx_sc_layouts_title.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              id="trx_sc_layouts_title_515615674"
                              className="sc_layouts_title sc_align_center with_content without_image without_tint"
                            >
                              <div className="sc_layouts_title_content">
                                <div className="sc_layouts_title_title">
                                  {" "}
                                 
                                </div>
                                <div className="sc_layouts_title_breadcrumbs">
                                  <div className="breadcrumbs">
                                   
                                   
                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



      <div className="page_content_wrap blog-details">
        <div className="content_wrap" >
          <div className="content">
            <div id="container" className="give-wrap container">
              <div id="content" role="main">
                <div
                  id="give-form-288-content"
                  className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                >
                  <div
                    id="give-sidebar-left"
                    className="give-sidebar give-single-form-sidebar-left"
                  >
                    <div className="images">
                      <img
                        width="1024"
                        height="576"
                        src={img1}
                        className="attachment-large size-large wp-post-image blog-img"
                        alt=""
                        decoding="async"
                        sizes="(max-width: 1024px) 100vw, 1024px"
                      />
                    </div>
                  </div>
                  <div className="summary entry-summary">
                    <h2 itemProp="name" className="give-form-title entry-title">
                      કતીબશાનો હૃદયપલટો

                    </h2>{" "}
                    <div
                      id="give-form-288-wrap"
                      className="give-form-wrap give-display-onpage"
                    >
                      <div className="give-goal-progress">
                        <div
                          className="raised blog-title"
                         
                        >
                          <span className="income">કતીબશાનો હૃદયપલટો
                          </span>
                        </div>
                      </div>

                      <div
                        id="give-form-content-288"
                        className="give-form-content-wrap give_pre_form-content"
                      >
                        <p className="paragraph-content" >
                          રામદેવજીએ પાંચ મોટા પીરોને પરચાઓ બતાવ્યા અને લાખાને ઇચ્છીત
                          વસ્તુઓ આપી. પ્રસંગ પ્રસંગનો પરચા પ્રતાપ બતાવ્યો; ત્યારથી ફકીરોના મનમાં
                          પૈઠી હતી.
                          વિધર્મીઓનાં છળ-કપટથી ભોળાંભલાં નરનારીઓને બચાવી સ્વધર્મ તરફ વાળવા રામદેવજી મારવાડ, મેવાડ, માળવા, કચ્છ, વાગડ, સિન્ધ, ગુજરાત અને કાઠિયાવાડમાં હિન્દુધર્મનો પ્રચાર કરવા નીકળ્યા. સંત મહાત્મા, સાધુ, ભક્તો, કૃતિ, સતી તથા સેવકોને પણ પોતાના સ્વધર્મનો પ્રચાર કરવાની આજ્ઞા આપી. રામદેવજીની આજ્ઞાથી નવ લાખ નેજાધારીઓએ નાનાં મોટાં શહેરો અને ગામડાંઓમાં ફરી વળી લોકોને સનાતન સ્વધર્મ તરફ વળ્યા.
                          વિધર્મી ફકીરોની પ્રપંચજાળમાંથી બચી લોકો સ્વધર્મ ઉપાસક બન્યા અને ફકીરો જ્યાં જાય ત્યાં હડધૂત થવા લાગ્યા. તેઓ ઘેર ઘેર રોટી માગવા જતા પરંતુ એ બંધ થવાથી જીવનનિર્વાહ માટે કોઈ માર્ગ ન રહ્યો. તેથી ફકીરો અકળાયા અને પોતાના બડાપીર કતીબશા પાસે અજમેરમાં ગયા.
                          અજમેરમાં કીતાબશાનું સ્થાન ‘બડાપીરકા તકિયા' નામથી જાણીતું હતું. દિલ્હીના બાદશાહના માનીતા પીર તરીકે એ ગણાતો હતો. એટલે બધા ફકીરો ત્યાં આવ્યા અને પોતાના દુઃખની વાત કરી.
                          છંદ
                          કતીબશા અજમેરમાં, વડો ગણાતો પીર, ફરિયાદે ત્યાં આવિયા, ટોળે મળી ફકીર. ટોળે મળી ફકીર, વાત વિગતેથી કહીને ને પૂજે હિન્દવાપીર, અધર્મી કહેતા અમને. રોટી અમને ના મળે, ભૂખ, દુઃખ ગંભીર, નરનારી ધિક્કારતા, અમને ગણી ફકીર.
                          ૧૦૦
                          શ્રી રામદેવ રામાયણ
                          ‘પીરે આલમ ! અમને ભૂખના દુઃખથી બચવા આશ્રય આપો. રામદેવપીરના પ્રચારથી ઠેકાણે ઠેકાણેથી હડધૂત થઈએ છીએ. ખા

                        </p>
                        <p className="paragraph-content" >
                          ‘પીરે આલમ ! અમને ભૂખના દુઃખથી બચવા આશ્રય આપો.
                          જેમાં
                          માંડ્યું. ફકીરોની વાત સાંભળી કતીબશા બોલ્યો : ‘સાંઈબાવા શાન્તિ, જ્ઞ ટુકડોયે મળતો નથી.' આવી રીતે સર્વ ફકીરોએ કતીબશાને દીનવાણીથી ધરો. તમારું દુઃખ થોડાક જ દિવસોમાં મટી જશે.' કતીબશાએ સાન્તવન આપ્યું ફકીરો ફરીથી બોલ્યા : ‘પીરે આલમ ! ગામેગામ અને શહેરમાં રામદેવપીરન
                          સેવકો છે. હિન્દવાપીરના નેજા ફરકે છે. ઘેર ઘેર ધજાઓ ફરકે છે. ધર્મસ્થાનોથી કોઈ ગામ બાકી નથી. સંત, સાધુ, ભક્તો અને સેવકો અમને મંદિર
                          ગણે છે તે સાંભળો :
                          ચોપાઈ
                          પીર ફકીરા, ઢોંગી ધુતારા, ઊલટા ધર્મે ઠગે ઠગારા,
                          રોટી ઘર ઘર માગી ખાવે, ભોળાં લોકોને ભરમાવે. જ્યાં ખેરાત માટે જઈએ છીએ; ત્યાં આવાં મેણાંટોણાં અને અપમાન સહન કરવાં પડે છે, અમારો કોઈ ભાવ પૂછતું નથી. હિદુસ્તાનમાં મોગલ શહેનશાહોનું રાજ હોવા છતાં અમારી આવી દશા છે !'
                          ફકીરોની વાત સાંભળી કતીબશા ઉશ્કેરાયો અને દિલ્હીના બાદશાહની પાસે ગયો. એણે બાદશાહને રામદેવપીર વિરુદ્ધ વાત કરી કે, આપણા ફકીરો, પીરો, સાંઈબાવાઓને એ હિન્દવાપીરના સેવકો ત્રાસ આપી રહ્યા છે. ફકીરોને ક્યાંય ટકવા દેતા નથી. આનો જો કોઈ ઉપાય ન થાય તો મુસલમાની ધર્મ ખતરામાં આવી પડશે .
                          કતીબશાની વાત સાંભળી બાદશાહ ઉશ્કેરાયો અને પોતાના વજીરોને હુકમ કર્યો કે, રામાપીરના જે ભક્તો હોય તેમને પકડી પકડીને અજમે૨માં લઈ આવો અને જ્યાં સુધી તેઓ આપણા ‘પીરે આલમ કતીબશાને પરચો ન આપે ત્યાં સુધી તેમને બંદીખાને રાખો !
                          બાદશાહની આજ્ઞાથી ગામેગામથી રામદેવજીના ઉપાસકોને અજમેરમાં કતીબશા પાસે લાવવા માંડડ્યા. કતીબશા દરેકને કહેતા કે, તમે હિન્દવાપીરને માનો છો, તો તમારી પીરાઈ બતાવો ? પીરાઈ ન બતાવી શકો તો આ ઘંટીએ દળવા બેસો. કતીબશાની ધર્મઘેલછાને સાધુ- સંતો મૂર્ખાઈ ભરેલી માનીને પોતાના ધર્મ માટે ‘જય રામદેવજી ! જય રામાપીર ! કહીને ધંટીએ દળવા બેસતા. કતીબશા હવે
                        </p>
                        <p className="paragraph-content" >
                          જો એબ અજમાવી તરકીબશા બન્યો, જેમ જેમ માલો વધતા થા તેમ
                          દ્વા
                          મંગાવી દળવા બેસાડતો ગયો. રામદેવના હજાર અનુયાયીઓ
                          ૨૭ અમરમાં ઘંટીએ બેસી કષ્ટ સહન કરવા લાગ્યા. કતીબશા વાંચતા ના સાધુ સંતો ઉપર જુલ્મ ગુજારવા લાગ્યો. કતીબશાના કુરે વર્તનથી એનો એજ ખળભળી ઊઠી. ઘણાંનાં મન દુઃખોમાં પા કતીબશાને મા શું છે વિફરે તો ધંતુલે બેસાડતાં સહેજ પણ વિચાર કરે તેવો નહોતો એટલે. જંતુફ્ફે બેઠેલાઓને ધર્મવિવાદમાં ઊતારવા કતીબશા નવી નવી વાતો ઊભી જ, પણ તેની પાસે સત્તા હોવાથી કોઈ એને જવાબ આપતા નહીં અને બધા
                          પણ કોઈ છેડતું નહીં.
                          ના ના વાતા
                          દોહા
                          ધર્માધર્મવાદમાં, કરે કદી ના
                          તંત,
                          કટુવેણને સાંભળી, સહન કરે તે સંત.
                          તીબશા ગુસ્સે થઈ અપમાનજક વેણ કહેતો છતાં એને કોઈ સામો જવાબ
                          મરતા નહીં.
                          દોહા
                          બન્યા ‘બાન’ અજમેરમાં, સાધુ સંત સહિત, દળવા આપી રેત.
                          ઘંટુલે બેસાડીને,
                          દરેકના ધંટુલા પાસે રેતીના ઢગલા કરી વારંવાર એ રેતીને દળાવવા માંડી. જો ક્રેઈન દળે તો તે ચાબુકથી શિક્ષા કરવાની ધમકી આપતો હતો.
                          ઘંટુલે રેતી દળવા કે બળદની જગાએ ઘાણીએ જોડાવા બધા સંતો તૈયાર હતા, તુ ભક્તિ અને શ્રદ્ધામાંથી ડગે એવા કોઈ નહોતા. એ જાણતા હતા કે,
                          જેમ
                          ચોપાઈ
                          કાર્ય વિષે રત મળે રહસ્યો, પાણી પામે કાગો તરસ્યો,
                          ધૂળધોયાને વસ્તુ મળતી, યત્ન થકી ઇચ્છાઓ ફળતી.
                          ભારત થઈએ તો કાર્યનું રહસ્ય પમાય છે. કંકર નાખતાં નાખતાં પાણી ઊંચું
                        </p>
                        <p className="paragraph-content" >
                          આવવાથી કાગડો પાણી પીએ છે. એ પ્રમાણે રેતી દળવામાં પણ ભાન રામદેવજીની ઇચ્છાનું કંઈક તો રહસ્ય છે જ; એમ સહુને લાગતું હતું.
                          ચોપાઈ
                          અબરખ, હીરા, રતન ચિરોડી, આરસ પારસ ચક્રમક મોડી, શાલીગ્રામ, કસોટી કહાવે, સમસ્યા એ સાક્ષાત બતાવે. આઠ કુળ પર્વતમાં ભગવાને સાક્ષાત સગુણ રહસ્ય બતાવી એમાંથી ગુસ અને જ્ઞાન મેળવી શકાય એવી વસ્તુઓ બનાવી, કે જે પાવકજ્વાળાથી પ્રજળે નહીં એવું અબરખ વગેરે વસ્તુઓ બનાવી. આરસ અને ચિરોડી જેને લોહકડીથી ફોડતા તેમાં અગ્નિ સંઘરાયેલો છે, તેનું રહસ્ય પ્રગટ થયું. રામદેવજીએ નિજભક્તોની કો કરવા ધાર્યું હોય એમ ઉપાસકો સમજતા હતા. જેમ ચિરોડીમાં પાવક પ્રસરેલો અને લોહ ટકરાવતાં અગ્નિની ચિનગારી ઝરે છે, એ પાષાણ અને લોહનું રહસ્ય છે. તેવું મહાન રહસ્ય ઘંટીના પથ્થરમાં ત્રિગુણા સંભાર ભરી ભક્તોને સંકેત માટે પથ્થરના ગુણ બતાવ્યા છે. એ પથ્થરનું નામ છે ‘પારસ’
                          ચોપાઈ
                          સ્પર્શ લોહ પારસનો થાયે, લોહ સુવર્ણ વિષે પલટાયે,
                          શત વરસે એ ગુણ ન જાયે, અવધિએ લોહા થઈ જાએ.
                          પારસને લોઢું સ્પર્શ કરતાં એ લોહ સોનું થઈ જાય છે અને સો વર્ષ બાદ મૂળ સ્થિતિમાં પલટાઈ જાય છે. હવે બીજા પ્રકારનો પારસ :
                          ચોપાઈ
                          સ્પર્શ લોહ પારસનો થાયે, લોહ કાયમી કંચન થાયે,
                          કંચન કનક સુવર્ણને નામે, હાટક એ પલટો નવ પામે.
                          એ લોઢા સાથે સ્પર્શે તો સોનું કનક-કંચન ‘હાટક’ નામથી કાયમ રહે. એ લોઢું થાય જ નહીં. હવે ત્રીજા પ્રકારનો પારસ :
                          ચોપાઈ
                          પારસ લોહા પરસ જ થાયે, લોહ પલટીને પારસ થાયે,
                          પારસ લોહ એક રૂપ થાયે, ત્રિગુણ બનીને નવ પલટાયે.
                          આવા લોઢામાંથી પારસરૂપ થયેલા ઉપાસકો આપત્તિ વેઠી રહ્યા છે એ રામદેવજી જાણતા હતા.
                        </p>
                        <p className="paragraph-content" >
                          તાબશાનો થયલરો ચોપાઈ
                          સંકટ વેઠે સંત સમાજ, જાણે રામદેવ મહારાજ, અજમેરમાં સ્વધર્મ મસંદા, વિપત વેઠે ધર્મના બંદા આદિ ધર્મની ડહોળાયેલી પરિસ્થિતિને સ્વચ્છ કરવા અને સ્વધર્મને વધુ વેગવાન બનાવવા માટે રામદેવજીની પચા પ્રતાપ બતાવવાની થીયા હતી. સિદ્ધ મહાત્માઓની સહનશક્તિ બતાવી, કતીબશાહનાં અધમકૃત્ય અને બાદશાહના કુંપદવાળા હુકમની ધર્મ- ઘેલછાને ગાળી, એમના દુરાચારો- અત્યાચારોવાળા મમત્વને દબાવવા રામદેવજી મહારાજની ઇચ્છા હતી. ધર્મજંગના મંડાણ મંડાયાં હતાં, જેથી સહન શક્તિવાળા પરમ સંતોનો વિજય થાય અને વિધર્મીઓની દુષ્ટતા લોકો સમજી જાય.
                          ચોપાઈ
                          સોરઠ ભોમ ઢેલડી ગામે, રણસિંહ ને ખીમડિયા નામે,
                          આવ્યા જોગી રણસિંહ દ્વારે, અજમેરની એ વાત ઉચ્ચારે.
                          સોરઠ ભૂમિમાં મચ્છુ નદીના કાંઠે ઢેલડી નામના ગામમાં રામદેવજીએ પ્રમોદેલા ભક્ત રાવત રણસિંહ અને ભક્ત ખીમડિયો બન્ને ધર્મના કોટ જેવા રક્ષક હતા, એ કોટવાળ ગણતા હતા. એવામાં એક જોગી મહાત્માએ અજમેરમાં રામદેવજીના આસ્તિકો ઉપર આવી પડેલી અણધારી ઉપાધિની વાત રાવત રણસિંહ અને ખીમડાને વિસ્તારપૂર્વક કરી.
                          ચોપાઈ
                          ભક્તજનોના ભેરૂ થાઓ, તત્પર થઈ અજમેર સિધાવો, મહાધર્મની શક્તિ બતાવો, સહુ ભક્તોને મુક્ત કરાવો !
                          દોહા
                          કરી વિગતે વાતને, જોગી થયા વિદાય,
                          થયા ભક્ત બે સાબદા, વાયક બીડલાં જાય.
                          જોગીએ વિપતની વિગતવાર વાત કહી. આ બંને સંતોને તાત્કાલિક જવા વિનંતી કરી, ત્યાંથી વિદાય થયા. બંને ભક્તોએ ધર્મધજાધારીઓને તત્પર થઈ તાત્કાલિક અજમેરમાં આવવા વાયક બીડાં મોકલ્યાં.
                        </p>
                        <p className="paragraph-content" >
                          દોહા
                          આવો સહુ અજમેરમાં, ધર્મ વલોણામાંય,
                          બદલ્યો દિલ્હી બાદશાહ, પંથ પરીક્ષા થાય.
                          આવાં વાયક મળતાં અનેક ભક્તો પોતાના મનમાં આશ્ચર્ય પામી વિચારમાં
                          પડ્યા અને તુરંત જ અજમેર જવા ઊપડ્યા.
                          દોહા
                          ખડીઓ ખાંપણ સાથમાં, આવ્યા ગામ મોજાર,
                          બંદીખાને બાન'ની, સંખ્યા ચૌદ હજાર.
                          રણસિંહ અને ખીમડો અજમેરમાં આવતાં એમને પ્રજાજનોએ તરત ખબર આપી કે, બંદીખાને પડીને ઘંટીઓ દળતા ‘બાન’ તરીકે દુઃખ સહન કરી રહેલ સંતોની સંખ્યા ચૌદ હજાર સુધી પહોંચી છે. તેમાં તમે બે વધુ ઉમેરાશો ! ગામમાં પ્રવેશતાં જ તેમને આ વાત મળી ગઈ. બંને સંતો સાવધ બન્યા અને શહેરની મધ્યમ આવી નરબંકાઓની પેઠે તેમણે લલકાર કર્યો :
                          દોહા
                          જય અલખ નકળંગ જય, સ્વધર્મ રક્ષણહાર, જય હો રામાપીરનો, ખલકે ખેલનહાર, અગાધ લીલા પીરની, સમજે ભક્તિવાન, સ્વધર્મ રક્ષણ કારણે, કરે દેહ કુરબાન.
                          ‘અલખ ધણીનો જય ! નકળંગ નાથનો જય ! પરમપીર રામદેવનો જય ! હિન્દવાપીરને ઘણી ખમ્મા !' આવા લલકાર અને પડકારથી બજારમાં માનવમેદની એકઠી થવા લાગી અને આ બે નવા ભક્તો આવવાની વાત આખા શહેરમાં પ્રસરી ગઈ. વાત સાંભળતાં કતીબશાહ ત્યાં આવી પહોંચ્યો.
                          દોહાં
                          કતીબશા ત્યાં આવિયો, વાતે વડછડ થાય, પીરાઈને બતાવવા, ત્રણેય દિલ્હી જાય.
                          ‘તમે રામદેવને પીર માનો છો ?' કતીબશાએ પૂછ્યું .
                          ‘તમે કોણ છો ?’ રણસિંહે સામે પૂછ્યું.
                          ‘હું ? પીરે આલમ કતીબશાપીર !’
                        </p>
                        <p className="paragraph-content" >
                          પીરે આલમ ! આ અજમેર ગામ છે કે આલમ
                          મારી આ એકલા અજમેરમાં જ નહીં પરંતુ બધી આલમમાં પ્રામિક છે એને રામદવાર હોય અને તમે તેમના ખરા ભક્ત હોય તો તમારી અને તમારા પીની બજ મને પીરે આલમ' તરીકે ઓળખે છે. બીજ થાન છોડીને મીથી વાત કરો કે પરાઇ બતાવો ? પીરાઈ નહીં બતાવી શકો તો તમારા માટે પણ ઘંટીઓ
                          તૈયાર છે ! ક
                          અરે ! કતીબશા ! તમે પીરે આલમ થઈ રામદેવજીની પીરાઇ ના ખુણો એ ૐમ મનાય ? પીરાઈ જોવી હોય તો દિલ્હીના બાદશાહ જેણે થોર થયી મહાત્માઓને પીડા પહોંચાડી પાપ વહોર્યું છે તેમને બતાવાય !' ખીમડે ગંભીરતાથી
                          કહ્યું
                          કતીબશા, ખીમડો અને રણસિંહ ત્રણે જણા દિલ્હી આવ્યા. બાદશાહની કચેરીમાં પ્રવેશતાં બેય ભક્તોએ ‘જય રામાપીર'ની ઘોષણા કરી. રામાપીરનું નામ સાંભળી ક્ચેરીમાં સર્વે ચમકી ગયા ! બાદશાહના નવાબે સંતોને પૂછ્યું : “તમે
                          કોણ છો ?”
                          ‘અમે ! રામદેવજી મહારાજના સેવકો છીએ.’
                          શાહેઆલમ ! એ પીરાઈ બતાવવા અહીં આવ્યા છે !' કતીબશાહે બાદશાહને જણાવ્યું.
                          પીરાઈ ! જોવી હોય તો અમે બાદશાહને વાયક આપીએ છીએ કે તેઓ અજમેરમાં આવે,’
                          દોહા
                          અજમેરમાં સંકટ સહે, સાધુ સંત સુધીર,
                          ચૌદ હજારે જોઈ લો, ઘંટીએ ઘંટીએ પીર.
                          ‘બાદશાહ અજમેરમાં જે ઘંટીઓ ફેરવી પોતાની કાયાને કષ્ટ આપવા છતાં પોતાના નિશ્ચયથી નથી ડગતા, એવા ઘંટીએ ઘંટીએ પીર છે. જે સત્યને માટે બલિદાન આપવા તૈયાર થાય તે પીર કહેવાય. આવો અને જુઓ !' રણસિંહ અને ખીમડે જણાવ્યું. બાદશાહ પોતાના વજીર અને કતીબશા સાથે અજમે૨માં આવ્યા. ઘંટુલા સ્થાનમાં પ્રવેશતા રણસિંહ અને ખીમડે ‘જય રામાપીર ! જય હિન્દવાપીર ! જય જય સંત સુધીર'નો જયનાદ કર્યો. બધાઓએ જય ઘોષણા ઝીલી લીધી અને કેદખાનામાં ઉત્સાહનું મોજું ફરી વળ્યું. જેમનાં દર્શનથી, પાપનો નાશ
                        </p>
                        <p className="paragraph-content" >
                          ફેરવતા જોઈ રાસિંહ અને ખીમડાનું દિલ દ્રવિત થઈ ગયું. બાદશાહે તેમન વિર થાય, જેમના ચરણસ્પર્શથી પતિતપાવન થઈ જાય એવા મહાત્માઓને
                          પામી જોઈ રહ્યો. આટલું દુઃખ ભોગવવા છતાં કોઈ ભક્તના મોઢા ઉપર જણાતી ન હતી. બધાના મોઢા ઉપર આનંદ તરવરતો હતો. કેદખાનાના
                          ભાગમાં એક વિશાળ તંબુમાં બધા ભક્તો ઘંટીઓ તાણી રહ્યા હતા
                          છંદ
                          રેત દાંતા કષ્ટ સહેતા, દેહ દમતા દુઃખવંતા, સરધાવંતા રામ રટતા, મહાબલવંતા જયવંતા.
                          રામનામનો મંત્ર જપતાં, ઘ૨૨૨ ઘંટી ખેચંતા,
                          કતીબશા ઉરમાં હરખંતા, બાદશાહ મુખ મલકતા.
                          '
                          કતીબશા બાદશાહને મલકાતો જોઈ પોતે હરખમાં આવી ગયો. તંબુમાં માટે ઉપર ગાદી બિછાવી હતી. તેવી ગાદી પર બાદશાહ અને કતીબશાહ બેઠા. બાદશાહે અને કતીબશાહે કટાક્ષમાં રણસિંહ સામે જોયું. ત્યાં તો કતીબશાહને રણસિંહે કહ્યું : “પીર !’ એક કરવત લાવો !'
                          કતીબશાએ કરવત મંગાવી.
                          કરવત આવતાં રણસિંહે બાદશાહ પાસે મૂકી અને કહ્યું : ‘આપને પરચો જોવો હોય તો બધા મહાત્માઓ ઘંટુલેથી થોડી વાર મુક્ત કરી અહીં સંતમેળો ભેગો થવા દો.’ રણસિંહના કહેવા પ્રમાણે થોડી વાર માટે બધા સંતોને ઘંટીઓ બંધ કરવા બાદશાહે ફરમાન કર્યું. બાદશાહના ફરમાન પ્રમાણે કતીબશાહે ઘંટીઓ બંધ કરાવી. બધાને સભાના રૂપમાં બેસાડ્યા. સભા વચ્ચે રણસિંહ ઊભો થઈ બોલ્યો ઃ ‘બાદશાહ ! પરચા-પ્રતાપ બતાવવો એ તો માથા સાટે માલ છે. આ ક૨વત અમારા માથે મૂકો અને માથું વેરવા માંડો ! જેના માથામાંથી દૂધની કે નિર્મળ નીરની ધારા નીકળે તે સાચા પીર અને લોહીની ધારા નીકળે તે પાખંડી ! અમારા ભક્તો તથા તમારા પીર ઓલિયા બધાની પરીક્ષા કરો.’ રાવત રણસિંહની વાત બાદશાહે કબૂલ
                          રાખી.
                          કતીબશા આ ભયંકર કસોટી સાંભળી ધ્રૂજી ગયો, પરંતુ એ બાદશાહનો નિશ્ચય ફેરવી શકે તેમ નહોતો, પહેલી કરવત રાવત રણસિંહને માથે મૂકી અને સામસામી ૨વત ખેંચવા માંડી. રણસિંહનું માથું વહેરાતાં તે ‘જય રામાપીર' બોલી ઊઠ્યો.

                        </p>
                        <p className="paragraph-content" >
                          જો જાય વચ્ચે તેના માથામાં લોહીને નો દુધની ધારા વહેવા માંડી . આ તે જરા મોંમાં આંગળાં નાખી ગયો
                          ખડોને માથે કરવતનું ઘર્ષણ થતાં એમ ગોખ્ખા પાણીની ધાર નીકળી અને જાન માય કરવતનું ઘર્ષણ થતાં લોહીની ધારા વહેતી થઈ. સંત સમાજે થ - - ” જય અલખ ધણીની જય !'ની ધોળા કરતાં બંધ રહેલા કેટલા
                          ૪૪ ઈવા લાગ્યા.
                          ચંદ
                          ઘર ઘર ફરતા ઘંટુલા, પડ ઊછળીને પછડાતાં, ડડડ ધડક થાય કડાકા, પડ તૂટતાં ટુકડા થાતાં. ઉછળી ગાદી પચો કતીબશા, ચીસ નાખતો પછડાતાં ! ઘડક ધબડક ધડક ધબડક, બાદશાહ ઊર ઘડકાતા. ઊડતા ટુકડા ઘંટુલા કેરા, તંબુ તડ તડ ચીરાતા, પચો તંબુનો થંભ તૂટીને, કડ કડ કડડાટી થાતાં. પથરા ઊડીને પડે ધબોધબ, બાદશાહ ભયભીત થાતાં, માફ કરો હિન્દવાપીર રામા, વેણ મુખથી ઉચ્ચારતા. જય રામાપીર જય હિન્દવાપીર, બાદશાહ શીર ઝૂકતા, કેશવ કહે દિલ્હીશાહને, પ્રચંડ પરચા પરખાતા. બાદશાહ બધા સંતો પાસે ક્ષમા માગી નમી પડ્યો અને બધા સંતોને મુક્ત કર્યા. કતીબશા રણસિંહને અને ખીમડાને શરણે રહેવા અને મહાધર્મ સ્વીકારવા તૈયાર
                          થયો. બધા સંતોની હાજરીમાં રણસિંહે એને પ્રમોદ્યો અને રામસાગર લઈ ‘જય રામદેવપીર કી જય ! જય અલખધણી !' ના જયનાદ કરતો શ્રી રામદેવજીના ગુણાનુવાદ ગાવા લાગ્યો.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
      {/* </div>
      </div> */}
      <div>
        <Footer />
        {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
      </div>
    </>
  );
};

export default BlogDetails10;
