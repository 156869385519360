import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails31 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery33.jpg";
    return (
        <>
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}
 <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      

            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                    
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            સિદ્ધાત્મા રામગર
                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                    
                                                >
                                                    <span className="income">સિદ્ધાત્મા રામગર

                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content">
                                                    ભાઠી હરભુજીએ ધર્મપ્રચાર કરવા મારવાડ, મેવાડ અને સોરઠ પ્રદેશમાં જુદાં જુદાં સ્થળે ફરી શ્રી રામદેવજીનાં ત્રણસો ને સાઠ દેવસ્થાન સ્થાપ્યાં. બાધા કરવા આવતાં લૂંટાયેલા દલુશેઠે પાછળથી ભેખ સ્વીકારી, માલવ પ્રદેશમાં ફરી છન્નુ સ્થળે ધર્મધજાઓ ફરકાવી. તેમના પુત્રનું નામ રામગર કે રામૈયો રાખ્યું હતું. એ રામદેવજીનો વરદાની અને કૃપાપાત્ર હતો. એ મોટો થતાં રામદેવજીની સમાધિ પાસે સેવા કરવા રહ્યો હતો. તે અખંડદીપ રાખી ત્યાં જપતપ કરતો હતો. એને સહું સિદ્ધાત્મા ‘રામગર સ્વામી' નામથી સંબોધતા. તેઓ પણ કમંડળ, ચીપિયો, ઝોળી, માળા અને પગની ઘૂંટી સુધી લાંબી કફની ધારણ કરી પ્રવાસે નીકળ્યા.
                                                    પાલી, સોજક, લાંબી, અબાર, ખુદાબડા, આકડાવ, ગોદાવ, તારડી, ઓદભ, હમેલિયા, સરપિયા ગામ વગેરે સ્થળે ફરતા ફરતા જોધપુર આવ્યા. જોધપુર નરેશ અને દિવાન હજારીમલ તથા નગરજનોમાં ઘેર ઘેર રામદેવજીની પાઠપૂજા અને ભજનભાવ થતો હતો. રામગરજી જોધપુર આવ્યા અને નગરના પ્રવેશદ્વાર પાસે એક શિવાલય જતાં તેના ઓટલા પર આસન જમાવ્યું. શંકરની પૂજા કરવામાં વિપ્રો આવતા તેથી ગામમાં વાયુવેગે વાત પ્રસરી ગઈ કે, કોઈ દેવાંશી માતમા પધાર્યા છે. દિવાનને ખબર મળતાં એ ત્યાં આવ્યા અને મહાત્માને એક ઘ્યાને માળા ફેરવતાં દીઠા. રામગરજીની માળા પૂરી થતાં એમણે દીવાન સામે હાથ ઊંચા કરી ‘જય રામાપીર' કહી માળા નીચે મૂકી. દીવાનને રામગરજીની પરીક્ષા કરવાની ઇચ્છા થઈ તેમણે પૂછ્યું.
                                                    ‘મહાત્મન્ ! આપ ક્યાંના વતની છો ?'
                                                    ‘ભગવાન રામદેવજીનાં ઘામ રણુંજાનો.' રામગરે કહ્યું.
                                                    ‘અમારા મહારાજા રામદેવજીના પરમ ભક્ત છે. દરબારગઢમાં મંદિર છે. મારી સાથે પધારો હું આપને મંદિરમાં દર્શન ક૨વા તેડવા આવ્યો છું.' રામગરજી દીવાન સાથે દરબારગઢમાં રામદેવજીના મંદિરે ગયા. દંડવત પ્રણામ કરી ‘જય નકળંગ, જય અલખધણી !'નો જયકાર કરીને બેઠા.
                                                    રાજમહેલ પાસે રામજીમંદિર હતું. મંદિરના પૂજારી રામનંદે પોતાની પુત્રીને

                                                </p>
                                                <p className="paragraph-content">
                                                    નૃત્ય શીખવ્યું હોવાથી એ રાજનર્તકી હતી. એનું નામ પરમાનંદી હતું. દીવાને પરમાનંદીને બોલાવી ભલામણ કરી કે, એક જોગી મહાત્માં આવ્યો છે. અને મોહવશ કરવા કચેરીમાં આવી નાચગાન કરી તારે તારી કળા બતાવવાની છે. દીવાને રાજાને તમામ વાતથી વાકેફ કરી દીધા અને કચેરી ભરાવા લાગી. નગરના પ્રતિષ્ઠિતજનોથી કચેરી હેગડાઠઠ ભરાઈ ગઈ. પરમાનંદી, એનો ભા
                                                    સારંગીવાળો અને તબલચીએ આવી સભામાં સ્થાન લીધું.
                                                    મહારાજ કચેરીમાં પધારતાં છડી પોકારાઈ અને દીવાન રામગરજીને તેડીને કચેરીમાં આવ્યા. 'જય રામાપીર' નો જયનાદ કરી રામગરજીએ બેઠક લીધી. ‘મહાત્મા ! આપ ક્યાંના વતની છો ?’ મહારાજાએ નમન કરતાં પૂછ્યું.
                                                    દોહા
                                                    મેં રામૈયા રામકા, ઇષ્ટદેવ પીર રામ,
                                                    પિતાજી દક્ષુ ભેખિયા, માતા સુમિત નામ.
                                                    દીવાને પરમાનંદીને પઢાવી રાખી હોવાથી એ ઊભી થઈ. શૃંગાર સર્જેલા વસ્ત્રાભૂષણો વિદ્યુત જેમ ચમકવા લાગ્યાં. સારંગી અને તબલાના મીઠાં સુર સાથે પરમાનંદીએ રામગર સામે સ્મિત કરી સમસ્યાભર્યું ગીત લલકાર્યું.
                                                    (રાગ : વાગેશ્વરી)
                                                    શયનવા સુના લાગે મેરી સજની,
                                                    પલકે પલકે ભીંજે નયનવા,
                                                    યુગ
                                                    સરિસ ભઈ રજની ...રીયનવાદ
                                                    પગના ઝાંઝરના ઠમકા-ઠેકાથી રામગર ખડખડાટ હસવા લાગ્યા. વાહ ! મારા રામ ! વાહ ! મોરા નકળંગીનાથ ! આવી સતી શિરોમણી જેવી દીકરીને પાપી પેટે ભજન ભુલાવી દીધું. એટલું બોલતાં સારંગીની તાંત તૂટી ગઈ ! તંબૂરાની જિવાળી · ફાટી અને ટુકડો નીચે પડ્યો !
                                                    બેટી ! તારા ગાવામાં મારા રામનું નામ નથી. એ તને સમજાયું ? બેટી ! હું મારા રામની કૃપાજાળમાં છું, એ જાળમાં મને આનંદ છે. તારી યુવાવય હવે વૃદ્ધાવસ્થા તરફ વળવાને બહુ લાંબો સમય નથી. તેં મને મોહવશ કરવા જે પરિશ્રમ કર્યો એ પણ જાણું છું. તારા અંતરમાં શું ભર્યું છે ? એ મારા રામથી અજાણ્યું નથી. રામ અને રામૈયો બંને જાગૃત છે.
                                                    મિયાબ બબ

                                                </p>
                                                <p className="paragraph-content">
                                                    છંદ
                                                    ચતુરી ચિત્ત માંથા કા ફંદા, કેસે મેં સતસંગ કર્મદા ? માનવ તનાથ ગોબર ગંદા, હીર નહી મેં અવતાર ધરંદા મેં. રામાપીર નામ વરંડા, શ્વાસો શ્વાસે જાપ કરંદા, અથ જીતે છે ઈતિ મરંદા, જીવન રામ કે ચળે પરંદા ‘બેટી ! સાંભળઃ
                                                    મામકી નામની ગુણીકા રામનામના અખંડ જાપ સ્મરણ અને નિષ્કામ ભક્તિ કરતી હતી. એ પરમધામની અધિકારી બની હતી. તું એવી પ્રભુભક્તિ અને ભજન કરવા લાગી જા ! તારી વૃદ્ધાવસ્થાએ અંગ, રૂપ, રંગ, કંઠ અત્યારના જેવાં રહેશે નહીં. જીવનને શા માટે હલકું બનાવે છે ?
                                                    ચોપાઈ
                                                    નાચે રાચે દેહી રૂપાળી, સુન પરમાનંદી મતવાલી.
                                                    ભીતર દંભ કપટ છલવાલી, જન્મે થઈશ મહિષી કાલી, પરમાનંદી ! તું મને પથભ્રષ્ટ કરવા નાચગાન કરી રહી છે. તારા ઉરમાં કાળપ છે, એ હું જાણું છું. તને તારા કર્મની શિક્ષા તો થશે જ. તારો હવે પછીનો અવતાર મહિષી-ભેંસનો થશે.”
                                                    રામગરની આવેશમય વાણી સાંભળી જોધપુર નરેશ, દીવાન અને પ્રજાજનો સ્તબ્ધ થઈ ગયાં. ગુણકા કંપવા લાગી. દયાની માગણી કરવા તેણે રામગરજી સામે શીર નમાવ્યું. એ વખતે અંતરિક્ષમાંથી પુષ્પવૃષ્ટિ થઈ. રામગરજીના ચમત્કારિક પરચાથી સર્વે એમના પગમાં પડી ગયા અને એમને શીલવંત મહાન સંત જાણી ક્ષમાયાચના કરવા લાગ્યા.
                                                    રામગરજી રૂઠ્યા ! પરમાનંદી પોતાના કૃત્ય માટે પસ્તાવા લાગી. તેણે એ વખતે પોતાના હીરા રત્નજડીત સુવર્ણ અલંકારો, પગનાં ઝાંઝર, કાનના કુંડળ ઉતારી નાખ્યા અને દીવાન સામે ધર્યાં, રાજાને નમન કરતાં તે બોલી :
                                                    ‘બાપુ ! આપ મહારાજાના આશ્રયે મારું જીવન અત્યાર સુધી નમ્યું છે. આ અલંકારો આપના તરફથી મને મળેલા છે. એનો પુણ્ય દાનમાં ઉપયોગ કરજો.' ‘પરમાનંદીએ ગળગળા કંઠે કહ્યું અને રામગરજીને હાથ જોડી કહેવા લાગી : ‘મહારાજ ! મારી અજ્ઞાનતાથી મેં આપનો અપરાધ કર્યો છે. હવેથી હું ભગવાન રામદેવજી મહારાજના નામસ્મરણ અને ભજનકીર્તનમાં મારું જીવન વિતાવીશ.'

                                                </p>
                                                <p className="paragraph-content">
                                                    પરમાનંદીએ આંસુ સારતાં કહ્યું. રામગરજીને એના મનને નિર્મળ જોઈ કહ્યું
                                                    દોહા
                                                    ભવ પાર.
                                                    જલ પદમણી થઈશ તું, મહિષીનો અવતાર, શરણું મળશે સંતનું, ઉતારશે રામગરજી છેલ્લાં વચન સંભળાવી, સહુને આશીર્વાદ આપી, તેઓ જાપુરથ વિદાય લઈ કચ્છમાં ધર્મપ્રચાર કરીને સૌરાષ્ટ્રમાં આવ્યા. એ નિદ્રાજીત હતા, રાતદિવસ જાગતા હતા અને રામદેવજીનું રટણ કરતા બેસી રહેતા. કોઇ કોઇ વાર તેઓ મનમસ્તીમાં ખડખડાટ હસતા. જુદા જુદા પ્રદેશોની ભાષાના શબ્દો ભેળસેળ કરી બોલતા. એમનું અગાધ જ્ઞાન અને શબ્દોથી પંડિતો, શાસ્ત્રીઓ, પુરાણીઓ તેમને વેદ વિભૂષણ ગણતા. સૌરાષ્ટ્રમાં ધર્મધ્વજ ફરકાવતા તેઓ નળકાંઠા આવ્યા.
                                                    ચોપાઈ
                                                    નળકાંઠા ભૂમિમાં આવે, તલાવડી પર સ્થાન જમાવે,
                                                    ગામ રંગપુર-સુરસંગ ભૂષા, સુણ્યા સંત પ્રભુતા રૂપા. નળકાંઠા ભૂમિમાં રંગપુર નામના ગામને પાદરે, એક નાની તલાવડીની પાળ ઉપર સુકાઈ ગયેલા ઝાડના ઠૂંઠાનું સ્થાન પસંદ પડવાથી તેમણે ત્યાં જ આસન જમાવ્યું. જોધપુરથી નીકળતાં તેમણે નિશ્ચય કર્યો કે, બનતા સુધી શહેર કે ગામ બહાર આસન રાખવું.
                                                    ચોપાઈ
                                                    નહીં નગર નહીં ગ્રામ પ્રવેશા, ઉદરની નિમિત્ત ફીકર નહીં લેશા, રામ સ્મરણમાં નિશદીન ગાળે, બેસી રહે તળાવની પાળે. રસ્તે આવતા જતા વટેમાર્ગુ આ યોગી મહાત્માને જોઈને તેમનાં દર્શન કરતા હતા. વટેમાર્ગુઓ પણ ઘડી વાર ત્યાં બેસતા પણ કોઈ સાથે વાતચીત કરવામાં તેઓ સમય ગાળતા નહીં.
                                                    ગામમાં સુરસંગજી દરબારના ડાયરામાં સંતના આગમન અને તળાવની પાળે બેસણાની વાત થઈ. કોઈ અવધૂત ગણે, કોઈ મસ્તક કહે, કોઈ ગાંડપણ કહે, આવી મનફાવતી વાતો થતી. એકે કહ્યું : બાપુ ! હું સવાર-સાંજ ખેતરે જતાં થોડી વાર એમની પાસે બેસું છું. જો મોજમાં હોય તો ઘડીક બોલે, હસે, ધ્યાનમાં રહે. કોઈ પાસે કશું માંગતા નથી. જ્યારે જુઓ ત્યારે એક જ આસન પર જાગતા બેઠા
                                                    હોય છે.

                                                </p>
                                                <p className="paragraph-content">
                                                    દોહા
                                                    વાત સુણીને આવિયા, સુરસંગજી રાય,
                                                    વિનય અને વિવેકથી, નમીને લાગ્યા પાય.
                                                    સુરસંગજી દિવ્યવિભૂતિ મહાત્માને જોતાં પ્રેમપૂર્વક ભક્તિભાવથી પગે લાગ્યા. બે હાથ જોડી સામે બેઠા અને પૂછ્યું :
                                                    ‘મહાત્મન્ ! આપ ક્યાંના વતની છો ? આપનું નામ શું ?’ રામગર એમના સામે એકી નજરે મીટ માંડીને જોઈ રહ્યા અને જવાબ આપ્યો નહીં. થોડી વાર પછી વિચાર કરી તેઓ બોલ્યા :
                                                    દોહા
                                                    છું. રામૈયો રામનો, રણુંજા મુજ નિવાસ,
                                                    વાટવ થઈને વિરમ્યો, ઠીકે ગણી આ વાસ.
                                                    ‘દરબાર ! હું રણુંજાના ભગવાન રામદેવજી મહારાજનો શરણાર્થી છું. ધર્મનો મર્મ સમજવા વિચરણ કરું છું. આ સ્થળ ઠીક લાગવાથી અહીં વિશ્રાન્તિ લઉં છું.' રામગરે કહ્યું. રામગરના પ્રસનન્તાપૂર્વક શબ્દો સાંભળી સુરસંગજી ખુશ- ખુશાલ બની ગયા અને બોલ્યા : ‘મહાત્મા રામગરજી, આજે આપનાં દર્શનથી હું પાવન થયો છું. તેઓએ વિનંતી કરી કહ્યું,
                                                    દોહા
                                                    ભાટી હરજી આવિયા, કરતા ધર્મપ્રચાર,
                                                    ( પૂજીએ રામદેવપીરને, વડવા વંશપરિવાર,
                                                    ‘મહાત્મા ધર્મપ્રચારના પ્રવાસે પરિભ્રમણ કરતા રામદેવજી મહારાજનાં શરણાગત ભાઠી હરજી અહીં પધાર્યા હતા. તેમણે અહીં જ વિસામો કરી લીધો હતો. અમારા વડવાઓએ તેમની પધરામણી કરી, નિજીયાધર્મના ઉપાસક બન્યા હતા. હું પણ તેમનો ઉપાસક ભક્તિભાવવાળો છું.

                                                </p>
                                                <p className="paragraph-content">
                                                    હું આપની સેવા ઇચ્છું છું. આપ મારી ઇચ્છા પૂર્ણ કરી,
                                                    દોહા
                                                    લાવું છું. ભોજન અહીં, આરોગો આઠાર,
                                                    સેવક ગણીને આપનો, પ્રેમે કરો સ્વીકાર.
                                                    तरत
                                                    “મહાત્મન્ ! હું આપના માટે ભોજન મંગાવું છું. કૃપા કરીને સ્વીકાર કરશો ?' સુરસંગજીએ કહ્યું. રામગરે માથું હલાવી ‘હા’ કહ્યું. દરબારે માણસને દોડાવ્યો અને ઝટ ભોજન તૈયાર કરાવી લઈ આવવા કહ્યું. કાંસાની થાળી અને થેબડી-તાંસળી બેય વાસણને રૂમાલ ઢાંકી માણસ આવ્યો અને ભોજન રામગરજી પાસે ધર્યું.
                                                    દોહા
                                                    એક કોળિયો લઈ અને, ધર્યો મુખની માંય, બેય પાત્રમાં જળ ભર્યું, નવાઈ પામ્યા રાય. ખડખડ હસવા લાગતા, સહુને અચરજ થાય, નાખ્યા. થાળી થંબડી, તલાવડીની માંય.
                                                    શીરા અને શાકનાં પાત્ર પરથી રૂમાલ ખસેડી લીધો. એક કોળિયો મોઢામાં મૂકી થાળી થેબડીમાં પાણી રેડ્યું. ખડખડાટ હસી થાળી અને થેબડીને તલાવડીમાં ફેંકી દીધાં. આ જોઈ ત્યાં બેઠેલા સર્વજનો નવાઈ પામ્યા.
                                                    પૂરું ખાધું નહીં અને પાણી રેડી કાંસાની કિંમતી થાળી અને થેબડી તલાવડીમાં ફેંકી ! માનો ન માનો પણ આ મહાત્માનું મગજ ઠેકાણે નથી. એમ સૌ જુદી જુદી વાતો કરવા લાગ્યા. બીજે દિવસે અને ત્રીજે દિવસે પણ તેમ કર્યું. તેથી ડાયરાના માણસોએ નક્કી કર્યું કે, આ સાધુને હવેથી શકોરાંમાં ભોજન પહોંચાડવું. સુરસંગજીએ રસોઈ થઈ જાય એટલે ‘શકોરા’માં ભરીને ઢાંકીને લાવવા પોતાના માણસોને ભલામણ કરી.
                                                    સવારમાં સુરસંગજીના માણસો રામગરજી પાસે આવ્યા અને બે શકોરાંમાં ભોજન ઢાંકીને તેમની પાસે મૂક્યું. એ વખતે ડાયરામાં થયેલી વાત પ્રમાણે શકોરાનું પરિણામ જોવા ઘણાં માણસો આવીને બેઠાં હતાં.
                                                    નિત્ય નિયમ પ્રમાણે રામગરજીએ ઢાંકેલા ‘શકોરાં' ઉપર પોતાનો માળાવાળો હાથ ફેરવી ઉચ્ચાર્યું : ‘બ્રહ્માર્પણ મસ્તુ.’
                                                    રૂમાલ ખેંચતાં શકોરામાં કોઈ વસ્તુ નહોતી – સાવ કોરાં હતાં. આ દૃશ્ય જોઈ

                                                </p>
                                                <p className="paragraph-content">
                                                    સિહા બગર
                                                    સુરસંગ તથા અન્ય માણસો દિવ્યૂઢ બની ગયા ! આશ્ચર્યચકિત થઇ ગયા ! રામગરજીએ હસીને બેય શારાને તલાવડીમાં ક્યાં ! શકોરાં પાણી ઉપર તરા
                                                    લાગ્યાં !
                                                    શકારામાં કોઈ વસ્તુ ન દેખતો લાવનાર સામે સુરસંગ જોઈ રહ્યા ! લાવનાર આશ્ચર્ય પામ્યો. અને દરબાર સામે નીચું માથું કરીને ધ્રૂજવા લાગ્યો,
                                                    ચોપાઈ
                                                    કૃતિકા કેરાં નિત્ય ફોરાં, રજકણની જાત શકીરા, તલાવડીમાં તરે શકો, શયાના નામે કોરાં, દોહા
                                                    નામ શકીરાએ સર્મ, પડ્યું ‘શમૈયા’નામ,
                                                    ત્રણ થાળી ત્રણ થંબડી, હતી રાયને ધામ.
                                                    આ તે ચમત્કાર કે પરચો ! કે સિદ્ધિ કે ક્રિયા ? એ સમજવામાં ન આવ્યું. રાજા અને પ્રજા રામગરજીને શરણે જઈ જ્ઞાનપ્રાપ્તિ માટે હંમેશા તેમની વાણી સાંભળવા લાગ્યા. અને રામગરજીના ઉપદેશો સાંભળી બધા રામદેવજીના આસ્તિક બન્યા,
                                                    સવો ભગત

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails31;
