import React from "react";
import Carousel from "react-bootstrap/Carousel";

import "./index.css";
// import React from 'react';
// import OwlCarousel from 'react-owl-carousel2';
// import 'react-owl-carousel2/style.css';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import Header from "../Header";
import { Helmet } from "react-helmet";

const AllFarmaan = () => {
    const farmaan1 = "/assets/wp-content/uploads/2018/12/farmaan-1.png";
    const farmaan2 = "/assets/wp-content/uploads/2018/12/farmaan - 2.png";
    const farmaan3 = "/assets/wp-content/uploads/2018/12/farmaan-3.png";
    const farmaan4 = "/assets/wp-content/uploads/2018/12/farmaan-4.png";
    const farmaan5 = "/assets/wp-content/uploads/2018/12/farmaan-5.png";
    const farmaan6 = "/assets/wp-content/uploads/2018/12/farmaan-6.png";
    const farmaan7 = "/assets/wp-content/uploads/2018/12/farmaan-7.png";
    const farmaan8 = "/assets/wp-content/uploads/2018/12/farmaan-8.png";
    const farmaan9 = "/assets/wp-content/uploads/2018/12/farmaan-9.png";
    const farmaan10 = "/assets/wp-content/uploads/2018/12/farmaan-10.png";
    const farmaan11 = "/assets/wp-content/uploads/2018/12/farmaan-11.png";
    const farmaan12 = "/assets/wp-content/uploads/2018/12/farmaan-12.png";
    const farmaan13 = "/assets/wp-content/uploads/2018/12/farmaan-13.png";
    const farmaan14 = "/assets/wp-content/uploads/2018/12/farmaan-14.png";
    const farmaan15 = "/assets/wp-content/uploads/2018/12/farmaan-15.png";
    const farmaan16 = "/assets/wp-content/uploads/2018/12/farmaan-16.png";
    const farmaan17 = "/assets/wp-content/uploads/2018/12/farmaan-17.png";
    const farmaan18 = "/assets/wp-content/uploads/2018/12/farmaan-18.png";
    const farmaan19 = "/assets/wp-content/uploads/2018/12/farmaan-19.png";
    const farmaan20 = "/assets/wp-content/uploads/2018/12/farmaan-20.png";
    const farmaan21 = "/assets/wp-content/uploads/2018/12/farmaan-21.png";
    const farmaan22 = "/assets/wp-content/uploads/2018/12/farmaan-22.png";
    const farmaan23 = "/assets/wp-content/uploads/2018/12/farmaan-23.png";
    const farmaan24 = "/assets/wp-content/uploads/2018/12/farmaan-24.png";
    const farmaan25 = "/assets/wp-content/uploads/2018/12/farmaan-25.png";
    const farmaan26 = "/assets/wp-content/uploads/2018/12/farmaan-26.png";
    const farmaan27 = "/assets/wp-content/uploads/2018/12/farmaan-27.png";
    const options = {
        loop: true,
        items: 2,
        margin: 10,
        autoplay: true,
        // dots: true,
        smartSpeed: 450,
        nav: true,
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 2,
            },
        },
    };

    return (
        <>
            <Helmet>
                <title>Ramapir | AllFarmaan Page</title>
                <meta name="keywords" content="In V.S 1515 Bhadrapad Sudi Thursday 11th, Bhagwan Ramdevji Maharaj took Samadhi. This is when he delivered his last message and the 24 Farmaan (24 divine commands), to his devotees." />
            </Helmet>
            {/* <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" /> */}
            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                    {/* <h1 className="sc_layouts_title_caption">
                              Gallery
                            </h1> */}
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                        {/* <Link
                                to="/"
                                className="breadcrumbs_item home"
                              >
                                Home
                              </Link> */}
                                                                        {/* <span className="breadcrumbs_item current">
                                Gallery
                              </span> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div>
                <h5 className="allfarman-heading">Farman</h5>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <img src={farmaan1} className="card-img-top" alt="Card 2 Image" />
                    </div>
                    <div className="col">
                        <h5 className="card-title custom-title">In V.S 1515 Bhadrapad Sudi Thursday 11th, Bhagwan Ramdevji Maharaj took Samadhi. This is when he delivered his last message and the 24 Farmaan (24 divine commands), to his devotees.</h5>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <h5 className="card-title">Bhagwan Ramdevji Maharaj said "Gatganga (Participants of this Communion are believed to be as pure as the Mother Ganges) listen to the following Twenty Four Divine Commands.</h5>
                    </div>
                    <div className="col">
                        <img src={farmaan2} className="card-img-top" alt="Card 2 Image" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <img src={farmaan3} className="card-img-top" alt="Card 2 Image" />
                    </div>
                    <div className="col">
                        <h5 className="card-title custom-title">Always keep away from sin and pay particular attention to the way of your life: Be kind to all living creatures and feed the hungry ones.</h5>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <h5 className="card-title">Confess your sins to your Satguru and always be ready to help others. Remember that life is too short and you must think of what is right and wrong.</h5>
                    </div>
                    <div className="col">
                        <img src={farmaan4} className="card-img-top" alt="Card 2 Image" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <img src={farmaan5} className="card-img-top" alt="Card 2 Image" />
                    </div>
                    <div className="col">
                        <h5 className="card-title custom-title">It does not suit a member of the Communion to get involved in gossip, jealousy or grudge. Accept the invitation to attend the Paat/Pooja with enthusiasm and attend to meditate in search of your soul.</h5>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <h5 className="card-title">You must submit to your Sadguru first to achieve knowledge and accomplish your goal. Have faith in the Almighty God, who will create goodness in you.</h5>
                    </div>
                    <div className="col">
                        <img src={farmaan6} className="card-img-top" alt="Card 2 Image" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <img src={farmaan7} className="card-img-top" alt="Card 2 Image" />
                    </div>
                    <div className="col">
                        <h5 className="card-title custom-title">A person may look clean and wears saffron clothes, but if his mind is malicious or corrupt and there is no sparkle in his eyes and face, consider him to be a Nagura - a person without guidance of a Sadguru.</h5>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <h5 className="card-title">Service to humanity is service to God, make that to be your duty that is the teaching of Sanatan Dharma. Relinquish lust and attachment to the material world and follow the principles of Sanatan Dharma.</h5>
                    </div>
                    <div className="col">
                        <img src={farmaan8} className="card-img-top" alt="Card 2 Image" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <img src={farmaan9} className="card-img-top" alt="Card 2 Image" />
                    </div>
                    <div className="col">
                        <h5 className="card-title custom-title">My followers are those who are polite, are kind, are honest of principle, and are living a moral life of high integrity. </h5>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <h5 className="card-title">Maat-pita guru seva karvi karvo atithi satkar Sva-dharmno pahelan vichaar karvo pachhi adarvo aachar. </h5>
                    </div>
                    <div className="col">
                        <img src={farmaan10} className="card-img-top" alt="Card 2 Image" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <img src={farmaan11} className="card-img-top" alt="Card 2 Image" />
                    </div>
                    <div className="col">
                        <h5 className="card-title custom-title">My followers are those who are polite, are kind, are honest of principle, and are living a moral life of high integrity. </h5>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <h5 className="card-title">Maat-pita guru seva karvi karvo atithi satkar Sva-dharmno pahelan vichaar karvo pachhi adarvo aachar. </h5>
                    </div>
                    <div className="col">
                        <img src={farmaan12} className="card-img-top" alt="Card 2 Image" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <img src={farmaan13} className="card-img-top" alt="Card 2 Image" />
                    </div>
                    <div className="col">
                        <h5 className="card-title custom-title">Have no doubt in your heart and mind about your faith in the Almighty God, and avoid lust for the pleasures of life, pride and arrogance. To accept the death as the only truth (reality) is the true knowledge and the rest is superfluous attractions and/or attachments. </h5>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <h5 className="card-title">Cut your cloth according to your coat, avoid the greed of fame. Have no greed for the chairmanship and your miseries will come to an end</h5>
                    </div>
                    <div className="col">
                        <img src={farmaan14} className="card-img-top" alt="Card 2 Image" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <img src={farmaan15} className="card-img-top" alt="Card 2 Image" />
                    </div>
                    <div className="col">
                        <h5 className="card-title custom-title">Be pure in your thoughts with high moral values and act accordingly. Live a independent life with full faith in the Almighty God. </h5>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <h5 className="card-title">Be sympathetic towards the poor and always be ready to help the needy. I will always remember such devotees and they will remain close to my heart.</h5>
                    </div>
                    <div className="col">
                        <img src={farmaan16} className="card-img-top" alt="Card 2 Image" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <img src={farmaan17} className="card-img-top" alt="Card 2 Image" />
                    </div>
                    <div className="col">
                        <h5 className="card-title custom-title">My true devotees are unselfish, impartial, honourable and believing in my words. </h5>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <h5 className="card-title">Those who spend their life for the cause of humanity and those who do not discriminate between colour, caste, creed or religion are worthy of my worship.</h5>
                    </div>
                    <div className="col">
                        <img src={farmaan18} className="card-img-top" alt="Card 2 Image" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <img src={farmaan19} className="card-img-top" alt="Card 2 Image" />
                    </div>
                    <div className="col">
                        <h5 className="card-title custom-title">Those devotees who have complete faith in me are the ones who will recognise me in visible and invisible forms and are worthy of my trust. </h5>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <h5 className="card-title">Those who perform good deeds and are sincerely devoted to me are worthy of these Farmaans and entitled to my blessings. </h5>
                    </div>
                    <div className="col">
                        <img src={farmaan20} className="card-img-top" alt="Card 2 Image" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <img src={farmaan21} className="card-img-top" alt="Card 2 Image" />
                    </div>
                    <div className="col">
                        <h5 className="card-title custom-title">Listen to all as an audience but follow my teachings. This will lead you to salvation. (Nirvana - a state where a soul is not reincarnated). </h5>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <h5 className="card-title">Those persons who are anonymous donors without any hope or wish for return of a favour and are happy at all times would always remain dear and close to my heart.</h5>
                    </div>
                    <div className="col">
                        <img src={farmaan22} className="card-img-top" alt="Card 2 Image" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <img src={farmaan23} className="card-img-top" alt="Card 2 Image" />
                    </div>
                    <div className="col">
                        <h5 className="card-title custom-title">There are nine forms of Bhakti (devotion). Salute all the nine forms of Bhakti and the sincere devotees are those who accomplish and live up to these forms of devotion. They are the ones who would achieve Moksha or Nirvana.</h5>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <h5 className="card-title">There are nine forms of Bhakti (devotion). Salute all the nine forms of Bhakti and the sincere devotees are those who accomplish and live up to these forms of devotion. They are the ones who would achieve Moksha or Nirvana.</h5>
                    </div>
                    <div className="col">
                        <img src={farmaan24} className="card-img-top" alt="Card 2 Image" />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <img src={farmaan25} className="card-img-top" alt="Card 2 Image" />
                    </div>
                    <div className="col">
                        <h5 className="card-title">I am the one who lives in the hearts of my devotees and I am the one who will take care of my devotees. I reincarnate in this universe in various different forms for the protection of Dharma (duty).</h5>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <h5 className="card-title custom-title">Ramdas has said, "Sant Jan (All Saints). Listen",</h5>
                    </div>
                    <div className="col">
                        <img src={farmaan26} className="card-img-top" alt="Card 2 Image" />
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="row row-cols-2 farmaan-space">
                    <div className="col">
                        <img src={farmaan27} className="card-img-top" alt="Card 2 Image" />
                    </div>
                    <div className="col">
                        <h5 className="card-title">When Baba Ramdev was about to take Samadhi he had a Green Flag in one hand and held a Bhamar Bhalo (a spear named "Bhamar" because of the distinctive sound made when the spear was launched) in the other hand.</h5>
                    </div>
                </div>
            </div>


            {/* <div className="container d-flex justify-content-center">
                <div className="item">
                    <div className="card ">
                        <img src={farmaan1} className="card-img-top " alt="Card 1 Image" />
                        <div className="card-body">
                            <h5 className="card-title custom-title">In V.S 1515 Bhadrapad Sudi Thursday 11th, Bhagwan Ramdevji Maharaj took Samadhi. This is when he delivered his last message and the 24 Farmaan (24 divine commands), to his devotees. </h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan2} className="card-img-top" alt="Card 1 Image" />
                        <div className="card-body">
                            <h5 className="card-title custom-title">Bhagwan Ramdevji Maharaj said "Gatganga (Participants of this Communion are believed to be as pure as the Mother Ganges) listen to the following Twenty Four Divine Commands.
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan3} className="card-img-top" alt="Card 1 Image" />
                        <div className="card-body">
                            <h5 className="card-title custom-title">Always keep away from sin and pay particular attention to the way of your life: Be kind to all living creatures and feed the hungry ones. </h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan4} className="card-img-top" alt="Card 1 Image" />
                        <div className="card-body">
                            <h5 className="card-title custom-title">Confess your sins to your Satguru and always be ready to help others. Remember that life is too short and you must think of what is right and wrong. </h5>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="container d-flex justify-content-center">
                <div className="item" >
                    <div className="card">
                        <img src={farmaan5} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">It does not suit a member of the Communion to get involved in gossip, jealousy or grudge. Accept the invitation to attend the Paat/Pooja with enthusiasm and attend to meditate in search of your soul.</h5>
                        </div>
                    </div>
                </div>
                <div className="item" >
                    <div className="card">
                        <img src={farmaan6} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">You must submit to your Sadguru first to achieve knowledge and accomplish your goal. Have faith in the Almighty God, who will create goodness in you.</h5>
                        </div>
                    </div>
                </div>
                <div className="item" >
                    <div className="card">
                        <img src={farmaan7} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">A person may look clean and wears saffron clothes, but if his mind is malicious or corrupt and there is no sparkle in his eyes and face, consider him to be a Nagura - a person without guidance of a Sadguru.</h5>
                        </div>
                    </div>
                </div>
                <div className="item" >
                    <div className="card">
                        <img src={farmaan8} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">Service to humanity is service to God, make that to be your duty that is the teaching of Sanatan Dharma. Relinquish lust and attachment to the material world and follow the principles of Sanatan Dharma.</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container d-flex justify-content-center" >
                <div className="item" >
                    <div className="card"  >
                        <img src={farmaan9} className="card-img-top" alt="Card 1 Image" />
                        <div className="card-body">
                            <h5 className="card-title custom-title">My followers are those who are polite, are kind, are honest of principle, and are living a moral life of high integrity. </h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan10} className="card-img-top" alt="Card 1 Image" />
                        <div className="card-body">
                            <h5 className="card-title custom-title">Maat-pita guru seva karvi karvo atithi satkar Sva-dharmno pahelan vichaar karvo pachhi adarvo aachar. </h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan11} className="card-img-top" alt="Card 1 Image" />
                        <div className="card-body">
                            <h5 className="card-title custom-title">Wake up early, wash and clean yourself and then do your prayers to the Lord with full concentration before tackling your daily tasks. </h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan12} className="card-img-top" alt="Card 1 Image" />
                        <div className="card-body">
                            <h5 className="card-title custom-title">Sit on one comfortable position; chant the name of the Lord with clean heart. You will only recognise your soul when you have control over the ten senses of your body (five senses and five functional parts of your body). </h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container d-flex justify-content-center">
                <div className="item">
                    <div className="card">
                        <img src={farmaan13} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">Have no doubt in your heart and mind about your faith in the Almighty God, and avoid lust for the pleasures of life, pride and arrogance. To accept the death as the only truth (reality) is the true knowledge and the rest is superfluous attractions and/or attachments.</h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan14} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">Cut your cloth according to your coat, avoid the greed of fame. Have no greed for the chairmanship and your miseries will come to an end</h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan15} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">Be pure in your thoughts with high moral values and act accordingly. Live a independent life with full faith in the Almighty God.</h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan16} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">Be sympathetic towards the poor and always be ready to help the needy. I will always remember such devotees and they will remain close to my heart.</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container d-flex justify-content-center">
                <div className="item" >
                    <div className="card"  >
                        <img src={farmaan17} className="card-img-top" alt="Card 1 Image" />
                        <div className="card-body">
                            <h5 className="card-title custom-title">My true devotees are unselfish, impartial, honourable and believing in my words. </h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan18} className="card-img-top" alt="Card 1 Image" />
                        <div className="card-body">
                            <h5 className="card-title custom-title">Those who spend their life for the cause of humanity and those who do not discriminate between colour, caste, creed or religion are worthy of my worship.</h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan19} className="card-img-top" alt="Card 1 Image" />
                        <div className="card-body">
                            <h5 className="card-title custom-title">Those devotees who have complete faith in me are the ones who will recognise me in visible and invisible forms and are worthy of my trust. </h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan20} className="card-img-top" alt="Card 1 Image" />
                        <div className="card-body">
                            <h5 className="card-title custom-title">Those who perform good deeds and are sincerely devoted to me are worthy of these Farmaans and entitled to my blessings. </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container d-flex justify-content-center">
                <div className="item">
                    <div className="card">
                        <img src={farmaan21} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">Listen to all as an audience but follow my teachings. This will lead you to salvation. (Nirvana - a state where a soul is not reincarnated).</h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan22} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">Those persons who are anonymous donors without any hope or wish for return of a favour and are happy at all times would always remain dear and close to my heart.</h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan23} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">There are nine forms of Bhakti (devotion). Salute all the nine forms of Bhakti and the sincere devotees are those who accomplish and live up to these forms of devotion. They are the ones who would achieve Moksha or Nirvana.</h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan24} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">There are nine forms of Bhakti (devotion). Salute all the nine forms of Bhakti and the sincere devotees are those who accomplish and live up to these forms of devotion. They are the ones who would achieve Moksha or Nirvana.</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container d-flex justify-content-center">

                <div className="item">
                    <div className="card">
                        <img src={farmaan25} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">I am the one who lives in the hearts of my devotees and I am the one who will take care of my devotees. I reincarnate in this universe in various different forms for the protection of Dharma (duty).</h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan26} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">Ramdas has said, "Sant Jan (All Saints). Listen",
                            </h5>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card">
                        <img src={farmaan27} className="card-img-top" alt="Card 2 Image" />
                        <div className="card-body">
                            <h5 className="card-title">When Baba Ramdev was about to take Samadhi he had a Green Flag in one hand and held a Bhamar Bhalo (a spear named "Bhamar" because of the distinctive sound made when the spear was launched) in the other hand.</h5>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* 
                
                
               
                
  */}

            {/* </OwlCarousel> */}

        </>
    );
};

export default AllFarmaan;
