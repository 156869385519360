import React from 'react'

// import { MDBCarousel, MDBCarouselItem, MDBCarouselCaption } from 'mdb-react-ui-kit';
import Header from '../Header';
import './Slider.css';

const Slider = () => {
const sliderimg1 = "/assets/wp-content/uploads/2018/12/Banner-1.jpg"
const sliderimg2 = "/assets/wp-content/uploads/2018/12/Banner-2.jpg"
const sliderimg3 = "/assets/wp-content/uploads/2018/12/Banner-3.jpg";
  return (
    <>
      
      <div id="carouselExample" className="carousel slide">
      <div className="carousel-bg">
         </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className='overlay'></div>
            <img src={sliderimg1} className="d-block img-fluid custom-img-2" alt="sliderimage1" />
            {/* <div className="carousel-caption">
           <p className='caption'>Faith Becomes Lame, When it   Ventures</p>
         </div> */}
          </div>
          <div className="carousel-bg">
         </div>
          <div className="carousel-item">
          <div className='overlay'></div>
            <img src={sliderimg2} className="d-block img-fluid custom-img-2" alt="sliderimage2" />
            {/* <div className="carousel-caption">
           <p className='caption'>No One Saves Us But Ourselves.</p>
         </div> */}
          </div>


          <div className="carousel-bg">
         </div>
          <div className="carousel-item">
          <div className="carousel-bg">
         </div>
         <div className='overlay'></div>
            <img src={sliderimg3} className="d-block img-fluid custom-img-2" alt="sliderimage3" />
            {/* <div className="carousel-caption">
           <p className='caption'>The Sun, The Moon, and The Truth</p>
         </div> */}
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>

    </>
    // <MDBCarousel showIndicators showControls fade Automatic interval={10000}>
    //   <MDBCarouselItem itemId={1}>
    //     <div className="carousel-bg">
    //     </div>
    //     <img src={sliderimg1} className='d-block img-fluid custom-img-2' alt='image 1' />
    //     <div className="carousel-caption">
    //       <p className="text-carousel">Faith Becomes Lame, When it   Ventures</p>
    //     </div>
    //   </MDBCarouselItem>

    //   <MDBCarouselItem itemId={2}>
    //     <div className="carousel-bg">
    //     </div>
    //     <img src={sliderimg3} className='d-block img-fluid custom-img-2' alt='image 2' />
    //     <div className="carousel-caption">
    //       <p className="text-carousel">No One Saves Us But Ourselves.</p>
    //     </div>
    //   </MDBCarouselItem>

    //   <MDBCarouselItem itemId={3}>
    //     <div className="carousel-bg ">
    //     </div>
    //     <img src={sliderimg2} className='d-block img-fluid custom-img-2' alt='image 3' />
    //     <div className="carousel-caption">
    //       <p className="text-carousel">The Sun, The Moon, and The Truth</p>
    //     </div>
    //   </MDBCarouselItem>
    // </MDBCarousel>
  )
}

export default Slider;