import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails27 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery29.jpg";
    return (
        <>
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}
 <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      

            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                   
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                         
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            ધીંગાને પ્રમોદયો
                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                    
                                                >
                                                    <span className="income">ધીંગાને પ્રમોદયો

                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content">
                                                    (૨૭) સાખી
                                                    જપ જેવું કોઈ તપ નહીં, નિશ્ચલ રાખો ખંત,
                                                    ભક્તિમાં નિષ્કામ તો, થાય સંત શીલવંત,
                                                    ભોજા તળાવનો વિસામો, દરબારે દીધેલ ખેસ, ખંતે દીધેલો ખીરનો પ્રસાદ લઈને વાતો કરતાં કરતાં પતિપત્ની નવાગામની સીમમાં પોતાનાં નેહમાં આવ્યાં. રાતે ડોકમાંથી માળા કાઢી, નિત્ય નિયમ મુજબ જપવા લાગ્યા. એની પત્ની પણ માળા લઈ ‘જય રામાપીર ! જય રામાપીર !' ઉચ્ચાર કરતી પાંચ વખત માળા ફેરવી ધીંગાને જ્યોત ધૂપનાં દર્શન કરાવ્યાં. રોટલા અને ખીરનો પ્રસાદ ખાઈપીને નિવૃત્ત થયાં અને ગાય, વાછડી, બકરાં, ઘેટાંને નીરણ પાણી કરી, લખો નેહના રક્ષણ માટે ચોકી કરવા ગયો અને આહીરાણી ધીંગાને સુવાડી પેલો કેસરી દુપટો તેને ઓઢાડીને પોતે સૂતી.
                                                    ચોપાઈ
                                                    પહોર પ્રભાતે ઘીંગો જાગ્યો, બેય ચક્ષુએ જોવા લાગ્યો,
                                                    બોલ્યો માડી જાગી જાને, છૂટી વાછડી ધાવે ગાને.
                                                    પ્રભાતે ધીંગો જાગ્યો, એ બેય આંખે દેખતો થયો, શરીરનાં કાન, આંખ, હાથપગ વગેરે દર્દવિહોણાં સાજાં તાજાં થઈ ગયાં. વાછડી છૂટીને દોડી ગાયને ધાવવા લાગી. એ જોઈ ધીંગો ઊંઘતી માને જગાડવા બોલ્યો.
                                                    ‘મા ! ઓ માડી ! વાછડી છૂટી છે અને ગાયને ધાવે છે, ઊઠ ! વાછડી વાળી લે !' ઘીંગો મોટા અવાજે બોલ્યો.
                                                    એકાએક ધીંગાનો અવાજ સાંભળીને આહીરાણી જાગી ગઈ, અને ધીંગાને ખાટલામાં બેઠેલો જોઈ આશ્ચર્યમાં ડૂબી ગઈ ! ઊઠીને ધીંગા પાસે દોડી ગઈ. સાવ નિરોગી, સશક્ત દીકરો જોતાં માતા મીઠડાં લઈ ઘેલી ઘેલી બની ગઈ ! દોડીને તે નેહના નાકે ખાટલામાં સૂતેલા લખાને જગાડી વાત કરવા લાગી. આહીરાણીની વાત સાંભળી લખો ઉતાવળો ઉતાવળો નેહમાં આવ્યો. ત્યાં ધીંગાને ગાય પાસે
                                                    ઊભેલો દીઠો.


                                                </p>
                                                <p className="paragraph-content">
                                                    લખાએ ધીંગાને ગાય પાસે ઊભેલો જોતા તેના હૃદયમાંથી ઉદ્ગાર ની ગયો. ‘વાહ ! વાહ ! મારા નાથ, વાહ મારા રામદે’જી ભગવાન ! આજ અને જે જણાય છે તે તમારી જ કૃપા લાગે છે. ’ લખાના આવા ઉદ્ગાર સાંભળતાં બા દોડતો આવી માતાપિતાને વંદન કરી ઊભો રહ્યો.
                                                    ચોપાઈ
                                                    હતો અપંગ અંધ ધીંગો, સાજોતાજો થઈ ગયો ધીંગો,
                                                    થાય ગામમાં વાતો ન્યારી, જોવા આવે નર ને નારી.
                                                    નવાગામમાં ઘેર ઘેર ઠેર ઠેર વાત પ્રસરી, દૂધ દેવા આવતી આહીરાણીનો અપંગ છોકરો ધીંગો હવે દોડે છે, અંધ હતો પણ હવે બેય આંખે દેખે છે. બહેરોભૂગો હતો એ સાંભળે છે, અને બોલે છે, આવી વાત ફેલાવાથી સ્ત્રીપુરુષોના ટોળેટોળ નેહમાં આવી ધીંગાને નિરખી આશ્ચર્ય પામતાં હતાં.
                                                    આજે લખાના આનંદનો પાર ન હતો. તેણે ભાણેજ રતનાને રણુંજાથી સાંઢણી મોકલી તેડાવ્યો. રતનો આવ્યો અને માનવમેળો જોઈ આશ્ચર્ય પામ્યો. રતનાને જોતાં ઘીંગો ઊઠીને સામે ગયો અને બે હાથ જોડી ઊભો રહ્યો ! રતનો બોલી ઊઠ્યો :
                                                    ‘મામા ! આ બધું કેવી રીતે બન્યું ? આ રહસ્ય સમજાતું નથી. થોડા દિ' પહેલાં તમે તો બાધા માટે આવ્યા હતા અને ટૂંક સમયમાં જ ભાઈ સાજો થઈ ગયો. વાહ ! પ્રભુ વાહ !'
                                                    ભાઈ બાધા પૂરી કરી રણુંજાથી આવતાં માર્ગમાં ભોજા તળાવે બે દરબારો વિસામો લેવા બેઠા હતા. અમે પણ બપોરો ગાળવા બેઠા હતા. એ વખતે મેં ભગવાન રામદે'જી મહારાજને ધરાવેલો ખીરનો પ્રસાદ બેયને આપ્યો, અને એમણે મને યાદી માટે કેસરી લૂગડાનો ખેસ આપ્યો. આપણે બધા ‘દેવળિયો’ નામે બોલાવતા હતા પણ દરબારે કહ્યું કે, એનું નામ ધીંગો રાખજો. એટલે એને હવે ઘીંગો કહીએ છીએ.' લખો બોલ્યો. ‘એ દરબાર હતા એમ તમે શાથી જાણ્યું મામા ?’ રતને પૂછ્યું, ‘એકના ખોળામાં તલવાર હતી. બીજાને કેસરી જામો અને કાનમાં કડી અને કુંડલ હતાં.' કડી કુંડલની વાતથી રતનો સમજી ગયો હતો, છતાં તેને થયું કે એ બધું હરભુજીને મળતાં નક્કી થશે તેથી તે બોલ્યો ઃ
                                                    ‘મામા ! તમે ત્રણે હવે મારી સાથે ચાલો ! આપણે હરભુજીને મળીએ.' લખો, એની પત્ની, ધીંગો, રતનો અને ગામનાં અન્ય માણસો પગપાળા

                                                </p>
                                                <p className="paragraph-content">
                                                    ચાલતાં ખેરકર ગામમાં આવ્યાં. હરભુજીની દરબાર ડેલીના દ્વાર પર ઘોળી ઘજા ફરકી રહી છે. અંદર ગયા તો મોટા ચોકમાં બાજુમાં તુલસીના માંજરિયા છોડ હતા. જમણી બાજુએ ગૌશાળા હતી અને ઓશરીમાં હરભુજી ઢોલિયા પર બેઠેલા હતા. હરભુજીને જોતાં લખો બોલી ઊઠ્યો; 'ધન્ય છે મારા બાપ ! અમારો અવતાર સફળ કર્યો.' આહીરાણી તરત ખોળો પાથરી નમી પડી અને ઘીંગો પગે લાગ્યો. હરભુજીએ બધાને આવકાર આપી રતનાને પૂછ્યું : ‘રતના ! તું ક્યાંથી ?’
                                                    બાપુ ! આ મારા મામી છે, આ એમનો દીકરો છે, આપ ભોજે તળાવ મળ્યા હતા ?”
                                                    'હા ! હું અને...' હરભુજી વાત કરતાં સંકોચાયા. “અને... મહારાજ રામદે’જી ! આ એમનો અભય પટકો' રતને કેસરી પટકો બતાવી કહ્યું . અને એ પટકાના પરચા પ્રતાપે સાજોતાજો થયેલો આ ધીંગો !' રતને વાત પૂરી કરી.
                                                    રાતે ધૂપદીપ કરી શ્રી રામદેવજીના ભજનકીર્તન ગાઈ સવારમાં રાતના અને લખાનાં અત્યંત આગ્રહથી હરજીએ ધીંગાને ગુરુદિક્ષા આપી પ્રમોદયો અને રામદેવના છેલ્લા ઉપદેશનાં અમૃતવચનો કહ્યાં અને એ ઉપદેશનો ગામેગામ પ્રચાર
                                                    કરવાનો આદેશ આપ્યો.
                                                    બેટા ! તું ધીંગો ! હવે ધીંગારામ બન્યો છું. ભગવાન રામદેવજીએ તને પ્રસાદ ખવરાવ્યો છે. તેથી તારો અવતાર સફળ થયો છે. તને યશ મળશે, એવા મારા આશીર્વાદ છે.’ હરભુજીએ કહ્યું.
                                                    લખો, રતનો અને નવાગામના માણસો ખેરકરથી આવી પોતાના નેહ ઉપર ધજા ફરકાવી. હરભુજીએ ધર્મપ્રચાર માટે નીકળતાં, સૌથી પહેલા લખાના નેહે આવવાનું કહ્યું હતું. આવી વાત સાંભળી ગામને ચોરે માણસો એકઠાં થઈ આમંત્રણ આપવાની વિચારણા કરવા લાગ્યાં. એ સમે ગામમાં ગોરપદું કરતો વેદો ગામોટ ત્યાં આવીને બેઠો.
                                                    ચોપાઈ
                                                    નવે ગામ વેદો ગામોટી, અભણ હતો પણ વાતો મોટી,
                                                    વેદશાસ્ત્રોની વાતો કરતો, યજમાનોના ઘર ઘર ફરતો.
                                                    વેદો ગામોટ યજમાનોના ઘેર ઘેર ફરતો અને પોતાને વેદશાસ્ત્રનું જ્ઞાન છે, એમ બતાવવા સાચુંજૂઠું સંભળાવ્યે જતો. તે સ્વભાવનો તીખો ! નિંદાખોર કજિયાળો ! વિઘ્નસંતોષી અને વાંકું બોલવામાં પાવરધો હતો. આથી કોઈ એને
                                                </p>
                                                <p className="paragraph-content">
                                                    છેડવાનું સાહસ કરતું નહીં. તેના સ્વભાવને જાણી એની વાત બધા જતી કન
                                                    કારણ કે :
                                                    ચોપાઈ
                                                    કરડીને કર્કશ કટુ વાણી, ધારે તેની કરતો હાની, સહુની સાથે બેઠો આવી, વેણ ઉચ્ચારે ચાવી ચાવી.
                                                    ગામમાં પ્રસરેલી વાત સાંભળીને વેદો ચોરે બેસવા આવ્યો હતો. ગામના લોકોનો સામૈયું કરવાનો નિર્ણય વેદાને ગમ્યો નહીં એટલે એ બોલ્યો :
                                                    ચોપાઈ
                                                    જમીનદાર હરભુજી કહાવે, પરચાને પીરાઈ બતાવે, વેદશાસ્ત્રનું જ્ઞાન ન એનું, સામૈયું શું કરીએ તેનું ? હરભુજી જમીનદાર ગરાસિયા છે. વેદશાસ્ત્ર તથા પુરાણનું તેમને જ્ઞાન નથી. પોતે પીર છે ! એમ મનાવી પરચા પૂરે છે એમ મેં સાંભળ્યું છે. વળી-
                                                    ચોપાઈ
                                                    રણુંજામાં જે રામદે'રે છે, એને હિન્દવા પીર ગણે છે,
                                                    વીર થાય પણ પીર જો થાયે, એને કેમ લગાયે પાયે ? રણુંજા ગામના રાજા રામદેવને હિન્દવાપીર ગણે છે. રાજપૂત પોતાને વીર ગણાવે તો ઠીક પણ પીર મનાવે એટલે કંઈ એને પાયે લગાય નહીં. સામૈયું તો વેદ વિશારદ, શાસ્ત્રી, પુરાણી, પંડિતનું હોય, અભય અને અજ્ઞાનીનું સામૈયું કેવી રીતે
                                                    થાય ?
                                                    પરંતુ એની વાતને કોઈએ ગણકારી નહીં અને પાંચ માણસોને ખેરકર ગામે મોકલી હરભુજીને આમંત્રણ આપ્યું. અને એ તેમણે સ્વીકાર્યું.
                                                    પોતાના અનુયાયી, ભજનિક, સેવાભાવી પાંચ સેવકોને સાથે લઈ, હરભુજીએ ધર્મપ્રચારાર્થે પદયાત્રા શરૂ કરી. પ્રથમ તેઓ લખાના નેશમાં આવ્યા. લખાએ નવાગામમાં તુરત સમાચાર પહોંચાડ્યા.
                                                    નેશમાં ગયા અને હરભુજીને સામૈયું કરી ધામધૂમથી તેડી આવ્યા. રાતે ભાવિક ઢોલ, નગારાં, શરણાઈ, ભૂંગળ વગેરે વાજિંત્રો સાથે ગામજનો લખાના ભક્તોએ ભજનની રમઝટ બોલાવી જમાજાગરણ કર્યું. એ જ રાતના વેદા ગામોટની અર્થામણ દૂધ આપનારી દૂઝણી ગાય મરણ પામી. વેદાને માથે અણધાર્યું દુઃ આવી પડ્યું. ગામમાં અરેરાટી ફેલાઈ. ગાયના મૃત્યુથી વેદો વ્યાકુળ થઈ વિલાપ
                                                    કરવા લાગ્યો.

                                                </p>
                                                <p className="paragraph-content">
                                                    અશુભ શુકનની વાતો થાયે, દેહ તજ્યો ગામોટી ગાયે,
                                                    હરભુજી જો પ્રસન્ન થાયે, ગામોટની ગા જીવંત થાય.
                                                    ૨૨૧
                                                    ગામમાં જાતજાતની વાતો ચાલી. કોઈ કહેતું કે હરભુજી મુસાફરી કરવા નીકળ્યા છે. એમને અપશુકન થયા. તો કોઈ કહેતું કે વેદાને ખરી શિક્ષા થઈ ! પણ જો તે હરભુજીને નમી પડે તો જરૂર ગાય જીવતી થાય ! કેટલાક લોકોએ વેદાને તકનો લાભ લેવા સમજાવ્યો અને હરભુજી પાસે તેડી ગયા.
                                                    વેદો હાથ જોડી બોલ્યો : ‘બાપુ ! રાજપૂતો ગૌ બ્રાહ્મણ પ્રતિપાળ છે. મારી ગાય કોઈપણ રોગ વિના મરી ગઈ. એનું દૂધ વેચીને હું જીવનનિર્વાહ ચલાવતો. મારી જીવનમૂડી જેવી એ ગાયને જીવતદાન આપી બેઠી કરો બાપુ !' વેદાની આંખમાંથી આંસુ ટપકવા લાગ્યાં.
                                                    ‘મહારાજ ! રોવો મા ! શાન્ત થાઓ ! તમે વિદ્વાન ભણેલાગણેલા છો. તમને શિખામણ આપવાની હોય નહીં.' હરભુજીએ વેદાને દિલાસો દીધો.
                                                    બાપ ! આપનો મનમઠારો સાંભળ્યો પણ તમે મારી માગણી પર ધ્યાન ના આપ્યું ? મારી ગાયને જીવતદાન આપો !'
                                                    ‘વેદાજી ! આ મૃત્યુલોક છે. જન્મે એનું વહેલુંમોડું પણ મૃત્યુ થાય છે. આ લોક જ એવો છે. મારું મૃત્યુ ક્યારે થશે એ મને ખબર નથી. ગાયને જીવતદાન આપવાની મારામાં શક્તિ હોય તો હું મરણ સમયે મને જ જીવતદાન કેમ ના આપું ?' હરભુજીએ કહ્યું . વેદો મૂળ સ્વભાવ પર આવી ગયો. જીભ સખણી ના રહી અને બોલ્યો :
                                                    ચોપાઈ
                                                    ‘ગયા રામ પેટાળમાં પોઢી, માથે ધૂળની ચાદર ઓઢી ! પીરના જ્યાં પગલાં થાય, ત્યાં કોઈનું મરણ કેમ થાય? વેદાના શબ્દ પ્રહારવાળી વાણી સાંભળી છતાં સંત હરભુજીનાં મનમાં ક્રોધને બદલે દયા ઊપજી અને તેઓ બોલ્યા :
                                                    સાખી
                                                    વચનબાણ મારો નહીં, વેદાજી મહારાજ ! સમરી, રામદે'પીરને, રાખે બેયની લાજ.

                                                </p>
                                                <p className="paragraph-content">
                                                    વેદાજી મહારાજ ! વચનબાણ મારીને તમે હૈયું હળવું કરો છો, પણ ખારી
                                                    પાસેથી કઈ સેવાની ઇચ્છા રાખો છો તે મને જણાવો ?' હરભુજી બોલ્યા. 'મારે બીજી કશી ઇચ્છા નથી. જો તમે ખરા પીર હોવ તો મારી ગાયને જીવની કરો !’ વેદાએ પોતાની માગણી ચોખ્ખા શબ્દોમાં કહી.
                                                    હરભુજીએ (બગલથેલી) ખભે ભરવેલી અલખઝોળીમાંથી ભસ્મ લઈ કમંડળમાં નાંખી કહ્યું : 'લો, આ કમંડળ. એમાં અમૃતજળ છે. તે ગાયનાં મુખમાં રેડી ગાય માતાની પરકમ્મા કરો અને ધૂન લગાવી આરાધ કરો...' હરભુજીની સૂચના લઈ વેદો ઘેર આવ્યો. એની સાથે માણસો પણ આવ્યા. વેદાએ રામદેવજીના નામની ધૂન મચાવી ગાયની પરકમ્મા કરી અને તેના મુખમાં કમંડળનું જળ રહ્યું થોડી વારમાં ગાય સળવળી અને એકવીશ પરકમ્મા પૂરી થતાં ગાય ઊભી થ ગમાણ તરફ ગઈ. આ જોઈ માણસો અચંબો પામી ગયા અને ઊલટભેર બુલંદ અવાજે ‘રામદેવપીરની જય ! હરભુજી સંતની જય !' ઘોષણા ગજાવી મૂકી, હરભુજીનાં દર્શન કરવા ગામમાં નરનારીઓ હલકે ચડ્યાં હતાં. મધરાતના હરભુજીને જાગામીટા સ્થિતિમાં ભણકાર સંભળાયા.
                                                    ચોપાઈ
                                                    હરભુજી માલવમાં જાવો, અંધશ્રદ્ધાળુને સમજાવો, નકળંગનો નેજો ફરકાવો, પીરાઈને પરચો બતલાવો. ભણકારમાં સંભળાયેલી શ્રી રામદેવજી મહારાજની આજ્ઞાથી હરભુજી સવારમાં બધાંની ભાવભરી વિદાય લઈ, પોતાના સેવકો સાથે માળવા પ્રદેશમાં જવા ચાલી નીકળ્યા. તેઓ પીંગલગઢ આવી પહોંચ્યા અને ગામની ભાગોળે શંકરના દેવળમાં ઉતારો કર્યો.
                                                    દોહા –12
                                                    મતમતાંર માલવા, સંખ્યા બાણું લાખ, Jh ધર્મ વિધર્મી માનવી, પ્રસરી શાખો શાખ.
                                                    આ પ્રદેશમાં વિધર્મીઓ પોતાની જાળ પૂરેપૂરી ફેલાવી ચૂક્યા હતા.
                                                    દોહા
                                                    પાદરમાં તકિયો કરી, ગામમાં ફરે ફકીર, મંત્રો બબડી બોટીને, એઠાં પાયે નીર.

                                                </p>
                                                <p className="paragraph-content">
                                                    ગામની ભાગોળે પોતાના નામના તકિયો જમાવી ફકીરો પોતાના સાગ્રીકો સાથે ગામમાં નીકળે, કબરપૂજા કરાવે, માંદા માણસોને દર્દથી છોડાવવા પોતાને તકિયે બોલાવે, મંત્રો બોલી પાણીનાં પાત્રમાં ફૂંકો મારીને પાણી એઠું કરી દર્દીને પાય. આવું તૂત મોટા પ્રમાણમાં ચાલતું હતું.
                                                    દોહા
                                                    દોરો, કોડી, ડોડિયું, તસબી ને તાવીજ,
                                                    પીંછી ઝાડે કડાં કડી, બેડી છેલ્લી ચીજ.
                                                    દોરા, કોડી, ડોડી, માદળિયાં તાવીજ, માળા વગેરે મંતરી આપે અને પીંછી, ઝાડી, કાનમાં કડી, પગમાં બેડી અને હાથમાં ચૂડલીના જેમ છલ્લો પહેરે એના પર પ્રસન્ન રહે, ધાર્યા કામ થાય. આવી લાલચોમાં લપટાઈ માળવાનાં અંધશ્રદ્ધાળુજનો ફકીરોની જાળમાં ફસાઈ ચૂક્યાં હતાં.
                                                    દોહા
                                                    ધર્મે સંક્રાંતિ થઈ, ભેદભરમ ભેલાણ,
                                                    પરધર્મીનું પેખતા, હરભુજી મેલાણ.
                                                    હરભુજીએ આ સંદેશમાં ફકીરોના મેલાણ અને ધર્મ ભેલાણને દીઠાં અને ગામોગામ ફરી ફરીને ભજન, બોધ, ઉપદેશ આપી સનાતન ધર્મના નેજાને ફ૨કતો કરી લોકોને સ્વધર્મ પાળવા સમજાવવા લાગ્યા.
                                                    સંત હરભુજીની સ્વધર્મ સમજાવતી અમૃતવાણી સાંભળવા નાનાં મોટાં ગામોમાં લોકો એમને આમંત્રણ આપવા લાગ્યાં. સભાને તેઓ રામદરબારના નામે સંબોધતાં. ઠેર ઠેરથી આવેલા હજારો માણસો એ રામદરબારમાં અમૃતવાણી સાંભળતાં.
                                                    માળવા જેવા મોટા પ્રદેશ માટે કહેવત હતી કે, ‘બાણું લાખ માળવો છશે ભાગે ફાળવો,’ માળવાની બાણું લાખની વસ્તી છઠ્ઠું ભાગે ફાળવવામાં આવી હતી. વસ્તી નાનાં મોટાં રજવાડાંમાં વહેંચાયેલી હોવાથી તેમનું કોઈ સંગઠન ન હતું. આથી બધા ભિન્નભિન્ન મતમતાંતરમાં ગૂંચવાઈ ગયા હતા. હરભુજીએ તેમને એક બની સંગઠન સાધી અધર્મનો સામનો કરવા પ્રેરણા આપી. હરભુજીની વાણીથી લોકો ઊંઘમાંથી જાગૃત થયા.
                                                </p>
                                                <p className="paragraph-content">
                                                    મલેચ્છની કબર પૂજા ત્યાગી અવર બન્યા રામદેવના અનુરાગી, અવરધર્મ નહીં નિજીયા તોલે, સભામહીં હરભુજી બોલે.
                                                    હરભુજી હજારો માણસોની સભામાં કહેવા લાગ્યા કે નિજીયાધર્મની તોલે કોઈ
                                                    ધર્મ નથી કારણ કે; એ શિવનો સ્થાપેલો આદિપંથ છે.
                                                    સમગ્ર માળવા પ્રદેશમાં ગામેગામ પ્રવાસ ખેડી હરભુજીએ શ્રોતાઓનાં મનપર
                                                    ઊંડી છાપ પાડી દીધી.
                                                    ચોપાઈ
                                                    રાજા, મહારાજા, શ્રીમંતા, જમીનદાર ઘીંગા ઘીમંતા, શબર, કિરાતને ભીલ, વસાવા, ભવડા, સાગર મદાવા, ગાડરિયા ક્ષત્રી વણજારા, સરાણિયા, દુબળા, કૌલાયા, બ્રાહ્મણ ક્ષત્રી વેશ્ય કહાવે,રામદેપીરને પૂજે ભાવે, હરિજન મુરિજન વર્ણોચારે, રામદેપીરનો જય ઉચ્ચારે. સમગ્ર માલવપ્રદેશમાં શ્રી હરભુજીનાં વચનામૃતમાં શ્રી રામદેવપીરનાં પરચા પ્રતાપના બોધથી ઘણા માણસોની બાધાઆખડી ફળવા લાગી. દુઃખ દરિદ્રતા, સંકટમાંથી મુક્ત થઈ આનંદ અનુભવવા લાગ્યાં.
                                                    હરભુજીના પ્રવાસ પરિભ્રમણે માલવ પ્રદેશમાંથી વિધર્મીઓનાં અડ્ડા, તકિયા, ઓટ, રેખા અને આસનોને ઉથલાવી નાખી. માલવપ્રદેશનાં લોકો રામદેવજી મહારાજાની ધજાધૂપ અને ભક્તિભાવ તરફ વળી ગયા.
                                                    દોહા
                                                    ઘેર ઘેર રામદેવપીરની, પ્રેમથી પૂજા થાય,
                                                    સમર્થે ચડતા થોડલે, આવી કરતા સહાય.
                                                    હરભુજી આઠ માસનો પ્રવાસ પૂરો કરી ભાદરવા સુદી એકમે રામદેવજીની
                                                    સમાધિને પૂજવાના સમયે રણુંજામાં આવ્યા.

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails27;
