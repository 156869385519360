import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";

import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery1.jpg";
  return (
    <>
     <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
      {/* <div className="body_wrap">
        <div className="page_wrap"> */}
      <Header />

      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id="46"
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="52ebb12"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          style={{ height: "30rem", marginBottom: "5rem" }}
        >
          <div className="elementor-background-overlay"></div>
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
              data-id="ae9d67e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fcf91b3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                      data-id="93dfaf4"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                          data-id="847c2c5"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                          style={{ height: "8rem" }}
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                          data-id="8fe49e3"
                          data-element_type="widget"
                          data-widget_type="trx_sc_layouts_title.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              id="trx_sc_layouts_title_515615674"
                              className="sc_layouts_title sc_align_center with_content without_image without_tint"
                            >
                              <div className="sc_layouts_title_content">
                                <div className="sc_layouts_title_title">
                                  {" "}
                                 
                                </div>
                                <div className="sc_layouts_title_breadcrumbs">
                                  <div className="breadcrumbs">
                                    
                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="page_content_wrap blog-details">
        <div className="content_wrap">
          <div className="content">
            <div id="container" className="give-wrap container">
              <div id="content" role="main">
                <div
                  id="give-form-288-content"
                  className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                >
                  <div
                    id="give-sidebar-left"
                    className="give-sidebar give-single-form-sidebar-left"
                  >
                    <div className="images">
                      <img
                        width="1024"
                        height="576"
                        src={img1}
                        className="attachment-large size-large wp-post-image blog-img"
                        alt=""
                        decoding="async"
                        sizes="(max-width: 1024px) 100vw, 1024px"
                      />
                    </div>
                  </div>
                  <div className="summary entry-summary">
                    <h2 itemProp="name" className="give-form-title entry-title">
                      શિવ-પાર્વતી સંવાદ
                    </h2>{" "}
                    <div
                      id="give-form-288-wrap"
                      className="give-form-wrap give-display-onpage"
                    >
                      <div className="give-goal-progress">
                        <div
                          className="raised blog-title"
                        >
                          <span className="income">શિવ-પાર્વતી સંવાદ
                          </span>
                        </div>
                      </div>

                      <div
                        id="give-form-content-288"
                        className="give-form-content-wrap give_pre_form-content"
                      >
                        <p className="paragraph-content">
                          શ્રી રામદેવ રામાયણ
                          શિવ-પાર્વતી સંવાદ
                          (१)
                          ઉલ્કાપાત અસુરનો, વધતાં અત્યાચાર, પૃથ્વી રૂ૫ ઘરી ઘેનુનું, કરી રહી પોકાર!

                          સત્યુગ પૂરો થયો અને નવી સૃષ્ટિનું નિર્માણ થયું. પૃથ્વી ઉપર દાનવો અને માનવોનો વંશ વિસ્તરવા માંડ્યો. દાનવો બલિષ્ટ હતા એટલે માનવોની તેમને કશી ગણતરી ન હતી. તેઓ મનફાવે તેમ યથેચ્છ વર્તન કરવા લાગ્યા. તેઓ યજ્ઞોમાં ભંગ પડાવતા, ને જે કોઈ તેમના સામું થતું તો તેને ખડુગના એક જ પ્રહારે ચીરી નાખતા. ઋષિમુનિઓ તેમના અસહ્ય ત્રાસથી ધ્રૂજવા લાગ્યા. આશ્રમની ગૌશાળાની ગાયોની કતલ કરતાં પણ તેઓ અચકાતા નહીં.

                          આ દાનવોની ઈચ્છા સમગ્ર પૃથ્વી ઉપર ત્રાસ ફેલાવી, સ્વર્ગ ઉપર આક્રમણ કરી, દેવોને હાંકી કાઢી સ્વર્ગનું આધિપત્ય મેળવી લેવાની હતી.
                          જેમ જેમ દાનવોનો અત્યાચાર વધતો ગયો તેમ તેમ પૃથ્વી કંપવા લાગી. પૃથ્વી અસુરોનો ત્રાસ સહન કરી શકી નહીં. તે ત્રાહ્ય ત્રાહ્ય પોકારવા લાગી?
                          પાપ-લતાઓ પાંગરી, પ્રસર્યો પાપાચાર, ઘરતી લાગી ધ્રૂજવા, સહી શકી નહીં ભાર!
                          છેવટે તે ગાયનું રૂપ ધારણ કરી કૈલાસ ઉપર દેવાધિદેવ મહાદેવ પાસે ગઈ. કૈલાસમાં ભગવાન સદાશિવ બેઠા હતા, તેમની આગળ આવીને તે થરથર ધ્રૂજતી ઊભી રહી.
                        </p>
                        <p className="paragraph-content" >
                          કંપે ગો પાતકના ભારે, પુનઃ પુનઃ પરિબ્રહ્મ ઉચ્ચારે, કરો સહાય શિવ શંભુ કૃપાળુ, હરો કષ્ટ મુજ દેવ દયાળુ.
                          ગાયના રૂપમાં આવેલી ધરતીમાતાને ભગવાન સદાશિવ ઓળખી ગયા અને આથર્ષ પામી બોલ્યા : 'દેવી! તમે આ રૂપમાં કેમ ? તમે તો જગતનો આધાર છો તમોને જે દુ:ખ હોય તે સુખેથી કહો.'
                          માતા પૃથ્વી ભોલ્યાં : 'હે દેવાવિદેવ ! મહાદેવ! મારું સંકટ તમારાથી અજાણ નથી. દાનવોનો અત્યાચાર હવે માઝા મૂકતો જાય છે.
                          નિર્દોષ સાધુ, સંતો, ત્યાગી, તપસ્વીઓ અને ઋષિમુનિઓને તેઓ અસદા ત્રાસ આપી રહ્યા છે. હોમ-હવન, યજ્ઞ-યાગમાં માંસના લોચા અને હાડકાં નાંપી ભ્રષ્ટ કરે છે. પૃથ્વી ઉપર વસતા માનવીઓની પીડાનો પાર નથી. મારા ઉપરથી આ દુષ્ટોનો ભાર ઉતારો એવી મારી પ્રાર્થના છે.”
                          ભોળાશંકરે માતા પૃથ્વીને સાનવના આપતાં કહ્યું:
                          દોહા વદતા શિવજી એ સમે, ધરણી ધીરજ ધાર, શ્રીહરિ પાપ પ્રજાળશે, ટળશે તુજ શીર ભાર. આદ્ય ધર્મને રક્ષવા, યુગે યુગે અવતાર, પાપીને સંહારવા, વિષ્ણુનો નિર્ધાર,
                          હે ધરતી ! તું ધીરજ રાખ ! તારા ઉપર વધી પડેલો ભાર શ્રીહરિ જાણે છે. શ્રીવિષ્ણુનો એ નિર્ધાર છે કે,
                          જ્યારે જ્યારે તારા ઉપર પાપીઓનો ભાર વધી પડે ત્યારે તેમણે અવતાર લઈને તે ભાર ઉતારવો અને ધર્મની સ્થાપના કરવી.
                          • પરતી અને સદાશિવનો આ સંવાદ પાસે બેઠેલાં પાર્વતી સાંભળી રહ્યાં હતાં. તેઓ ભોલ્યાં :
                          શિવપાર્વતી સંવાદ
                          ચોપાઈ
                        </p>
                        <p className="paragraph-content" >
                          ત્રિપુરારી, કયો ધર્મપંથ મંગલકારી ? કૃપાનાથ ટાળો મુજ ભ્રાન્તિ, પળે ધર્મ મળે ઉર શાન્તિ.
                          સતી ઉમાની વાત સાંભળી મહાદેવજી બોલ્યા:
                          ચોપાઈ
                          થઈ પ્રસન્ન કહે શ્રી ત્રિપુરારી, કથા સુણો સતી મંગલકારી, ધર્મ સનાતન પંથ અપારા, ધનવારિ ગ્રહી સરિત વિહારી. હે શૈલસુતા ! હે પાર્વતી ! જેમ એક ઘનનું-મેઘન જળ લઈને જુદી જુદી નદીઓ જુદા જુદા માર્ગે વિચરે છે. એવી રીત આપણા મહાધર્મના આશ્રયે નાના પ્રકારના અનેક પંથો ઉત્પન્ન થયા છે.
                          ચોપાઈ
                          મહાધર્મ જે આદિ કહીએ, વિગતે કહું હું ધ્યાનમાં લઈએ, એક મતે રહેતાં નરનારી, નિજિયા ધર્મે હોય નિજારી.
                          દોહા
                          જળકૂપે જ્યમ રેંટથટ, ભરાય ને ઠલવાય, જુદા જુદા પંચ પણ, થાળે ભેળા થાય.
                          જેમ રેંટ ફરતાં રેંટના જુદા જુદા ઘડાઓ કૂવામાં જાય છે અને ભરાઈને ઉપર આવે છે. ઉપર આવીને એ જુદા જુદા ઘડાઓનું પાણી એક જ થાળામાં ઠલવાય છે. તેમ જુદા જુદા સંપ્રદાયોનું થાળું તો આ આપણો મહાધર્મ જ છે. બધા જ સંપ્રદાયો આખરે તો આ મહાધર્મના થાળામાં જ ભેળા થાય છે.
                          જુદા જુદા સંપ્રદાયો સગુણ-નિર્ગુણના ભેદભાવોની ચર્ચા ઊભી કરી તેમાં મતમતાંતર ઊભા કરે છે. દરેક સંપ્રદાય પોતાનો કક્કો જ ખરો છે એમ કહે છે, પરંતુ આપણો મહાધર્મ પ્રકૃતિનાં પાંચે તત્ત્વોને આવરી વરી લઈ જીવશિવનો સમન્વય સાથે છે.
                          ચોષાઈ પાંચ તત્વ નિયમે ઉપજાવ્યાં, માનવરક્ષણ કાજ બનાવ્યાં, વસુંધરા બીજીયા-બીજધારી, આભ સંગ્રહે વાદળ વારિ.
                        </p>
                        <p className="paragraph-content" >
                          આ પાંચ તવશે જરથ્વી, જલ, તેજ, વાયુ અને આકાશને જીવ સાથે મેળવ માનવદેશના રક્ષણ માટે આ પાંચેય માનવદેહ ભાયાને પોતાનામાં સમાવી તેનું પોષાય છે. આ બા 18 12 આ રાખ્યનું છે. ભીજ સંઘરવાથી તે બીજીયા કહેવાય છે. આ બીજીનું પોષણ ગમ ઘરતીનું એલાં વાદળોના વારિથી થાય છે. એટલે વાદળો પોતાનામાં રાખણન મંડળમાં સહેલું છે. આકાશમાં રહેલું જળ પૃથ્વી પર પડતાં જ પૃથ્વી પાંચે તત્ત્વોને ગોષણ કરી પેલા બીજને પ્રગટ કરે છે. આથી બીજ ફાલે, ફૂલે અને ફળે છે.
                          ચોપાઈ
                          બીજ પોષક શોષક પંચતત્ત્વા, માનવરક્ષણ ક્રિયા મહત્ત્વા, શ્રુતિ સ્મૃતિ ઋષિ મુનિ ધ્યાની, કર્મક્રિયા શાને વિસ્તારી. બીજમાં પાંચે તત્ત્વોનું શોષણ થઈ તેનું પોષણ થાય છે. આ રીતે સૃષ્ટિ સર્જન સદાકાળ ચાલ્યા કરે છે. આ સૃષ્ટિના જીવાત્માઓ શ્રુતિ એટલે સાંભળીને, સ્મૃતિ એટલે સ્મરણમાં રાખીને ઋષિમુનિ અને જ્ઞાની-ધ્યાનીઓનો ઉપદેશ પચાવી યુહ નિર્મળ બની છેવટે મોક્ષપદ પામે છે.
                          ચોપાઈ
                          બ્રહ્મ અને માયા ગુણધારી, હેતુપૂર્ણ સર્જીત નરનારી, કર્મ ધર્મ જાગૃતિ નિંદભારી, અચલ ક્રિયા સુણો શૈલકુમારી
                          સૃષ્ટિ સર્જનના સંગુલ હેતુને પૂર્ણ કરવા માટે નરનારીનું સર્જન કર્યું. તે જાગૃત અવસ્થામાં સત્કર્મો કરે છે, તેમ છતાં નિદ્રાવસ્થામાં પણ તેના શરીરની ક્રિયા ચાલતી રહે છે. જેથી તેનું શરીર સચવાય છે ને ફરી પાછો જાગૃત થાય છે ત્યારે તે ધર્મ-કર્મ
                          કરે છે અને તેના વિચારો દૃઢ થતાં તે ભક્તિ-ભાવમાં રંગાય છે.
                          દોહા
                          મતિમંદ આક્રંદથી, કરે વ્યથાનું બ્યાન, નિદ્રાધિન થયે, રહે નવ સુખદુઃખનું ભાન. જાગૃત અવસ્થામાં શરીરને થા કે દર્દથી થતી પીડાનું વર્ણન માનવી વિલાપ કરીને - આક્રંદ કરીને કહે છે, પરંતુ જ્યારે તેને નિદ્રાવસ્થા પ્રાપ્ત થાય છે. ત્યારે
                        </p>
                        <p className="paragraph-content">
                          તેને આ દુ:ખદઈ યાદ રહેતાં નથી, કારણ કે તેનો જીવાત્મા શરીરની બહારની સૃષ્ટિમાં વિહરતો હોય છે.
                          ચોપાઈ
                          માયા મહાબલ બગે ન કોઈ, નિશ્વય ગૂઝે વીરલા સોઈ, વિજય વરે નર ભક્તિધારી, સુણો ધ્યાન દઈ શૈલકુમારી.
                          હે પાર્વતી ! શૈલકુમારી મહાબળવંતી માયાના અનેકવિધ આકર્ષલ હોય છે, તેથી માનવી તેમાં ફસાય છે અને છેવટે તે માયાનો ભોગ-નૈવેધ બની જાય છે. કોઈ વીર પુરુષ માયાના ભ્રમિત કરનારા આકર્ષલાથી અંજાતા નથી અને તુકર્મો વડે પ્રભુને મેળવવા રાત-દિવસ યત્ન કરે છે. આવા સતત થત્ન કરનારા મનુષ્યો માયામાં ફસાતા નથી અને ભક્તિ માર્ગમાં રચ્યાપચ્યા રહે છે. ભગવાન પણ તેમને સહાય કરે છે અને તેઓ વિજયને પામે છે.
                          દોહા ભજન કષ્ટભંજક બને, ભજતા વીરલા સંત, ભવસાગરથી તારવા, ભેરૂ બને ભગવંત.
                          હે પાર્વતી ! શ્રીફળના ગર્ભમાં જે નિર્મળ અને મીઠું મધુરું જળ છે. તેને મેળવવાની આશાએ કાચલીએ ગર્ભજળને પોતાની ગોદમાં અટકાવી રાખ્યું. કાચલીને એ મીઠું પાણી મેળવવાનો સ્વાર્થ હતો. કાચલીના આ સ્વાર્થની ગર્ભજળને જાણ થતાં એ જળ ટોપરું બની ગયું, કારણ કે એ બ્રહ્મ ઉપાસક હતું. તે કાચલીની માયામાં ફસાવા માગતું ન હતું.
                          કાચલીની આશા નિષ્ફળ જતાં તેણે ગર્ભને પોતાનાથી છૂટો પાડી દીધો, તેથી તે ગોળો બન્યો અને પાણી ચુસાઈ જવાથી ખડખડિયું બની ગયું તેથી ભેદ ભેદાઈ ગયો. સદાશિવની આ વાત સાંભળી પાર્વતી પ્રસન્ન થયાં અને તેમણે ઉત્કંઠાથી
                          પૂછયું :
                          દોહા
                          કૃપાનાથ આપે કહ્યું, કર્મધર્મનું જ્ઞાન, નિજીયા પંથ નિજારનું, પાલન કહો ભગવાન.
                        </p>
                        <p className="paragraph-content" >
                          એ કૃપાનાલ ! હવે તમે મને આ નિજારપંથનું વિધિની રીત સમજાવો
                          ચોપાઈ
                          ને પરસ્ત્રીને પુરુષ ગણે માતા, પરપુરુષને સ્ત્રી ગણે ભાતા, જતી સતી ભક્તો નરનારી, પાળે નિજીયા ધર્મ નિજારી. પાર્વતી ! જે પુરુષ ધરાઈ સસ્ત્રીને પોતાની માતા સમાન માને છે. અને પોતાના મનમાં ફૂહ માતૃભાવ રાખે છે અને જે સ્ત્રી પર પુરુષને સહોદર ભાઈ જેણે ગણે છે તેઓને જ આ નિજીયા ધર્મમાં સ્થાન છે.
                          દોહા
                          વદે પાર્વતી વિનયથી, કહો ગૃહસ્થાશ્રમ મર્મ, નરનારી મત ભિન્નતા, પાળે શી રીતે ધર્મ? સ્વામી! ગૃહસ્થાશ્રમ મુખ્ય ધર્મ ગણાય છે. તેઓની માન્યતામાં ભિન્નતા હોય તો આદિ મહાધર્મને કઈ રીતે પાળે ? પાર્વતીજીએ પૂછયું. પાર્વતીને ઉત્તર આપતા ભગવાન બોલ્યા :
                          ચોપાઈ

                          મહાધર્મ સમતાથી ધારી, કહું રીત સુણ શૈલકુમારી, માનવધર્મ સેવા સત્કર્મે, નિશ્વલ રહેતા નિજીયા ધર્મે, તે સતી ! ગૃહસ્થાશ્રમનો ધર્મ માનવસેવા છે. મહાધર્મનું એ પ્રથમ સોપાન-પગથિયું છે. અને જેઓ એ ધર્મને નિજધર્મ (પોતાનો ધર્મ) માનતા હોય છે, પરંતુ એમની પૂર્વજન્મના કર્મ-અંતરાયને કારણે એની કરણી આવરણી હોવાથી એમની માન્યતામાં ભિન્નતા હોય છે. જેને પૂર્વજન્મમાં ભક્તિભાવ વરેલો છે, એવા પતિ-પત્ની એકમતવાળાં હોય છે. તેઓ આ નિજધર્મ પાળતાં હોય છે.
                          દોહા આદિ ધર્મ યાત્રિકનાં, મન મત એક સ્વભાવ, ભવસાગર રહેજે તરે, ભક્તિ વાહન નાવ.
                        </p>
                        <p className="paragraph-content" >
                          ધર્મપંથ પર હોય માંતર, ધર્માધર્મી ભેદ નિરંતર, પતિ-પત્ની મત જો ધર્માન્તર, ગુદાસ્થાશ્રમ બને કુંઠીત તંતર, સ્વધર્મ પંથ પર પતિ-પત્નીના જુદા મત હોય તેમનું ગૃહસ્થાશ્રમનું તંબ કુંઠિત વાજિંત્ર જેવું હોય છે. અને એમનો ગૃહસ્થાશ્રમ નામનો જ હોય છે.
                          વનદળના થર્ષણ થકી. પાવક પરગટ થાય. એકમતા ભક્તિ વડે, ઉર પ્રકાશ જણાય.
                          જેમ આકાશમાં જળ ભરેલાં વાદળાં પરસ્પર મળતાં-ટકરાતાં તેમાં પાવક-વીજળી પ્રકાશીત થાય છે. તેમ પતિ-પત્નીના મત અને મનની એકતા હોય અને ભક્તિભાવમાં પૂરા રંગાયા હોય તો એમનાં હૃદયમાં દિવ્ય પ્રકાશ થાય છે અને એમનાં અવથોળ અંતરમાંથી અંધકારનો નાશ થાય છે. એ ભક્તિવાન પતિ-પત્નીનું જીવન ધન્ય બને છે.
                          સોરઠો
                          એકમતા નરનારથી, ભજન ભક્તિ જો થાય, માયા રસડી તૂટતાં, ભવબંધનથી છૂટતા.
                          હે પાર્વતી ! એક મત, એક મન, એક ધર્મવાળાં ઉપાસક નર અને નારી પરમેશ્વરના ભજન ભાવમાં ભરપૂર રહે છે ને તેથી તેમની માથાની રસડી મોહપાશ તૂટી જાય છે અને એ યુગેયુગોનાં ભવબંધનથી છૂટે છે. પાર્વતીએ આ મહાન ધર્મતત્ત્વ સમજી લઈને ફરી પૂછ્યું :
                          દોહા
                          વદે પાર્વતી વિનયથી, સ્વામી! જગદાધાર, પૂજા વિધિ કર્મને, કહોને વિગતવાર, ધર્મકર્મ ને આચરણ, નેક ટેક ને નેમ, નિજીયાપંથી ભક્તજન, સિદ્ધિ પામે કેમ?'
                        </p>
                        <p className="paragraph-content">
                          ધર્મપંથ પર હોય માંતર, ધર્માધર્મી ભેદ નિરંતર, પતિ-પત્ની મત જો ધર્માન્તર, ગુદાસ્થાશ્રમ બને કુંઠીત તંતર, સ્વધર્મ પંથ પર પતિ-પત્નીના જુદા મત હોય તેમનું ગૃહસ્થાશ્રમનું તંબ કુંઠિત વાજિંત્ર જેવું હોય છે. અને એમનો ગૃહસ્થાશ્રમ નામનો જ હોય છે.
                          વનદળના થર્ષણ થકી. પાવક પરગટ થાય. એકમતા ભક્તિ વડે, ઉર પ્રકાશ જણાય.
                          જેમ આકાશમાં જળ ભરેલાં વાદળાં પરસ્પર મળતાં-ટકરાતાં તેમાં પાવક-વીજળી પ્રકાશીત થાય છે. તેમ પતિ-પત્નીના મત અને મનની એકતા હોય અને ભક્તિભાવમાં પૂરા રંગાયા હોય તો એમનાં હૃદયમાં દિવ્ય પ્રકાશ થાય છે અને એમનાં અવથોળ અંતરમાંથી અંધકારનો નાશ થાય છે. એ ભક્તિવાન પતિ-પત્નીનું જીવન ધન્ય બને છે.
                          સોરઠો
                          એકમતા નરનારથી, ભજન ભક્તિ જો થાય, માયા રસડી તૂટતાં, ભવબંધનથી છૂટતા.
                          હે પાર્વતી ! એક મત, એક મન, એક ધર્મવાળાં ઉપાસક નર અને નારી પરમેશ્વરના ભજન ભાવમાં ભરપૂર રહે છે ને તેથી તેમની માથાની રસડી મોહપાશ તૂટી જાય છે અને એ યુગેયુગોનાં ભવબંધનથી છૂટે છે. પાર્વતીએ આ મહાન ધર્મતત્ત્વ સમજી લઈને ફરી પૂછ્યું :
                          દોહા
                          વદે પાર્વતી વિનયથી, સ્વામી! જગદાધાર, પૂજા વિધિ કર્મને, કહોને વિગતવાર, ધર્મકર્મ ને આચરણ, નેક ટેક ને નેમ, નિજીયાપંથી ભક્તજન, સિદ્ધિ પામે કેમ?'
                        </p>
                        <p className="paragraph-content">
                          ચાર જૂગમાં પંથમાં, કયા થયા પ્રસિદ્ધ ? તે પામિયા, પ્રભુદર્શનને કયા સંત ને સિદ્ધ ?
                          સદાશિવે પ્રસન્નતાથી કહેવા માંડયું
                          : ચોપાઈ
                          ભુવેશ્વર કહે સુણો ભવાની, આદ્ય ધર્મ સનાતન માની, પ્રભુ ભજન સ્મરણ નીમ નીતિ, પરહિત સહાયે જેની પ્રીતિ, અચલ, અડગ મન ભક્તિ કરતાં, વિવેકભરી વાણી ઉચ્ચરતાં, મારું તારું અહં ન લાવે, એ સિદ્ધ સાધુ સંત કહાવે
                          ચોપાઈ
                          બ્રહ્મકુંભનો પાટ સ્થપાયે, પંચદેવની પૂજા થાયે, ભજન કીર્તન કરે સતસંગા, નરનારી પવિત્ર મતગંગા. નવનાથ ને સિદ્ધ ચોરાશી, પહાડ ગુફા ગિરિકંદરવાસી, સંત ભક્ત મુનિ ધર્મધામી, જતિ સતી નામી અનામી.
                          દોહા
                          ધર્મધ્વંસ નિવારવા, વિષ્ણુનો નિર્ધાર, ધર્મ સ્થાપના કાજ એ, યુગ યુગ લે અવતાર. ત્રેતામાં શ્રીરામ ને દ્વાપર કૃષ્ણ મુરારી, કલિયુગમાં પીર રામદેવ, વિષ્ણુના અવતારી.
                          ચોપાઈ
                          વંદન કરીને વદે ભવાની, સમજી ધર્મ હું ત્રિશૂલપાણિ, ધર્મવાત વિગતે ઉચ્ચારી, ભાંગી પ્રભુજી શંકા મારી ! આ પ્રમાણે ભગવાન સદાશિવે ધર્મતત્ત્વ સમજાવી સતી પાર્વતીની શંકાનું
                          સમાધાન કર્યું.
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
      {/* </div>
      </div> */}
      <div>
        <Footer />
        {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
      </div>
    </>
  );
};

export default BlogDetails;
