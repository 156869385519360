import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails25 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery27.jpg";
    return (
        <>
         <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}


            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                   
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                         
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            શ્રી રામદેવજીની સમાધિ

                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                    
                                                >
                                                    <span className="income">શ્રી રામદેવજીની સમાધિ
                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content">
                                                    રામદેવજીએ ભાદરવા સુદી અગિયારશને દિવસે ડાલીબાઈની સમાધિ પાસે દશ હાથ છેટે બીજી સમાધિ ખોદાવી. કેસરિયો જામો પહેરી હાથમાં શ્રીફળ લઈ માતા, પિતા, ભાઈ, બહેન તથા કુટુંબના તુંવરા રાજપૂત સરદારો તથા તમામ નગરજનો સહીત વાજતે ગાજતે રામાસરોવર પર સમાધિ લેવા પ્રયાણ કર્યું.
                                                    પોકણગઢથી આવેલા તમામ નગરજનો રામાસરોવર પર રાહ જોતા ઊભા હતા, ત્યાં રામદેવજી આવી પહોંચ્યા. એકઠા થયેલા સર્વજનો શ્રીફળ ને કુંકુમથી રામદેવજીને વધાવવા લાગ્યા.
                                                    રામદેવજી સહુની સમક્ષ કહેવા લાગ્યા કે, ‘મારા ભક્તજનો ! દરેક મહિનાની સુદી બીજને દિવસે પાટોત્સવ કરો. ભજનભાવથી જમાજાગરણ કરી રાત્રિ વિતાવજો. જ્યાં જ્યાં જમા જાગરણના પાટ પુરાશે ત્યાં હું અંતરીક્ષમાં હાજર રહીશ. દર વરસે મહા અને ભાદરવા માસમાં દશમ અને અગિયારશે રણુંજામાં મેળો ભરજો. એમાં જૈ પ્રસાદ આવે તે દશમનો પ્રસાદ મારો અને અગિયારશનો પ્રસાદ ભૈરવ ગુફાના ભૈરવાનો સમજજો, એ મારાં છેલ્લાં વચન છે.
                                                    મારી સમાધિ થયા પછી શંકર આવે કે વિષ્ણુ આવે તોપણ કોઈ સમાધિનો પડદો ખોલશો નહિ. જ્યાં ઘીની જ્યોત, ગુગળનો ધૂપ અને ધોળી ધજા હશે ત્યાં મારી પૂજા ગણાશે, મારું સ્મરણ કરનારા મારા ભક્તોની ભેરે રહીને હું મનધાર્યાં કામ કરીશ. આ મારું વચન છે.
                                                    શ્રી રામદેવજીએ એ પ્રમાણે આજ્ઞા કરી વચનો સંભળાવી, પિતા અજમલજી તથા માતા મિન્નલદેને દંડવત્ પ્રણામ કર્યા, વીરમદેવજીને ભાવથી ભેટી નમન કરી, એકઠા થયેલા સહુજનોને નમસ્કાર કર્યા. અજમલજીના હાથે રતન કટોરો, વીરગેડિયો, અભય અંચળો સમાધિમાં મુકાવ્યા. અમલડાબળી અજમલજીને સોંપી, પોતે સમાધિમાં ઊતરી ઊભા રહી સૌને આશીર્વાદ આપવા જમણો હાથ ઊંચો કરી, શબ્દોચ્ચાર કર્યો :

                                                </p>
                                                <p className="paragraph-content">
                                                    પરિત્રાણાય સાધુનામ વિનાશાય ચ દુષ્કૃત્રામ્, ધર્મ સંસ્થાપનાર્થાય, સંભવામિ યુગે યુગે.'
                                                    એટલું કહી ‘હરિ ૐૐ'ના ધ્વનિ સાથે રામદેવજી સમાધિમાં અદૃશ્ય થઈ અને સમાધિ પુરાઈ ગઈ.
                                                    સંવત પંદરસો ને પંદરની સાલમાં ભાદરવા સુદી અગિયારસને ગુરુવારન
                                                    રોજ શ્રી રામદેવજીએ નિર્વાણપદ લીધું અને અમરાપુરમાં વાસ કર્યો.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails25;
