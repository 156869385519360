import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails32 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery34.jpg";
    return (
        <>
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}
 <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      

            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                    
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            સવો ભગત

                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                   
                                                >
                                                    <span className="income">સવો ભગત


                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content" >
                                                    ચોપાઈ
                                                    સોરઠમાં ઝાંઝરકા ગામે, વણકર સવા ભગતને નામે,
                                                    નિજીયાપંથી એક નિજારી, ચોહર ભજને નિમાધારી, રંગપર ગામથી થોડે દૂર ઝાંઝરકા ગામને ઝાંપે પડાળિયા ઘરમાં સવો ભગત રહેતા હતા. ઘરમાં પાણિયારા પાસે મોટા ગોખમાં રામદેવનું થાપન, ઘોડો અને ધૂપેલિયું રહેતું. પૂજાની સાધનસામગ્રીવાળા તેમના ઘરને ઘણા લોકો ‘રામાપીરનું થાનક' કહેતા. તેઓ સવારમાં સૂર્યોદય પહેલાં ઊઠી ઠંડા પાણીથી નાહી ધોયેલાં વસ્ત્રો પહેરી રામદેવપીરના સ્થાને ધૂપદીપ કરી પછી પોતે કપડાં વણવા બેસતા. ભોજન વખત થાળ ધરાવીને પછી જ ખાવા બેસતા. વેજુ વેચતા ગામડે ફરતાં ફરતાં પણ તેમનો અખંડ જાપ ચાલુ હોય.
                                                    રાત્રે સૂતાં પહેલાં તંબૂરો અને તાલ-મંજિરા લઈ રામદેવપીરની સાવળો, આરાધનાં ચાર ભજન ગાઈને માળાજાપ કરતા થાકે ત્યારે જ સૂઈ જતા. આવા નિમાધારી હોવાથી એમને જ્યાં ભજન સમારંભ હોય ત્યાંથી વાયક બિડાં આવતાં. બિડાં વધાવી તેઓ સવરામંડપમાં જતાં હતા.
                                                    રોજકા ગામનો વહેપારી જેચંદ સુતરિયો સુતરની આંટીઓની ગાંસડીઓ એક બળદના એક્કામાં ભરીને સવા ભગત પાસે વેજુ વળાવવા આવ્યો. એણે વાત કરી કે રંગપરને પાદર તલાવડી પાસે એક મહાત્મા આવ્યા છે. ખાતાપીતા નથી. ઊંઘતા નથી. મરજીમાં આવે તો બોલે છે; મરજીમાં આવે તો ખડખડાટ હસવા લાગે છે. વળી આંખો ઢાળીને ધ્યાનમાં બેસી જાય છે. આવા મનમોજી છે.
                                                    સવા ભગતે ધ્યાનપૂર્વક વાત સાંભળી. જેચંદ શેઠની સુતરની ગાંસડીઓ ઠેકાશે મૂકી, પોતાના ભત્રીજાને ઘર સોંપી એ જ વખતે તેઓ રંગપર તરફ રવાના થયા. દર્શનની તાલાવેલીમાં એક ધ્યાનથી ચડીચોટ પગ ઉપાડતા, તેઓ તલાવડી પાસે આવી રામગરનાં દર્શન કરી બધાથી દૂર બેઠા. રામગરજીએ સવા ભગતના ભક્તિભાવભર્યા હૃદયને પારખી લીધું. પોતાનો હાથ ઊંચો કરી ભગતને પોતાની પાસે બોલાવ્યા.

                                                </p>
                                                <p className="paragraph-content">
                                                    રંગપરના દરબારો અને અન્ય માણસોની વચ્ચે જઈને કેમ બેસાય ? જેઓ વેજાના તાકાને લેતાં પાણીની છાંટ નાખે પછી લે, તેઓ એમને અડે શેના? મહાત્મા પાસે બોલાવે છે પણ એ આભડછેટ સમજતા નથી. અહીં બેઠેલા બધા એમના જેવા નથી ! હવે શું કરવું ? ભગત મૂંઝાણા અને તેમને મહાત્માના અપમાનનો પણ ભય લાગ્યો, પરંતુ મનમાં સમસમીને તેઓ બેસી રહ્યા. રામગરે ફરી વાર ભગત સામે દૃષ્ટિ કરી તેમની મૂંઝવણ સમજી લીધી. અને ફરી વાર એમણે ભગતને પોતાની પાસે આવવા હાથ ઊંચો કર્યો અને ‘અહીં આવો, આવો' નો ઉચ્ચાર કરવા લાગ્યા. બેઠેલા સહુનાં મન સંકોચાયાં, પણ કોઈ અંતરાય નાખી શકે તેમ નહોતું. એટલામાં સુરસંગજી બોલ્યા : ‘સવા ભગત ! તમારા પર ગુરુદેવની કૃપા થઈ છે ! અમારો કોઈનો સંકોચ રાખશો નહીં. જાઓ અને એમની પાસે બેસો.' સવો ભગત પાસે આવતાં રામગરજી બોલ્યા :
                                                    દોહા
                                                    સવા સવાયા હોઈજા, તજીશ નહીં નિજ કર્મ,
                                                    સેવા કરી દે ટુકડો, કહું સનાતન ધર્મ.
                                                    સવા નામ પ્રમાણે ભક્તિમાં સવાયો બનજે અને તારા કર્મને છોડીશ નહીં. તારી કમાણીમાંથી કંઈ સંઘરીશ નહીં. આંગણે આવે તેમને ટુકડો આપતાં ખચકાઈશ નહીં. સવા, તું સવગુણ છે.' રામગરજીએ કહ્યું.
                                                    ચોપાઈ
                                                    સેવા સહાયે સવાયા, મન નિર્મળ ને પાવન કાયા,
                                                    જન હિતકારી પર ઉપકારી, નિજીયા ધર્મે નેક નિજારી.
                                                    સાચે જ સવો સેવામાં સવાયો હતો. તેનું મન અને કાયા નિર્મળ હતાં. તે પરોપકારી અને નિજીયાધર્મનો દૃઢ ઉપાસક હતો, તેથી સવાને રામગરજીએ ગુરુદિક્ષા આપી. પોતાની કફની ફાડીને તેનો ટુકડો આપતાં કહ્યું : ‘લે આ અલખનો નેજો, તારા આંગણે રોપજે. કોઈ પણ આવે અને અન્નપાણીથી તૃપ્ત કરજે અને નિષ્કામ ભક્તિ કરજે.'
                                                    કેટલાક ભક્તિના બહાને સુખસંપત્તિ અને ધન મેળવવા માયાના બંધન તરફ દોડ્યા જાય છે, દંભી હોય છે માટે તું એવાઓના સહવાસમાં ફસાઈશ નહીં. લે, આ ભસ્મ ! તારી ધારણા સિદ્ધ કરશે.' રામગરે આ પ્રમાણે સવા ભગતને આદેશ આપી ભસ્મ આપી.

                                                </p>
                                                <p className="paragraph-content">
                                                    ગુરુ આદેશ માથે ચડાવી સવા ભગત ઉભરાતા આનંદે પોતાને ઘેર આવ્યા. સભાજનોને પણ રામગરના બોધ- ઉપદેશની અંતરમાં ઊંડી છાપ પડી, ઝાંઝરકામાં આવીને સવા ભગતે પોતાના ઘર ઉપર ધજા ચઢાવી.
                                                    ‘જય રામાપીર ! જય સદ્ગુરુ રામગર !'નો જયનાદ ગજાવી ધાને ફરકતી કરી. ગામનાં ભાવિક ભક્તોમાં ભક્તિભાવ વધ્યો. સવા ભગતને સહ ‘સવગણ
                                                    નાથ, સવગણ બાપા !' ના નામથી સંબોધવા લાગ્યા.
                                                    સવા ભગતે વેજાનું વણાટકામ પોતાના ભત્રીજાને સોંપ્યું. વેજાના તાકા વેચવાનું કામ રંગપરના સુતરિયાને સોંપ્યું. પોતે ભજનની સગવડ માટે ભાર હળવો કર્યો. હવે તેઓ રોજ ગુરુદર્શન કરી ગામમાં જઈ વેજા આપી આવે. આખો દિવસ ગુરુની
                                                    સેવા કરી અને સાંજે ઘેર આવે.
                                                    ઘેર આવી જમી પરવારી રાત્રે ભજન કરી પછી જ સૂઈ જાય.
                                                    વણાટકામ ચાલુ હતું. સવા ભગત માળા લઈ ઝાંપે બેઠા હતા. એ વખતે ખોડા નામના સુતા૨ની દીકરીનો સોળ વરસની ઉંમરનો દીકરો તાવની બીમારીથી મરણ પામ્યો. ગામમાં ‘હાહાકાર' થઈ ગયો. અરેરાટી વ્યાપી ગઈ !
                                                    ગામના માણસો શબ લઈને સ્મશાને જવા નીકળ્યા. નનામી લઈને ડાઘુઓ સવગણ ભગતના ઝાંપા આગળથી નીકળતાં જોઈ સવો ભગત ઝાંપે આવ્યા અને ભાણાના મરણની વાતથી એમને મનદુઃખ થયું. ‘શબદર્શન’ કરવાની ઇચ્છાથી તેમણે નનામીને નીચે ઊતરાવી, ભાણાના મોઢાને ઉઘાડીને વંદન કર્યું. શબને પગના નખથી શીખા સુધી હાથ ફેરવી માળા જપ કરતાં કરતાં પરકમ્મા કરવા લાગ્યા. બધા આ જોઈ રહ્યા પણ તેનું ખરું રહસ્ય કોઈ સમજી શક્યું નહીં.
                                                    સવા ભગત પરકમ્મા કરતાં કરતાં અંતિમ નિશ્ચય પર આવ્યા કે જો શબમાં પ્રાણ સંચાર ન થાય તો પોતે ત્યાં જ જીવતાં સમાધિ લેવી. આ નિર્ધાર ગુરુ રામગરે ધ્યાનમાં જોયો અને પાસે બેઠેલા સુરસંગ બાપુ તથા અન્ય માણસોના સામે ખડખડાટ હસી પડ્યા. આ વખતે સવા ભગતે પરકમ્મા કરી માળાવાળો હાથ શબના ઉપર ફેરવતાં ભાગ઼ો આંખો ઉઘાડી બેઠો થયો.
                                                    ‘જે ! જે ! સવગણ બાપા ! જે જે ગુરુ રામગરજી ! જય રામાપીર ! જય નકળંગ !' ની ઘોષણાથી ફળિયું ગાજી ઊઠ્યું . ડાઘુઓ અને ગામનાં નરનારીઓમાં આનંદ છવાઈ ગયો. બધા સવગણ ભગતના ચરણ સ્પર્શ કરી ભાણાને લઈને ઘેર આવ્યાં.

                                                </p>
                                                <p className="paragraph-content">
                                                    દોહા
                                                    વાતો પ્રસરી ભાલમાં, નવાઈ પામ્યા લોક,
                                                    આવે ગામે ગામથી, લોકો થોકે થોક.
                                                    સવા ભગતના પરચાની વાત વાયુવેગે ભાલ પંથક, નળકાંઠા વગેરે ગામોમાં પ્રસરી. સવો વણકર ‘મડદા જીવતા કરે છે.” આ વાત સાંભળી સહુને નવાઈ લાગી. ભાવિક ભક્તોને એમની ભક્તિનો પરચા પ્રતાપ લાગ્યો અને ગામો- ગામથી લોકોનાં ટોળે ટોળાં ઝાંઝરકામાં આવ્યાં. સવગણ ભગતના પ્રતાપે ઝાંઝરકા જાત્રાધામ બની ગયું.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails32;
