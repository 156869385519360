import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails34 = (props) => {
  const  img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery36.jpg";
    return (
        <>
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}
 <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      

            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                   
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            શ્રી રામદેવજાનું મંદિર
                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                    
                                                >
                                                    <span className="income">શ્રી રામદેવજાનું મંદિર

                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content" >
                                                    દોહા
                                                    રામગરજીના આશરે, થયો
                                                    દેવદરબાર,
                                                    સેવામાં બે પોળિયા, સવો અને સરદાર.
                                                    વેદ વાયક જેવી નિર્મળ વાણીથી રામગરજીના આસને રંગપર અને આજુબાજુના શ્રોતાજનોની સંખ્યા વધતી ગઈ. એ બધાંની આગતા- સ્વાગતા માટે સરદારસંગ (ફક્કડનાથ), સવા ભગત અને સુરસંગજી ખડે પગે રહેતા હતા.
                                                    લાખિયાન્નીમાં, ભક્ત ગીગો વાળંદ રામદેવના આસ્તિક હતા. રંગપરના સંત રામગરજીની ખ્યાતિ સાંભળી ગીગા ભગત તરત જ ખડિયો લઈ રંગપરની તલાવડી પાસે આવ્યા અને મહાત્મા રામગરજીને દીઠા. મહાત્મા રામગર સભામાં જ્ઞાનકથ કહી રહ્યા હતા. તેમની બોધવાણીનો પ્રવાહ એકધારો ચાલતો હતો.
                                                    લાખિયાન્નીથી રંગપર દૂર હતું, છતાં ગીગા ભગતે વાળુ કરીને ઘેરથી નીકળી સવારે રામગરની સભામાં હંમેશા આવતા હતા. આ વાત ગામમાં પ્રસરી અને ચોરે એકત્ર થયેલા ડાયરાના બધા માણસો રંગપર આવ્યા. ડાયરાના મહેમાનોને સુરસંગજીએ પોતાની ડેલીએ ઉતારો આપ્યો. તે પછી સાંજની સભામાં સહુ સાથે આવ્યા. રામગરજીનું વ્યાખ્યાન સાંભળી બધા સુરસંગજીને ધન્યવાદ આપી કહેવા
                                                    લાગ્યા :
                                                    ઘણા જ્ઞાની મહાત્મા જોયા ! ઘણા સાંભળ્યા પણ આ... ખરેખર રામદેવની જ શ્રીમુખવાણી છે. નહીંતર આવો ઉપદેશ, બોધ, દાખલા બીજા પાસે ન હોય.’ ગીગા ભગતે રામગરજીને ધન્યવાદ આપી અને દરેક મહિનાની પૂનમે રંગપર આવવાનો નિશ્ચય કરી સહુ લાખિયાણી આવ્યા.
                                                    રંગપુરથી થોડે દૂર વાસણા નામના ગામમાં ચાકળા, ચંદરવા, તોરણ બનાવવામાં કારીગર ગણાતા સઈ ભગત વસરામ વખણાતા હતા.
                                                    વસરામનાં ઘરનાં ઓરડામાં રામદેવપીરની મૂર્તિ અને લીલા રેશમનો બનાવેલો ઘોડો બાજોટ પર થાપ્યો હતો. વાસણાના લોકો એમને ‘વહતો ભગત' કહેતા. સાંજે વાળુપાણીથી પરવારીને ચોહર-ચાર ભજન ગાવાનું એમને નિમ હતું.

                                                </p>
                                                <p className="paragraph-content">
                                                    તેમના ભજન વખતે સાધુ કાનદાસ વગેરે ભજનિકો આવતા અને એ પણ ભજનો
                                                    લલકારતા.
                                                    દોહા
                                                    રંગપર ભક્તિરંગમાં, તલાવડીને તીર,
                                                    ૨ટતા રામાપીરને, બેઠા સિદ્ધ સુધીર.
                                                    -
                                                    વહતા ભગતે વાત સાંભળી કે, રંગપરમાં રામદેવજીના ભક્ત તલાવડીમાં આસન સ્થાપ્યું છે. રાત-દિવસ ભજનભાવ ચાલે છે.
                                                    આ વાત સાંભળી ભગત અને સાથીદાર ભક્તો રંગપર આવ્યા, વિશાળ માંડવો રોપેલો દીઠો. માનવીનું રંગપર જાત્રાધામ બન્યું હતું. ગામની પ્રજા ભક્તિરંગમાં રંગાઈ હતી. એ દૃશ્ય જોઈ ભગત મંડપમાં આવી રામગરજીના ચરણ- સ્પર્શ કરી સભામાં બેઠા.
                                                    રામગરજી નિયમ મુજબ ધ્યાનમાં હતા. થોડી વારે મૌન છોડ્યું, ત્યારે એમની દૃષ્ટિ વહતા ભગત સામે ગઈ અને ઝોળીમાંથી ભસ્મ કાઢીને આપતાં કહ્યું : ‘ભગતરામ લો ! આપ ધૂપ-વિભૂતિને લઈ જાવ. તમારા ઘરમાં સ્થાપન કરેલા લીલુડા ઘોડાને ભસ્મ તિલક કરજો.' આ સાંભળી વહતી ભગત અને તેમના સાથીદારો આશ્ચર્યમુગ્ધ થઈ ગયા ! હાથના ડાબા પંજા ઉપર જમણો પંજો રાખી તેમણે ભસ્મ લીધી; હર્ષાવેશમાં તેમનું હૃદય પુલકિત બની ગયું તેથી તેઓ
                                                    બોલ્યા :
                                                    'બાપ ! મારું જીવતર લેખે લાગ્યું. દર્શનથી ભવનું દુઃખ ભાંગી ગયું.' આમ કહી તેમણે ચરણમાં પાઘડી મૂકી. મંડપમાં બેઠા પછી થોડી વાર મૌન પાળવું એવો આદેશ હોવાથી સમગ્ર શાંતિ છવાઈ હતી.
                                                    1)
                                                    ગામમાં મોભાદાર ગણાતા મહાજન લોકો સુરસંગજી પાસે આવ્યા અને કહેવા લાગ્યા : ‘સંતવાણી અમૃત જેવી નિર્મળ વાણી છે. ત્યાગમૂર્તિ મહાત્મા રામગરજી મનમસ્ત જોગી છે ! મોજ આવે ત્યાં સુધી રહેશે અને આસન ઉપાડી વિદાય થશે તો એમનાં દર્શન અને બોધનો લાભ ગુમાવીશું, માટે આપણે તેઓ અહીં સ્થિર થાય તેવો ઉપાય કરવો જોઈએ.
                                                    દોહા
                                                    નિશ્ચળ, સ્થળ સ્થિર થાય જો, અહીં મંદિર બંધાય, સ્વામી સ્થાયી થઈ રહે, ધર્મ ધામ સ્થપાય

                                                </p>
                                                <p className="paragraph-content">
                                                    મહાજનોની વાત સુરસંગજીએ સાંભળી અને આનંદપૂર્વક પોતાની સંસ્કૃત આપી અને મોજ પારખી વાત કરવાનો નિશ્ચય કર્યો. વહેલી સવારે સુરસંગને ત્યાં એક સાંઢણી સવાર આવ્યો. ડેલી બંધ હોવાથી ડેલીની સાંકળ ખખડાવી, સાંકળનો અવાજ સાંભળી અંદર સૂતેલા ચાકરે ડેલી ઉઘાડી.
                                                    સુરસંગજી સેવાપૂજા પરવારી ખંડ બહાર આવ્યા, ત્યારે ડેલીવાળાએ સવાર આવ્યાની ખબર આપી. આવનારને આદરમાન સહિત ડેલીમાં બેસાડી પૂછ્યું ‘તમે ક્યાંથી આવો છો ? જે કામ હોય તે કહો.' સાંઢણી સવારે કહ્યું :
                                                    ‘બાપુ ! જોધપુરના રાણાજીના કાકા માનસંગજી રામદેવજીના પૂર્ણ ભક્ત છે. રણુંજાના તીર્થધામે બીજ અને પૂનમે જાય છે. એ રામગરજી મહારાજના શિષ્ય છે એમને સમાચાર મળ્યા કે સ્વામી રામગરજી રંગપરમાં દરબાર સુરસંગજીને શિષ્ય સ્થાપી ત્યાં બિરાજ્યા છે, તેથી તેમણે આ મૂર્તિ અહીં મોકલી છે.’
                                                    કંતાનના મોટા કોથળામાં રેશમી લૂંગડામાં વીંટાળેલી મૂર્તિને બહાર કાઢી. મૂર્તિ ઘણી જ સુંદર હતી. રામદેવજી ઘોડા પર બિરાજ્યા છે, હાથમાં ભમ્મર ભાલો છે. સુરસંગજી ભક્તિભાવે આ ભવ્ય પ્રતિમાને જોઈ રહ્યા.
                                                    બીજે દિવસે ગામના લોકો આ વાત સાંભળીને દરબારની ડેલીએ આવવા લાગ્યા. બધાએ રંગપરમાં શ્રી રામદેવનું મંદિર બાંધવાનો સંકલ્પ કર્યો. ગામલોકોના આ પવિત્ર સંકલ્પને ગામોગામથી ટેકો મળવા લાગ્યો અને તન, મન અને ધનથી મદદ કરવા લાગ્યા. ગામની પાસે વિશાળ જગા પસંદ ક૨વામાં આવી અને ત્યાં મંદિર ચણાવા લાગ્યું. થોડા સમયમાં મંદિર તૈયાર થઈ ગયું.
                                                    દોહા
                                                    સવરામંડપ સિદ્ધનો, પાટપૂજા
                                                    થાય,
                                                    મૂર્તિને પધરાવવા, મળ્યો. સંતસમુદાય.
                                                    શ્રી રામદેવજીનું મંદિર તૈયાર થયું અને રામગરજીની આજ્ઞા મુજબ સંતો, ભક્તો, નામાંકીત ધર્માત્માઓના સ્થાનકે વાયક મોકલી શ્રી રામદેવજીની મૂર્તિની પ્રાણપ્રતિષ્ઠા કરી મંદિરમાં પધરાવવા માટે સહુને તેડાવ્યા.
                                                    પાટણથી સંત જેમલ રાઠોડ, મેવાડથી રૂપાંદે, અંજારથી તોરલ, નિરલબાઈ, લીલમબાઈ, સતી લોયણ, ગિરનારથી મહાત્મા રામેતવન, એમના શિષ્ય ભોજલરામ, (ભોજો ભગત) કહળસંગ, ગંગાસતી વગેરે આવ્યાં. પંચાળમાંથી ગેબીનાથજી તથા આપો, સોરઠમાંથી સતાધારથી ગીગો, પુરબથી દેવીદાસ, આપો

                                                </p>
                                                <p className="paragraph-content">
                                                    દાનો અને આપો શાર્દુલ જેવા નિમાધારી ધર્મધુરંધરો આવ્યા.
                                                    ત
                                                    સવરામંડપ રોપ્યો, પાટપૂજા કરી, રામગરજીએ ભગવાન શ્રી રામદેવજી મહારાજની આરતી ઉતારી. રામદેવજી મહારાજની જય...!' ના જયનાદોથી મંડપ ગાજી ઊઠ્યો, સુરસંગજીએ મંદિરનાં શિખરે રોપવાના નેજાની પૂજા કરી.
                                                    ગિરનારના રામેતવન અને ગિરનારી ગેબીનાથે રામગરજીને ઘૂપ, ભસ્મ તિલક કરી નમન કર્યું. સંત સમુદાયના સાંનિધ્યમાં શ્રી રામદેવજીની મૂર્તિ મંદિરમાં પધરાવી. શીખર પર ધર્મધજા ફરકાવી.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails34;
