import React, { useEffect } from "react";
import { useState } from "react";

import "./Header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';


const Header = () => {
  const logoimg = "/assets/wp-content/uploads/2018/12/4.png";
  const isMobile = window.innerWidth <= 990;
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    // const position = window.scrollY;
    // setScrollPosition(position);
    if( window.innerWidth <= 768 ) {
      if(window.pageYOffset > 230) {
        document.getElementById('header-nav').classList.add('fixed')
      }
      else {
        document.getElementById('header-nav').classList.remove('fixed')
      }
    }
  };

  window.addEventListener("scroll", handleScroll);

  useEffect(() => {
  }, []);

  let location = useLocation();
  return (
    <>
      <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />

      <header>
        <div className="container-fluid " >
       
          <nav className="navbar navbar-expand-lg navbar-dark" id="header-nav" style={{ backgroundColor: 'rgb(29, 50, 38)!important' }}>
            <div className="navbar-brand d-block d-lg-none" to="#">
              <NavLink className="nav-link logo" to="/">
                <img src={logoimg} alt="Vihara" width="50px" height="60" />
              </NavLink>
            </div>
            <div>
              {isMobile && <h3 className="mobile-only">JAY BABARI</h3>}
            </div>
            <button
              className="navbar-toggler navbar_toggle"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" style={{ width: '23px', height: '12px' }} />
            </button>
            <div className="collapse navbar-collapse " id="navbarSupportedContent">
              <ul className="navbar-nav ">
                <li className="nav-item">
                  <NavLink className={`nav-link ${location.pathname === "/" ? "active" : ""}`} aria-current="page" to="/">
                    HOME
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    className={`nav-link dropdown-toggle ${location.pathname === "/Audio" ||
                      location.pathname === "/Video" ||
                      location.pathname === "/Gallery2" ? "active" : ""
                      }`}
                    to="/blank"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    GALLERY
                  </NavLink>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <NavLink className="dropdown-item" to="/Audio">
                        BHAJANS
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/Video">
                        VIDEOS
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/Gallery2">
                        IMAGES
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    className={`nav-link dropdown-toggle ${location.pathname === "/About"
                      || location.pathname === "/plan-visit" ? "active" : ""}`}
                    to="/ABOUT"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    ABOUT
                  </NavLink>
                  <ul
                    className="dropdown-menu nav-item"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <NavLink className="dropdown-item" to="/About">
                        TEMPLE
                      </NavLink>
                    </li>
                    <li>
                      <NavLink className="dropdown-item" to="/plan-visit">
                        PLAN A VIST
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="navbar-brand d-none d-lg-block" to="#">
                <NavLink className="nav-link center-logo logo" to="/">
                  <img src={logoimg} alt="Vihara" width="100" height="60" />
                </NavLink>
              </div>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink className={`nav-link ${location.pathname === "/BlogMain" ? "active" : ""}`} to="/BlogMain">
                    STORIES
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className={`nav-link ${location.pathname === "/Books" ? "active" : ""}`} to="/Books ">
                    BOOKS
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className={`nav-link ${location.pathname === "/Contact" ? "active" : ""}`} to="/Contact ">
                    CONTACT US
                  </NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;