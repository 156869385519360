import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails33 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery32.jpg";
    return (
        <>
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}
 <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      

            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                    
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                         
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            પૂંજીને બાધા ફળી !
                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                    
                                                >
                                                    <span className="income">પૂંજીને બાધા ફળી !

                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content" >
                                                    પૂંજી રાખે ટેકને, બાધા લઈ મનમાંય,
                                                    સવાતણી બાધા ફળી, પુત્રવતી એ થાય.
                                                    રોજકા ગામથી રબારણો રંગપર આવતી હતી. રંગપરના સીમાડે ખેતરમાં કોશ ચાલતો જોઈ તેઓ ખેતરનું ખોડીબારું ખસેડીને કૂવા ઉપર આવી. સાકરિયા કૂવાના ઠંડાં અને મીઠાં પાણીથી થાળામાં હાથપગ ધોઈ, ઘડી વિસામો લેવા બેઠી. રબારણો પાસે કોશ હાંકનારની સ્ત્રી પૂંજી આવી અને બેઠી.
                                                    ‘બાઈયું ! કયે ગામથી આવો છો ?' પૂંજીએ પૂછ્યું.
                                                    અમે રોજકા ગામનાં છીએ. રંગપરમાં માવો વેચવા જઈએ છીએ.' એમ કહી પૂંજીને માવાનું દડબું આપતાં કહ્યું કે, બોન ! લે ! માવો ! તું યે ખા અને તારા બાળકને ખવડાવજે ! બીજી રબારણે પોતાના બોઘરણામાંથી માવાનું દડબું આપતાં કહ્યું કે, બોન લે ! માવો. પટેલને આપ. કોશ હાંકતા હાંકતા ખાય. ત્રીજી રબારણે પણ માવાનાં પીંડાને તોડી પૂછ્યું : બોન ! તારે ભાણી-ભાણિયાં કેટલાં છે ? હું તને માવો આપુ છું. ખંતે ખંતે ખવરાવજે. બાળકો હોંશે હોંશે ખાશે !' દીકરા- દીકરીની પૂછપરછ કરવાથી પૂંજીની આંખ આંસુથી ભીંજાઈ અને ગળગળા અવાજે
                                                    તે બોલી :
                                                    ‘બાઈયું ! આવા મબલખ પાક આપે એવાં ખેતર, વાડી બધુંય છે પણ ભગવાનને ઘેર શેર માટીનો તૂટો પડ્યો છે. એકાદ બાળક આપત તો મારું વાંઝિયામેણું ભાંગત. મારે દીકરા કશું નથી !' પૂંજીએ જણાવ્યું.
                                                    ‘બોન ભગવાનને ઘેર શેર માટીનો તૂટો નથી પડતો પણ તને કોઈ માલમી નથી મળ્યો. તું ઝાંઝરકાવાળા સવગણ બાપાની બાધા રાખ. તારી બાધા ફળશે અને દીકરા દેશે. નવ મહિને ઘોડિયું ખૂંદતો દીકરો થાશે.' એમ કહી રબારણો માવો વેચવા રંગપર ગામે જવા ચાલતી થઈ.
                                                    શ્રદ્ધા અને ટેકથી પૂંજીએ બાધા-વ્રત લીધું અને નવ મહિને એણે દીકરાને જન્મ આપ્યો. પુત્રરત્ન સાંપડવાથી એના હરખનો પાર રહ્યો નહીં

                                                </p>
                                                <p className="paragraph-content">
                                                    સવારમાં નિત્ય નિયમથી પરવારી વેજાના તાકા માથે લઈ સવા ભગત રંગપર આવતા હતા અને રંગપરથી પૂંજી પટલાણી ભાત લઈ પટેલને ખવડાવવા ખેતર તરફ આવતી હતી.
                                                    બે બાજુ ભૂંભરિયા તોરની વંડી જેવી વાડમાંથી મારગ નીકળતો હોવાથી એને ભૂંભરિયા નવેલી કહેતા હતા. માંડ એક ગાડું જઈ શકે એવા સાંકડા મારગવાળી નવેળી ટૂંકા ગાળામાં હતી. એ નવેલીમાં સવા ભગતને માથે વૈજા જોઈ પૂજીએ તાડૂકીને પૂછ્યું :
                                                    ‘અલ્યા ! વણકર છું ? નાતે મેત્તર છું ! ઢેઢ છું ?'
                                                    ‘હા બા ! વણકર છું, પણ ઢેઢ નથી. ઢેઢ કેવો હોય ?' ભગતે પૂછ્યું.
                                                    *હવે મૂંગો રે, મેતર એ જ ઢેઢ ! આવી નળીમાં સામો હાલ્યો આવ્યો ? મારું ભાત અભડાવ્યું ? ખેતરનો રખેવાળ ખાશે શું ?' એમ ક્રોધમાં તાડૂકા કરતી પૂંજી બોલવા લાગી. ભગતને પૂંજીના ગરમ શબ્દોની કાંઈ અસર થઈ નહીં. ‘બોન, નવેળીમાં આવતા પેલાં બરાબર ધ્યાન રાખીશ, હવે ભૂલ નહીં કરું,' એમ બોલી ભગતે બે હાથ જોડી નમન કરી ચાલવા માંડયું.
                                                    ક્રોધાવેશમાં ઊકળી ઊકળી પૂંજ ગાળોનો સૌર વરસાવતી નવેળી બહાર નીકળી. ત્યાં વિસામો લેવા એક પસાયતો ઊભો હતો. ગાળોની રમઝટ સાંભળી ચોંક્યો અને પૂછ્યું :
                                                    ‘પટલાણી ! શું થયું ? ગાળોનો વરસાદ વરસાવતા જાવો છો ?'
                                                    ‘ગાળો ના દે તો એને પૂજે ! નવેલી સાંકડી બે-ત્રણ માણસ જાય એવા મારગમાં ઓલ્યો વેજાવાળો વણકર સામો હાલ્યો આવ્યો અને મારું ભાત અભડાવ્યું. ‘પટલાણી, તું દીકરાની મા થઈ કોના પ્રતાપે ?'
                                                    ‘પ્રતાપ ! રામાપીરની ધોળી ધજાવાળા સવગણ બાપાનો.' પૂજીએ જણાવ્યું. ‘એ જ એ નેજાવાળા બાપા હતા. તને બાધાનું ફળ આપનાર ઝાંઝરકાવાળા સવા ભગત હતા. હવે મૂંગી મૂંગી જઈ નમી પડ.' પસાયતે જણાવ્યું. પસાયતાની વાત સાંભળી પૂંજીનું મન ધ્રૂજી ગયું ! દિગ્મૂઢ બની ગઈ ! આ ગયા એ જ સવગણ બાપા ? બાધા માનતા ચાલે છે એ ?'
                                                    ‘હા ! એ જ ! જા ઝટ ! રંગપર તલાવડીની પાળે એમના ગુરુ રામગર પાસે બેઠા હશે, ‘પસાયતે જણાવ્યું. પૂં તરત પાછી વળી. કરેલી ભૂલનો પસ્તાવો કરતી

                                                </p>
                                                <p className="paragraph-content">
                                                    તલાવડીની પાળે આવી. રામગરજ પાસે સુરાગ અને ગામનાં ઘણાં માણસો બેઠાં હતાં. સવા ભગત સત્સંગમાં બેઠેલા જોઈ પૂંજી પાલવ પાથરીને પગે લાગી, ‘સવગણ બાપા ! મેં તમને ઓળખ્યા નહીં અને નોં કેવાનાં વેશ કીધાં બાપા ! હું તો તમારી દીકરી છું. તમારી બાધાએ રતન જેવા દીકરા પામી છે બાપ !' પૂંજી બોલતાં બોલતાં રોવા લાગી.
                                                    સભામાં સહુ આશ્ચર્યચકિત થઈ ગયા. બાઈ કાંઈક અપરાધમાં આવી છે, એમ ધારી સુરસંગજીએ પૂછ્યું :
                                                    ‘બાઈ ! તારા વાંકની વાત તો કર ?'
                                                    ‘બાપુ ! હું ખેતરે ભાત લઈને જતી હતી. ભૂંભરિયા નવેળીમાં બાપા આવતા હતા. માથે વેજાની ગાંસડી જોઈ કોઈ મેતર વરણ છે. ઢેઢ છે. એણે મારું ભાત અભડાવ્યું એમ જાણીને મેં ગાળો દીધી. બાપા ! સાંભળીને ચાલ્યા ગયા. મને નવેળીના છેડે ઊભેલા પસાયતે કહ્યું કે, એ ઝાંઝરકાવાળા સવગણબાપા હતા.'
                                                    ઢેઢની આભડછેટની આ માન્યતાને આપણા સનાતનધર્મ સાથે ભેળવાય નહીં. માણસ માણસને અડતાં અભડાય ! એ તૂત પાખંડી લોકોએ ઊભું કર્યું છે !' રામગરજી બોલ્યા.
                                                    ‘ગુરુદેવ ! આપ અમને એ વિશે સત્ય સમજાવો.' સભામાં સુરસંગજી અને અન્ય જીજ્ઞાસુઓ પૂછવા લાગ્યા.
                                                    મનુ ભગવાને ચાર વર્ણ બતાવ્યા. શીશ, કર, ઉદર અને પાય. બ્રાહ્મણ, ક્ષત્રી, વૈશ્ય અને શૂદ્ર એ એક જ અંગના ભાગ છે, તે દરેક મનુષ્યમાં હોય છે. શિક્ષણ, રક્ષણ, પોષણ અને સેવા આ ચાર વિભાગ ગણાવ્યા છે. એટલે ઊંચનીચ, નાના મોટા કોઈ નથી.
                                                    પંજાને જોતાં આંગળીઓ લાંબી ટૂંકી જણાય છે, પણ હથેળી ત૨ફ ચારે આંગળી વાળતાં લાંબી ટૂંકી હોતી નથી. બાળક જન્મતાની સાથે ૐનો ઉચ્ચાર કરી આપણને સમાનતા સમજાવે છે.
                                                    સૃષ્ટિના સર્જનહારને ઊંચનીચના ભેદ છે, એમ જણાવ્યું હોત તો એમને અવતરવા માટે પ્રસવના બે વિભાગ જુદા માર્ગ કરીને જુદી જુદી રીતે જન્મ આપત, પરંતુ એવું નથી.

                                                </p>
                                                <p className="paragraph-content">
                                                    ચોપાઈ
                                                    અંગુલિ લાંબા ટૂંકા વેઢા, દંત જીભ રક્ષિતા પેઢા,
                                                    તન તાંતે નવ હોયે રેઢા, અંગે ક્રોધ ગણાય. ઢેઢા.
                                                    દાંત જીભનું રક્ષણ કરે છે. દાંતનું પેઢાં રક્ષણ કરે છે. શરીરમાં નસના છેડા નથી. સળંગ છે. આવા પવિત્ર તનમાં જો ક્રોધ ઘર કરી બેઠો હોય તો તે પોતાનું લક્ષણ બતાવવા સારાસારનું ભાન રહેવા દેતો નથી. એ ક્રોધને ઢંઢ કહેવાય છે.
                                                    બાઈના શરીરમાં રહેલો ઢેઢ બેઠો થયો, ત્યારે જ બાઈ ક્રોધથી ગાળો બોલવા લાગી. ક્રોધ જ ઢેઢ છે.'
                                                    રામગરના ધર્મઉપદેશમાં પૂંજીને ઘણું જાણવાનું મળ્યું. એનો પતિ પણ ભાતનું મોડું થવાથી શોધતો શોધતો ત્યાં આવી ધર્મચર્ચા સાંભળવા લાગ્યો.
                                                    બાઈ, તારો દીકરો લઈ આવ !' સવા ભગતે કહ્યું . બાઈએ દીકરાને લાવી નમાડ્યો. પટેલ અને પૂંજીબાઈ પણ નમન કરવા લાગ્યાં.
                                                    બાઈ ! દીકરી ! તારૂં ભાતું લાવ.'
                                                    પૂંજીએ ભાત છોડતાં રોટલાને બદલે સુખડી નીકળી ! ‘લે, આ રામદેવજી મહારાજની પ્રસાદી. તારી ઘઉંગોળ, ઘીની બાધા પૂર્ણ થઈ. હવે શાંતિથી તારે ઘેર જા. ખા પી અને મજા કર, બાપ !' કહી ભગતે એની બાધા સ્વીકારી છોકરાને માથે હાથ મૂક્યો. પતિપત્ની રામગરનાં ચરણમાં પડી ગયાં અને આશીર્વાદ મેળવી ઘેર આવ્યાં.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails33;
