import { useState } from 'react';

const BooksRead = () => {
  const chp1 = "/books/ramdev-ramayan.pdf";
  return (
    <>
     <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
      <div>
        <iframe src={chp1} style={{ width: '100%', height: '100rem' }} />
      </div>
    </>
  );
};
export default BooksRead;
