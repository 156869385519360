import React, { useState, useRef, useEffect, } from "react";
import styles from "./AudioPlayer.module.css";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import { FaList } from "react-icons/fa";
import { MdOutlineSkipNext } from "react-icons/md";
import { MdOutlineSkipPrevious } from "react-icons/md";
import { FaVolumeUp } from "react-icons/fa";
import { TbRewindForward30 } from "react-icons/tb";
import { TbRewindBackward30 } from "react-icons/tb";
import { Link } from "react-router-dom";
const AudioPlayer = (props) => {
  const img = "../../assets/audioImg/krishna.webp";
  // console.log('audio', props.state);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(0.5);
  const [mute, setMute] = useState(false);
  const [currentsong, setCurrentSong] = useState(0);
  const [prevSongToggleindex] = useState("");
  const [show, setshow] = useState(false);
  const audioPlayer = useRef();
  const progressBar = useRef();
  const animationRef = useRef();
  const song = '../../assets/songs/song.mp3';
  const song1 = '../../assets/songs/Aarti.mp3';
  const song2 = '../../assets/songs/aarti2.mp3';
  const songsData = [
    {
      name: song,
      title: "Chaleya",
      isPlaying: false,
    },
    {
      name: song1,
      title: "Hanuman Chalisa",
      isPlaying: false,
    },
    {
      name: song2,
      title: "Aarti",
      isPlaying: false,
    },
  ];
  const [data, setData] = useState(songsData);
  useEffect(() => {
    const seconds = Math.floor(audioPlayer.current.duration);
    setDuration(seconds);
    progressBar.current.max = seconds;
  }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!isPlaying);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  // const togglePlayPause = () => {
  //   const prevValue = isPlaying;
  //   if (isPlaying === true)
  //     console.log('dgfd', isPlaying);
  //   console.log('audio player toggle play pause', props.state);
  //   setIsPlaying(isPlaying);
  //   console.log('updated state toggleplaypause', prevValue);
  //   if (!prevValue) {
  //     audioPlayer.current.play();
  //     animationRef.current = requestAnimationFrame(whilePlaying);
  //   } else {
  //     audioPlayer.current.pause();
  //     cancelAnimationFrame(animationRef.current);
  //   }
  // };

  // const togglePlayPause = (index) => {
  //   if (isPlaying === index) {
  //     audioPlayer.current.pause();
  //     setIsPlaying(null);
  //   } else {
  //     audioPlayer.current.setAttribute('src', songsData[index]);
  //     audioPlayer.current.play();
  //     setIsPlaying(index);
  //   }
  // };
  // const togglePlay = (index, isPlay) => {
  //   const prevValue = isPlay;
  //   const newState = data.map((song, s_index) => {
  //     if (index === s_index) {
  //       if (song.isPlaying == true) {
  //         setIsPlaying(prevValue);
  //         // props.onPlay(true);
  //         return { ...song, isPlaying: false };
  //       }
  //       else {
  //         return { ...song, isPlaying: true };
  //       }
  //     }
  //     else {
  //       return { ...song, isPlaying: false };
  //     }
  //   })
  //   setData(newState)
  //   if (prevValue) {
  //     audioPlayer.current.pause();
  //   }
  //   else {
  //     setCurrentSong(index);
  //     setSongPlayer(index);
  //   }
  //   setIsPlaying(!prevValue);
  // };
  const togglePlay = (index, isPlay) => {
    const newState = data.map((song, s_index) => {
      if (index === s_index) {
        return { ...song, isPlaying: !isPlay }; 
      } else {
        return { ...song, isPlaying: false }; 
      }
    });
  
    setData(newState);
    if (!isPlay) {
      setCurrentSong(index);
      setSongPlayer(index);
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
    setIsPlaying(!isPlay); 
  };
  
  const PrevSongHandler = () => {
    let current_song_index = audioPlayer.current.getAttribute('data-index');
    let prev_song_index = parseInt(current_song_index) - 1;
    if (songsData[prev_song_index] !== undefined) {
      setCurrentSong(prev_song_index)
    }
    else {
      let total_songs = songsData.length;
      prev_song_index = total_songs - 1;
      setCurrentSong(prev_song_index)
    }
    setSongPlayer(prev_song_index)
  }
  const NextSongHandler = () => {
    let current_song_index = audioPlayer.current.getAttribute('data-index');
    let next_song_index = parseInt(current_song_index) + 1;
    if (songsData[next_song_index] !== undefined) {
      setCurrentSong(next_song_index)
    }
    else {
      next_song_index = 0;
      setCurrentSong(next_song_index)
    }
    setSongPlayer(next_song_index)
  }
  const setSongPlayer = (song_index) => {
    audioPlayer.current.setAttribute("src", songsData[song_index]['name']);
    animationRef.current = requestAnimationFrame(whilePlaying);
    setIsPlaying(true)
    audioPlayer.current.play();
  }
  const whilePlaying = () => {
    if (audioPlayer.current && audioPlayer.current.currentTime !== undefined) {
      progressBar.current.value = audioPlayer.current.currentTime;
      changePlayerCurrentTime();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      console.error("Error: audioPlayer.current or its properties are not available");
    }
  };

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  };
  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      "--seek-before-width",
      `${(progressBar.current.value / duration) * 100}%`
    );
    setCurrentTime(progressBar.current.value);
  };

  const backThirty = () => {
    progressBar.current.value = Number(progressBar.current.value - 30);
    console.log('back thirty', progressBar.current.value);
    changeRange();
  };
  
  const VolumeHandler = (event) => {
    const newVolume = parseFloat(event.target.value);
    audioPlayer.current.volume = newVolume;
    setVolume(newVolume);
  };

  const forwardThirty = () => {
    if (progressBar.current) {
      const newValue = Number(progressBar.current.value) + 30;
      const maxValue = 1000;
      if (newValue <= maxValue) {
        progressBar.current.value = newValue;
        changeRange();
      }
    } else {
      console.log('error');
    }
  };

  const MuteHandler = () => {
    setMute(!mute);
    if (mute === true) {
      audioPlayer.current.volume = 0;
      setVolume(0);
    } else {
      audioPlayer.current.volume = 0.5;
      setVolume(0.5);
    }
  };
  return (
    <>
      <div className="table-section">
        {/* <Link onClick={toggle}>
        </Link> */}
        <table className="table table-hover ">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Play</th>
              <th scope="col">Title</th>
            </tr>
          </thead>
          <tbody>
            {data.map((song, index) => (
              <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>
                  <Link
                    onClick={() => togglePlay(index, song.isPlaying)}
                    className={styles.playPause}
                  >
                    {song.isPlaying ? <FaPause /> : <FaPlay className={styles.play} />}
                  </Link>
                </td>
                <td>{song.title}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.audioPlayer} style={{ display: isPlaying ? 'flex' : 'none' }}>
        <audio ref={audioPlayer} src={song} data-index={currentsong} preload="metadata">
        </audio>
        <div className={styles.leftGroup}>
          <img src={img} className={styles.img} />
          <div className={styles.TitleText}>
            <h4>{songsData[currentsong].title}</h4>
          </div>
        </div>
        <div className={styles.centerGroup}>
          <Link className={styles.forwardBackward} onClick={PrevSongHandler}>
            <MdOutlineSkipPrevious />
          </Link>
          <Link className={styles.forwardBackward} onClick={backThirty}>
            <TbRewindBackward30 />
          </Link>
          <Link onClick={togglePlayPause} className={styles.playPause}>
            {isPlaying ? <FaPause /> : <FaPlay className={styles.play} />}
          </Link>
          <Link className={styles.forwardBackward} onClick={forwardThirty}>
            <TbRewindForward30 />
          </Link>
          <Link className={styles.forwardBackward} onClick={NextSongHandler}>
            <MdOutlineSkipNext />
          </Link>
        </div>
        <div className={styles.rightGroup}>
          <div className={styles.currentTime}>{calculateTime(currentTime)}</div>
          <div>
            <input
              type="range"
              className={styles.progressBar}
              defaultValue="0"
              ref={progressBar}
              onChange={changeRange}
            />
          </div>
          <div className={styles.duration}>
            / {duration && calculateTime(duration)}
          </div>
        </div>
        <div className={styles.lastGroup}>
          <div className={styles.volumeControl}>
            <FaVolumeUp className="volumeup"
              onClick={MuteHandler}
              onChange={VolumeHandler}
            />
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={VolumeHandler}
              className={styles.volumeSlider}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default AudioPlayer;