import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import './blog.css';
import AudioPlayer from "../Audio/AudioPlayer";
import { Helmet } from "react-helmet";

const BlogMain = (props) => {
    const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery1.jpg";
    const img2 = "/assets/wp-content/uploads/2018/12/ramdev-gallery3.jpg";
    const img3 = "/assets/wp-content/uploads/2018/12/ramdev-gallery4.jpg";
    const img4 = "/assets/wp-content/uploads/2018/12/ramdev-gallery34.jpg";
    const img5 = "/assets/wp-content/uploads/2018/12/ramdev-gallery6.jpg";
    const img6 = "/assets/wp-content/uploads/2018/12/ramdev-gallery7.jpg";
    const img7 = "/assets/wp-content/uploads/2018/12/ramdev-gallery8.jpg";
    const img8 = "/assets/wp-content/uploads/2018/12/ramdev-gallery9.jpg";
    const img9 = "/assets/wp-content/uploads/2018/12/ramdev-gallery10.jpg";
    const img10 = "/assets/wp-content/uploads/2018/12/ramdev-gallery12.jpg";
    const img11 = "/assets/wp-content/uploads/2018/12/ramdev-gallery13.jpg";
    const img12 = "/assets/wp-content/uploads/2018/12/ramdev-gallery14.jpg";
    const img13 = "/assets/wp-content/uploads/2018/12/ramdev-gallery15.jpg";
    const img14 = "/assets/wp-content/uploads/2018/12/ramdev-gallery16.jpg";
    const img15 = "/assets/wp-content/uploads/2018/12/ramdev-gallery17.jpg";
    const img16 = "/assets/wp-content/uploads/2018/12/ramdev-gallery18.jpg";
    const img17 = "/assets/wp-content/uploads/2018/12/ramdev-gallery19.jpg";
    const img18 = "/assets/wp-content/uploads/2018/12/ramdev-gallery20.jpg";
    const img19 = "/assets/wp-content/uploads/2018/12/ramdev-gallery21.jpg";
    const img20 = "/assets/wp-content/uploads/2018/12/ramdev-gallery22.jpg";
    const img21 = "/assets/wp-content/uploads/2018/12/ramdev-gallery23.jpg";
    const img22 = "/assets/wp-content/uploads/2018/12/ramdev-gallery24.jpg";
    const img23 = "/assets/wp-content/uploads/2018/12/ramdev-gallery25.jpg";
    const img24 = "/assets/wp-content/uploads/2018/12/ramdev-gallery26.jpg";
    const img25 = "/assets/wp-content/uploads/2018/12/ramdev-gallery27.jpg";
    const img26 = "/assets/wp-content/uploads/2018/12/ramdev-gallery28.jpg";
    const img27 = "/assets/wp-content/uploads/2018/12/ramdev-gallery29.jpg";
    const img28 = "/assets/wp-content/uploads/2018/12/ramdev-gallery30.jpg";
    const img29 = "/assets/wp-content/uploads/2018/12/ramdev-gallery31.jpg";
    const img30 = "/assets/wp-content/uploads/2018/12/ramdev-gallery32.jpg";
    const img31 = "/assets/wp-content/uploads/2018/12/ramdev-gallery33.jpg";
    const img32 = "/assets/wp-content/uploads/2018/12/ramdev-gallery34.jpg";
    const img33 = "/assets/wp-content/uploads/2018/12/ramdev-gallery35.jpg";
    const img34 = "/assets/wp-content/uploads/2018/12/ramdev-gallery36.jpg";
    const img35 = "/assets/wp-content/uploads/2018/12/ramdev-gallery37.jpg";
    const img36 = "/assets/wp-content/uploads/2018/12/ramdev-gallery38.jpg";
    const img37 = "/assets/wp-content/uploads/2018/12/ramdevpir15.jpeg";
    const img38 = "/assets/wp-content/uploads/2018/12/ramadevpir24.jpg";
    const img39 = "/assets/wp-content/uploads/2018/12/ramdevpir14.jpeg";

    return (
        <>
            <Helmet>
                <title>Ramapir | Stories</title>
                <meta name="keywords" content=" શ્રી રામ દેવજીનું પ્રથમ મંદિર 1859 માં બનાવવામાં આવ્યું હતું, 1459 સીઇમાં રામદેવ પીટના નિધન પછી
                        સાડા ત્રણ સદીઓ પછી. એક દંતકથા અનુસાર, ઠંડો અલ્લાહયારમાં એક હિંદુ વ્યક્તિએ પ્રતિજ્ઞા લીધી કે જો તેને સંતાન પ્રાપ્ત થશે, તો તે તાંડો અલ્લાહયારમાં રામા પીરનો મેળો (મેળો) ગોઠવશે. તેમની ઈચ્છા પૂરી થતાં તેઓ આજના રાજસ્થાન, ભારતના રામદેવરામાં આવેલા રામ પીટના મૂળ મંદિરમાંથી આજના પાકિસ્તાનમાં આવેલા ટંડો અલ્લાહયાર ખાતેથી એક માટીનો દીવો લાવ્યા અને અહીં મંદિર બનાવ્યું." />
            </Helmet>

            <Header />

            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}

                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div>
                <h5 className="stories-title">Stories</h5>
            </div>
            <div className="page_content_wrap ">
                <div className="content_wrap">
                    <div className="content">
                        <div className="posts_container columns_wrap columns_padding_bottom">
                            <div className="column-1_2">
                                <article
                                    id="post-686"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-686 post type-post status-publish format-standard has-post-thumbnail hentry category-news"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" src={img1} className="attachment-medium size-medium"
                                            alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails'} rel="bookmark" target="_blank" className="v-title">  શિવ-પાર્વતી સંવાદ</Link>
                                        </h4>
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            ઉલ્કાપાત અસુરનો, વધતાં અત્યાચાર, પૃથ્વી રૂ૫ ઘરી ઘેનુનું, કરી રહી પોકાર!
                                            સત્યુગ પૂરો થયો અને નવી સૃષ્ટિનું નિર્માણ થયું. પૃથ્વી ઉપર દાનવો અને માનવોનો વંશ વિસ્તરવા માંડ્યો. દાનવો બલિષ્ટ હતા એટલે માનવોની તેમને કશી ગણતરી ન હતી. તેઓ મનફાવે તેમ યથેચ્છ વર્તન કરવા લાગ્યા. તેઓ યજ્ઞોમાં ભંગ પડાવતા, ને જે કોઈ તેમના સામું થતું તો તેને ખડુગના એક જ પ્રહારે ચીરી નાખતા. ઋષિમુનિઓ તેમના અસહ્ય ત્રાસથી ધ્રૂજવા લાગ્યા. આશ્રમની ગૌશાળાની ગાયોની કતલ કરતાં પણ તેઓ અચકાતા નહીં.
                                            આ દાનવોની ઈચ્છા સમગ્ર પૃથ્વી ઉપર ત્રાસ ફેલાવી, સ્વર્ગ ઉપર આક્રમણ કરી, દેવોને હાંકી કાઢી સ્વર્ગનું આધિપત્ય મેળવી લેવાની હતી...

                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-120"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-120 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '358px' }}
                                            src={img2} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />

                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails2'} rel="bookmark" className="v-title" target="_blank">નંદનો અવતાર-અજમલજી</Link>
                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            પિત્રાઈએ પેહો ગર્યો, કુટુંબે થયાં કહેત, નહીંતર ભૂમિ લેત, મારવાડ મેવાડની દિલ્હીમાં તુંવર શાખાનો ક્ષત્રિયવીર અનંગપાળ રાજ કરતો હતો. એનું સૈન્યદળ બળવાન હતું તેથી મેવાડમાં તેની આણ વર્તાવા લાગી. એને પોતાના વંશના રાજપૂતોનું પીઠબળ હોવાથી દૂર દૂરના પ્રદેશના બંડખોરો અને મુસલમાન બાદશાહો પણ તેના ઉપર હલ્લો કરવામાં જોખમ માનતા હતા.
                                            અનંગપાળને બે પુત્રીઓ હતી; કમળાદેવી અને રૂપસુંદરી. કમળાદેવીને અજમેરના રાજા સોમેશ્વર ચૌહાણ વેરે પરણાવી હતી. એને એક પુત્ર થયો.તેનું નામ પૃથ્વીરાજ હતું. બીજી દીકરી રૂપસુંદરીને કનોજના રાજા વિજયપાળ સાથે પરણાવી હતી, એને પુત્ર થયો...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails2'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-144"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-144 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" src={img3} style={{ height: '300px' }}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails3'} rel="bookmark" target="_blank" className="v-title">શ્રી રામદેવજીનું પ્રાગટય </Link>
                                        </h4>{" "}
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            સતી સ્વપ્નમાં દેખતાં, મહાતેજ પરકાશ! અશ્વ તણા અસવારનો, થયો દર્શન આભાસ. ભાદરવા સુદી દશમની મધરાત પછી સતી મિણલદેને સ્વપ્ન આવ્યું. મહેલમાં તેજનો મહાપ્રકાશ છવાયો હતો અને એમને શ્વેતાંગી રૈવત પર અસવાર થયેલા, કેસરી જામો, માથે મુકુટ અને હાથમાં ભાલાનું આયુધ ઘારણ કરેલી દિવ્ય વિભૂતિ દેવનાં દર્શન થયાં. થોડી વારે તેમનું સ્વપ્ન સરી ગયું. તેઓ જાગૃત થયા અને તરત જ અજમલજીને સ્વપ્નની વાત કરી. સતીના સ્વપ્નની વાત સાંભળી એ વખતે એમને પણ આભાસમાં જે સમસ્યા થતી જણાઈ કે જે શ્રી દ્વારિકાધિશના આગમનન આગાહી રૂપે હતી.
                                            પરોઢિયે મિણલદે ગૌશાળામાં ગયાં...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails3'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-146"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-146 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img4} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails4'} rel="bookmark" target="_blank" className="v-title"> લીલુડો ઘોડો</Link>
                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            હીરચીરને થોડલે, થયા રામદે' સ્વાર, થનગન નાચી અશ્વ એ, ચડયો ગગનમોજાર,
                                            શ્રી રામદેવજી બણ વરસની ઉંમરના થયા. તેમનાં બુદ્ધિ, ચાતુર્ય, સમજસિહ- અને વાતચીત કરવાની કુશળતાથી સૌ કોઈ અંજાઈ જતાં હતાં. રામદેવ ખોળામાં બેસાડી રાજમહેલના ઝરૂખામાં રાણી મિણલદે બેઠાં હતાં, એટલામ રામકુંવરને દૂધ પાવા ઝારી લઈને દાસી આવી. બરાબર એ સમયે મહેલના ઝરૂપ નીચેથી એક ઘોડેસ્વારને જતો રામદેવજીએ દીઠો અને એવા ઘોડા પર બેસવાન હઠ લીધી. તેઓ બોલ્યા કે, મા ! મને આવો ઘોડો અપાવો તો જ દૂધ પીઉ,
                                            બાળહઠ આગળ કોઈનો ઉપાય ચાલ્યો નહીં...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails4'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div
                                className="column-1_2">
                                <article
                                    id="post-142"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-142 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img5}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails5'} rel="bookmark" target="_blank" className="v-title"> લાખો વણઝારો</Link>
                                        </h4>{" "}
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            લખી, લખો લખેસરી, લાખો લખપતિ નામ, નગરે વહેપારી વડો, રહે સાંભરગઢ ગામ. દિલ્હીપતિ પૃથ્વીરાજથી જુદા પડયા પછી પૂરણજી નામના ચૌહાણે અજમેર પર ચડાઈ કરી અને વાગડની ભૂમિમાં દદરેડ નામના ગામને વસાવ્યું. એ ભૂમિ ખારાપાટવાળી હોવાથી ખેડયા વિનાની રહેતી તેથી ત્યાં જંગલોનો વિસ્તાર વધારે હતો. આ વાગડની નિર્જળ ભૂમિના જંગલમાં ગોરખનાથે ધૂણો સ્થાપ્યો. ગોરખના યોગબળથી આકર્ષાઈ પૂરણજી તેમના શિષ્ય બન્યા. ગોરખનાથ ચૌહાણ રાજાના ગુરુ થયા...

                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails5'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div
                                className="column-1_2"
                            >
                                <article
                                    id="post-148"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-148 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img6}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />

                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails6'} rel="bookmark" target="_blank" className="v-title"> પીરોનું આગમન</Link>

                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            રણુજામાં રામદે'પીર પ્રગટ્યા છે. એમણે ભૈરવા રાક્ષસને માર્યો; નિયંત્ર વાવડીને જળથી ઊભરાવી, વણજારાની સાકર ખારી થઈ ગઈ. એવા પરચા છું છે. એમના પર શ્રદ્ધા રાખી તેમની ઉપાસના કરવાથી મન ધાર્યા કામ થાય છે આવી વાતો મારવાડનાં નાનાં મોટાં ગામોમાં પ્રસરી. આથી રામદેવજી પરચાધાર હિન્દવાપીર છે. એમ લોકો માનતાં થઈ ગયાં...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails6'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>

                            {/* 7 to 12 */}
                            <div className="column-1_2">
                                <article
                                    id="post-686"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-686 post type-post status-publish format-standard has-post-thumbnail hentry category-news"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" src={img7} className="attachment-medium size-medium" style={{ height: '300px' }}
                                            alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails7'} rel="bookmark" target="_blank" className="v-title"> પીર પરાજય</Link>
                                        </h4>
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            રણુજામાં રામદે'પીર પ્રગટ્યા છે. એમણે ભૈરવા રાક્ષસને માર્યો; નિયંત્ર વાવડીને જળથી ઊભરાવી, વણજારાની સાકર ખારી થઈ ગઈ. એવા પરચા છું છે. એમના પર શ્રદ્ધા રાખી તેમની ઉપાસના કરવાથી મન ધાર્યા કામ થાય છે આવી વાતો મારવાડનાં નાનાં મોટાં ગામોમાં પ્રસરી. આથી રામદેવજી પરચાધાર હિન્દવાપીર છે. એમ લોકો માનતાં થઈ ગયાં; અને કબર પૂજા કરવી, હકીરોન દોરાયાગા બાંધવા, જંત્રમંત્રનાં માદળિયા બાંધવાં, મંત્રેલા એઠાં પાણી પીવાં, કવા પર ચાદર, ફૂલ, ધૂપ તથા [ ધજા ચડાવવાં એવાં કાર્યો હિન્દુધર્મ માટે યોગ્ય નથી. પરઘર્મીઓ પોતાના ધર્મમાં ભેળવવા પીરનાં ચમત્કારોની ખોટી વાતો કરી લોકોને વિશ્વાસમાં લઈ વટલાવે છે, ધર્મભ્રષ્ટ કરવા ફકીરો જાતજાતના પાખંડ કરે છે.

                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails7'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-120"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-120 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img8} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />

                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails8'} rel="bookmark" className="v-title" target="_blank"> જય હિન્દવાપીર</Link>
                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            શઠજન શઠતા નવ તજે, તજે ન ઢાળા ઠંગ, સજ્જન સમતા નવ તજે, જેને એક જ રંગ. રામદેવજી અને પીરી હજી દરબારગઢ સુધી પહોંચી શક્યા નથી, ત્યાં તો નદીનાં પૂર પેઠે ફકીરોના ટોળે ટોળાં આવ્યાં. બપોર થતાં તો એ ટોળાંનો હજારોની સંખ્યામાં જમાવ થયો. જોતજોતામાં જુવાળ એવો વધ્યો કે આખું ગામ ફકીરોથી ઊભરાયું.મારવાડ વાગડ મેવાડા, આવી ઊમટયાં ફકીર ધાડાં, કચ્છ સિંધ માળવ પરદેશી, દીન દરવેશી અનેક વેશી. જાતજાતનાં ફકીરો આવેલા જોઈને પીરો મનમાં ને મનમાં મલકાવા લાગ્યા...

                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails8'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-144"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-144 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" src={img9} style={{ height: '300px' }}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails9'} rel="bookmark" target="_blank" className="v-title"> સતી ડારલદે’ની સમાધિ </Link>
                                        </h4>{" "}
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            કણ્વ અંગિરા બૃહસ્પતિ, આતિથ્ય ગાલવધામ,
                                            પાંચ ઋષિના આશ્રમો, પાંચાલયનું નામ.
                                            પૂર્વે ઉત્તર દિશાથી પશ્ચિમ દિશા સુધી અયોધ્યાના રાજા માંધાતાની રાજ હકુમત હતી. પાંચાળ ભૂમિના છ ોજનના વિસ્તારમાં માંડવગઢ નામની નગરી હતી...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails9'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-146"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-146 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img10} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails10'} rel="bookmark" target="_blank" className="v-title"> કતીબશાનો હૃદયપલટો</Link>
                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            રામદેવજીએ પાંચ મોટા પીરોને પરચાઓ બતાવ્યા અને લાખાને ઇચ્છીત
                                            વસ્તુઓ આપી. પ્રસંગ પ્રસંગનો પરચા પ્રતાપ બતાવ્યો; ત્યારથી ફકીરોના મનમાં
                                            પૈઠી હતી.
                                            વિધર્મીઓનાં છળ-કપટથી ભોળાંભલાં નરનારીઓને બચાવી સ્વધર્મ તરફ વાળવા રામદેવજી મારવાડ, મેવાડ, માળવા, કચ્છ, વાગડ, સિન્ધ, ગુજરાત અને કાઠિયાવાડમાં હિન્દુધર્મનો પ્રચાર કરવા નીકળ્યા...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails10'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div
                                className="column-1_2">
                                <article
                                    id="post-142"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-142 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img11}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails11'} rel="bookmark" target="_blank" className="v-title"> ભયંકર રાક્ષસ ભૈરવો</Link>
                                        </h4>{" "}
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            રાક્ષસ નામે ભૈરવો, મરુભોમની માંય, વટાવડા વેરી ગણી મારી ખાતો ત્યાંય. ચોપાઈ
                                            ભૈરવસિંહ કુશ દર્ભવંશી, રાક્ષસ થઈ બનતો વિધ્વંશી, વાટવ અંતે મરે ઝઝૂમી, બાર ગાઉ થઈ નિર્જન ભૂમિ. મારવાડમાં લોહગઢ ગામમાં ભૈરવસિંહ નામનો રાજપૂત કુશ-દર્ભવં શાખાનો હતો...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails11'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div
                                className="column-1_2"
                            >
                                <article
                                    id="post-148"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-148 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img12}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />

                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails12'} rel="bookmark" target="_blank" className="v-title"> સાત જન્મની સેવિકા ડાલીબાઇ </Link>

                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            ગોમટ નામના ગામનો એક કઠિયારો જંગલમાંથી લાકડાનો બારો વર્ષ ગામ * ખાવાનો વિચાર કર્યો. તે ભારો નીચે મૂકીને બેઠો. એટલામાં તેણે જમીનમાં હ આવતો હતો. રસ્તામાં એક પીંપળના વૃક્ષની છાયા જોઈ. તેણે બે કડી બેસી
                                            રડવાનો અવાજ સાંભળ્યો...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails12'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>

                            {/* 13 to 18 */}
                            <div className="column-1_2">
                                <article
                                    id="post-686"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-686 post type-post status-publish format-standard has-post-thumbnail hentry category-news"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" src={img13} className="attachment-medium size-medium" style={{ height: '300px' }}
                                            alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails13'} rel="bookmark" target="_blank" className="v-title"> સંત લખમો માળી</Link>
                                        </h4>
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            ફૂલવાડી ફોરી રહી, તીર્થ રણુંજા ધામ, રામ ભાગને રક્ષતો, લખમો માળી નામ. સવારમાં ફૂલછાબ લઈ, ગૌશાળમાં જાય, ડાલીબાઈને મંદીને, ફૂલછાબ દે ત્યાંય. વીણી વિવિધ ફૂલડાં, ડાલી ગૂંથે હાર, જઈ પહેરાવે પીરને, પ્રભુ કરે સ્વીકાર. નેક ટેક ને નિયમમાં, ચળે ન ચિત્ત લગીર, નિશદિન ભક્તિભાવમાં, પ્રસન્ન રામદેપીર.
                                            મેવાડમાં મેવાસા ગામના રાજા રાહોલ માલો તથા તેની પત્ની સતી રૂપ મેઘ-ધારૂઆનાં શિષ્ય હતાં...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails13'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-120"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-120 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img14} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />

                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails14'} rel="bookmark" className="v-title" target="_blank"> સુરસંગનો હૃદયપલટો</Link>
                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            કુંડા કાશ્મીર ગામ હતું જ્યાં, સુરસંગ રાજા રાજ કરે ત્યાં, પૂજે કબરો ફૂલ ચડાવે, સાધુ સંતને દંભી ગણાવે. મેવાડનાં હુડા કાશ્મીર નામનું ગામ હતું. ત્યાં હુડા શાખાનો રાજપૂત રાજા રાજ કરતો હતો. રાજાનું નામ સુરસંગ હતું. સુરરાજા મનસ્વીપણે નવા નવા તર્ક ઊભા કરતો હતો. એને સનાતન ધર્મની ઘણી સુગ હતી. વિધર્મીઓની વાત તેને તુરત રવો, વગેરે વિધવિધ પ્રકારની ક્રિયા કરવી તેવો તેણે નિયમ લીધો હતો.                                         </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails14'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-144"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-144 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" src={img15} style={{ height: '300px' }}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails15'} rel="bookmark" target="_blank" className="v-title"> ધર્માંધ સિકંદર નમ્યો ! </Link>
                                        </h4>{" "}
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            સાખી
                                            સ્થળે સ્થળે ગાજી રહ્યો, ‘રામતુંહી'નો જાપ, નમ્યો દિલ્હીનો બાદશાહ, જોથો પીર પ્રતાપ. કાળબળના કારણે દિલ્હીના રાજસિહાસન પર વિધર્મીઓનાં પગરણ થયાં અને મેં સિહાસન ગોઝારું બની ગયું.
                                            દિલ્હીપતિ અનંગપાળ તુંવરાની રાજ્યવ્યવસ્થા કંઈક ટકી અને કંઈક ડગી...          </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails15'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-146"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-146 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img16} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails16'} rel="bookmark" target="_blank" className="v-title"> રામાપીર અને જામાપીર</Link>
                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            પૂજાવાને પીર થઈ, ઈચ્છા દઈ મનમાંય, જામાજી મમતે ચડયો, જાંબેસરનો રાય,
                                            સંઘવીઓ રામદેવજીનાં દર્શને આવતા જતા હતા. માર્ગમાં જાંબુસર નામનું રણુંજા તીર્થઘામ હોવાથી સતી, સેવકો, ભક્તો, સાધુ-સંતો અને મહંતો ગામને પાદર પીપળિયો કૂવો અને બાજુમાં ઘટાટોપ વડની છાયા હતી, તેથી આ જતા યાત્રાળુઓ ત્યાં વિશ્રાંતિ લેવા બેસતા...                                         </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails16'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div
                                className="column-1_2">
                                <article
                                    id="post-142"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-142 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img17}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails17'} rel="bookmark" target="_blank" className="v-title">  સગુણાબહેનને કંકોતરી</Link>
                                        </h4>{" "}
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            શ્રી રામદેવજીની ઉંમર વીસ વરસની થઈ. રામદેવજી તો
                                            । હિંદવાપીર છે એ
                                            લોકવાયકા દેશપરદેશ ફેલાઈ. રામદેવજીની પીરાઈ અને ચમત્કારો વિષે ઉમરકોટના સોઢારાજા જયવંતસિંહના દરબારમાં બિરદાવવા આવેલા ભાર કવિઓએ રામદેવજીના ચમત્કારોની પ્રશંસા કરી અને તેઓ હજુ કુંવારા છે એ વાત
                                            વાતમાં કહી નાખ્યું...     </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails17'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div
                                className="column-1_2"
                            >
                                <article
                                    id="post-148"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-148 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img18}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />

                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails18'} rel="bookmark" target="_blank" className="v-title"> સગુણાનો પુકાર </Link>

                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            સગુલ્લાએ આખી રાત અજંપામાં વિતાવી, પઢિયારરાયને થયેલા ચમત્કાર અને કેન્દ્રની યુક્તિથી તે સાવ અજાણી હતી. તે વહેલી સવારમાં સાસુ પાસે આવી જ લાગી : “તમે કાલે વચન આપ્યું હતું કે, આવતી કાલે રણુંજા મોકલીશ, તો
                                            વેલું તૈયાર થાઉં છું.
                                            ‘સગુણા ! તને મોકલવાની મારી ના નથી પણ આ તારા પાંચ વર્ષના કુંવરને અહીં મૂકીને જા...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails18'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            {/* 19 to 24 */}
                            <div className="column-1_2">
                                <article
                                    id="post-686"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-686 post type-post status-publish format-standard has-post-thumbnail hentry category-news"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" src={img19} className="attachment-medium size-medium" style={{ height: '300px' }}
                                            alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails19'} rel="bookmark" target="_blank" className="v-title"> રામદેવજી નાં લગ્ન</Link>
                                        </h4>
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            રણુંજા ગામના નરનારીઓ દરબારગઢમાં આનંદ ઓચ્છવ ઊજવી અ નીકળ્યો. ગામ બહાર આવી તેઓ વરવેલમાં બિરાજ્યા. ઢોલ, ગામો, મા માતા મિણલદેએ રામદેવજીને કુંકુમ તિલક કરી આશીર્વાદ આપ્યા અને વ વાગવા લાગ્યાં. જાનમાં જવા તૈયાર થયેલા સગાંસંબંધી ગાડા પર સવાર થઈ, અજમલરાય પધાર્યા. રામદેવજીએ હરભુજીને કહ્યું કે, ‘ભાઈ' ! તમે આ પ્રમા એટલામાં રામદેવજીની માસીના દીકરા હરભુજી, ભાઈ વીર,
                                            જ બેસો.'
                                            ‘પણ ભાઈ ! આ ગાડાને બળદ ક્યાં જોડ્યા છે ?' ‘તમે બેસો તો ખરા !' રામદેવે કહ્યું...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails19'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-120"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-120 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img20} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />

                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails20'} rel="bookmark" className="v-title" target="_blank">  સગુણાના પુત્રને જીવતદાન</Link>
                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            શ્રી રામદેવજીની જાન રણુંજાને દરવાજે આવીને ઊભી રહી. લાસા અને લક્ષ્મી દેવજીને પોંખવા આવ્યાં. તે જોઈ રામદેવજીએ પૂછ્યું : ‘સગુણાબહેન કેમ નથી
                                            મા ક
                                            ‘ભાઈ ’સગુણાનો કુંવર બીમાર છે એટલે તે તેના બાળકની સારવારમાં રોકાઈ છે.' લાસાએ કહ્યું.
                                            “સગુણાબહેનને તેડી લાવો. મારે એમના હાથે જ મંગલતિલક કરાવવું છે.                                         </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails20'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-144"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-144 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" src={img21} style={{ height: '300px' }}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails21'} rel="bookmark" target="_blank" className="v-title">  મેવાડના દલુ વાણિયાને પરચો
                                            </Link>
                                        </h4>{" "}
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            માળવ કેરો સંઘવી, ગામ રણુંજા જાય, ભીડર કેરો વાણિયો, દલુ પૂછતો ત્યાંય. કયા ગામનો સંઘ ને કઈ જાત્રાએ જાય, કૃપા કરીને સંઘવી, કહો એનો મહિમાય ?
                                            શેઠ ! અમે માળવાથી આવીએ છીએ. રણુંજામાં પરગટ પરચા પૂ શ્રી રામદેવપીરની જાત્રાએ જઈએ છીએ. ભાદરવા માસની દેશમ- અગિયારશને ત્યાં મોટો મેળો ભરાય છે. દર વરસે અમારો સંઘ રામાપીરનાં દર્શને જાય છે, પરગટ રામા પીરજી, ગામ રણુંજા માંય,
                                            માગ્યા પરચા પૂરતા, સમર્યે કરતા સહાય...      </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails21'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-146"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-146 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img22} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails22'} rel="bookmark" target="_blank" className="v-title">  માલડે અને જેસલ-સંતમિલન</Link>
                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            ગુંજામાં શ્રી રામદેવજીએ પાટપૂજા, મંડપમેળાનો સમારંભ કરવાની મ
                                            ખીમડાને મંડપના કોટવાળ તરીકે નક્કી કરીને તેને ખબર આપી.
                                            સાખી
                                            રામે મંડપ રોપિયો, ગામ રણુંજા માંય, ખીમડિયાને ખબર દઈ, તેડાવ્યો છે ત્યાંય, વાયક બીડલાં વાળિયાં, ખીમડિયો લઈ જાય, ગતગંગાને નોંતરે, મંડપ મેળો થાય...                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails22'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div
                                className="column-1_2">
                                <article
                                    id="post-142"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-142 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img23}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails23'} rel="bookmark" target="_blank" className="v-title">  નેતલદેને પરચો
                                            </Link>
                                        </h4>{" "}
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            સવારના પહોરમાં રામદેવ દાતણ કરતા હતા, ત્યાં તેમનાં ભાભીએ આપી કે, 'મહારાજ ! આપણી ગાયમાતા મરી ગઈ છે.' ‘ભાભી ! આપણી ગાય મરે જ નહીં.' રામદેવજીએ કહ્યું. ‘ચાલો બતાવું, હું જોઈને આવી છું.'
                                            'બર
                                            રામદેવજી ભાભી સાથે ગાયવાડામાં ગયા ને ગાયને દાતણ અડકાડી કહ્યું
                                            *ઊઠો ઊઠો ગાયમાતા ! ઊઠો ઊઠો !'
                                            ગાય તુરત ઊઠી ઊભી થઈ ગઈ. રામદેવજી ભાભીને કહેવા લાગ્યા : ‘કેમ ભાભી ગાય મરેલી કહેતાં હતાં ને ?' ભાભી શરમાઈ અચરજ પામ્યાં અને ગાયને
                                            જીવતી જોઈ આનંદ પામ્યાં...     </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails23'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div
                                className="column-1_2"
                            >
                                <article
                                    id="post-148"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-148 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img24}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />

                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails24'} rel="bookmark" target="_blank" className="v-title"> ડાલીબાઈની સમાધિ </Link>

                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            ડાલીબાઈ રણુંજાથી થોડેક દૂર ગાયોનું ધણ ચરાવતાં હતાં. તેમણે ઢોલનગારાંનો નાદ સાંભળ્યો. શો પ્રસંગ હશે ? એમ વિચારતાં હતાં, ત્યાં એ ગોવાળને જતો જોયો. તેને ડાલીબાઈએ પૂછ્યું, કે ભાઈ ! નોબતો ગડગડે છે ત દરબારમાં કંઈ પ્રસંગ છે કે શું ?’
                                            ગોવાળે અચંબો પામતાં ડાલીબાઈને કહ્યું : ‘તમે દરબારમાં રહો છો તોય ખબ
                                            નથી કે ?’...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails24'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            {/* 25 to 30 */}
                            <div className="column-1_2">
                                <article
                                    id="post-686"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-686 post type-post status-publish format-standard has-post-thumbnail hentry category-news"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" src={img25} className="attachment-medium size-medium" style={{ height: '300px' }}
                                            alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails25'} rel="bookmark" target="_blank" className="v-title"> શ્રી રામદેવજીની સમાધિ</Link>
                                        </h4>
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            રામદેવજીએ ભાદરવા સુદી અગિયારશને દિવસે ડાલીબાઈની સમાધિ પાસે દશ હાથ છેટે બીજી સમાધિ ખોદાવી. કેસરિયો જામો પહેરી હાથમાં શ્રીફળ લઈ માતા, પિતા, ભાઈ, બહેન તથા કુટુંબના તુંવરા રાજપૂત સરદારો તથા તમામ નગરજનો સહીત વાજતે ગાજતે રામાસરોવર પર સમાધિ લેવા પ્રયાણ કર્યું.
                                            પોકણગઢથી આવેલા તમામ નગરજનો રામાસરોવર પર રાહ જોતા ઊભા હતા, ત્યાં રામદેવજી આવી પહોંચ્યા...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails25'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-120"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-120 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img26} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />

                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails26'} rel="bookmark" className="v-title" target="_blank">   હરભુજીનાં મનોમંથન</Link>
                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            પરખી શક્યા નહીં હરભુજી, લીલા વિવિધ રંગ વિભૂતી, ભેદ મર્મ કાંઈ નવ સમજાતા, આકુળ વ્યાકુળ મનમાં થાતા.
                                            પોતે રામદેવજીને મળ્યા એનાં પ્રમાણરૂપે ચારે વસ્તુઓ સાથે લાવ્યા હતા. શોકાગ્ર સગાંઓને એ વસ્તુઓ આપી, સર્વે આશ્ચર્યમુગ્ધ બન્યા અને સમાધિમાં પધરાવેલી વસ્તુઓ બહાર કેવી રીતે આવી એના ભરમમાં પડી ગયા...                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails26'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-144"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-144 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" src={img27} style={{ height: '300px' }}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails27'} rel="bookmark" target="_blank" className="v-title">   ધીંગાને પ્રમોદયો
                                            </Link>
                                        </h4>{" "}
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            જપ જેવું કોઈ તપ નહીં, નિશ્ચલ રાખો ખંત,
                                            ભક્તિમાં નિષ્કામ તો, થાય સંત શીલવંત,
                                            ભોજા તળાવનો વિસામો, દરબારે દીધેલ ખેસ, ખંતે દીધેલો ખીરનો પ્રસાદ લઈને વાતો કરતાં કરતાં પતિપત્ની નવાગામની સીમમાં પોતાનાં નેહમાં આવ્યાં...     </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails27'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-146"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-146 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img28} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails28'} rel="bookmark" target="_blank" className="v-title">  સંત ઉગમશી બાવા</Link>
                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            શ્રેષ્ઠ ધરા સોરઠતણી, કરમી ધરતી કચ્છ,
                                            ગોરખ ધૂણો સ્થાપતા, ‘જય જય અચલગચ્છ.’
                                            સૌરાષ્ટ્રની સંધી-સમાસવાળી કચ્છ ભૂમિમાં 'અચલગચ્છ' આદિનાથનાં સ્થાનને પસંદ કરી ગોરખનાથે ‘ધીણોધર' નામના ડુંગર પર પોતાનું આસન સ્થાપ્યું...                                       </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails28'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div
                                className="column-1_2">
                                <article
                                    id="post-142"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-142 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img29}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails29'} rel="bookmark" target="_blank" className="v-title">  ધાનદાતા ધીંગારામ
                                            </Link>
                                        </h4>{" "}
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            લખો આહીર નેકટેક અને નિમાધારી બની દરેક માસની પૂનમે રણુંજાતીર્થધામ જતો. ત્યાં ધૂપ-દીપ કરી ધજા ચડાવી અને રામદેવજીની સમાધિને પૂજી ધૂપભસ્મ લઈ પાછો આવતો. નિયમમાં એ અડગ હતો એટલે એ પૂનમ ભરવા ગયો. લખાની પત્ની નવેગામ હટાણે ચીજવસ્તુઓ ખરીદવા ગઈ.
                                            ધીંગાની ઉંમર સોળ વરસની થઈ. એ રામદેજીનો પરમ ઉપાસક અજપા જાપવાળો હોવાથી એને ઘીંગારામના નામથી સહુ સંબોધતા હતા. તેની માતાએ બપોરે ખાવા માટે ઘડી રાખેલો રોટલો અને દૂધ તથા દહીંનો વાટકો લઈને તેઓ ખાવા ઊઠ્યા એ વખતે...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails29'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div
                                className="column-1_2"
                            >
                                <article
                                    id="post-148"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-148 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img30}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />

                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails30'} rel="bookmark" target="_blank" className="v-title"> ધનોભગત-રામબાણવાળો </Link>

                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            સોરઠ ભોમે ‘ધોળા' ગામે, કણબી ભગત ધનજી નામે, રામદેવપીરનો ભગત કહાવે, યાત્રા ધામ રણુંજા જાવે, સોરઠના મધ્ય ભાગમાં આવેલું ‘ધોળા' નામનું ગામ. ત્યાં સરવાળિયા શાખાનો કણબી પટેલ ધનજી (ધનો) નામે હતો. વરસમાં એક વાર તે ગિરનારની યાત્રાએ જતો અને રામદેવપીરના મંદિરમાં દર્શન કરી ભજન ધૂનમાં રાત્રિ ગાળતો.
                                            ધનાનો ભક્તિભાવ જોઈ ગિરનારની મુચુકુન્દ ગુફામાં રહેતા સિદ્ધ મહાત્મા રામેતવને એને શિષ્ય કર્યો...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails30'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            {/* 31 to 39 */}
                            <div className="column-1_2">
                                <article
                                    id="post-686"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-686 post type-post status-publish format-standard has-post-thumbnail hentry category-news"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" src={img31} className="attachment-medium size-medium" style={{ height: '300px' }}
                                            alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails31'} rel="bookmark" target="_blank" className="v-title"> સિદ્ધાત્મા રામગર</Link>
                                        </h4>
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            ભાઠી હરભુજીએ ધર્મપ્રચાર કરવા મારવાડ, મેવાડ અને સોરઠ પ્રદેશમાં જુદાં જુદાં સ્થળે ફરી શ્રી રામદેવજીનાં ત્રણસો ને સાઠ દેવસ્થાન સ્થાપ્યાં. બાધા કરવા આવતાં લૂંટાયેલા દલુશેઠે પાછળથી ભેખ સ્વીકારી, માલવ પ્રદેશમાં ફરી છન્નુ સ્થળે ધર્મધજાઓ ફરકાવી. તેમના પુત્રનું નામ રામગર કે રામૈયો રાખ્યું હતું. એ રામદેવજીનો વરદાની અને કૃપાપાત્ર હતો. એ મોટો થતાં રામદેવજીની સમાધિ પાસે સેવા કરવા રહ્યો હતો. તે અખંડદીપ રાખી ત્યાં જપતપ કરતો હતો. એને સહું સિદ્ધાત્મા ‘રામગર સ્વામી' નામથી સંબોધતા...

                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails31'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-120"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-120 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img32} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />

                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails32'} rel="bookmark" className="v-title" target="_blank">   સવો ભગત</Link>
                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            સોરઠમાં ઝાંઝરકા ગામે, વણકર સવા ભગતને નામે,
                                            નિજીયાપંથી એક નિજારી, ચોહર ભજને નિમાધારી, રંગપર ગામથી થોડે દૂર ઝાંઝરકા ગામને ઝાંપે પડાળિયા ઘરમાં સવો ભગત રહેતા હતા. ઘરમાં પાણિયારા પાસે મોટા ગોખમાં રામદેવનું થાપન, ઘોડો અને ધૂપેલિયું રહેતું. પૂજાની સાધનસામગ્રીવાળા તેમના ઘરને ઘણા લોકો ‘રામાપીરનું થાનક' કહેતા...                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails32'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-144"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-144 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" src={img33} style={{ height: '300px' }}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails33'} rel="bookmark" target="_blank" className="v-title">   પૂંજીને બાધા ફળી !

                                            </Link>
                                        </h4>{" "}
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            પૂંજી રાખે ટેકને, બાધા લઈ મનમાંય,
                                            સવાતણી બાધા ફળી, પુત્રવતી એ થાય.
                                            રોજકા ગામથી રબારણો રંગપર આવતી હતી. રંગપરના સીમાડે ખેતરમાં કોશ ચાલતો જોઈ તેઓ ખેતરનું ખોડીબારું ખસેડીને કૂવા ઉપર આવી ...     </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails33'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-146"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-146 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img34} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails34'} rel="bookmark" target="_blank" className="v-title">  શ્રી રામદેવજાનું મંદિર</Link>
                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            દોહા
                                            રામગરજીના આશરે, થયો
                                            દેવદરબાર,
                                            સેવામાં બે પોળિયા, સવો અને સરદાર.
                                            વેદ વાયક જેવી નિર્મળ વાણીથી રામગરજીના આસને રંગપર અને આજુબાજુના શ્રોતાજનોની સંખ્યા વધતી ગઈ. એ બધાંની આગતા- સ્વાગતા માટે સરદારસંગ (ફક્કડનાથ), સવા ભગત અને સુરસંગજી ખડે પગે રહેતા હતા...                                      </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails34'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div
                                className="column-1_2">
                                <article
                                    id="post-142"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-142 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img35}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails35'} rel="bookmark" target="_blank" className="v-title">  ચાર શિષ્યોની પીરાઈ
                                            </Link>
                                        </h4>{" "}
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            તમારી ભક્તિ અને સેવાવૃત્તિ જોઈ હું ખુશ થયો છું. જે ઇચ્છા હોય તે માગો !'
                                            “બાપુ ! સ્વામી ! ગુરુદેવ ! મારે ધનદોલત, જમીન, જાગીર, પુત્ર પરિવાર તો છે. મારું જીવન પૂરું થતાં પહેલાં રામદેવજી મહારાજનાં દર્શનની ઝાંખી થાય એવી મારી ઇચ્છા છે...  </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails35'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div
                                className="column-1_2"
                            >
                                <article
                                    id="post-148"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-148 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img36}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />

                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails36'} rel="bookmark" target="_blank" className="v-title"> ફક્કડાનાથ </Link>

                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            મેવાડમાં ચિત્તોડગઢના શીશોદિયા કુળનાં સૈન્ય દિલ્હીના અકબર બાદશાહના સૈન્ય સાથે ઘણી વખત ટકરાતાં હતાં. રાણો કુંભો અને રાણા પ્રતાપે ચિત્તોડ છોડવું પડ્યું, છતાં દુશ્મનાવટની વેલી પાંગરતી ગઈ.
                                            એ શાખાના ઘણા ‘ઘરઘોડિયા' જમીનદાર ક્ષત્રીઓ જુદાં જુદાં કામે વળ્યા...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails36'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-686"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-686 post type-post status-publish format-standard has-post-thumbnail hentry category-news"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" src={img37} className="attachment-medium size-medium" style={{ height: '300px' }}
                                            alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails37'} rel="bookmark" target="_blank" className="v-title">   મહાવીર ફક્કડાનાથ !</Link>
                                        </h4>
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            ગુરુ રામગર મૂઠિયા, માથે મૂક્યો હાથ, સેવામાં હનુમાન તું, જય હો ! ફક્કડાનાથ !
                                            સવા, ગીગા, વસતા એ ત્રણે શિષ્યોને રામગરજીએ પીરાઈ-સિદ્ધિ આપી ધર્મધજાધારી કર્યા. એમના પટશિષ્ય ફક્કડાનાથે મંદિર તૈયાર કરવામાં રાતદિવસ ઉજાગરા વેઠી સખત પરિશ્રમ કર્યો...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails37'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-120"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-120 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" style={{ height: '300px' }}
                                            src={img38} className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />

                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails38'} rel="bookmark" className="v-title" target="_blank">  વેણ ફગો વિપ્ર
                                            </Link>
                                        </h4>{" "}

                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            સોરઠ ભોમે શિહોર ગામે, ગોર વિષ્ઠ આણંદજી નામે, ક્કડાએ દીધાં વરદાના, તુજ ઘર સાત થશે સંતાના.
                                            ફક્કડાનાથના સેવક શિહોર ગામના રાજપુરોહિત આણંદજીની સેવાભક્તિ જાણીતી હતી. તે ફક્કડાનાથની આજ્ઞા પ્રમાણે વર્તતો હતો અને રાજદરબારમાં પણ તે ફક્કડાનાથને પણ ઝમરાળાનો જાગતો દેવ કહી પ્રસંશા કરતો હતો...                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails38'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                            <div className="column-1_2">
                                <article
                                    id="post-144"
                                    className="post_item post_format_standard post_layout_classic post_layout_classic_2 post-144 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-mantra tag-practice tag-yoga"
                                >
                                    <div className="post_featured with_thumb hover_icon">
                                        <img width="300" height="169" src={img39} style={{ height: '300px' }}
                                            className="attachment-medium size-medium" alt="" decoding="async" loading="lazy"
                                            sizes="(max-width: 300px) 100vw, 300px" />
                                        <div className="mask" />
                                    </div>{" "}
                                    <div className="post_header entry-header">
                                        <h4 className="post_title entry-title">
                                            <Link to={'/BlogDetails39'} rel="bookmark" target="_blank" className="v-title"> ભક્ત દાહા ખાચરને જીવતદાન
                                            </Link>
                                        </h4>{" "}
                                    </div>
                                    <div className="post_content entry-content">
                                        <div className="post_content_inner">
                                            કાઠી ભકત નસીદપુર ગામે, ‘આપા દાહા ખાચર' નામે, ફક્કડાનાથનો શિષ્ય કહાવે, રામદે'પીરની ધૂન લગાવે.
                                            નસીદપુર નામના ગામમાં ખાચર, ખુમાણા, ખવડવાળા, જળું એવી શાખાના કાઠી રાજપૂતો, બ્રાહ્મણ, વાણિયા, કણબી વગેરે રહેતા હતા. ગામ સંપીલું અને સુખસંપત્તિવાળું ગણાતું હતું...
                                        </div>{" "}
                                    </div>
                                    <div className="sc_services_item_button sc_item_button more_info">
                                        <a href='/BlogDetails39'
                                            className="sc_button sc_button_default sc_button_size_small"
                                        >
                                            More Info
                                        </a>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix" />
                </div>
            </div>


            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>


        </>
    )
}

export default BlogMain;   