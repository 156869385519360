import { Link } from "react-router-dom";
import Footer from "../Footer";
import Header from "../Header";
import { Helmet } from "react-helmet";
import "./Gallery.css";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FcLikePlaceholder } from "react-icons/fc";
// import instagram1 from "../../assets/wp-content/uploads/2021/05/post777-copyright-370x370.jpg";
// import instagram2 from "../../assets/wp-content/uploads/2021/05/post666-copyright-370x370.jpg";
// import instagram3 from "../../assets/wp-content/uploads/2021/05/post555-copyright-370x370.jpg";
// import instagram4 from "../../assets/wp-content/uploads/2021/05/post444-copyright-370x370.jpg";
// import instagram5 from "../../assets/wp-content/uploads/2021/05/post333-copyright-370x370.jpg";
// import instagram6 from "../../assets/wp-content/uploads/2021/05/post111-copyright-370x370.jpg";
import React from "react";
import AudioPlayer from "../Audio/AudioPlayer";
import Slider from "../Slider/Slider";

const Gallery2 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery1.jpg";
  const img2 = "/assets/wp-content/uploads/2018/12/ramdev-gallery9.jpg";
  const img3 = "/assets/wp-content/uploads/2018/12/ramdev-gallery3.jpg";
  const img4 = "/assets/wp-content/uploads/2018/12/ramdev-gallery13.jpg";
  const img5 = "/assets/wp-content/uploads/2018/12/ramdev-gallery14.jpg";
  const img6 = "/assets/wp-content/uploads/2018/12/ramdev-gallery21.jpg";
  const img7 = "/assets/wp-content/uploads/2018/12/ramdev-gallery20.jpg";
  const img8 = "/assets/wp-content/uploads/2018/12/ramdev-gallery22.jpg";
  const img9 = "/assets/wp-content/uploads/2018/12/ramdev-gallery4.jpg";
  const img10 = "/assets/wp-content/uploads/2018/12/ramdev-gallery2.jpg";

  //image 11 to 20
  const img11 = "/assets/wp-content/uploads/2018/12/ramdev-gallery5.jpg";
  const img12 = "/assets/wp-content/uploads/2018/12/ramdev-gallery6.jpg";
  const img13 = "/assets/wp-content/uploads/2018/12/ramdev-gallery7.jpg";
  const img14 = "/assets/wp-content/uploads/2018/12/ramdev-gallery8.jpg";
  const img15 = "/assets/wp-content/uploads/2018/12/ramdev-gallery10.jpg";
  const img16 = "/assets/wp-content/uploads/2018/12/ramdevpir6.jpeg";
  const img17 = "/assets/wp-content/uploads/2018/12/ramdev-gallery12.jpg";
  const img18 = "/assets/wp-content/uploads/2018/12/ramdev-gallery15.jpg";
  const img19 = "/assets/wp-content/uploads/2018/12/ramdev-gallery16.jpg";
  const img20 = "/assets/wp-content/uploads/2018/12/ramdev-gallery17.jpg";


  //image 21 to 30
  const img21 = "/assets/wp-content/uploads/2018/12/ramdev-gallery18.jpg";
  const img22 = "/assets/wp-content/uploads/2018/12/ramdev-gallery19.jpg";
  const img23 = "/assets/wp-content/uploads/2018/12/ramdev-gallery23.jpg";
  const img24 = "/assets/wp-content/uploads/2018/12/ramdev-gallery24.jpg";
  const img25 = "/assets/wp-content/uploads/2018/12/ramdev-gallery25.jpg";
  const img26 = "/assets/wp-content/uploads/2018/12/ramdev-gallery26.jpg";
  const img27 = "/assets/wp-content/uploads/2018/12/ramdev-gallery27.jpg";
  const img28 = "/assets/wp-content/uploads/2018/12/ramdev-gallery28.jpg";
  const img29 = "/assets/wp-content/uploads/2018/12/ramdev-gallery29.jpg";
  const img30 = "/assets/wp-content/uploads/2018/12/ramdev-gallery30.jpg";


  //image 31 to 40
  const img31 = "/assets/wp-content/uploads/2018/12/ramdev-gallery31.jpg";
  const img32 = "/assets/wp-content/uploads/2018/12/ramdev-gallery32.jpg";
  const img33 = "/assets/wp-content/uploads/2018/12/ramdev-gallery33.jpg";
  const img34 = "/assets/wp-content/uploads/2018/12/ramdev-gallery34.jpg";
  const img35 = "/assets/wp-content/uploads/2018/12/ramdev-gallery35.jpg";
  const img36 = "/assets/wp-content/uploads/2018/12/ramdev-gallery36.jpg";
  const img37 = "/assets/wp-content/uploads/2018/12/ramdev-gallery37.jpg";
  const img38 = "/assets/wp-content/uploads/2018/12/ramdev-gallery38.jpg";
  const img39 = "/assets/wp-content/uploads/2018/12/ramdev-gallery39.jpg.webp";
  const img40 = "/assets/wp-content/uploads/2018/12/ramdev-gallery40.jpg.webp";


  //image 41 to 50
  const img41 = "/assets/wp-content/uploads/2018/12/ramdev-gallery41.jpg.webp";
  const img42 = "/assets/wp-content/uploads/2018/12/ramdev-gallery42.jpg";
  const img43 = "/assets/wp-content/uploads/2018/12/ramdev-gallery43.jpg";
  const img44 = "/assets/wp-content/uploads/2018/12/ramdev-gallery44.jpg";
  const img45 = "/assets/wp-content/uploads/2018/12/ramdev-gallery45.jpg";
  const img46 = "/assets/wp-content/uploads/2018/12/ramdev-gallery46.jpg";
  const img47 = "/assets/wp-content/uploads/2018/12/ramdevpir1.jpeg";
  const img48 = "/assets/wp-content/uploads/2018/12/ramdevpir2.jpeg";
  const img49 = "/assets/wp-content/uploads/2018/12/ramdevpir3.jpeg";
  const img50 = "/assets/wp-content/uploads/2018/12/ramdevpir5.jpeg";

  const itemData = [
    {
      img: img1,
      title: "Bed",
      height: 465,
    },
    {
      img: img2,
      title: "Books",
      height: 275,
    },
    {
      img: img3,
      title: "Sink",
      height: 353,
    },
    {
      img: img4,
      title: "Kitchen",
      height: 295,
    },
    {
      img: img5,
      title: "Blinds",
      height: 268,
    },
    {
      img: img6,
      title: "Chairs",
      height: 268,
    },
    {
      img: img7,
      title: "Laptop",
      height: 310,
    },
    {
      img: img8,
      title: "Doors",
      height: 390,
    },
    {
      img: img9,
      title: "Coffee",
      height: 275,
    },
    {
      img: img10,
      title: "Storage",
      height: 525,
    },
    {
      img: img11,
      title: "image11",
      height: 460,
    },
    {
      img: img12,
      title: "image12",
      height: 445,
    },
    {
      img: img13,
      title: "image13",
      height: 400,
    },
    {
      img: img14,
      title: "image14",
      height: 350,
    },
    {
      img: img15,
      title: "image15",
      height: 350,
    },
    {
      img: img16,
      title: "image16",
      height: 365,
    },
    {
      img: img17,
      title: "image17",
      height: 400,
    },
    {
      img: img18,
      title: "image18",
      height: 530,
    },
    {
      img: img19,
      title: "image19",
      height: 425,

    },
    {
      img: img20,
      title: "image20",
      height: 525,
    },
    {
      img: img21,
      title: "image21",
      height: 460,
    },
    {
      img: img22,
      title: "image22",
      height: 450,
    },
    {
      img: img23,
      title: "image23",
      height: 500,
    },
    {
      img: img24,
      title: "image24",
      height: 500,
    },
    {
      img: img25,
      title: "image25",
      height: 550,
    },
    {
      img: img26,
      title: "image26",
      height: 460,
    },
    {
      img: img27,
      title: "image27",
      height: 650,
    },
    {
      img: img28,
      title: "image28",
      height: 550,
    },
    {
      img: img29,
      title: "image29",
      height: 275,
    },
    {
      img: img30,
      title: "image30",
      height: 525,
    },
    {
      img: img31,
      title: "image31",
      height: 550,
    },
    {
      img: img32,
      title: "image32",
      height: 340,
    },
    {
      img: img33,
      title: "image33",
      height: 353,
    },
    // {
    //   img: img34,
    //   title: "image34",
    //   height: 268,
    // },
    {
      img: img35,
      title: "image35",
      height: 513,
    },
    {
      img: img36,
      title: "image36",
      height: 600,
    },
    {
      img: img37,
      title: "image37",
      height: 268,
    },
    {
      img: img38,
      title: "image38",
      height: 210,
    },
    {
      img: img39,
      title: "image39",
      height: 275,
    },
    {
      img: img40,
      title: "image40",
      height: 525,
    },
    {
      img: img41,
      title: "image41",
      height: 460,
    },
    {
      img: img42,
      title: "image42",
      height: 275,
    },
    {
      img: img43,
      title: "image43",
      height: 450,
    },
    {
      img: img44,
      title: "image44",
      height: 268,
    },
    {
      img: img45,
      title: "image45",
      height: 450,
    },
    {
      img: img46,
      title: "image46",
      height: 450,
    },
    {
      img: img47,
      title: "image47",
      height: 268,
    },
    {
      img: img48,
      title: "image48",
      height: 500,
    },
    {
      img: img49,
      title: "image49",
      height: 350,
    },
    {
      img: img50,
      title: "image50",
      height: 520,
    }
  ];
  return (
    <>
      <Helmet>
        <title>Ramapir | Gallery </title>
        <meta name="keywords" content="આ ગામમાં તંવર રાજપૂત અને સંત બાબા રામદેવજીએ 1459 એડીમાં 33 વર્ષની ઉંમરે સમાધિ લીધી હતી. તે એક રાજસ્થાની રાજા હતો જેણે 14મી સીમાં પોખરણ પર શાસન કર્યું હતું. તેને ભગવાન કૃષ્ણનો અવતાર માનવામાં આવતો હતો. હિન્દુ, મુસ્લિમ, જૈન અને શીખ તેમના અનુયાયીઓ છે. ગામનું નામ બાબા સંતના નામ પરથી રાખવામાં આવ્યું છે. 1931માં બિકાનેરના રાજા ગંગા સિંહ દ્વારા સમાધિની આસપાસ એક મંદિર બનાવવામાં આવ્યું હતું.
                        બાબા રામદેવ પાસે કેટલીક જાદુઈ, હોશિયાર શક્તિઓ છે જેણે તેમની ખ્યાતિ દૂર દૂર સુધી ફેલાવી છે." />
      </Helmet>

      <Header />
      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id="46"
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="52ebb12"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          style={{ height: "30rem", marginBottom: "5rem" }}
        >
          <div className="elementor-background-overlay"></div>
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
              data-id="ae9d67e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fcf91b3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                      data-id="93dfaf4"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                          data-id="847c2c5"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                          style={{ height: "8rem" }}
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                          data-id="8fe49e3"
                          data-element_type="widget"
                          data-widget_type="trx_sc_layouts_title.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              id="trx_sc_layouts_title_515615674"
                              className="sc_layouts_title sc_align_center with_content without_image without_tint"
                            >
                              <div className="sc_layouts_title_content">
                                <div className="sc_layouts_title_title">
                                  {" "}
                                 
                                </div>
                                <div className="sc_layouts_title_breadcrumbs">
                                  <div className="breadcrumbs">
                                   

                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div>
        <h5 className="Gallery2-title">Gallery</h5>
      </div>
      <div className="container">
        <ImageList variant="masonry" cols={3} gap={8} className="img-section">
          {itemData.map((item) => (
            <ImageListItem key={item.img} className="ImageListItem">
              <a href={`${item.img}`} data-lightbox="image-1" data-title={item.title} >
                <img
                  className="Image"
                  srcSet={`${item.img}`}
                  src={`${item.img}`}
                  style={{ height: item.height, width: item.width }}
                  alt={item.title}
                  loading="lazy"
                />
                <div className="middle">
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="icon" />
                </div>
              </a>
            </ImageListItem>
          ))}
        </ImageList>
      </div>

      {/* <h2 className="instagram-heading">Instagram</h2>
            <p className="followus-heading">
                                FOLLOW US
                              </p>{" "}
                              <br />
      <div className="instagram-section">
        <div id="image-container">
          <img src={instagram1} width="800" height="496" alt="" />
          <img src={instagram2} width="800" height="496" alt="" />
          <img src={instagram3} width="800" height="496" alt="" />
          <img src={instagram4} width="800" height="496" alt="" />
          <img src={instagram5} width="800" height="496" alt="" />
          <img src={instagram6} width="800" height="496" alt="" />
        </div>
      </div> */}

     

      <div>
        <Footer />
        {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay }) } /> */}
      </div>
    </>
  );
};

export default Gallery2;