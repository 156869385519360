import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails35 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery37.jpg";
  return (
    <>
      {/* <div className="body_wrap">
        <div className="page_wrap"> */}

<meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
      <Header />
      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id="46"
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="52ebb12"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          style={{ height: "30rem", marginBottom: "5rem" }}
        >
          <div className="elementor-background-overlay"></div>
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
              data-id="ae9d67e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fcf91b3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                      data-id="93dfaf4"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                          data-id="847c2c5"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                          style={{ height: "8rem" }}
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                          data-id="8fe49e3"
                          data-element_type="widget"
                          data-widget_type="trx_sc_layouts_title.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              id="trx_sc_layouts_title_515615674"
                              className="sc_layouts_title sc_align_center with_content without_image without_tint"
                            >
                              <div className="sc_layouts_title_content">
                                <div className="sc_layouts_title_title">
                                  {" "}
                                 
                                </div>
                                <div className="sc_layouts_title_breadcrumbs">
                                  <div className="breadcrumbs">
                                   
                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



      <div className="page_content_wrap blog-details">
        <div className="content_wrap" >
          <div className="content">
            <div id="container" className="give-wrap container">
              <div id="content" role="main">
                <div
                  id="give-form-288-content"
                  className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                >
                  <div
                    id="give-sidebar-left"
                    className="give-sidebar give-single-form-sidebar-left"
                  >
                    <div className="images">
                      <img
                        width="1024"
                        height="576"
                        src={img1}
                        className="attachment-large size-large wp-post-image blog-img"
                        alt=""
                        decoding="async"
                        sizes="(max-width: 1024px) 100vw, 1024px"
                      />
                    </div>
                  </div>
                  <div className="summary entry-summary">
                    <h2 itemProp="name" className="give-form-title entry-title">
                      ચાર શિષ્યોની પીરાઈ

                    </h2>{" "}
                    <div
                      id="give-form-288-wrap"
                      className="give-form-wrap give-display-onpage"
                    >
                      <div className="give-goal-progress">
                        <div
                          className="raised blog-title"
                         
                        >
                          <span className="income">ચાર શિષ્યોની પીરાઈ


                          </span>
                        </div>
                      </div>

                      <div
                        id="give-form-content-288"
                        className="give-form-content-wrap give_pre_form-content"
                      >
                        <p className="paragraph-content" >
                          દોહા
                          ગુરુએ ચારે શિષ્યોને, અપ્યું પીરાઈ દાન, ધાર્યા. પરચા પૂરતા, ફરકે ધર્મ નિશાન.
                          રંગપરના મંદિરની વ્યવસ્થા થઈ ગઈ હોવાથી રામગરજીએ શિષ્યોને સિદ્ધિ
                          આપવા કસોટી કરવા માંડી,
                          સુરસંગજીને ઝોળીમાંથી ભસ્મ આપી કહ્યું કે, ‘સુરદેવ ! લો, વિભૂતિ ! તમારી ભક્તિ અને સેવાવૃત્તિ જોઈ હું ખુશ થયો છું. જે ઇચ્છા હોય તે માગો !'
                          “બાપુ ! સ્વામી ! ગુરુદેવ ! મારે ધનદોલત, જમીન, જાગીર, પુત્ર પરિવાર તો છે. મારું જીવન પૂરું થતાં પહેલાં રામદેવજી મહારાજનાં દર્શનની ઝાંખી થાય એવી મારી ઇચ્છા છે.’
                          સુરસંગજીને રામદેવજીનાં દિવ્યદર્શનની ઝાંખી કરાવી ગુરુજીએ તેમની ઇચ્છા પૂરી કરી. સવો ભગત વેજુ વાણી તૈયાર કરી વેજાનાં વહેપારીને આપવા ઝાંઝરકાથી નીકળ્યા. ડગલે ડગલે શ્વાસે શ્વાસે ‘રામાપીર'નું રટણ કરતા જાય છે. એમને રંગપરના સીમાડે બે સાધુ મળ્યા. એમણે ઊંચો હાથ કરી ભગતને પાસે બોલાવ્યા બે અને કહ્યું : ‘ભગત ! તમારા વેજામાંથી લંગોટ જેટલું વસ્ત્ર આપશો ?' ભગત ઘડી વાર મૂંઝવણમાં પડી ગયા. પછી બાર ગજના બધા તાકા છે એમાંથી ફાડી આપું અને વેપારીને પૈસા કાપી દઉં. આમ વિચાર કરી પોટલું નીચે ઉતાર્યું અને એક બાર ગજના વેજામાંથી અર્ધું કાપી આપ્યું.
                          વેપારીને માલ આપતાં ભગતે કહ્યું કે, 'શેઠ ! તાકામાંથી છ ગજ વેજું મેં લીધું છે. ભાવ હોય તે ગણીને મારી મજૂરીમાંથી પૈસા કાપી લો.’ વેપારીએ છએ તાકા બે-ત્રણ વખત માપી જોયા, બધા તાકા બાર બાર ગજના જ હતા.
                          દોષો
                          વેજા રેજા બાર ગજ, નહીં માપમાં કાપ,
                          પરથમ પરચો પૂરીયો, પીરાઈનો પ્રતાપ.

                        </p>
                        <p className="paragraph-content">
                          'ભગત ! જૂઠું બોલ્યા ને ? કયા તાકામાંથી વૈજું ફાડયું છે ? બધા તોકો બાર બાર ગજના છે.' સવો ભગત વિચારમાં પડી ગયા. વિચાર કરતા ગુરુનાં દર્શને આવી બેઠા પણ એનું મનોમંથન ચાલતુ હોવાથી ગુરુ એમને જોઈને હસી પડ્યા.
                          ‘સવા ! કામ પતાવીને આવ્યા ?' ગુરુએ પૂછ્યું.
                          ભગતે તાકાની વાત કરીને કહ્યું કે, સ્વામી મેં છ ગજ વેજો કાપેલો છતાં બરાબર નીકળ્યું.'
                          ‘સવા ! તારી વાત સાચી છે. લે ! એ કાપેલી છ ગજ વેજાનો ટુકડો ! આ વેજાના ટુકડાની ત્રણ ધજા કરજો. એક ઝાંઝરકા, એક વાસણા અને એક લાખિયાણીના ધર્મસ્થાને ફરકાવજો.'
                          દોહા
                          મધરાતે હોકારતો, ભિક્ષુક ગીગા દ્વાર,
                          ભૂખ્યો છું બે દિનનો, કરતો એ ઉચ્ચાર.
                          ‘ભગત ! બાપુ ! ઉઘાડો,' એમ મોટા અવાજે બોલી કમાડની સાંકળ ખખડાવતો એક ચીંથરવીંધર કપડાંવાળો માણસ ગીગા ભગતના દ્વારે આવી ઊભો. ભગતે અવાજ સાંભળી ખડકીનું બારણું ઉઘાડવું.
                          ‘બાપા ! બે દિવસથી ખાધું નથી, ભૂખ્યો છું.'
                          ‘આવો ! બેસો ! ભગત.' પાણીનો કળશિયો ભરીને મૂક્યો. રસોડામાં ગયા અને કોઠામાંથી રોટલો લીધો અને આવીને બોલ્યા
                          ‘ભાઈ, દૂધ જવારી વાળ્યું છે.'
                          ‘બાપુ ! છાશ તો છે ને ?!
                          ‘તમે અતિથિ ! મારે આંગણે આવ્યા, રોટલા ભેગી છાશ આપું તો મારો ટેક નિર્ધાર ડગી જાય. છાશનું દોણું ભર્યું છે પણ રોટલા ભેગું શાક હોત તો છાશ તમને આપત બાપ !' ભગતે કહ્યું.
                          બાપુ ! પાણી ભરી વાટકો કે છાલિયું આપો તો પાણી અને રોટલો ખાઈ લઈશ.’ ભિક્ષુકના કહેવા મુજબ ભગતે મોટો વાટકો આપ્યો અને પાણી આપ્યું. અતિથિએ એના પાણીવાળા વાટકામાં રોટલાનું બટકું કરી અંદર બોલ્યું અને હસી
                          પડ્યા !
                          એમના હસવાથી ઓશરીની ડેલીમાં સૂતેલાં બધાં જાગી ગયાં ! ‘ભગત 1 જૂઠું


                        </p>
                        <p className="paragraph-content">
                          બોલ્યા ને ? આ પાણી ક્યાં છે ? આ તો ખીર છે. મારી પરીક્ષા કરી ને ?' ભિક્ષુકે ભગતને કહ્યું. એ સાંભળી ભગતનું ધ્યાન વાડકે દોરાયું, સાચે જ વાડકામાં ખી હતી. નીરમાંથી ખીર કેમ થઈ ! એની ગડમથલમાં એ વખતે તેઓ ગુરુ પાસે જવા નીકળ્યા. માર્ગ ભૂલ્યા અને ઊભા રહ્યા; અને ત્યાંથી માર્ગ ભૂલ્યા છે, એવું ભાન થયું ! એ વખતે એક વટેમાર્ગુ ઘોડેસ્વાર ત્યાંથી નીકળ્યો અને ભગતને રંગપરન
                          માર્ગે ચડાવ્યા.
                          ભગતે ગુરુને સવિસ્તાર વાત કરી. એ વખતે રામગરજીએ એટલું જ કહ્યું કે, ‘ગીગા ! આ તો તારી ભક્તિની કમાઈ છે.’
                          પૂનમની રાતનું જમા જાગરણ, પાટપૂજા હોવાથી ગીગો ભગત મશાલ પ્રગટાવી ઊભા હતા. એ વખતે રાત્રે ત્રણ વાગે મશાલની જ્યોત ઝાંખી પડી. ગુરુએ ગીગાને કહ્યું : ‘ગીગા ! મશાલની જ્યોતનો પ્રકાશ વધારવા તેલ પૂર !'
                          ‘બાપુ ! કુલડીમાં તેલ નથી. હું એની જ વિટંબણામાં પડ્યો છું.' ગીગાએ
                          જણાવ્યું.
                          ‘તો જા, કુડલી લઈ તલાવડીમાં, પાણી ભરી આવ પણ મશાલને ઝળકતી રાખ !' ગુરુએ કહ્યું.
                          ગીગાએ કુલડીમાં તલાવડીનું પાણી ભર્યું ને મશાલમાં પૂર્યું તો તેલ થઈ ગયું ! આખી રાત મશાલની જ્યોત ઝળહળતી રહી. સવારે ગીગાને ધજા આપી, ભસ્મ તિલક કર્યું અને લાખિયાણીમાં રામદેવજીનું દર્શનધામ સ્થાપી ધજા ફરકાવવા આજ્ઞા કરી.
                          દોહા
                          ગીગે દીધો રોટલો, ધર્યું વાડકો નીર, પરચે પૂર્યો ગીગવે, નીર થઈ ગઈ ખીર. ભરી નીરથી કુંડલી, તરત તેલ થઈ જાય, મશાલ ઝળહળતી થઈ, પરચો દીઠો ત્યાંય.
                          ગીગા ભગતે લાખિયાણીમાં જઈ સવરા મંડપ પાટપૂજા કરી ધર્મધજા ફરકાવી. વાસણામાં વહતા ભગતે ધર્મ ધજા ફરકાવી એમના કાકાની અંતિમક્રિયામાં અગિયારમું- બારમું કરવાનો રિવાજ હોવાથી ભગત મૂંઝવણમાં મુકાયા. પાસે બે તાંબા- પિત્તળનાં વાસણ પણ નો'તાં રહ્યાં. વાસણા અને રંગપર રાતદિ’ ભજનમાં

                        </p>
                        <p className="paragraph-content">
                          રહેવાથી તેઓ મિલકત વસાવી શક્યા ન હતી.
                          ભગતે ગુરુને વાત કરી. ગુરુકૃપાથી એમને અણધારી રીતે નાત જમાડવા માટે બધી વસ્તુઓ ભરીને એક ગાડાવાળી મૂકી ગયો.
                          ભગતે સુખડીનું પકવાન કરી નાત જમાડી, સંત, સાધુ, અતિથિ, સગાંવહાલાં, ભક્તો અને બ્રાહ્મણોને જમાડયાં. કુટુંબીઓ અને બહેન- દીકરાઓને લોટામાં સુખડી ભરી લહાણું કરવા માટે ઘોલેરાથી એક ગાડીવાળો લોટાનો કોથળો ભરી મૂકી ગયો હતો. ભગતે એ લોટાઓમાં સુખડી તાસ ભરી લહાણામાં વહેંચી. પછી તેઓ ગુરુ પાસે આવ્યા અને સવિસ્તાર વાત કરી. એ વખતે ભગતને ભસ્મનો ચાંદલો કરી રોટી આપવાનો નિયમ પાળવાની આજ્ઞા કરી.
                          વહતા ભગત ગુરુ આશા-આદેશ લઈ વાસણા આવ્યા. વાસણા રામદેવજીનું દર્શનધામ બન્યું.
                          સોરઠો
                          એ... ઝાંઝરકાને ઝાંપલે, રામાપીરનું સ્થાન, અતિથિને દઈ માન, સવો ખવરાવે સુખડી.
                          દોહા
                          એ...સવગણ કેરી ધરમધજા, રામદેવપીરનું ધામ, દુ:ખ ટળે બાધા ફળે, અને ધાર્યાં થાયે કામ. એ...ગીગે ભક્તિ આદરી, લાખિયાણીની માંય, ગુરુકૃપાએ પીરાઈ મળી, નીરમાંથી ખીર થાય. એ...જલતી જ્યોત મશાલની, તેલ વિના ઝંખાય ભરી કુલડી જળ તણી, નીર તેલ થઈ જાય. એ... વસતાને ભક્તિ ફળી, વાસ વાસણા ગામ, નજો ફરકાવી કર્યું, રામદેપીરનું ધામ. એ... અફર વચન વસતા તણું, સફળ થાય વરદાન, અતિથિને દે ટુકડો, અને દેતો આદર માન. એ... માનવ સેવા કારણે, ત્રણે ભક્ત સુધીર, બિરદાવલી, જય જય રામાપીર.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
      {/* </div>
      </div> */}
      <div>
        <Footer />
        {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
      </div>
    </>
  );
};

export default BlogDetails35;
