import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails26 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery28.jpg";
    return (
        <>
         <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}


            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                   
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                         
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            હરભુજીનાં મનોમંથન

                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                    
                                                >
                                                    <span className="income"> હરભુજીનાં મનોમંથન

                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content" >
                                                    ચોપાઈ
                                                    પરખી શક્યા નહીં હરભુજી, લીલા વિવિધ રંગ વિભૂતી, ભેદ મર્મ કાંઈ નવ સમજાતા, આકુળ વ્યાકુળ મનમાં થાતા.
                                                    પોતે રામદેવજીને મળ્યા એનાં પ્રમાણરૂપે ચારે વસ્તુઓ સાથે લાવ્યા હતા. શોકાગ્ર સગાંઓને એ વસ્તુઓ આપી, સર્વે આશ્ચર્યમુગ્ધ બન્યા અને સમાધિમાં પધરાવેલી વસ્તુઓ બહાર કેવી રીતે આવી એના ભરમમાં પડી ગયા.
                                                    હરભુજીનાં અને રામદેવજીના મિલનનો મર્મ સમજી કોઈએ ઊંડો વિચાર કર્યો નહીં. અને કદાચ રામદેવજી સમાધિમાંથી બહાર પધાર્યા હોય તો જ આ ચાર વસ્તુઓ એમને મળી હોય. એમ ધારીને સમાધિ ખોદીને નક્કી કરવા સહુએ સમાધિ ખોદવાની શરૂ કરી, ત્યાં તો એક ગેબી અવાજ સંભળાયો -
                                                    ચોપાઈ
                                                    પરખો નવ મરજાદા મારી, શબ્દ નાદ સંભળાયો ભારી,
                                                    ત્રણ પેઢીએ પીર પ્રગટશે, સુંવરા ડગી ગયા વિશ્વાસે.
                                                    ‘હૈ તુંવરાઓ ! સમાધિનું ઢાંકણ ખોદી મારાં વચનોની પરીક્ષા કરો નહીં. સમાધિ વખતે તમને વચન આપ્યું હતું કે તુંવરા કુળમાં ત્રણ ત્રણ પેઢીએ પીર પ્રગટ થશે. એ વચનમાં વિશ્વાસ ન રહ્યો ? તમે ત્રણ જ દિવસમાં ડગી ગયા ? હવે તમે જોષી, પંડિત, યાચક બની જીવન ગુજારો, આ વાત બધાં સગાંવહાલાંએ હરભુજીને કરી. તેથી હરભુજીને ચિંતા થવા લાગી કે, રામદેવજીની મર્યાદા લોપવા બદલ પોતે અપરાધી બન્યા છે. સર્વ સગાંઓમાં પોતે ભોંઠા પડ્યા છે તેથી તે શરમિંદા બની રણુંજાથી પોતાને ગામ ખેરકર આવવા ચાલી નીકળ્યા.
                                                    વ્યાકુળ દશાથી ઘેરાયેલા હરભુજીની બુદ્ધિ રૂંધાઈ ગઈ. તેઓ જેમ જેમ માર્ગ વટાવતા જાય છે, તેમ તેમના મનમાં વિચારોનાં મોજાં પુરની માફક ઊછળવા લાગ્યાં. તેઓ મનોમંથનમાં પડ્યા. એક વાર મિથિલાનગરમાં-

                                                </p>
                                                <p className="paragraph-content">
                                                    જનક સ્વપ્ન દૃશ્ય ઘેરાયા, ઉભય કારણો સત્ય જણાયાં, દૃશ્ય મિલન મુજ નજરે તરતું, તોયે સત્ય એ અસત્ય ઠરતું.
                                                    તેડાવ્યા. જ્ઞાનચર્ચામાં સ્વપ્ન સ્થિતિ અને જાગૃત સ્થિતિ બંને સત્ય ઠર્યો હતો. પરંતુ રાજા જનકને સ્વપ્ન આવ્યું હતું, એનો ભેદ અને મર્મ સમજવા અષ્ટાવક્ર મુનિને મેં જે દૃશ્ય મિલન જોયું એ સ્વપ્ન ન હતું. હજી મારી નજરે છે. છતાં પણ તે અન્ય અસત્યમાં ફેરવાઈ ગયું ? મારો પરભવ થયો ? હું જૂઠો ઠર્યો ? સ્વભાવનો પ
                                                    માથે આવી પડચો.
                                                    ચોપાઈ
                                                    હરભુજી ઘડી ઘડી વિચારે, શરમ અને ભોંઠાપણ ભારે, પૂછે સગાં પ્રજા નરનારી, કહું કેમ વિગત વિસ્તારી ? શરમ અને ભોંઠાપણથી અકળાતા હરભુજી ઘડી ઘડી વિચારતા હતા કે ઘેર જઈશ કે તરત સગાંસંબંધી પ્રજાનાં નરનારીઓ વાત સાંભળવા ભેગા થશે. એમને વાત વિગત વિસ્તારપૂર્વક હું શી રીતે કહીશ ! હું પોતે જ નિમિત્ત બન્યો છું. મેં પોતે જ બધાને દોર્યા અને સત્ય હતું તે અસત્ય ઠર્યું, આ વાત શી રીતે સમજાવીશ. આવો વિચાર કરતા તેઓ એક જ ધૂનમાં ચાલ્યા જાય છે, જ્યા રામદેવજીનું મિલન થયું હતું, એ સ્થળે ભોજા તળાવ પાસે તેઓ આવી પહોંચ્યા.
                                                    દોહા
                                                    ન
                                                    મળ્યા હતા જે સ્થાન પર, વીર રામદેવપીર,
                                                    હરભુજી ત્યાં આવિયા, ભોજા તળાવ તીર.
                                                    જ્યાં બંને ભાઈઓ મળ્યા હતા એ ભોજા તળાવને તીરે આવતાં હરભુજીના હૃદયમાં દિવ્ય પ્રકાશ થયો. તેઓ અચેતન હતા તે ચેતનમાં આવ્યા અને રામદેવજી બેઠા હતા એ સ્થળેથી ચરણરજની મૂઠી ભરી ત્યાં ને ત્યાં જ બેસી ગયા.
                                                    દોહા
                                                    ચર્ણરેણું ધરી શીર પર, ત્યાગે પાણી અન્ન, કાં તો દેહ પડી જશે, પ્રભુ થશે પ્રસન્ન. ત્યાળુ પાણી અન્નને, અર્ધું હવે શરીર, શરણે લો સમરથ ઘણી, પરચાધારી પીર. મહારાજ ! પ્રભુ ! હું આપને ઓળખી શક્યો નહીં. દ્વારકાનાથ ! મારાથી આપ

                                                </p>
                                                <p className="paragraph-content">
                                                    પડદે રમ્યા દેવ ! હવે હું મારું કાળું મુખ કોને બતાવું? ક્યાં જાઉં ? જઇને શું કરું ? હવે તો આપ બિરાજ્યા હતા, એ ચરણની રજ માથે ચડાવી અપરાથી અને પાપી શરીરને પાવન કરી, અન્ન-પાણીનો ત્યાગ કરી, મારું શરીર આપને થશે ધરું છું. આપ સમરથ ધણી છો ! નાથ ! હે પરચાધારી ! મને શરણે લેજો.... આમ દૃઢ નિશ્ચય કરી તે જ ઘડીથી તેમણે અન્ન જળનો ત્યાગ કરી દીધો, ઉપવાસને આજે સાતમો દિવસ હતો.
                                                    સાત દિવસના ઉપવાસથી આંખો ઊંડે ઊતરી ગઈ, અંગનું હલનચલન બંઘ થઈ ગયું, દેહનું ભાન ભુલાઈ ગયું, ફક્ત હોઠ ફફડતા હતા તે પણ હવે બંધ થઈ ગયા. તેમનો જીવાત્મા બ્રહ્મરંધ્રમાં ઊંચે ચઢી ગયો. તેમનું શરીર કામ કરતું થંભી ગયું.
                                                    દોહા
                                                    ચિંતા પેઠી પીરને, સાંભળે જપ ઉચ્ચાર,
                                                    ભમ્મર ભાલો કર ધરી, થયા ઘોડલે સવાર.
                                                    હરભુજીની અંતિમવડી લાગવાથી શ્રી રામદેવજીને મનમાં ચિંતા પેઠી, અને પ્રસન્ન ચિત્તે હરભુજીને બચાવવા વસ્ત્રાભુષણ સજી, હાથમાં ભમ્મર ભાલો ધારણ કરી, ઘોડેસ્વાર થયા અને એમનાં આગમનના મંગલ એંધાણ જણાવા લાગ્યાં. ચોપાઈ
                                                    વડ, પીપળ, વેલી તરુ ડોલે, પંખી રવ કરતાં કિલ્લોલે,
                                                    હેરો લેતું તળાવ વારી, સમીર મંદ વહેં શાન્તિકારી. રામદેવજીના આગમનના વધામણાનાં માંગલિક દૃશ્યો દેખાવા લાગ્યાં. હરભુજીની નજીક વડ, વૃક્ષો, પીંપળા, પીંપળ, વેલી, વેલા, તરુવરમાં એમને નાનાં મોટાં વૃક્ષો ઉમંગમાં આવી ડાળીઓ નમાવી નમન કરતાં હોય એવા વિધવિધ દૃશ્યો દેખાવા લાગ્યા. ભોજાતળાવનું પાણી લહેરો લેવા લાગ્યું. સર્વત્ર શાંતિ છવાઈ ગઈ હતી.
                                                    ચોપાઈ
                                                    ડાળે ડાળે પંખી રાચે, મોર કળા કરીને નાચે,
                                                    ઘોડેથી ઉતરીને હેઠા, હરભુજીની સામે બેઠા.
                                                    પંખીઓ ડાળે કૂદી મીઠા મધુરા અવાજ કરવા લાગ્યાં. મોર વિદ્યુત પેઠે, મેઘધનુષ્યના રંગનાં પીંછાવાળા, વિષ્ણુનાં અંગ જેવા કંઠવાળા, માથે ત્રિલોકની

                                                </p>
                                                <p className="paragraph-content">
                                                    લગી અને કાળ કેરાં નયનવાળા મલપતા કળા કરી નાગા માંગલિક વાતાવરણમાં તેમને પોતાના દેહનું સ્મરણ ન રહ્યું. તેમની રામદેવજીએ તેમના માથે હાથ મૂક્યો અને બોલ્યાઃ
                                                    ગોપાઈ
                                                    હરભુજી હઠ છોડો ભાઈ, જોઈ તમારી ભક્તિ સવાઈ, રામે હાથ ધર્યો ધન્ય બોલી, હરભુજીએ આંખો ખોલી.
                                                    साम
                                                    રામદેવજીના હાથની શીતળતા લાગતાં જ હરભુજીની ભાવસમાધિ :
                                                    અને તેમણે આંખો ખોલી.
                                                    દોહા
                                                    દીઠા રામદે'ભાઈને હરખ્યા મનમાં અપાર, જય હો રામાપીરની, પ્રેમે કર્યો ઉચ્ચાર.
                                                    શ્રી રામદેવ અને હરભુજી પ્રેમપૂર્વક વાતો કરવા લાગ્યા. એટલામાં બે ગામની સામે નવાગામ સીમાડામાં નેહ ધરાવતો લખા નામનો આહીર પોતાન | ખભે પાંચ વરસની ઉમરનો અપંગ છોકરો ઉપાડીને પોતાને ગામ જતો હતો. એની પત્ની પણ હતી. અપંગ છોકરાને ખભેથી બહુ જ ધીરેથી નીચે ઉતાર્યો અને પોતે તળાવમાં હાથપગ- મોઢું ધોઈ, શીતળ જળ પીને છોકરાને પાયું. રામદે અને હરભુજીને દરબાર જાણીને નમન કર્યું અને પાળે વિસામો લેવા બેઠા. હરભુજીએ આહીરને પૂછ્યું :
                                                    ‘તમે ક્યાં રહો છો ! આવા પાંગળા છોકરાને બહુ હડદોલવો જોઈએ નહિ. બાપુ ! અમે ખેરકરથી થોડે દૂર નવાગામની સીમના નેહમાં રહીએ છીએ. મારું નામ લખો આયર, અમે આ છોકરાને પગે લગાડવા અને બાધા પૂરી કરવ ગયા પણ મારા ઘજાવાળા ધણી રામદેવપીરે જીવતા સમાધિ લીધી. એટલે સમાધિનાં દર્શન કર્યા, ધોળી ધજા ચડાવી, ખીરનો પ્રસાદ ધરાવી, હવે અમારા નેહ જવા નીકળ્યા છીએ. બપોરો ગાળવા તળાવની પાળે વડનો છાંયો જોઈ બેઠાં છીએ. આહીરની અધૂરી વાત ઉપાડી લઈ આહીરાણી બોલી :
                                                    અમે આયરા જાત રબારી, પીરની બાધા હતી અમારી, દીધો દીકરો અંગ કઢંગું, અંધો મૂંગો પગનો લંગુ. બાપુ ! રણુંજામાં મારો ભાણેજ રતનો ભગત રહે છે. એના માથે રામાપીરન

                                                </p>
                                                <p className="paragraph-content">
                                                    ચારે હાથ છે. વચન આપે તો ફરે નહીં એવા વરદાનવાળો છે. તેણે અમને વેલ આપ્યું કે, રામાપીરની બાધા રાખી શ્રદ્ધાથી ધૂપદીવો કરો. તમને મારું ઘણી રામદેવજી એક દીકરો દેશે અને સંતાનવાળા કરશે. અમે રાખેલી બાધા ફળી. દેવળવાળાએ દયા કરી અને દીકરો દીધો, પરંતુ આંખે અંધ, મૂંગો, કઢંગુ અંગ અને હાથપગનો સાવ અપંગ. દીકરો પાંચ વરસનો થતાં બાધા પૂરી કરવા અમે રણુંજા ગયા હતા. પણ પીર સમાધિમાં પોઢયા હતા એટલે...
                                                    સમાધિએ દીકરાને નમાવી, પીરને ખીર પ્રસાદ ધરાવી,
                                                    હવે અમારાં નેહે જાશું, રામાપીરનાં કીર્તન ગાશું.
                                                    અમે રણુંજા ગયા હતા. ત્યાં થોડા દિ' પહેલાં પીર સમાધિ લઈ પડદે પોઢી ગયા હતા. એટલે એ સમાધિએ દીકરાને નમાવી સાકર, નાળિયેર, ધૂપધજા અને જ્યોત કરી, ખીરનો પ્રસાદ ધરાવી, અમારી બાધા પૂરી કરી. હવે અમારા નેહે જઈએ છીએ.
                                                    બાપુ ! તમે જમીનદાર, સુખસંપત્તિવાળા મોટા માણસ છો. તમારે ત્યાં આવો દીકરો હોય તો એને જીવતાં સુધી પાલનપોષણની સગવડ મળે.
                                                    અમો વૃદ્ધ થયા છીએ. આ દેહનો ભરોસો નથી. અમારા શરીર છૂટી જશે ત્યારે આ અપંગની શી દશા થશે ? એ ચિંતા જીવને સતાવે છે. જો ધીંગો દીકરો હોત તો સારું.' આટલું બોલતાં આહીરાણીનું હૈયું ભરાઈ આંખમાંથી આંસુ ટપકવા લાગ્યાં.
                                                    ‘બાઈ ! રો મા, બેટા ! રામદેવજી મહારાજ અંતરયામી છે. તમારી ઇચ્છા અને આરાઘના તેમનાથી અજાણ્યાં નથી. આ જ દીકરો તમારા મનોરથ પૂરશે. એનું નામ ઘીંગો રાખજે અને ઘીંગા નામથી પ્રસિદ્ધ થશે.'
                                                    આવા શાંતિભર્યાં આશ્વાસન વચનો સાંભળી પતિપત્ની બેય શીર ઝુકાવી નમી પડ્યાં. રામદેવજી એમના ભક્તિભાવ અને આર્તનાદને સાંભળી રહ્યા હતા.
                                                    ચોપાઈ
                                                    ખીર પ્રસાદ ઘરે આહીરા, પ્રેમે જમો પ્રસાદી વીરા,
                                                    પતિ પત્ની વિનયથી બોલે, કોઈ દેવ નહીં રામદે’ તોલે,
                                                    બાપુ ! લો આ પ્રસાદી ! પ્રેમથી જમો.

                                                </p>
                                                <p className="paragraph-content">
                                                    કાપ્યું મેણું વાંઝિયા, થયા ઉર ઉછરંગ, સરધા છે કે દીકરો, સાજો થશે અપંગ,
                                                    ધોડલાવાળો પાર પાડશે.’ આહીરાણીએ થોડા સમયમાં પોતાનું હતું ઠાલવી તમામ ‘અમારા મનોરથો શુભકામનાવાળા હશે તો અમારો નાથ | વાત કહી દીધી. રામદેવજીએ ખીરનો પ્રસાદ વાટકામાં લીધો અને હરભુજીનસ પારણાં કરાવ્યાં. પછી થોડી ખીર આરોગી. બાકીની ખીર છોકરાને ખવડાવી.
                                                    જોઈ પતિ-પત્ની રાજી થયાં.
                                                    »
                                                    ‘બાઈ ! બેટા ! આ છોકરાંને આવાં જાડાં લૂગડે ઢાંકો છો તેથી તેનું અપંગ અકળાઈ જાય. લે, આ પટકોં' એમ કહી રામદેવજીએ પોતાની કમ્મરેથી છેકે કેસરી રંગનો દુપટો આપતાં કહ્યું કે, એને રાતે ઓઢાડજે એટલે એ અકળાય નહીં, દુપટો લઈ પતિ પત્ની નમન કરી પોતાના નેહે જવા રવાના થયાં. જે કારણે હરે || અને રામદેવજી મળ્યા એમાં ત્રણ કારણોનો ઉકેલ થયો. ત્યાં સુધી પરસ્પર ક
                                                    વાતચીત કરી શક્યા નહીં હોવાથી હવે વાતની શરૂઆત કરી.
                                                    ‘મહારાજ ! વચન આપી વિશ્વાસમાં રાખી મારી હાંસી થાય એવું કર્યું ? સમાધિ ઉખેળાવી હું અપરાધી બન્યો, સગાંઓમાં મને ભોંઠો પાડ્યો ?’
                                                    “હરભુજી ! મારું કોઈ કામ કારણ વિનાનું હોતું નથી. અત્યારે જ મારાં ત્રણ કાર્યો પૂર્ણ થયાં છે.' રામદેવજીએ કહ્યું : ‘કૃપાનાથ ! એ ત્રણે કાર્ય કયાં હરભુજીએ પૂછ્યું.
                                                    ‘પહેલું કારણ તમારા આત્મ બલિદાનના નિર્ધારને રોકવાનું. બીજું કારણ તમારા ઉપવાસ હોવાથી આવેલી પ્રસાદી ખીર જમાડીને પારણાં કરાવવાનું અને ત્રીજું કારણ આહીરની બાધા માન્ય રાખી છોકરાને દુઃખ રહિત કરી તમારો શિષ્ય બનાવવાનું.'
                                                    ‘ભગવાન ! આવો અપંગ ચેલો મારા માટે જ રાખ્યો છે ? મારે પાલવવો પડશે ?'
                                                    ‘હરભુજી ! તમે ઘોર નિદ્રામાંથી જાગૃત થયા છો. તમને આદિપંથે દોરી માયામોહથી બચાવી, માનવસેવા તરફ વાળી, સિદ્ધ બનાવવાના મારા મનોરથ છે.’ ‘કૃપાનાથ ! મને સિદ્ધ કરવો છે ?’ હરભુજી આશ્ચર્ય પામી બોલ્યા. - ‘હા ! એ કારણ પણ સાંભળો.

                                                </p>
                                                <p className="paragraph-content">
                                                    તાપે તમે તપાયા ભાઈ, કાયા માયા હું ન કાંઈ,
                                                    નિર્મળને અષ્ટધા શક્તિ, આપું તમને નવધાભક્તિ, હરભુજી ! તમે તો તપોધન બન્યા છો. મારી કૃપા મેળવવા દેહનું બલિદાન દેવા તૈયાર થયા છો. તમે નિર્મળ દેહી બન્યા છો એટલે તમને સિદ્ધિ, પ્રસિદ્ધિ અને ભક્તિ ત્રણે આપું છું. તમે ભક્ત તરીકે પણ જગતમાં પ્રસિદ્ધ થશો. આહીરનો છોકરો કાલે સાજોતાજો થઈ જશે. આહીરાણીની આટલી દૃઢ શ્રદ્ધાભક્તિ નિષ્ફળ જાય ખરી ? એ છોકરો તમારી પાસે લાવે ત્યારે એને શિષ્ય સ્થાપજો અને આ ભસ્મનું તિલક કરજો; રામદેવજીએ ખલકમાંથી ભસ્મ કાઢી ચપટી ભરીને આપી અને કહ્યું : ‘એ છોકરો યુવાન થતાં ધીંગા ભગતને નામે પ્રસિદ્ધ થશે.'
                                                    ચોપાઈ
                                                    હવે તમે માલવામાં જાવો, સ્વધર્મનો નેજો ફરકાવો,
                                                    અંધશ્રદ્ધાળુને સમજાવો, પાખંડીને પરચો બતાવો.
                                                    તમારા પરચા પ્રતાપથી લોકોને સન્માર્ગે વાળી શકશો. માંસાહાર કરનારાઓને કહેજો કે પારકું માંસ ખાવાથી તમારી જીભને ક્ષણિક આનંદ થાય છે. પરંતુ એ ક્ષણિક આનંદને જ કારણે બીજાનો જીવ લેવો એ તમને ગમે છે ? આવું રાક્ષસ કરતાંય દુષ્ટ કાર્ય તમને ગમે છે ખરું કે ? આવું કર્મ તમને શોભે છે ?
                                                    વ્યસનીઓને કહેજો કે ભક્તો ભગવાન પાસે સબુદ્ધિની માગણી કરે છે, અને તમે તમારી સ્થિર બુદ્ધિ ગુમાવી, પૈસા ખરચી પાગલ બનો છો. તેજાબ જેવું પીણું તમારી સુંવાળી હોજરીને કોરી નાખે છે. એ તેમને સારું લાગે છે ? દારૂના વ્યસનમાં જાદવોની કેવી અવદશા થઈ હતી ?
                                                    પોતાનો મહાન ધર્મ છોડી પરધર્મમાં પગ મૂકી તમારો આ ભવ શીદ બગાડી રહ્યા છો ? જેનો આ ભવ બગડે તેનો પેલો ભવ સુધરે ખરો ?
                                                    ન્યાયનીતિથી મહેનત કરો અને ભગવાન જે હકનું આપે તે ખાવ, વગર મહેનતે પૈસા મેળવવા માટે જુગાર રમશો નહિ. જુગારમાં નળરાજા અને યુધિષ્ઠિરની શી દશા થઈ હતી તે યાદ કરો.
                                                    શ્રી રામદેવજીની અમૃતભરી વાણી સાંભળી હરભુજી બોલ્યા : ‘આપનો ઉપદેશ હું માથે ચડાવું છું. નાનપણથી હું આપની સાથે વ્યાવહારીક કાર્યોમાં ભળ્યો પણ હું અભાગી રહ્યો, આપને ઓળખી શક્યો નહીં, આપની આજ્ઞા પ્રમાણે કાર્યમાં
                                                </p>
                                                <p className="paragraph-content">
                                                    પ્રવૃત રહીશ. પણ મારે એક વચન મેળવવાની ઇચ્છા છે, સ્વીકારો તો તૃપ્તિ થાય
                                                    હરભુજી ગદ્ગદ થઈને બોલ્યા.
                                                    ‘કહો !' રામદેવજીએ કહ્યું.
                                                    વિધર્મીઓના પાખંડથી ભોળાંભલાં હિન્દુ નરનારીઓને નિજધર્મ સમજાવવા અને ‘કૃપાનાથ ! મને ભક્તિ સિદ્ધ આપી, સનાતન ધર્મનો નેજા ફરકાવી, બોધ આપવા સ્થળે સ્થળે પરિભ્રમણ કરવા આજ્ઞા કરી છે, તે પ્રમાણે હું જીવનની છેલ્લી ઘડી સુધી પ્રયત્ન કરીશ. પણ આપના આ દિવ્ય તેજોમય દર્શનનો લાભ મ મળ્યા જ કરે, અને જ્યારે હું આરાધ કરું ત્યારે મને અંતરિક્ષમાં પણ દર્શ આપવાની તમારે કૃપા કરવી. હાલમાં મારી સ્થિતિ જનકરાજા જેવી છે. ચોપાઈ
                                                    જનક સ્વપ્ન દૃશ્ય ઘેરાયા, ઉભય કારણો સત્ય સમજાયા, તાદૃશ્ય મિલન એ નજરે તરતું, તોય સત્ય એ અસત્ય ઠરતું! પ્રભુ ! જનકરાજા સ્વપ્ન અને વર્તમાન બેય સમજ્યા હતા. આપણા સ્થળના મિલનમાં એ સ્વપ્ન હતું અને સત્ય હતું. પણ આપની માયાએ મને આંતર્યો અને સમાધિ ઉખેડવા સુધી હું સમજી શક્યો નહીં, એનું દુઃખ મારા મનમાંથી જતું નથી.'
                                                    તમે એ બધી ચિંતા છોડીને કામે લાગી જાવ. વિધર્મીઓની ખરાબ પ્રવૃત્તિમાંથી આપણા બંધુઓને બચાવો અને માંસાહાર, જુગાર, વ્યસનો, ચોરી, જારીમાં પડી નર્કના માર્ગે દોડી રહેલા આપણા બાંધવોને વાળી ધર્મના સાચા રસ્તે લાવો. તમારા આ શુભ કાર્યમાં હું તમારા સાથે જ છું.’ શ્રી રામદેવજીનો ઉપદેશ સાંભળી હરભુજીએ પોતાની પાઘડી ઉતારી વંદન કરવા મસ્તક નમાવ્યું. જ્યાં તેઓ વંદન કરી ઊંચું જુએ છે, એટલામાં તો રામદેવજી અદૃશ્ય થઈ ગયા.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails26;
