import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails8 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery9.jpg";
    return (
        <>
         <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}


            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                   
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                       
                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            જય હિન્દવાપીર
                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                    
                                                >
                                                    <span className="income">જય હિન્દવાપીર</span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content" >
                                                    શઠજન શઠતા નવ તજે, તજે ન ઢાળા ઠંગ, સજ્જન સમતા નવ તજે, જેને એક જ રંગ. રામદેવજી અને પીરી હજી દરબારગઢ સુધી પહોંચી શક્યા નથી, ત્યાં તો નદીનાં પૂર પેઠે ફકીરોના ટોળે ટોળાં આવ્યાં. બપોર થતાં તો એ ટોળાંનો હજારોની સંખ્યામાં જમાવ થયો. જોતજોતામાં જુવાળ એવો વધ્યો કે આખું ગામ ફકીરોથી ઊભરાયું.
                                                    ચોપાઈ
                                                    મારવાડ વાગડ મેવાડા, આવી ઊમટયાં ફકીર ધાડાં, કચ્છ સિંધ માળવ પરદેશી, દીન દરવેશી અનેક વેશી. જાતજાતનાં ફકીરો આવેલા જોઈને પીરો મનમાં ને મનમાં મલકાવા લાગ્યા.
                                                    ચોપાઈ
                                                    મુસોપીર મનમાં મલકાયે, આનંદથી હૈયું છલકાયે, એવું જોમ બતાવે કોઈ, રામદેવ જાપે ગભરાઈ. મુસાએ ત્યાં આવેલા સાંઈઓને કહ્યું : 'એવું જોમ બતાવો કે રામદેવ ગભરાઈ જાય. એના પરચાથી કોઈ ઢીલા પડી જશો નહીં અને ભોજન વખતે જુદી જુદી વસ્તુઓ માંગજો. એમાં જો એમ કહે કે એ વસ્તુઓ નથી, તો એ પીરાઈવાળો નથી એમ જાહેર કરી, આપણે સહુ એનો ફજેતો કરી ચાલ્યા જઈશું. મુસાએ પોતાની પાસે આવેલા જુગલસાંઈને ખાનગીમાં સૂચન અને સલાહ આપી. રામદેવજી મુસાની મુરાદ અને ચાલબાજીને સમજી ગયા હતા. એમનાથી કાંઈ અજાણ્યું ન હતું, પણ આવા મોટા પ્રવાહ અને માનવપુર દેખીને અજમલજી ચિંતામાં પડે નહીં એટલે તેમણે અજમલજીને કહ્યું :
                                                </p>
                                                <p className="paragraph-content" >
                                                    બાપુ ! નવ ચિંતા કરો, નિશ્ચલ રહો મનમાંહી, કોઈ ન મુજ સામે ટકે, હારી જાશે આંહીં.
                                                    બાપુ ! આ જમેલો મારી ઈચ્છાથી મંડાયો છે અને તે સ્વધર્મ માટે છે. તહ મનમાં સંકલ્પ વિકલ્પ કરી ચિંતા કરશો નહીં. સામા પક્ષના પરચા કે પીરાઈ છે મારી ઈચ્છાથી એમને બક્ષેલાં છે, એટલે મારી સામે એમની પીરાઈ જરાકે ટાહે
                                                    આ કાંઈ મારો કસોટી કાળ નથી. આ તો ધર્મવિહોણાં ઘમંડીઓનો કસૌટ કાળ છે. મદાંધોને માનવધર્મનું ભાન કરાવી જ્ઞાન આપવા માટે મેં આ કાર્યારબ કર્યો છે. રામદેવજીએ અજમલજીને જણાવ્યું.
                                                    ચોપાઈ
                                                    પીર મુસો મસ્તાનો સાંઈ, પરચા પૂરી શક્યા ન કાંઈ, રાજસભામાં સર્વે આવે, જાજમનો ટુકડો બિછાવે. રામદેવજીના પીરાઈ પ્રતાપને જોઈને પીર ખસિયાણા પડી ગયા હતા. પોતે જે ધારણા અને હિંમતથી આવ્યા એ હિંમત ઓસરી ગઈ હતી. રામદેવજી સાથે સહુ દરબારગઢના ચોકમાં આવતાં એમને બેસવાના આસન માટે સવાહાથ લાંબી પહોળી જાજમનો ટુકડો બિછાવ્યો અને તેમના ઉપર બેસવા વિનંતી કરી.
                                                    ચોપાઈ
                                                    આપ કાજ જાજમ બિછાવી, પીર બિરાજો શાન્તિ લાવી, જાજમ નાની પીર વધારે, એમાં પાંચેય કેમ સમાવે? 'રામદેવ ! આ બધાંને દેખીને બુદ્ધિ ખસી તો નથી ને? ગભરામણ થવાથી બુદ્ધિ કામ કરતી નથી કે શું? ભલા માણસ ! આટલા ટુકડામાં મારું શરીર પણ સમાય તેવું નથી તો અમે પાંચેય શી રીતે બેસીશું?' મુસાએ કહ્યું.
                                                    'પીરજી ! હું બધાંને દેખી ગભરાઉ તેવો નથી. મારી બુદ્ધિ ઠેકાણે જ છે. તમારા સન્માન -સત્કારથી મને ખૂબ જ આનંદ થાય છે. આપ ધર્મધુરંધર છો, પરચાધારી
                                                </p>
                                                <p className="paragraph-content" >
                                                    પારો એકાવીને બેસણાજમનો એક ઢકરો જ ભિાવ્યો છે. તમે તમારી મહિનાથી
                                                    ચોપાઈ જાજમ ટુકડો ભલે બિછાવી, મોટી કરો પરથો બતલાવી,
                                                    આનંદથી બેસો પીર સાંઈ, નથી જાજમ નાની પથરાઈ. રામદેવ ! આવા નાના કાર્યમાં પીરાઈ બતાવવી એવી પીરાઈ સસ્તી નથી. હરાઈના પ્રતાપને આવા ચીંથરાના ટુકડામાં પુરાય નહીં. અમે ધારીએ તો આ ટાડો ઊડીને સૂર્યને ઢાંકી દે, પણ એવો આ સમથ નથી.' મસ્તાના પીરે પોતાની "ક્તિ બતાવતાં કહ્યું.
                                                    પીરજી ! તમે જાજમ પર ભેંસો, જાજમ તમને નાની લાગે છે, એ તો તમારો કૃષ્ટિદોષ છે. બેસી તો જુઓ ?' રામદેવજીએ આગ્રહ કરતાં જણાવ્યું. દોહા
                                                    પ્રથમ મુસ બેસતાં. સરક સરક રવ થાય, જાજમ વધતાં બેસતો, મસ્તાનો પીર ત્યાંય,
                                                    મુસો પીર જાજમ પર બેસતાં સરક! સરક! એવો અવાજ જાજમમાંથી નીકળ્યો અને જાજમ સવા હાથ વધી ગઈ. જાજમનો સરક ! સરક! નો વિચિત્ર અવાજ સાંભળી મુસો આશ્ચર્યચકિત થઈ ગયો. તેમ છતાં પણ પોતાની અક્કડાઈ જાળવી રાખવા જાજમ પર બેઠો. ત્યાં તો પાછી વળી જાજમ સવાહાથ વધી! મસ્તાના પીર બેસતાં ફરી જાજમનો અવાજ થયો અને જાજમ સવાહાથ વધી. મુસો અને મસ્તાન બંને પીરો આશ્ચર્યમુગ્ધ થઈ એકબીજા સામે જોવા લાગ્યા. આ અજાયબ અવાજને તેઓ કળી શક્યા નહીં.
                                                    ચોપાઈ
                                                    ગડદાપીર દગડાપીર બેસે, જાજમ વધતી જાય વિશેષે, બેસે જ્યમ જ્યમ પીર ફકીરો, જાજમ વધતી લીરો લીરો.
                                                </p>
                                                <p className="paragraph-content" >
                                                    અવાજ કરતી વધવા લાગી. આ કૌતુક જોવા હૈયે હૈયું દળાય એટલી માનવ મેય જામી.
                                                    ચોપાઈ
                                                    એક લાખ એંશી હજાર, ગામ બહાર પહોંચ્યા વિસ્તારા, સરક સરકનો અવાજ થાયે, જાજમ સવા હાથ વધી જાયે. જાજમ પર એક લાખ અને એંશી હજાર માણસો બેઠા તોય સવાહાય જ ખાલી જણાઈ અને જાજમમાંથી 'સરક...સરક' નો અજાયબ અવાજ થતો ગયું અને જાજમ વધતી ગઈ.
                                                    ચોપાઈ
                                                    ફરે સભા ફરતો મતવાલો, ચોકીદાર ભમ્મરિયો ભાલો, પીર ફકીરમાં વાતો થાયે, કંઈ ગભરાયા મનની માંથે. આખી સભાની માનવ મેદનીની ચારે બાજુ ભમ્મરિયો ભાલો ચોકી કરતો ચક્કર લગાવવા લાગ્યો. આ ચમત્કારિક દૃશ્ય જોઈ ફકીરો અને પીરો મૂંઝાવા લાગ્યા, હેબતાઈ ગયા અને ગભરાવવા લાગ્યા. પીરોએ પરસ્પર નવો તુક્કો વિચાર્યો કે એવું તરકટ કરવું કે, રામદેવજી ના પાડે અને ભોજન વિના ભૂખ્યા પેટે ઊઠીને ચાલતાં થવું !
                                                    દોહા ભોજન સહુને આપવા, રામદે કરે વિચાર, સમય થયો ભોજન તણો, કહો તે કરું તૈયાર. “પીરજી! મહાત્મા હવે સર્વને ભોજન કરવાનો સમય થતો હોવાથી આપ કહો પકવાન તૈયાર કરાવું ?' શ્રી રામદેવજીએ પૂછ્યું. [
                                                    ચોપાઈ
                                                    મખ્ખન, મીસરી, ચાવલ લાવો, કેસર ને કસ્તુરી મિલાવો, હરદમ એવું ભોજન કરીએ, બીજી વસ્તુને નવ ખાઈએ. 'માખણ, સાકર, દૂધમાં નાખી ચોખાની ખીર બનાવાય અને અંદર કેસર
                                                </p>
                                                <p className="paragraph-content" >
                                                    સિવાય મેવા, મીઠાઈ, ફળ, પકવાન અમે ખાતા નથી. વીરાએ ખોરવ્યું અમારો છે. એ ८३
                                                    ચોખા સવાશેર રંઘાવે, ખુરબોવાળી ખીર ત્યાં શ્રાપે, જમો પીરજી સમરથ સાંઈ, માગી લેજો જોઈએ કાંઈ. ધીરજ ખીરની રસોઈ તૈયાર થઈ ગઈ છે. જમવા પધારો અને પ્રેમથી પ્રસાદ લો. જોઈએ તે માગી લેજો. મનમાં મૂંઝાતા નહીં.' એમ રામદેવજીએ કહ્યું. ત્યાં તો પીરોએ નવો તુક્કો કાઢ્યો.
                                                    ચોપાઈ
                                                    વાસણ કામ ન આવે અમારે, અમે ભૂલ્યા તે સ્થાન અમારે, બરતન વિણ ભોજન નહીં લઈએ, એક દિન ભૂખ વેઠી રહીએ. 'રામદેવ ! પીરાઈમાં જે પૂરા હોય છે એમની નેક ટેક તમે શું જાણો ? અમે પાક નીમાધારી, નેજાધારી, પીરનાપીર છીએ. પરાયા વાસણમાં ભોજન કરીએ નહીં. અમારાં ભરતન લાવવાં ભૂલી ગયા છીએ એટલે આજની રાત અમે ભૂખ્યા રહીશું, પણ તમારા વાસણમાં તો ભોજન લઈશું નહીં!' પાંચે પીરોએ એકબીજા સામે જોઈને કહ્યું.
                                                    બરતન પાંચે પીરનાં, લાવી મેલ્યાં ત્યાંચ, પીરો હેરત પામિયા, દિગ્મૂઢ થઈ જાય !
                                                    રામદેવજીએ અજમેર, મુલતાન, મક્કા સુધી હાથ લંબાવી પીરોનાં વાસણો લાવીને ત્યાં હાજર કર્યાં. પોતાનાં વાસણો જોતાં બધા પીરો હેરત પામી ગયા. મોટા મોટા ધર્માચાર્યો અને પીરોમાં સર્વશ્રેષ્ઠ મનાતા પીરો આવા ઉપરાઉપરી પરચા જોઈ પ્રભાવિત થઈ ગયા. મનમાં જાણી ગયા કે આ અદ્ભુત વ્યક્તિ કોઈ ઓલિયાનો અવતાર છે. પીરાઈમાં શૂરો પૂરો છે. હવે એને વધારે છેડવામાં કાંઈ સાર નથી. કદાચ આપણા ઉપર જોખમ આવી પડે તો ! આપણે આલમમાં હલકા પડી જશું.
                                                </p>
                                                <p className="paragraph-content" >
                                                    શ્રી રામદેવ રામાયણ
                                                    પીરાઈનો અંચળો ઉઘાડો પડી જશે અને લાખો ફકીરોમાં ફજેત થઈ નાસવું પડે આવું પરસ્પર પીરોએ વિચાર્યું. એટલામાં તો ખીર પીરસાવા માંડી. પાણ પ્રસન્નતાથી ખીરનું ભોજન કર્યું.
                                                    પીરોના અગાઉના સંકેતને અનુલક્ષીને ફકીરો જાતજાતની વસ્તુઓ ભોજનમ માગવા લાગ્યા. એમને પીરોની સૂચના અને સલાહ હતી કે, જે 'નથી' એટલું જ કહે તો રામદેવમાં પીરાઈ નથી એવો આક્ષેપ મૂકી સર્વેએ ગાલય જવું. પ્રજાજનો ‘અલખ ઝોળીઓ' ખભે લટકાવી પીરસણિયા થઈ ફકીરોનો જમાતમાં ઘૂમવા લાગ્યા. સવારથીઓ સહુનો આગેવાન હતો. ચીજ માંગો છે
                                                    ચોપાઈ
                                                    કલ્પે વસ્તુ ભિન્ન ભિન્ન ભાતી, પાત્ર મહીં પીરસાતી જાતી, કોઈએ ઠંડા ટુકડા માગ્યા, મળતા તેને એ મન માગ્યા. ભોજનમાં શું માગવું? એવી વિવિધ કલ્પનાથી તેઓ જે નિશ્ચય કરે, તે વસ્તુ એના પાત્રમાં તરત આવી જતી. આવી પ્રચંડ શક્તિશાળી વ્યક્તિના કારણે પીરો-ફકીરો ભયભીત દશામાં આવી પડ્યા.
                                                    ચોપાઈ
                                                    પીરસણિયાના બોલ મજાના, અલખ ઝોળી ખલક ખજાના, શીર મુકુટ કેસરિયા જામા, સ્થળ સ્થળ દેખાયે પીર રામા. પીરસલિયા જે માંગે એ પીરસવા ઘૂમવા લાગ્યા અને 'અલખઝોળી ખલક ખજાના' ઉચ્ચાર કરી ઝોળીમાંથી આપવા લાગ્યા. વળી તેમને રામદેવજી અનેક રૂપે દેખાવા લાગ્યા.
                                                    મરાઠી સાખી
                                                    કહે વિરમદે સુણો ફકીરા, ખાધા વિન્ન ના જાશો, ચોકીઆત છે ચપળ અમારો, ભાલેથી ભોંકાશો, માગો તે તમને મળશે, સહુને શાન્તિ વળશે.
                                                    “પીર મહાત્મા ! સાંઈ ફકીર! સર્વે જે જોઈએ તે માગી લેશો, કોઈએ ભૂખ્યાં
                                                </p>
                                                <p className="paragraph-content" >
                                                    પડ્યો રંગમાં ભંગ એ ટાણે, પાંચે પીર પ્રતાપ પ્રમાણે, ઘડી ઘડીમાં કૌતુક થાયે, એમ પરસ્પર વાતો થાયે. પીરોનો જે હેતુ હતો, જે કામે આવ્યા હતા એ કાર્યમાં તેઓ નિષ્ફળ ગયા. એમણે જે ધાર્યું હતું, તેમાં તે કાંઈ કરી શક્યા. નહીં અને પરીક્ષા લેવાને બદલે પરીક્ષા સાપવા આવ્યા હોય એમ સહુને લાગ્યું.
                                                    •પીરજી ! હવે હું આપની શી સેવા કરું ?' રામદેવજીએ પૂછયું.
                                                    દોહા પાંચે પીરો બોલીયા, સેવા ગુણ સુધીર ઈશ્વર જેવા ઓલિયા, જય જય રામાપીર.
                                                    ચોપાઈ
                                                    ગર્વ અહંકાર ગળ્યો અમારો, દીઠો પરચા પ્રતાપ તમારો, વિનય, વિવેક ને સેવા જોઈ, ઓલીયા સમ પીરાઈ હોઈ. 'રામદેવજી ! તમારો આદર, સન્માન, સત્કાર, વિવેક, શાન્તિ, ચીરજ, ગંભીરતા અને પરચાની પૂરેપૂરી પીરાઈ જોઈ અમને તમે દેવાંશી અવતારી પુરુષ છો એવું લાગ્યું. બધા પીરો બોલી ઊઠયા:
                                                    
                                                    'રામશા, ઈમામશા, ઈમામશા, રામશા' આમ શ્રી રામદેવજીને ઈમામશા બરોબર ગણી તેમને 'જય રામદેવપીર !'નો જયનાદ ગુજાવ્યો અને સૌએ ભાવભીની વિદાય માગી. પાંચે પીરોનો ગર્વ ગળી ગયો હતો એટલે રામદેવજી, વીરમદેવજી, અજમલજી વગેરેએ તેમને ફૂલહાર પહેરાવ્યા અને વિદાય આપવા રણુજાના સીમાડા સુધી સાથે ગયા. રણુંજાના લોકો પણ તેમને પાંચ પીંપળી સુધી વળાવવા ગયા. જ્યાં પીંપળી આવી ત્યાં રામદેવજી ઊભા રહ્યા અને સવારથીઆ સુતારને પીપળીનું એક ડાળું કાપવાનું કહ્યું. સવારથીઓ તુરત પીપળી ઉપર ચઢી ગયો અને ઘડીકમાં એક ડાળ કાપીને નીચે
                                                </p>
                                                <p className="paragraph-content" >
                                                    નાંખી. શ્રી રામદેવજીએ પાંચે પીરને પીંપળીની ડાળ ઉપર બેસવાનું કહ્યું. છે. પીરો શ્રી રામદેવજીની વાત સાંભળી નવાઈ પામ્યા. તેઓ કાંઈ સમજી શક્યા નહ એટલે એકબીજાની સામે જોવા લાગ્યા. પીરોના મનની મૂંઝવણ રામદેવજી સમજ ગયા એટલે રામદેવજીએ તેમને ધીરજ આપતાં કહ્યું : તમે કોઈ જાતની શંકા કર્યા વિના આ ડાળી ઉપર બેસો. આ ડાળી તમને મક્કામાં પહોંચાડી દેશે. રામદેવજીન વાત સાંભળી તેમના મનમાં ધીરજ આવી, તેથી તેઓ રામદેવજીની સૂચના પ્રમાણે ડાળ ઉપર બેઠા. શ્રી રામદેવજીએ 'અલખ નિરંજન'નો અલખનાદ ગજાવ્યાં. અલખનાદ ગજાવતાં જ આશ્ચર્ય થયું ! પાંચે પીરોને લઈને પીંપળાની ડાળ આકારણ માર્ગે મક્કા તરફ ઊડવા લાગી.
                                                    ઊડતી ડાળ ઉપર બેઠેલા પીરોએ રામદેવજીનો અલખનાદ ઝીલી લીધો અને “અલખનિરંજન'નો નાદ ગજાવતા આકાશ માર્ગે ઊડવા લાગ્યા
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails8;
