import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails28 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery30.jpg";
    return (
        <>
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}
 <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      

            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                   
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                          
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            સંત ઉગમશી બાવા

                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                   
                                                >
                                                    <span className="income">સંત ઉગમશી બાવા


                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content" >
                                                    શ્રેષ્ઠ ધરા સોરઠતણી, કરમી ધરતી કચ્છ,
                                                    ગોરખ ધૂણો સ્થાપતા, ‘જય જય અચલગચ્છ.’
                                                    સૌરાષ્ટ્રની સંધી-સમાસવાળી કચ્છ ભૂમિમાં 'અચલગચ્છ' આદિનાથનાં સ્થાનને પસંદ કરી ગોરખનાથે ‘ધીણોધર' નામના ડુંગર પર પોતાનું આસન સ્થાપ્યું.
                                                    ચોપાઈ
                                                    કેરા કોટમાં ઉગમા નામે, ચમાર એ વહેપારી ભામે,
                                                    પીર કબરની પૂજા કરતો, ધૂપ ધજા ને ડમરો ધરતો. કચ્છનાં કેરાકોટ ગામના પાદરમાં ચમારવાસ હતો. તેમાં એક ચમાર રહેતો હતો. એનું નામ ઉગમશી હતું પરંતુ તે ‘ઉગમો ભામવાળો' કહેવાતો.
                                                    ઉગમો પીર ફકીર પ્રત્યે પ્રેમ ધરાવતો હતો. એટલે કબરપૂજા કરી ધૂપધજા અને ડમરો ચડાવતો. સ્વધર્મ પ્રત્યે એને સુગ હતી, એના મનમાં ઠસી ગયું હતું કે;
                                                    ચોપાઈ
                                                    સાધુ સંત અને ઠાકોરદ્વારા, હિન્દુ ધર્મનાં એ લૂંટારા, ચેલા મંડી ગુરુજી થાય, ફરી ઘરો ઘર માગી ખાયે. આવું સ્વધર્મ વિરોધી ભૂત ફકીરોએ ઉગમાના મનમાં ભરાવ્યું હતું.
                                                    દોહા
                                                    ઉગમો એકી લઈ અને, વેચવા આવ્યો ભામ,
                                                    નખત્રાણા ગામે વસે, મેઘ ધારવો નામ.
                                                    ઉગમી બળદ એકામાં સુકા રૂંવાટિયાં (ભામ) ચામડાં ભરી વેચતો કચ્છના નખત્રાણા ગામના પાદરે આવ્યો.
                                                    પાદરમાં મેઘવાસ હતો. ત્યાં મેઘવાળ જાતિના હરિજનો વસતા હતા. આ મેઘવાસમાં મેઘો નામે એક હરિજન વસતો હતો, મેઘો મુંજ લાવીને સૂંથિયા, ઈંઢોણી, રાંઢવાં, દોરી, ખાટલા ભરવાનાં વાણ બનાવી વેચતો હોવાથી ઉગમો બળદ માટે રાશ ખરીદવા મેઘાને ત્યાં આવ્યો. મેધાને ત્યાં એણે તુલસીક્યારો જોયો

                                                </p>
                                                <p className="paragraph-content">
                                                    એટલે એણે પૂછ્યું :
                                                    શ્રી રામદેવ રામાયણ
                                                    ‘મેઘા ! તેં તુલસી વાવ્યાં છે. એમાં સુગંધ ના હોય, ડમરો વાવ્યો હોત ને
                                                    બહુ
                                                    સ્વધર્મ
                                                    સુગંધ મળત અને પીરને પ્રસન્ન કરવા કબરે ચડાવી શકાત. ડમરો પીરને હોય છે.’ મેઘાએ કહ્યું : ‘ભાઈ ! તમે હિન્દુ થઈ કબરને પૂજો છો ! પરધર્મ વહાલો ગણ્યો ? ક્યાં ડમરો અને ક્યાં પવિત્ર તુલસી ! મારે આંગણે ક જોગી પધાર્યા હતા. એમણે કમરબંધખૂજ મેખલા (લંગોટી રાખવા) માગી. મેં એમને મુંજની મેખલા બનાવી દીધી એથી એ ખૂબ પ્રસન્ન થયા અને એમની માથે
                                                    ઘીણોધર ડુંગરે તેડી ગયા.
                                                    દોહા
                                                    ધીણોધર અવધૂત વસે, કોઈ નહીં એની જોડ,
                                                    કોળ્યું તુલસી કાષ્ટને, થયો માંજરિયો છોડ.
                                                    એ અવધૂત તપેસરી હતા. એમણે મને તુલસીનું સૂકું કાષ્ટ લાવીને એમાંથી ભાંગીને ટુકડો આપીને કહ્યું કે, આ ટુકડાને તું માટીના ક્યારામાં રોપજે અને જય સીંચજે. એ કોળશે અને તુલસીનો માંજરિયો છોડ થશે. એ તુલસી તું તારા ઇષ્ટદેવનું નામ બોલી ભોજનમાં ખાજે અને તુલસીની પૂજા કરજે. તારી ઉન્નતિ થશે. ત્યારથી આ તુલસીક્યારો છે.
                                                    ઉગમો ઘીણોધર ડુંગરે આવી મહાત્માને નમન કરી બેઠો, મહાત્મા ! અવધૂત કામનાથ નામથી પ્રસિદ્ધ હતા. યોગમાં પૂર્ણ સિદ્ધ હતા. એમણે ઉગમાની પાઘડીમાં વીંટાળેલું લીલા રંગનું કપડું જોયું, ગળામાં ગળિયલ માદળિયું દીઠું. હાથમાં ડમરાની ડાળખી જોઈને તે બોલ્યા : ‘ભાઈ ! તેં પાધડીમાં લીલો રૂમાલ કેમ રાખ્યો છે ?” ‘મહાત્મા ! એ રૂમાલ નથી. એ તો પીરપટકો છે.’ કામનાથે પૂછ્યું : ‘અને ડમરો ?’ આ ડમરો પણ લીલા રંગનો છે. પીરની કબર પૂજામાં એ ચડાવાય છે એથી પીર પ્રસન્ન રહે છે.' ઉગમે જણાવ્યું, ‘ભાઈ ! હિન્દુ થઈ સ્વધર્મ છોડીને પરધર્મી બન્યો ?’ કામનાથે પૂછ્યું, ‘મહાત્મા ! હિંદુઓમાં અનેક દેવદેવીઓ છે પણ એ પીરના જેવા તરત પરચા પૂરતા નથી. પીરની કબરની પૂજા કરવાથી મને વેપારમાં
                                                    બરકત જણાય છે.’
                                                    ।। એમ કહી ઉગમે ડમરાના લાકડાનો ટુકડો તેમના સામે ધર્યો અને કહ્યું કે, ‘મેથી મુંજવાળાને તમે લાકડામાંથી તુલસી છોડ થાય એવો ચમત્કાર કરી બતાવ્યો તેવો ચમત્કાર જોવાની ઇચ્છાએ હું આવ્યો છું જોગીરાજ !' ઉગમે કહ્યું. ‘તું પરધર્મીઓની સંગતથી સ્વધર્મ ચૂક્યો. વિવેક, વિચાર, વાણીના ગુણ તારામાં નથી. જા, ફકીરો
                                                </p>
                                                <p className="paragraph-content">
                                                    પાસેથી ચમત્કાર જો. હું અહીં ચમત્કારો બતાવવા જાદુગર થઈ બેઠો નથી. આ ધૂણો અવધૂતનો છે.
                                                    દોમા
                                                    ઈયળને ભમરી કરે, નહીં પલટાવે ભમરો,
                                                    તુલસી દલ કલિમળ હરે, દેવ ન પરો ડમરો.
                                                    ભમરી ઈયળને ડંખ મારીને પોતાના જેવી પાંખોવાળી ભમરી બનાવી દે છે. એ ભમરાના રૂપમાં નથી પલટાવતી. ભમરો ગુનગુન અવાજ કરે છે પણ એ ગુણવાળો નથી હોતો. તારી આંખોમાં મને તારો ભૂતકાળ દેખાય છે. તું પૂર્વજન્મમાં યોગભ્રષ્ટ થયેલો છે. ગુરુદ્રોહ કરવાથી તું જામનાથમાંથી ભામનાથ બન્યો છે. તે અજીયા વધુ (બકરીના વધનું) પાપ કરવાથી રાતદિવસ ચારડાં ચૂંથવાનો તારો અવતાર થયો છે. કામનાથની વાત સાંભળી ઉગમો ચોંકી ગયો અને બે હાથે નમન કરી પૂછ્યું :
                                                    ‘બાપુ ! મારા અવિવેક માટે મને ક્ષમા કરી મારા પૂર્વજન્મની વાત જણાવવા કૃપા કરો.’ ઉગમે યોગીના ચરણમાં માથું નમાવી કહ્યું .
                                                    ‘ઉગમા ! ગયા જન્મે તું ગિરનારના યોગસિદ્ધ મહાત્મા ગેબીનાથનો શિષ્ય હતો. એ વખતે ગિરનારની તળેટીમાં પંચમકારી' પંથવાળા એ સવરામંડપમાં આચાર્યપદની ગાદી માટે તારે જવું નહીં, એવી મારી આશા છે. તેમ છતાં તે ગુરુ આદેશની અવગણના કરી, આચાર્ય બનવાની લાલસામાં ત્યાં ગયો અને તારા આચાર્યપદ તળે મંડપમાં અજીયા વધ-બકરીનો વધ કરવામાં આવ્યો.
                                                    આખો બનાવ ગુરુ ગેબીનાથે યોગ સમાધિમાં જોઈ લીધો અને તને ગુરુદ્રોહી ગણીને ઠપકો આપતાં ઉચ્ચાર્યું કે, તેં ધર્મના નામે નિર્દોષનો વધ કર્યો. મારી આજ્ઞાનું ઉલ્લંઘન કર્યું. હવે તું ભામનાથ થઈને અવતરીશ. ઉગમા ! આ તારો જન્મ એ ગુરુદ્રોહનું કારણ છે.’ વાત સાંભળી ઉગમાને મનમાં બહુ આધાત થયો. તે બોલી ઊઠ્યો : ‘બાપુ ! હું પાપાત્મા છું. મારા અપરાધની ક્ષમા કરો. મારા ઉદ્ધારનો માર્ગ બતાવો.' પશ્ચાત્તાપથી તેની આંખોમાંથી પાણી વહેવા લાગ્યું. આ જોઈ કામનાથે કહ્યું :
                                                    ‘ઉગમા ! તારી પાઘડીમાંનું લીલું કપડું, ગળામાં પહેરેલું ગળી રંગનું માદળિયું, હાથનું તાંબાનું કડું, પગમાં પહેરેલી પીરદુવા નામની લોઢાની બેડી અને ડમરો એ બધું કાઢી નાખ. પહેલાં બંધનથી મુક્ત થા એટલે તને ગુરુ ગેબીનાથના દર્શન થાય. તુરત એણે તમામ વસ્તુઓ કાઢી નાખી.

                                                </p>
                                                <p className="paragraph-content">
                                                    યોગીએ ધૂણામાંથી ભસ્મની ચપટી ભરી, શૃંગી બજાવી ‘અલખ અલખ પીર રામદેવજી ! જયગુરુ ગેબીનાથ !' નો મોટા અવાજે ઉચ્ચાર કરી કપાળમાં ભસ્મૃતિલક કરતાં તે સમાધિસ્થ થઈ ગયો અને તેને ગિરનારની ગુફામાં બિરાજેલા ગેબીનાથનાં દર્શન થયાં. થોડી વાર પછી તે સમાધિમાંથી
                                                    થયો અને કામનાથના ચરણે નમી પડ્યો.
                                                    દોહા
                                                    જાગૃત થતાં ઉગમો, અચરજ પામ્યો ત્યાંય,
                                                    જય જોગી જય ગુરુવરા, પચો ચરણની માંય. કામનાથે કહ્યું : ‘મેં તને ગુરુદર્શન કરાવ્યાં. હવે ગયા જન્મનો અધૂરો યોગ સંપૂર્ણ કરવા તું મારવાડમાં, જ્યાં રણુંજા તીર્થધામ છે ત્યાં જા. શ્રી રામદેવપીર તને તારી ગુમાવેલા પોગસિદ્ધિ પાછી આપશે.' કામનાથની સૂચનાથી ઉગમો અને મેઘઘારૂઓ રણુંજામાં આવ્યા.
                                                    ચોપાઈ
                                                    ભવ્ય દ્વાર દીઠું રણુંજાનું, માઢ મેડી પર ટકોરખાનું,
                                                    નોબત વાગે સાંજ સવારે, નેજા ફરકે પ્રવેશદ્વારે. અંબાડી સહિત હાથી આવે જાય એટલો ઊંચો દરવાજો હતો. દરવાજા ઉપર માઢમેડીમાં ટકોરાખાનું હતું. તેમાંથી નોબત અને શરણાઈના મધૂરા સુરો આવી રહ્યા હતા. આ બધું જોઈ બંને મુગ્ધ થઈ ગયા અને બજાર, ચૌટા, ચોક જોતાં જોતાં તે રાજગઢમાં પ્રવેશ્યા.
                                                    આજે પૂનમનો દિન હોવાથી સાધુસંતો, સતીસેવકો ઓચ્છવ ઉજવવા આવ્યા હતાં. ઉગમો અને મેઘો એમની સાથે સભામાં બેઠા.
                                                    દોહા
                                                    પુનમના ઓચ્છવ મહીં, મળ્યો ભક્ત સમુદાય, રામદેવ બિરાજ્યા, ધર્મસભાની માંય.
                                                    રામદેવજી ધર્મસભામાં બિરાજ્યા એ વખતે ઉગમો અને મેઘો એમના ચરણમાં પડી પગે લાગ્યા. રામદેવજીએ ઉગમા સામે નજર માંડી, એનું અંતર પારખી લીધું એનું હૃદય વાંચતાં તેઓ સમજી ગયા કે, આ બંને જણ ધર્મના આધારસ્તંભ બનીને નિજીયા ધર્મનો પૂરો પ્રચાર કરે તેવા છે, તેથી શ્રીરામદેવજીએ તેમને ઉપદેશ આપી
                                                    ગુરુદિક્ષા આપી.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails28;
