import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails6 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery7.jpg";
  return (
    <>
     <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
      {/* <div className="body_wrap">
        <div className="page_wrap"> */}


      <Header />
      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id="46"
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="52ebb12"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          style={{ height: "30rem", marginBottom: "5rem" }}
        >
          <div className="elementor-background-overlay"></div>
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
              data-id="ae9d67e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fcf91b3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                      data-id="93dfaf4"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                          data-id="847c2c5"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                          style={{ height: "8rem" }}
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                          data-id="8fe49e3"
                          data-element_type="widget"
                          data-widget_type="trx_sc_layouts_title.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              id="trx_sc_layouts_title_515615674"
                              className="sc_layouts_title sc_align_center with_content without_image without_tint"
                            >
                              <div className="sc_layouts_title_content">
                                <div className="sc_layouts_title_title">
                                  {" "}
                                 
                                </div>
                                <div className="sc_layouts_title_breadcrumbs">
                                  <div className="breadcrumbs">
                                   
                                    <span className="breadcrumbs_delimiter"></span>
                                   
                                   
                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



      <div className="page_content_wrap blog-details">
        <div className="content_wrap" >
          <div className="content">
            <div id="container" className="give-wrap container">
              <div id="content" role="main">
                <div
                  id="give-form-288-content"
                  className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                >
                  <div
                    id="give-sidebar-left"
                    className="give-sidebar give-single-form-sidebar-left"
                  >
                    <div className="images">
                      <img
                        width="1024"
                        height="576"
                        src={img1}
                        className="attachment-large size-large wp-post-image blog-img"
                        alt=""
                        decoding="async"
                        sizes="(max-width: 1024px) 100vw, 1024px"
                      />
                    </div>
                  </div>
                  <div className="summary entry-summary">
                    <h2 itemProp="name" className="give-form-title entry-title">
                      પીરોનું આગમન
                    </h2>{" "}
                    <div
                      id="give-form-288-wrap"
                      className="give-form-wrap give-display-onpage"
                    >
                      <div className="give-goal-progress">
                        <div
                          className="raised blog-title"
                          
                        >
                          <span className="income">પીરોનું આગમન</span>
                        </div>
                      </div>

                      <div
                        id="give-form-content-288"
                        className="give-form-content-wrap give_pre_form-content"
                      >
                        <p className="paragraph-content" >
                          રણુજામાં રામદે'પીર પ્રગટ્યા છે. એમણે ભૈરવા રાક્ષસને માર્યો; નિયંત્ર વાવડીને જળથી ઊભરાવી, વણજારાની સાકર ખારી થઈ ગઈ. એવા પરચા છું છે. એમના પર શ્રદ્ધા રાખી તેમની ઉપાસના કરવાથી મન ધાર્યા કામ થાય છે આવી વાતો મારવાડનાં નાનાં મોટાં ગામોમાં પ્રસરી. આથી રામદેવજી પરચાધાર હિન્દવાપીર છે. એમ લોકો માનતાં થઈ ગયાં; અને કબર પૂજા કરવી, હકીરોન દોરાયાગા બાંધવા, જંત્રમંત્રનાં માદળિયા બાંધવાં, મંત્રેલા એઠાં પાણી પીવાં, કવા પર ચાદર, ફૂલ, ધૂપ તથા [ ધજા ચડાવવાં એવાં કાર્યો હિન્દુધર્મ માટે યોગ્ય નથી. પરઘર્મીઓ પોતાના ધર્મમાં ભેળવવા પીરનાં ચમત્કારોની ખોટી વાતો કરી લોકોને વિશ્વાસમાં લઈ વટલાવે છે, ધર્મભ્રષ્ટ કરવા ફકીરો જાતજાતના પાખંડ કરે છે. એવું સહ જાણી ગયાં તે પછી લોકો આવા તરકટી ફકીરોને પોતાના આંગણેથી હડધૂત
                          કરી પુત્કારી કાઢતાં અને અશ કે રોટી કોઈ આપતું નહીં. આમ શ્રી રામદેવજીના પ્રચારથી હિન્દુઓમાં અંધશ્રદ્ધા ઓછી થવા લાગી. બધાંની આંખો ઊઘડી ગઈ. સૌને શ્રી રામદેવજીનો ઉપદેશ સાચો લાગવા માંડયો અને બધા હિન્દુ ધર્મમાં પાછા વળવા લાગ્યા.
                          રણુજાના રામદેવજી એ ભગવાનના અવતાર છે. મનના મનોરથ પૂરે છે. તેમની ઉપાસનાથી ધાર્યા કામ થાય છે. એવું માનતા લોકોને પરચા પ્રતાપ પણ મળતો હતો. ધારેલાં કાર્યો સફળ થતાં હોવાથી ગામેગામ સ્ત્રીપુરુષોમાં ભક્તિભાવ અને સ્વધર્મ પ્રત્યેની લાગણી ઉત્પન્ન થવા લાગી.
                          ઘણા સ્થળે તો ફકીરોને ગામમાં ઊતરવા પણ દેતા નહીં. ગામની ભાગોળે, પાદરમાં, નદી, તળાવ કાંઠે કે કોઈ વૃક્ષ તળે પણ બેસવા દેતાં નહીં. એટલે તેમને માટે કોઈ સ્થળે અન્ન જમાવી બેસવાનું બંધ થયું. હિંદુઓને છેતરવા આવનારા ફકીરો હવે પોતે જ છેતરાવા લાગ્યા.
                          છેવટે કંટાળીને મારવાડમાં ફરનારા બધા જ ફકીરો અજમેર ગયા. અજમેરમાં દામનપીર નામે એક ઓલિયો રહેતો હતો. તેમની પાસે જઈને બધા ફકીરોએ પોતાની વિટંબણા અને જીવનનિર્વાહમાં ભોગવવી પડતી મુશ્કેલીની વાત કરી. દામનપીરે શાંતિપૂર્વક વાત સાંભળી અને કહ્યું કે, તમે મવતાનમાં શૌ પાણી-
                        </p>
                        <p className="paragraph-content" >
                          મસ્તાનાપીર, દગડુધીર, બુજરૂકશા, ગડદિયાપીર, દિલાવરશા અને જબરભપીર પાસે જારૂ અને તેમની સલાહસૂચના પ્રમાણે એ ઉલાવરશા આપ્યું. તેમની સલાહ પ્રમાણે બધા મુલતાનમાં આવ્યા. મસ્તાનાના તકિયે બીજા પીરોને એકઠા કરીને પોતાની મુસીબતો જણાવી. ફકીરોની વાત સાંભળી મસ્તાનાપીર ચિંતામાં પડી ગયા. હિન્દવાપીર નામ સાંભળતાં સૌને નવાઈ લાગી. બયા પીરોનું હું મન પણ આંચકો ખાઈ ગયું. બધા એકમત થઈ મક્કાના મોટા મનાતા મુસાપીર બાવલશા અને ચાવલશા નામના ઘુરંધર પીરને તેડી લાવવા માણસો મોકલ્યા. આમંત્રણ મળવાથી બધા પીરોની મુલતાનમાં સભા ભરાઈ. પીરોની સભામાં હકીરોએ પોતાની કફોડી હાલતની વાત કહી.
                          ફકીરોની વાત સાંભળી મુસાએ ઊંચા અવાજે કહ્યું કે, કોઈ હીન્દવોપીર હોઈ શકે નહીં. જો કોઈ હિન્દુ પોતાને પીર કહેવડાવતો હોય તો આપણે કોઈ પણ ભોગે તેને પીર કહેવડાવતો અટકાવવો જોઈએ. તમે બધા હકીરો ગામેગામ ફરી વળો અને પીર, ઓલિયા, મુજાવર, બાંગી વગેરેને તૈયાર કરી રણુંજા બોલાવો. અમે રણુજા આવીએ છીએ, રણુજામાં સભા ભરી પીર કહેવાતા છોકરાની કસોટી કરી એવા દાખલો બેસાડીશું કે કોઈ વખત કોઈ હિન્દુ પોતાને પીર કહેવડાવવાનું નામ नबे.
                          મુસાપીરની વાત બધાને ગમી અને હજારોની સંખ્યામાં બધાએ રણુંજામાં એકઠા થવાનો નિશ્ચય કર્યો. રામદેવની પીરાઈની પરીક્ષા કરતાં તે હારી જશે. આપલને જરૂર નમી પડશે એવી બધાને ખાતરી થઈ. મુસાપીરે કહેવા માંડયું:
                          ચોપાઈ
                          અજમેર મક્કા ને મુલતાના, પીરાઈમાં પાંચેય સમાના, ચમત્કાર કરવો બહુ ભાંતિ, પીર ન થાયે હિન્દુ જાતિ.
                          મુસાપીરની વાત સાંભળી દામનપીર કહેવા લાગ્યો :
                          ચોપાઈ પાંચેય પીર રણુજા જઈ, પારખવા એની પીરાઈ, મહા સુદ બીજ મંગલવારે, આવી પહોંચો બધાય ત્યાંય. આવતા મંગળવારે મહા સુદ બીજ છે. હિન્દુ જાતિમાં મહા સુદ બીજનો મહિમા
                        </p>
                        <p className="paragraph-content" >
                          વધારે ગણાય છે. બીજને હવે આઠ દિવસ બાકી છે. એ દિવસે દૂર દૂરચ્ય ફકીરો રણુંજામાં આવી પહોંચે એવી ખબર આપવા ગામેગામ ધૂમી વળો.
                          દોહા
                          પીરોના નિરધારની, સાંભળતાં પ્રભુ વાત, અંતરયામી રામદે, અંતરમાં હરખાત. શ્રી રામદેવજીએ પીરોના નિર્ધારની વાત સાંભળી લીધી અને મનમાં ને મત્ર તેઓ આનંદ પામવા લાગ્યા. શ્રી રામદેવજીને હસતા જોઈ વીરમદે, અજમલક અને માતા મિલણદેને આશ્ચર્ય થયું.
                          ચોપાઈ
                          માતાપિતા વિરમદે ભાઈ, ચમ્મર ઢોળે ડાલીબાઈ,
                          હસતા દીઠા રામદે જ્યારે, અચરજ પામ્યાં સર્વ ત્યારે. આમ એકાએક રામદેવજીનું હસવાનું કારણ કોઈ સમજી શક્યું નહીં. માતા મિણલદે અને ડાલીબાઈ પણ રામદેવજીને હસતા જોઈ નવાઈ પામ્યાં. જાણે દૂર દૂર કોઈ દૃશ્ય દેખાતું હોય તેમ તેઓ અંતરીક્ષમાં જોઈને હસતા હતા. અજમલજી ૫૩ આ જોઈને વિચારમાં પડી ગયા. તેમને હસવાનું કશું કારણ ન સમજાયું એટલે
                          અજમલજીએ પૂછ્યું :
                          ચોપાઈ
                          હસ્યા રામ કારણ કહો ભાઈ! પડી ભાંતિ સહના મનમાંહી, વિગતેથી એ વાત જણાવો, અંતર ખોલીને સંભળાવો. 'ભાઈ રામ! આપણે બધાં જે વાતો કરતાં હતાં તેમાં હસવા જેવી કોઈ વાત ન હતી છતાં તમે કેમ હસી પડયા ? તમારા હસવાનું કારણ અમો સમજી શક્યા નથી.
                          દોહા
                          વદી રહ્યા ત્યાં રામદે, ભાંતિ ન ધરો લગાર, પીરો પરચો પરખવા, આવશે અહીં અપાર. વિધર્મીપીરો ટોળે મળીને મારી પરીક્ષા લેવા મંગળવારે અહીં આવવાના છે. એમના મનમાં તર્ક, તરંગ, અહંભાવ અને પીરાઈના પદના મદથી જે વાતો કરતા
                        </p>
                        <p className="paragraph-content" >
                          તતા તે સાંભળીને મને હસવું આવ્યું. અહીંની વાતથી મને હસવું આવ્યું નથી. મહા ૬ બીજના દિવસે હજારો અને લાખો માણસોથી સુદ સને પીરો આવશે. એ લોકોને ખબર નથી ? |ગામ ઊભરાઈ જશે એટલા ફકીરો મેં જ આપી છે. કે, તેમને અહીં આવવાની પ્રેરણા પણ
                          ચોપાઈ
                          અધર્મીઓને સામે લડવું, સ્વધર્મ કેરી શક્તિ બતાવું, અસુરારી થઈને વિચરતી, યુગ યુગ ધર્મની રક્ષા કરતો. અધર્મીઓનો હું રાત્રુ થઈ ધર્મની રક્ષા કરવા તૈયાર રહું છું. એમનાં દુષ્ટ કૃત્યોવાળી આસુરીવૃત્તિઓનો નાશ કરવા, હું પુગે યુગે અવતાર લઈ ધર્મની રક્ષા કરું છું. માટે ટે હું એમના મનમાં પ્રેરણા જગાડી મારા સામે લાવવાનો છું. તમે એમનો માનપૂર્વક આદર સત્કાર કરજો. પ્રજાજનોને પણ આ વાતની જાણ કરી તૈયાર રાખજો. આવનારા પોતાને માત્ર પીર માને છે અને હું હિન્દુપીર કહેવાતો હોવાથી તેઓ મારી પરીક્ષા કરવા ઈચ્છિત . ભોજન માગશે. તે વખતે હું એ વસ્તુ નથી એમ કહું એટલે તેઓ મને હલકો પાખંડી કહેશે. આવો મનસૂબો કરી એ બધા આવવાના છે. તો એમને ભોજનમાં જે માગે તે આપવું. એની જુગતિ ભાઈ વિરમદેજીને હું જણાવીશ, તમે કોઈ ચિંતા કરશો નહીં.
                          3.
                          આ પ્રમાણે અજમલજીને કહી, તેમણે ત્યાં આવેલા પ્રજાજનોને કહેવા માંડયું
                          ચોપાઈ
                          અલખ ઝોળી ને ખલક ખજાનો, પ્રેમ છલોછલ રૂડી પ્રજાનો, જે માગે તેને તે મળશે, વચન સત્ય અમારું પળશે.
                          તમે તમારા ખભે અલખ ઝોળીઓ ભરાવો અને ભોજનમાં જે કોઈ પીર, ફકીર ગમે તે માગે એ વખતે ઝોળીમાં હાથ નાખીને 'અલખ ઝોળી, ખલક ખજાના'નો નાદ કરજો એટલે ધારેલી વસ્તુ હાથમાં આવશે. માટે કોઈએ જરાકે ગભરાવવું કે
                          મૂંઝાવું નહીં. આમ પોતાનાં માતા-પિતા તથા હાજર રહેલાં પ્રજાજનોને હિંમત આપતાં રામદેવજીએ કહ્યું.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
      {/* </div>
      </div> */}
      <div>
        <Footer />
        {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
      </div>
    </>
  );
};

export default BlogDetails6;
