import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AudioPlayer from "../Audio/AudioPlayer";
import Slider from "../Slider/Slider";
const PlanVisit = (props) => {
  const imageWidths = {
    full: '568w',
    medium: '273w',
    small: '370w',
  };
  const img1 = '/assets/wp-content/uploads/2018/12/ramdev-gallery3.jpg';
  const img2 = '/assets/wp-content/uploads/2018/12/post-18-copyright-370x230.jpg';
  const img3 = '/assets/wp-content/uploads/2018/12/post-10-copyright-370x230.jpg';
  const img4 = '/assets/wp-content/uploads/2018/12/post-14-copyright-370x230.jpg';
  const img5 = "/assets/wp-content/uploads/2018/12/contact-icon-1 (1).png";
  const img6 = "/assets/wp-content/uploads/2018/12/contact-icon-2 (1).png";
  const img8 = '/assets/wp-content/uploads/elementor/thumbs/image-about-1-q8yv90fzaluhfh7xhs7w1dqz09tsx85udd2vz2hi4g.png';
  const srcSet = `${img1} ${imageWidths.full}, ${img1} ${imageWidths.medium}, ${img1} ${imageWidths.small}`;
  return (
    <>
      <Helmet>
        <title>Ramapir | PlanVisit </title>
        <meta name="keywords" content="શ્રી રામદેવજીએ 1442માં ભાદ્રપદ શુક્લ એકાદશીના રોજ રાજસ્થાનમાં રામદેવરા (પોખરણથી 10 કિમી) ખાતે 33 વર્ષની વયે સમાધિ લીધી હતી.
                                    રામદેવનું વિશ્રામ સ્થાન ધરાવતું મંદિર સંકુલ રાજસ્થાનમાં રામદેવરા (પૉખરણથી 10 કિમી) ખાતે આવેલું છે. મંદિરનું માળખું 1931માં બિકાનેરના મહારાજા ગંગા સિંહ દ્વારા રામદેવના અંતિમ વિશ્રામ સ્થાનની આસપાસ બાંધવામાં આવ્યું હતું.
                                    રામદેવરા જોધપુરથી જેસલમેર માર્ગ પર પોખરણ નજીક જેસલમેરથી લગભગ 120 કિમી દૂર આવેલું છે. રામદેવરામાં બાબા રામદેવજીનું મંદિર આવેલું છે. વિશ્વભરમાંથી ભક્તો આખા વર્ષ દરમિયાન મંદિરની મુલાકાત લે છે. અહીં ઓગસ્ટ અને ફેબ્રુઆરી મહિનામાં રામદેવરાનો મેળો ભરાય છે અને ભારતભરમાંથી
                                    મોટી સંખ્યામાં ભક્તો તેમાં હાજરી આપે છે.
                                    રામ દેવરા સ્ટેશનના નજીકના રેલ્વે સ્ટેશન દ્વારા મંદિર સુધી પહોંચી શકાય છે" />
      </Helmet>
      <Header />

      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id="46"
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="52ebb12"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          style={{ height: "30rem", marginBottom: "5rem" }}
        >
          <div className="elementor-background-overlay"></div>
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
              data-id="ae9d67e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fcf91b3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                      data-id="93dfaf4"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                          data-id="847c2c5"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                          style={{ height: '8rem' }}
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                          data-id="8fe49e3"
                          data-element_type="widget"
                          data-widget_type="trx_sc_layouts_title.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              id="trx_sc_layouts_title_515615674"
                              className="sc_layouts_title sc_align_center with_content without_image without_tint"
                            >
                              <div className="sc_layouts_title_content">
                                <div className="sc_layouts_title_title">
                                  {" "}

                                </div>
                                <div className="sc_layouts_title_breadcrumbs">
                                  <div className="breadcrumbs">


                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div>
        <h5 className="plan-visit">Plan Visit</h5>
      </div>

      <div className="page_content_wrap" >
        <div className="content_wrap">
          <div className="content">
            <article
              id="post-387"
              className="post_item_single post_type_page post-387 page type-page status-publish hentry"
            >
              <div className="post_content entry-content">
                <div
                  data-elementor-type="wp-post"
                  data-elementor-id="387"
                  className="elementor elementor-387"
                >
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-5266975 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="5266975"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-extended">
                      <div
                        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-f3b64a0 sc_inner_width_none sc_layouts_column_icons_position_left"
                        data-id="f3b64a0"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-0fb77b9 sc_fly_static elementor-widget elementor-widget-image"
                            data-id="0fb77b9"
                            data-element_type="widget"
                            data-widget_type="image.default"
                          >
                            <div className="elementor-widget-container">
                              <img
                                decoding="async"
                                src={img8}
                                title="image-about-1"
                                alt="image-about-1"
                                loading="lazy"
                              />{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0e8c6f9 sc_inner_width_none sc_layouts_column_icons_position_left"
                        data-id="0e8c6f9"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-adb11b2 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                            data-id="adb11b2"
                            data-element_type="widget"
                            data-widget_type="trx_sc_title.default"
                          >
                            <div className="elementor-widget-container">
                              <div
                                id="trx_sc_title_60400323"
                                className="sc_title sc_title_default"
                              >
                                <span className="sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default plan-visit-title">
                                  WELCOME - રામદેવરામાં સમાધિ અને મુખ્ય મંદિર
                                </span>
                                <div className="sc_item_descr sc_title_descr">
                                  <p>
                                    શ્રી રામદેવજીએ 1442માં ભાદ્રપદ શુક્લ એકાદશીના રોજ રાજસ્થાનમાં રામદેવરા (પોખરણથી 10 કિમી) ખાતે 33 વર્ષની વયે સમાધિ લીધી હતી.
                                    રામદેવનું વિશ્રામ સ્થાન ધરાવતું મંદિર સંકુલ રાજસ્થાનમાં રામદેવરા (પૉખરણથી 10 કિમી) ખાતે આવેલું છે. મંદિરનું માળખું 1931માં બિકાનેરના મહારાજા ગંગા સિંહ દ્વારા રામદેવના અંતિમ વિશ્રામ સ્થાનની આસપાસ બાંધવામાં આવ્યું હતું.

                                    રામદેવરા જોધપુરથી જેસલમેર માર્ગ પર પોખરણ નજીક જેસલમેરથી લગભગ 120 કિમી દૂર આવેલું છે. રામદેવરામાં બાબા રામદેવજીનું મંદિર આવેલું છે. વિશ્વભરમાંથી ભક્તો આખા વર્ષ દરમિયાન મંદિરની મુલાકાત લે છે. અહીં ઓગસ્ટ અને ફેબ્રુઆરી મહિનામાં રામદેવરાનો મેળો ભરાય છે અને ભારતભરમાંથી
                                    મોટી સંખ્યામાં ભક્તો તેમાં હાજરી આપે છે.
                                    રામ દેવરા સ્ટેશનના નજીકના રેલ્વે સ્ટેશન દ્વારા મંદિર સુધી પહોંચી શકાય છે, NH 1 એ સૌથી નજીકનો હાઇવે છે જે મંદિરથી ઉત્તર પશ્ચિમથી અને શહેરના ઉત્તર ભાગથી ચાલે છે.
                                    આ સંકુલમાં ડાલીબાઈ જેવા તેમના શિષ્યો અને તેમના કેટલાક મુખ્ય શિષ્યોની સમાધિઓ પણ છે. સંકુલમાં મક્કાના પાંચ મુસ્લિમ પીરની કબરો પણ છે.
                                    તેમાં પાણીના પગથિયાના કૂવા પણ છે, જેનું પાણી ભક્તો માને છે કે તે ઉપચાર શક્તિ ધરાવે છે

                                  </p>
                                </div>
                                <Link to='/About' id="sc_button_212540216" className="sc_button sc_button_default sc_item_button sc_item_button_default sc_title_button sc_button_size_normal sc_button_icon_left">
                                  <span className="sc_button_text">
                                    <span className="sc_button_title">
                                      Read More
                                    </span>
                                  </span>
                                </Link>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-b4f14d9 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="b4f14d9"
                    data-element_type="section"
                    data-settings='{"stretch_section":"section-stretched","background_background":"gradient"}'
                  >
                    <div className="elementor-container elementor-column-gap-extended">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a33f8e6 sc_inner_width_none sc_layouts_column_icons_position_left"
                        data-id="a33f8e6"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          {/* <div
                            className="elementor-element elementor-element-c84429f sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                            data-id="c84429f"
                            data-element_type="widget"
                            data-widget_type="trx_sc_title.default"
                          >
                            <div className="elementor-widget-container">
                              <div
                                id="trx_sc_title_1695216792"
                                className="sc_title sc_title_default rules_section"
                              >
                                <span className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_above sc_item_title_style_default">
                                  RULES
                                </span>
                                <h1 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                                  <span className="sc_item_title_text">
                                    You Have to Know <br /> Monastery Rules
                                  </span>
                                </h1>
                              </div>{" "}
                            </div>
                          </div> */}
                          {/* <div
                            className="elementor-element elementor-element-8a6a6ee sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="8a6a6ee"
                            data-element_type="widget"
                            data-widget_type="spacer.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                              </div>
                            </div>
                          </div> */}
                          {/* <div
                            className="elementor-element elementor-element-3f92847 sc_fly_static elementor-widget elementor-widget-trx_sc_services"
                            data-id="3f92847"
                            data-element_type="widget"
                            data-widget_type="trx_sc_services.default"
                          >
                            <div className="elementor-widget-container">
                              <div
                                id="trx_sc_services_1111039289"
                                className="sc_services sc_services_default sc_services_featured_top blog_section"
                              >
                                <div className="sc_services_columns_wrap sc_item_columns sc_item_posts_container sc_item_columns_3 trx_addons_columns_wrap columns_padding_bottom">
                                  <div className="trx_addons_column-1_3">
                                    <div className="sc_services_item no_links with_content with_image sc_services_item_featured_top post-327 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-programs">
                                      <div className="post_featured with_thumb hover_icon sc_services_item_thumb">
                                        <img
                                          loading="lazy"
                                          width="370"
                                          height="230"
                                          src={img2}
                                          className="attachment-vihara-thumb-services size-vihara-thumb-services wp-post-image"
                                          alt=""
                                          decoding="async"
                                        />{" "}
                                        <div className="mask"></div>
                                        <div className="icons">
                                          <Link to='/About2' aria-hidden="true" style={{ color: 'rgb(29,50,38)' }}>+</Link>
                                        </div>
                                      </div>
                                      <div className="sc_services_item_info">
                                        <div className="sc_services_item_header">
                                          <h4 className="sc_services_item_title">
                                            <a>
                                              The Happiness Program
                                            </a>
                                          </h4>
                                        </div>
                                        <div className="sc_services_item_content ">
                                          <p className="p_sep_section" style={{ marginTop: '50px' }}>
                                            {" "}
                                            The practical knowledge of
                                            breathing techniques gives you
                                            balance and wisdom.
                                          </p>
                                        </div>
                                        <div className="sc_services_item_button sc_item_button">
                                          <Link to='/About2' aria-hidden="true"
                                            className="sc_button sc_button_default sc_button_size_small"
                                          >
                                            More Info
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="trx_addons_column-1_3">
                                    <div className="sc_services_item no_links with_content with_image sc_services_item_featured_top post-326 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-programs">
                                      <div className="post_featured with_thumb hover_icon sc_services_item_thumb">
                                        <img
                                          width="370"
                                          height="230"
                                          src={img3}
                                          className="attachment-vihara-thumb-services size-vihara-thumb-services wp-post-image"
                                          alt=""
                                          decoding="async"
                                          loading="lazy"
                                        />{" "}
                                        <div className="mask"></div>
                                        <div className="icons">
                                          <Link to='/About2' aria-hidden="true" style={{ color: 'rgb(29,50,38)' }}>+</Link>
                                        </div>

                                      </div>
                                      <div className="sc_services_item_info">
                                        <div className="sc_services_item_header">
                                          <h4 className="sc_services_item_title">
                                            <a>
                                              Sahaj Samadhi Meditation
                                            </a>
                                          </h4>
                                        </div>
                                        <div className="sc_services_item_content">
                                          <p>
                                            The state of samadhi will help you
                                            experience deep inner peace as
                                            well as increase self-awareness.
                                          </p>
                                        </div>
                                        <div className="sc_services_item_button sc_item_button">
                                          <Link to='/About2' aria-hidden="true"
                                            className="sc_button sc_button_default sc_button_size_small"
                                          >
                                            More Info
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="trx_addons_column-1_3">
                                    <div className="sc_services_item no_links with_content with_image sc_services_item_featured_top post-325 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-programs">
                                      <div className="post_featured with_thumb hover_icon sc_services_item_thumb">
                                        <img
                                          width="370"
                                          height="230"
                                          src={img4}
                                          className="attachment-vihara-thumb-services size-vihara-thumb-services wp-post-image"
                                          alt=""
                                          decoding="async"
                                          loading="lazy"
                                        />{" "}
                                        <div className="mask"></div>
                                        <div className="icons">
                                          <Link to='/About2' aria-hidden="true" style={{ color: 'rgb(29,50,38)' }}>+</Link>
                                        </div>
                                      </div>
                                      <div className="sc_services_item_info">
                                        <div className="sc_services_item_header">
                                          <h4 className="sc_services_item_title">
                                            <a>
                                              Advanced Meditation Program
                                            </a>
                                          </h4>
                                        </div>
                                        <div className="sc_services_item_content">
                                          <p>
                                            For an extraordinary sense of
                                            peace, you have to go beyond your
                                            habitual active mind.
                                          </p>
                                        </div>
                                        <div className="sc_services_item_button sc_item_button">
                                          <Link to='/About2' aria-hidden="true"
                                            className="sc_button sc_button_default sc_button_size_small"
                                          >
                                            More Info
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-623c898 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="623c898"
                    data-element_type="section"
                    style={{ marginTop: '80px' }}
                  >
                    <div className="elementor-container elementor-column-gap-extended">
                      <div
                        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-297030c sc_inner_width_none sc_layouts_column_icons_position_left"
                        data-id="297030c"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-e8eef12 sc_fly_static elementor-widget elementor-widget-image"
                            data-id="e8eef12"
                            data-element_type="widget"
                            data-widget_type="image.default"
                          >
                            <div className="elementor-widget-container">
                              <img
                                decoding="async"
                                width="568"
                                height="150"
                                src={img1}
                                className="attachment-full size-full wp-image-390"
                                alt="schedule image 1"
                                loading="lazy"
                                srcSet={srcSet}
                                sizes="(max-width: 568px) 100vw, 568px"
                              />{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c2daaed sc_inner_width_none sc_layouts_column_icons_position_left"
                        data-id="c2daaed"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-28d84b6 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                            data-id="28d84b6"
                            data-element_type="widget"
                            data-widget_type="trx_sc_title.default"
                          >
                            <div className="elementor-widget-container">
                              <div
                                id="trx_sc_title_1222012286"
                                className="sc_title sc_title_default"
                              >
                                <span className="sc_item_subtitle sc_title_subtitle sc_item_subtitle_above sc_item_title_style_default schedule-title">
                                  SCHEDULE - અનુસૂચિ

                                </span>
                                <h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
                                  <span className="sc_item_title_text">
                                    Our Daily <br />
                                    Schedule - શ્રી બાબા રામદેવ મંદિર રામદેવરા દર્શનનો સમય

                                  </span>
                                </h1>
                              </div>{" "}
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-cadadca sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="cadadca"
                            data-element_type="widget"
                            data-widget_type="spacer.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-a93079d sc_fly_static elementor-widget elementor-widget-text-editor"
                            data-id="a93079d"
                            data-element_type="widget"
                            data-widget_type="text-editor.default"
                          >
                            <div className="elementor-widget-container schedule_text">
                              <p className="schedule_text">
                                Temple is open every day from 4.00 A.M. to 9.00 P.M. Except Winter, when it is
                                open from 5:00 AM. till 9:00 P.M. This is our habitual schedule:
                              </p>{" "}
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-8525d1c sc_fly_static elementor-widget elementor-widget-spacer"
                            data-id="8525d1c"
                            data-element_type="widget"
                            data-widget_type="spacer.default"
                          >
                            <div className="elementor-widget-container">
                              <div className="elementor-spacer">
                                <div className="elementor-spacer-inner"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="elementor-element elementor-element-34f7a4c sc_fly_static elementor-widget elementor-widget-text-editor"
                            data-id="34f7a4c"
                            data-element_type="widget"
                            data-widget_type="text-editor.default"
                          >
                            <div className="elementor-widget-container ">
                              <ul className="trx_addons_list_custom schedule_text1" style={{ marginTop: '15px' }}>
                                <li>
                                  મંદિર દરરોજ સવારે 4.00 થી રાત્રે 9.00 વાગ્યા સુધી ખુલ્લું રહે છે.
                                </li>
                                <li>
                                  શિયાળામાં તે સવારે 5:00 થી રાત્રે 9:00 વાગ્યા સુધી ખુલ્લું રહે છે.
                                </li>
                                <li>
                                  <span>બાબા રામદેવ મંદિર રામદેવરા આરતી દર્શનનો સમય
                                  </span>
                                </li>
                                <li>
                                  મંગળા આરતી (અભિષેક) શિયાળામાં સવારે 5 વાગ્યે અને ઉનાળામાં સવારે 4 વાગ્યે
                                </li>
                                <li>
                                  સવારે 8 વાગ્યે ભોગ આરતી
                                </li>
                                <li>
                                  બપોરે 3:45 વાગ્યે આરતી
                                </li>
                                <li>
                                  સાંજની આરતી સાંજે 7:00 વાગ્યે
                                </li>
                                <li>
                                  રાત્રે 9:00 વાગ્યે શયન આરતી
                                </li>
                              </ul>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="elementor-widget-container">
            <div
              id="trx_sc_icons_528506280"
              className="sc_icons sc_icons_default sc_icons_size_medium sc_align_center"
            >
              <div className="sc_icons_columns_wrap sc_item_columns trx_addons_columns_wrap columns_padding_bottom">
                <div className="trx_addons_column-1_2">
                  <div className="sc_icons_item">
                    <div className="sc_icons_image">
                      <img
                        decoding="async"
                        loading="lazy"
                        src={img5}
                        alt="Icon"
                        width="116"
                        height="116"
                      />
                    </div>
                    <div className="sc_icons_item_details">
                      <h4 className="sc_icons_item_title">
                        <span>Address</span>
                      </h4>
                      <div className="sc_icons_item_description">
                        <span>B-816, Stratum @Venus Ground, Opp. Jhansi ki rani BRTS bus stand, Nehrunagar, Ahmedabad, Gujarat, India</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="trx_addons_column-1_2">
                  <div className="sc_icons_item">
                    <div className="sc_icons_image">
                      <img
                        decoding="async"
                        loading="lazy"
                        src={img6}
                        alt="Icon"
                        width="116"
                        height="116"
                      />
                    </div>
                    <div className="sc_icons_item_details">
                      <h4 className="sc_icons_item_title">
                        <span>Mail</span>
                      </h4>
                      <div className="sc_icons_item_description">
                        <a href="mailto:kartik@stalwartitsolution.com">
                          kartik@stalwartitsolution.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
          {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay }) } /> */}
        </div>
        <Footer />
      </div>
    </>
  );
};
export default PlanVisit;