import React from "react";
import Header from "../Header";
import './index.css';

const Footer = () => {
  return (
    <>
      
      <footer className="bg-body-tertiary text-center text-lg-start">
        <div
          className="text-center p-3 footer-section"
        >
        Design & Developed by -&nbsp; 
          <a className="text-body" href="http://stalwartitsolution.com" target="_blank" style={{ textDecoration: 'none',color : '#84e584!important' }}>
          Stalwart It Solution
          </a>
        </div>
      </footer>
    </>
  )
};

export default Footer;
