import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails13 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery15.jpg";
    return (
        <>
         <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}


            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                   
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            સંત લખમો માળી


                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                    
                                                >
                                                    <span className="income">સંત લખમો માળી

                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content">
                                                    ફૂલવાડી ફોરી રહી, તીર્થ રણુંજા ધામ, રામ ભાગને રક્ષતો, લખમો માળી નામ. સવારમાં ફૂલછાબ લઈ, ગૌશાળમાં જાય, ડાલીબાઈને મંદીને, ફૂલછાબ દે ત્યાંય. વીણી વિવિધ ફૂલડાં, ડાલી ગૂંથે હાર, જઈ પહેરાવે પીરને, પ્રભુ કરે સ્વીકાર. નેક ટેક ને નિયમમાં, ચળે ન ચિત્ત લગીર, નિશદિન ભક્તિભાવમાં, પ્રસન્ન રામદેપીર.
                                                    મેવાડમાં મેવાસા ગામના રાજા રાહોલ માલો તથા તેની પત્ની સતી રૂપ મેઘ-ધારૂઆનાં શિષ્ય હતાં. એક દિવસ તેઓ મલ્લીનાથના મસુરિયા બાગ પર સંતમેળામાં ગયા અને મલ્લીનાથના ધૂણે ઉતારો રાખ્યો. આ સંતમેળામ સાધુસંતોની પ્રેમભાવે સેવા કરનારો એક યુવાન સૌનું ધ્યાન ખેંચતો હતો. કે યુવાન પણ મેઘ ધારૂઆના દિવ્ય તેજથી અંજાઈ મલ્લીનાથની આજ્ઞાથી ધારૂઆનો શિષ્ય બન્યો. એનું નામ લખમશી હતું.
                                                    લખમશી ફૂલછોડની જાતો, રોપણ, પોષણ અને ઉછે૨માં પાવરધો હતો; એન અખતરા પણ એવા કે ફૂલછોડની કલમ એકબીજા છોડમાં દાખલ કરી એક છોડ્ય બે જાતનાં પુષ્પો ઉગાડી નવી જ સુગંધ મહેકાવતો. તે મેવાસા જતાં પહેલ રામદે’જીના દર્શને આવ્યો . વાતમાંથી વાત નીકળતાં અજમલજીની ઇચ્છા ફૂલવા
                                                    કરવાની હોવાથી તેમણે લખમશીને ત્યાં રોકયો .
                                                    દોહા
                                                    કૃપા પ્રભુની મેળવ્યું, ભક્તિવંતો થાય, ભવબંધન છૂટી જતાં, જીવ શિવ થઈ જાય.
                                                    લખમશી હવે અજમલજીની ઇચ્છા પ્રમાણે રામબાગમાં ફૂલછોડ વાવવ

                                                </p>
                                                <p className="paragraph-content">
                                                    લવાડીની સુગંધ દૂર દૂર સુધી પ્રસરવા લાગી. લખમશીને સહુ લખમો માળી . લેખની પર રામદેવજીનો પ્રેમ વધતો ગયો. એણે એવી ફૂલવાડી બનાવી ભક્ત પણ કહેતા. ભજનભાવમાં એને બહુ પ્રેમ હોવાથી જ્યાં હોય ત્યાં તે પહોંચી જતો. એની બુદ્ધિ તીવ્ર હોવાથી સત્સંગની પણ ભાગ લેતો. આથી એને ઘણા માણસો ભક્ત પણ કહેતા હતા.
                                                    જ્ઞાનચર્ચામાં
                                                    જોમાં તેને કોઈ શબ્દ ન સમજાય તો તે ઘેર આવીને તે શબ્દ પર વિચાર મનન
                                                    રો પછી જ ઊંઘતો.
                                                    લખમાનો નિત્ય-નિયમ હતો કે, બ્રહ્મ મુહૂર્તમાં ઊઠી, ક્વે સ્નાન કરી, પવિત્ર - ધોયેલાં કપડાં પહેરી, ફૂલવાડીને જળ અંજલિથી વધાવીને પછી પુષ્પો વીણવાં મને ફૂલોથી ફૂલછાબ ભરી પ્રાતઃકાળે ડાલીબાઈની ગૌશાળામાં જઈને આપવી. રંગ પ્રમાણે વિવિધ રીતે પુષ્પોની ગૂંથણી કરી ડાલીબાઈ ફૂલમાળા બનાવતી અને સવારના છ વાગ્યે રામદેવજી સ્નાન કરી વસ્ત્રો ધારણ કરી રહ્યા હોય એ સમયે જમહેલમાં જઈ, રામદેવજીને વંદન કરી ફૂલહાર પહેરાવતી. છૂટાં પુષ્પો પૂજા માટે મૂકી આવતી.
                                                    અજમલજીને
                                                    ચોપાઈ
                                                    ઘસ્યું પાવઠું મૂકતા પાયે, કૂવા મહીં લખમો પટકાયે, ભેખડ ભારે અંગ દટાયે, મસ્તક લોહી લુહાણ થાયે. નિત્ય-નિયમ મુજબ ડાલીબાઈને ફૂલછાબ આપીને રામદેવજીની પ્રિય ગાય રામેતીને નમન કરીને લખમો બાગમાં આવ્યો. કૂવામાંથી એક ડોલ પાણી ખેંચવા પાવઠે પગ મૂકતાં કાંઠાની ભેખડ ધસી અને લખમો ભેખડના ભાર તળે દબાયો અને કાળ ફૂટી જતાં લોહીની ધારા નીકળવા લાગી. ધડી વાર તે મૂર્છિત થઈ ગયો. થોડી વારે કંઈક ચેતન આવતાં એ ભેખડના ભારમાંથી નીકળવા મહેનત કરવા લાગ્યો, પણ એ નીકળી શક્યો નહીં. તેને લાગ્યું કે હવે હું બચી શકીશ નહીં. તેથી તે રામદેવજીને અંતિમ આર્તનાદે પુકાર કરવા લાગ્યો :
                                                    “વેલાનાં વછોયા રે, ભવે ભેળા નહીં રે થઈએ રે.'
                                                    કે મહારાજ ! કૃપાનાથ ! દીનદયાળ ! મારી છેલ્લી ફૂલછાબનો હાર | સ્વીકારજો. ભગવાન ! હું આપની સેવાભક્તિમાં જીવન ગુજારી રહ્યો હતો પણ | મારા પાપી પગલે રામકૂવાનું પાવડું તોડ્યું. હું મારી શ્રદ્ધા-ભક્તિમાં હારી ગયો. વે હું નહીં જીવું, કારણ કે મારે બચવાનું બારું નથી. ભેખડ એટલી બધી ભારે છે. તેમાંથી કોઈ કાળે નીકળી શકાય તેમ નથી.

                                                </p>
                                                <p className="paragraph-content">
                                                    દેહ દટાણો કૂપમાં, હવે ન ટકશે અંગ, શરણે લો સેવકગણી, નેજાધારી નકળંગ,
                                                    તે રામદેવજીને આરાધ કરતો પોતાના સંભારણાં સંભારીને ઊંચા
                                                    કહેવા લાગ્યો :
                                                    રે,
                                                    બેલીડાની સાથે રે બજારૂમાં માલતા એજી... બેલીડો બેદલ થયો છે આજ...
                                                    મને સાથે રાખી બેલી કહીને બોલાવતા. આપ તો દુ:ખીયાના બેલી છો, પરંતુ તા ‘હે નાથ ! આપ જ્યારે લીલુડા ઘોડલે સવારી કરી બજારમાં નીકળતા એણે તો મને બેલીડા બેલીડા ! કહીને સાથે લઈને બજારુંમાં મહાલતા એ યાદી કે ભુલાય ? આપની સેવાની અમરવેલમાં લખમો પાંદડારૂપ હતો. એ વેલાથી વ છૂટી ગયું હોય એમ મારો દેહ હવે ટકશે નહીં. આ ભવે હવે ભેળા નહીં થવા આપની ઓળખાણ અધૂરી રહી. એ ઓળખાણ કુંજડા પક્ષી જેવી પણ ન રહી
                                                    એમ કહી બોલ્યો :
                                                    કુંજલડી વિયાણી રે, ઈંડાં મેલ્યા બેટમાં રે, એજી... પંખીડે લીધી વિદેશ કેરી વાટ, ત્રણ ચાર માસે રે, આવી બચ્ચા ઓળખ્યા રે, એજી... પરખ્યા ઉદરે ઘડિયલ ઘાટ, વેલાનાં વછોયા રે ભવે ભેળાં નહીં થઈએરે.
                                                    ધૂનધણી ! બાબા રામદે’જી ! કુંજડા પંખીનાં ટોળાં આકાશમાર્ગે ઊડતાં રિયાના બેટમાં વિસામો લેવા ઊતર્યા. એમાં કુંજડી માદાઓના વૃંદે દરિયાન ટમાં ઈંડાં મૂક્યાં. તે પછી એ વિદેશની વાટે ઊડીને ચાલ્યાં ગયાં. એના વિશ્વાસ ને શ્રદ્ધાથી સાગરદેવ પ્રસન્ન થયા અને...
                                                    મેરામણ માયાળું ૨ે ઈંડા એવાં જાળવ્યાં રે, એજી... ઈંડાથી બચડાં ડિઝ એવા વિશ્વાસ ભક્તિ રે પંખીડાં જાણતાં રે, રમતાં થાય. ર. એજી... મારા મનની રહી છે મનમાંય.
                                                    વેલાના વછોયા રે ભવે ભેળાં નહીં થઈએ રે. હરિ
                                                    .**
                                                    આવો એક ધારો આર્તનાદ સાંભળી પીરસાયેલા ભોજનનો ત્યાગ કરી
                                                    । દોડતા આવ્યા અને કૂવાકાંઠે આવી... ‘લખમ્મા !... ખમા !...
                                                    દેવજી તુરત
                                                    જ જાવ ! એમ કહી બે હાથ પસારી ભેખડને સ્પર્શતાં ચેડાં પોડાં પથ્થરો કરે
                                                    તેમણે લખમાને ઉપાડી બહાર
                                                </p>
                                                <p className="paragraph-content">
                                                    એક ધારો આર્તનાદ સાંભળી પીરસાયેલા ભોજનનો ત્યાગ કરી હજરત દાડતા આવ્યા અને કૂવાકાંઠે આવી... 'લખમ્મા !... લખમ્મા !... અને બ્રેઇને પોતાનાં કમર ફટકાને ફાડી કપાળે પાટો બાંધ્યો અને તેના શરીરે ગયા. તેમણે લખમાને ઉપાડી બહાર કાઢયો. એનાં કપાળમાંથી નીકળતા
                                                    એમ કહી બે હાથ પ્રસારી ભેખડને સ્પર્શતાં રોડાં થોડાં પથ્થરો કરે
                                                    હાથ ફેરવીને
                                                    પૂછવા લાગ્યા :
                                                    ‘લખમા ! હવે પીડા થાય છે ?'
                                                    કૃપાનાથ ! આપે મને કૂવા બહાર કાઢતાં હાથ પકડ્યો ત્યારે મારા ક્ષણભંગુર માં કોઈ અણમોલ શક્તિએ સ્થાન લીધું હોય તેવો દિવ્ય પ્રકાશ થયો હતો . પાટો આર્યતા આપે માથે હાથ મૂક્યો તે વેળા મને બ્રહ્મતેજ જણાયું. અત્યારની નહીં પણ કાર્યો રાજા ભવની પીડા ગઈ. પણ
                                                    પાટાનો
                                                    બાંધનારો રે, એ શું જાણે પીડને રે, એજી...એ શું જાણે જનની મનની પીડ. એવો લખમો માળી કે છે રે, વીતેએ જન વર્ણવે રે, વેલાના વછોયા રે, ભવે ભેળાં નહીં રે થઈએ રે. નાથ ! જે વેલાથી પાંદડું વિખૂટું થઈ ગયું તે આ વેલા ભેળું નહીં ભળે, એમ લાગ્યું હતું, પણ આપની કૃપાથી મારો ભવ સુધરી ગયો. હવે પછીની પીડા સહન નહીં થાય, એનો ઉપાય આપના હાથમાં છે.
                                                    ‘હવે તારી શી ઇચ્છા છે લખમા ?' રામદેવજીએ પૂછ્યું.
                                                    ‘નાથ ! પ્રસૂતિની પીડા કેવી હોય છે તે પ્રસૂતા સ્ત્રી જ જાણે અને માતાના દરમાં નવ માસ ઊંધે મસ્તક રહેલો જે પીડા સહન કરે છે, તે જાણતો હોય છે. મૈક બ્રહ્મમાંથી ઉદ્ભવી બીજા બ્રહ્મનું પ્રાગટ્ય થાય છે, એવી આપની રમત છે. એ જન્મ અને મરણની પીડાથી મને મુક્ત કરો દયાળુ !
                                                    દોહા
                                                    બેસી ! ઝામ્યું બાવ કાર્ય મૂક્યો પ
                                                    મુજ ઇચ્છા પૂરી કરો, હૃદય બિરાજી નાથ.
                                                    ધરા એ જ મારી ઇચ્છા છે. તે પૂરી કરો. ' લખમે પ્રાર્થના કરતાં કહ્યું. લખમા આપે મારો હાથ ઝાલ્યો છે, માથે હાથ મૂક્યો છે, તો આપ મારા હૃદયમાં કની પર્ણ કૃપા થઈ. એની નિષ્કામ ભક્તિથી પ્રસન્ન

                                                </p>
                                                <p className="paragraph-content">
                                                    ચોપાઈ
                                                    રામસાગરને સાથે લેજે, ભજન ભાવમાં શૂરો રેજે, મનમાં કલ્પિશ તેવું થાશે, પરચાધારી થઈ પૂજાશે. ‘લખમા ! તું ભજનભાવમાં રામસાગર તંબૂરો સાથે રાખજે. તંબુરાનો રણઝણતાં તારા મુખમાંથી જ્ઞાનવાણી પ્રગટ થશે. તે વાણીને તું મારા પ્રસાદ રૂપ
                                                    માનજે.'
                                                    દોહા
                                                    વાણી વચન દઈ રામદે, ત્યાંથી થયા વિદાય, લખમાને તે સમયથી, પરા જ્ઞાન પ્રગટાય.
                                                    તામિ
                                                    શ્રી રામદેવજીએ લખમાને વાણીરૂપે જ્ઞાનનું દાન આપ્યું અને તેઓ ત્યાંથી વિદાય થયા.

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails13;
