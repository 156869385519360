import React from "react";
import Header from "../Header";
import Footer from "../Footer";

import { Link } from "react-router-dom";
import AudioPlayer from "../Audio/AudioPlayer";

const BlogDetails17 = (props) => {
  const img1 = "/assets/wp-content/uploads/2018/12/ramdev-gallery19.jpg";
    return (
        <>
         <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}


            <Header />
            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                   
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="menu_mobile_overlay"></div>
      <div className="menu_mobile menu_mobile_fullscreen scheme_inherit">
        <div className="menu_mobile_inner">
          <a className="menu_mobile_close icon-cancel"></a>
          <Link to={'/'} className="sc_layouts_logo">

            <img
              src={logoimg}
              alt="Vihara"
              width="134"
              height="78"
            />{" "}
          </Link>
          <nav
            className="menu_mobile_nav_area"
            itemScope
            itemType="//schema.org/SiteNavigationElement"
          >
            <ul id="menu_mobile_1510458944">
              <li
                id="menu_mobile-item-203"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-203"
              >
                <a href="/">
                  <span>Home</span>
                </a>

              </li>
              <li
                id="menu_mobile-item-204"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-204"
              >
                <a href="#">
                  <span>Gallery</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-209"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-209"
                  >
                    <a href="/Audio">
                      <span>Audio</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-208"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-208"
                  >
                    <a href="/Video">
                      <span>Video</span>
                    </a>
                  </li>
                  <li
                    id="menu_mobile-item-206"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                  >
                    <a href="/Gallery2">
                      <span>Images</span>
                    </a>
                  </li>

                </ul>
              </li>
              <li
                id="menu_mobile-item-224"
                className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children menu-item-224"
              >
                <a href="#">
                  <span>About</span>
                </a>
                <ul className="sub-menu">
                  <li
                    id="menu_mobile-item-447"
                    className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-407 current_page_item menu-item-447"
                  >
                    <a
                      href="/About"
                      aria-current="page"
                    >
                      <span>Ashram</span>
                    </a>
                  </li>

                  <li
                    id="menu_mobile-item-449"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-449"
                  >
                    <a href="/plan-visit">
                      <span>Plan a Visit</span>
                    </a>
                  </li>
                </ul>
              </li>

              <li
                id="menu_mobile-item-316"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-316"
              >
                <a href="/BlogMain">
                  <span>Stories</span>
                </a>
              </li>
              <li
                id="menu_mobile-item-312"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"
              >
                <a href="/Books">
                  <span>Books</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
 */}



            <div className="page_content_wrap blog-details">
                <div className="content_wrap" >
                    <div className="content">
                        <div id="container" className="give-wrap container">
                            <div id="content" role="main">
                                <div
                                    id="give-form-288-content"
                                    className="post-288 give_forms type-give_forms status-publish has-post-thumbnail"
                                >
                                    <div
                                        id="give-sidebar-left"
                                        className="give-sidebar give-single-form-sidebar-left"
                                    >
                                        <div className="images">
                                            <img
                                                width="1024"
                                                height="576"
                                                src={img1}
                                                className="attachment-large size-large wp-post-image blog-img"
                                                alt=""
                                                decoding="async"
                                                sizes="(max-width: 1024px) 100vw, 1024px"
                                            />
                                        </div>
                                    </div>
                                    <div className="summary entry-summary">
                                        <h2 itemProp="name" className="give-form-title entry-title">
                                            સગુણાબહેનને કંકોતરી


                                        </h2>{" "}
                                        <div
                                            id="give-form-288-wrap"
                                            className="give-form-wrap give-display-onpage"
                                        >
                                            <div className="give-goal-progress">
                                                <div
                                                    className="raised blog-title"
                                                    
                                                >
                                                    <span className="income">સગુણાબહેનને કંકોતરી

                                                    </span>
                                                </div>
                                            </div>

                                            <div
                                                id="give-form-content-288"
                                                className="give-form-content-wrap give_pre_form-content"
                                            >
                                                <p className="paragraph-content" >
                                                    શ્રી રામદેવજીની ઉંમર વીસ વરસની થઈ. રામદેવજી તો
                                                    । હિંદવાપીર છે એ
                                                    લોકવાયકા દેશપરદેશ ફેલાઈ. રામદેવજીની પીરાઈ અને ચમત્કારો વિષે ઉમરકોટના સોઢારાજા જયવંતસિંહના દરબારમાં બિરદાવવા આવેલા ભાર કવિઓએ રામદેવજીના ચમત્કારોની પ્રશંસા કરી અને તેઓ હજુ કુંવારા છે એ વાત
                                                    વાતમાં કહી નાખ્યું.
                                                    સોઢારાજાને નેતલદે નામે અષ્ટવંકી એકરૂપી પુત્રી હતી. કોઈ પણ રાજપૂત તેને પરણતો ન હોવાથી રાજા મૂંઝાતા હતા. રામદેવજીની પ્રશંસાથી સોઢાજીનું મન આકર્ષાયું. કચેરી બરખાસ્ત કરી રાણી પાસે રામદેવજીની સાંભળેલી પ્રશંસાની વાત કરી. નેતલદે પણ સાંભળવામાં તલ્લીન થઈ ગયાં હતાં તે બોલી ઊઠ્યાં : ‘માતા મારાં લગ્ન તો રામદેવજી સાથે જ થવાનાં છે.'
                                                    ‘બેટા ! તારા અંગમાં અનેક ખોડો છે, તેથી તને કોઈ સ્વીકારતું નથી. તો રામદેવજી શી રીતે સ્વીકારશે ?’

                                                    ‘માતાજી ! હું મનથી તેમની ભક્તિ કરું છું. તેઓ મને જરૂર સ્વીકારશે.' સોઢારાયે નેતલદેની આ વાત સોઢા સરદારો પાસે કરીને કહ્યું : ‘રણુંજામાં અજમલજીના કુંવર રામદેવજી સાથે નેતલનાં લગ્નનું કહેણ મોકલીએ તો કેમ ?'
                                                    સોઢા સરદારો વાત સાંભળી કહેવા લાગ્યા કે, એ અસંભવિત છે છતાં આપણું સોઢાકુળ છે, રાજ્ય માતબર છે, અજમલજી તો રણુંજા ને પોકરણગઢ એમ બે જ ગામના ગરાસદાર હોઈ અંજાઈને લગ્ન સ્વીકારે તો ના નહીં.
                                                    ‘તો આપણે રણુંજા સગાઈનું શ્રીફળ મોકલીએ અને પખવાડિયામાં જ લગ્નનું કહેણ મોકલીએ.'
                                                    સહુ એકમત થતાં રાજગોરને શ્રીફળ અને લગ્નપડો આપી રણુંજા મોકલ્યા. અજમલરાય ડાયરો જમાવી બેઠા છે, ત્યાં રાજગોરે આવી અજમલજીને આશીર્વાદ દીધા. અજમલજીએ રાજગોરનો સત્કાર કરી બેસાડ્યા અને પૂછ્યું : ‘ક્યાંથી આવો છો ગોર ?'

                                                </p>
                                                <p className="paragraph-content">
                                                    “હું ઉમરકોટથી આવું છું.” ગોરે કહ્યું. પછી તેણે લગ્નનો છો ને શ્રીફળ અજમલજીને આપ્યાં. તેમણે સોઢાજીએ મોકલેલી રામદેવજની લગ્નપત્રિકા વાંગી. ડાયરામાં બેઠેલા તુંવાર રાજપૂતો પત્રિકા સાંભળી ખુશ થયો. અજમલજી પણ મ થયા. તેમણે વિચાર્યું કે રાજ્ય મોટું છે તો સંબંધ બંધાય તો મોટી ઓથ મળે. આવી તકને વધાવી લેવી જોઈએ એમ વિચારી શિરપાવ આપી રાજી કરી ગૌરને વિદાય
                                                    આપતાં કહ્યું :
                                                    ‘ભૂદેવ ! સોઢારાજાને કહેજો કે લગ્નની તૈયારી કરે, અમે નક્કી કરેલી તિથિએ જાન લઈને આવીશું.'
                                                    રાજગોર કામ સફળ થવાથી સારો શિરપાવ મળશે, એ આશાએ ઉમરકોટ આવી ખબર આપ્યા. સોઢારાય દીકરી ઠેકાણે પડવાથી ખુશ થયા.
                                                    રણુંજામાં સૌએ શેરી ચૌટાં શણગારવા માંડ્યા અને રામદેવજીના લગ્નની ધામધૂમથી તૈયારી કરવા લાગ્યા. શુભ મુહૂર્તે અજમલજીએ કંકોત્રીઓ લખાવી. લગ્નનો દિવસ નજીક આવવા લાગ્યો. સૌ સગાંસંબંધી આવી ગયાં. રામદેવજીની વહાલસોયી બહેનો લાસા અને લક્ષ્મી આવ્યાં, પણ સગુણાબહેન આવ્યાં ન હતાં. રામદેવજીના મનમાં થતું હતું કે, હજુ સગુણાબહેન કેમ નથી આવ્યાં. તેમણે માતાજીને વાત કરી. મિણલદેએ કહ્યું : ‘ભાઈ, સગુણાના સાસરિયાના પઢિયાર રાજપૂત સાથે તમારા પિતાને વાંધો પડ્યો છે. આજ વીસ વીસ વરસ થયા છતાં સગુણાને મોકલતા નથી. તેઓ મોટા એટલે આપણે શું કરીએ ?’
                                                    ‘માતાજી બહેનને કંકોત્રી મોકલી તેડાવો, એમના વિના તો મને બહુ દુઃખ થશે.' શ્રી રામદેવજીએ કહ્યું.
                                                    *બેટા, લગ્નનો વખત થોડો રહ્યો છે અને પિંગલગઢ જતાં- આવતાં એક મહિનાનો સમય લાગે. હવે લગ્નને અઠવાડિયું જ બાકી રહ્યું છે.’
                                                    મિણલદેનાં દુઃખી વચનોથી રામદેવજીના અંતરમાં આઘાત લાગ્યો. તે પોતે જ સગુણાબહેનને તેડવા જવા તૈયાર થયા.
                                                    મિણલદેએ તેમને સમજાવતાં કહ્યું : ‘પીઠી ચોળેલા શરીરે બહાર ન નીકળાય તો પછી પરગામ કેમ જવાય ? જો તમારો આગ્રહ હોય તો બહેનને કોઈની જોડે કંકોત્રી મોકલી તેડાવો’
                                                    રામદેવે કંકોત્રી તૈયાર કરી. સાંઢણીસ્વાર રતના રાયકાને સમરથિયા નાઈને
                                                    મોકલી તેડાવ્યો. રતનો આવતાં કહ્યું, ‘રતના, તારું અગત્યનું કામ પડ્યું છે.'

                                                </p>
                                                <p className="paragraph-content">
                                                    ‘દેવ ! તમારી આજ્ઞાની જ વાર, જે કામ હોય તે કહો !'
                                                    'તારે અત્યારે જ પીંગલગઢ જઈ સગુણાબહેનને આ લગ્નની કંકોત્રી
                                                    આપી તેડી લાવવાનાં છે.”
                                                    ‘ભલે મારા બાપ, અત્યારે જ સાંઢિયો તૈયાર કરું છું, પણ.... “પણ શું ? રતના કેમ અચકાયો ?' રામદેવે પૂછ્યું.
                                                    હાથોમ
                                                    ‘અત્યારે એક જ ઘરડો સાંઢિયો છે. લાંબી વાટે ધાર્યા સમયે પહોંચીશ કે નહીં
                                                    એ વિચારે જરાક અચકાયો.'
                                                    ‘ફિકર ન કર સાંઢિયો ભલે ઘરડો હોય, હું મૂંઝાઈશ નહીં. રતના, તું તહે
                                                    તૈયાર થા. કાંઈ ભીડ પડે તો મને યાદ કરજે.'
                                                    ‘ભાઈ રતના ! જોજે હોં ! પઢિયાર બહુ આકરા સ્વભાવના છે. વિવેક રાખી
                                                    કળથી કામ લેજે ભાઈ !' મિણલદેએ સૂચના આપતાં કહ્યું.
                                                    “એની ફિકર ન કરશો માતાજી ! પઢિયાર ભલે આકરા સ્વભાવના હોય, સુગુણાબહેનને તો મોકલશે ને ?’
                                                    ‘રતના ! તું બેફીકર ઉપડ. હું તારી પડખે જ છું.'
                                                    અન
                                                    રતનો મધરાતે સાંઢિયે સ્વાર થયો અને સાંઢિયાને પીંગલગઢ તરફ હંકારી મૂક્યો. રતનો ઘણે દૂર નીકળી ગયો. સાંઢિયો ઘરડો હોવાથી ઘણો થાકી ગયો હતો. રતનો ના છૂટકે સાંઢિયો ઝોકારી જરા આડે પડખે પડ્યો. થોડી વારમાં જ તેના ઉપર નિદ્રાદેવી સ્વા૨ થઈ ગયાં.
                                                    બેની રે સૂતાં રે રંગમહેલમાં જીરે, અને સપનું આવ્યું માઝમ રાત, રતનો રાયકો રે અમને તેડવા આવ્યો જી રે,
                                                    અને પિયરે પરણે રામદેવ - ભાઈ. બેની રાતના સગુણા ભરનિંદમાં સૂતાં છે. એમને સ્વપ્નું આવ્યું કે, ભાઈ રામદેવનાં લગ્નની કંકોત્રી લઈ રાયકો પોતાને તેડવા આવી રહ્યો છે. સગુણા ઝબકીને જાગી અને જ્યાં સગુણાના દિયરનાં લગ્ન અગિયારશે હોવાથી તેનાં સાસુ, નણંદ, જેઠાણી એ બધાં લગ્નની વાતો કરે છે, ત્યાં હરખભેર દોડી આવી અને કહેવા લાગીઃ રાયકો લગ્નની કંકોત્રી લઈને આવી રહ્યો છે.' સાસુ, નણંદ અને જેઠાણી સગુણા ‘માતાજી, મને સ્વપ્નું આવ્યું છે કે, મારા ભાઈ રામદેવ પરણે છે અને રતનો
                                                </p>
                                                <p className="paragraph-content">
                                                    ન સાંભળીને ખડખડાટ હસી પડ્યાં અને કહ્યું સુણો રે વહુજી રે તમને સપનું આવ્યું રે, અને રે સપનાં સાથેાં ના હોય, વીસ વીસ વ૨સો વીતી ગયાં જી રે અને રે તેડવા આવેનવ કોઈ રે, બેની સદ્ગુ ! તને સપનું આવ્યું કે ભ્રમણા થઈ ? આજ વીસ વીસ વરસથી તારા રાજેથી ખબરઅંતર નથી પૂછી એવા તુંવરા તીખા છે, તે તને તેડવા આવે બા. સનુગ્રાની જેઠાણી હસીને મરડાટમાં બોલી :
                                                    સાસરિયે પરણે સગો દિયરીઓ જી રે, અને પિયેરે પરણે રે રામદેવ ભાઈ,
                                                    જૂઠાં રે બાનાં વહુજી સોણલાં જી રે,
                                                    અમે સમજ્યાં પિયરની સગાઈ... બેની. “સગુલ્લા ! તારા પિયરની સગાઈ તો એવી છે કે કોઈએ કંકોત્રી પણ મોકલી નથી. ત્યાં આવું ખોટું બહાનું કાઢીને દિયરના લગ્નમાં રહેવું નથી તેથી પિયરમાં જવા માટે જ તે સપનાની વાત ઉપજાવી કાઢી છે કે શું ?
                                                    સગુણાને જેઠાણીનાં વચન સાંભળી સપનાની વાત કરવામાં ઉતાવળ થઈ ગઈ છે એમ લાગ્યું.
                                                    તેની સાસુએ કહ્યું : ‘વહુજી ! તમારું સ્વપ્નું સાચું હશે અને લગ્નની કંકોત્રી આવશે તો હું જરૂર રણુંજે મોકલીશ. હવે મધરાત વીતી ગઈ છે, માટે સૂઈ જાવ.' સાસુના કહેણથી સગુણા શયનખંડમાં આવી વિચાર કરવા લાગી : ‘હે ભગવાન ! સપનું સાચું પડજો, નહીં તો સૌ મેણાં મારી ચેન નહીં પડવા દે.’ રસ્તામાં ઘસઘસાટ ઊંઘી ગયેલો રતનો રાયકો સવારે ઝબકી જાગ્યો. ત્યાં થોડે દૂર એન્ને એક નગ૨ જોયું તેથી સાંઢિયો લઈને નગર તરફ ચાલ્યો. પાદરે જઈ કૂવા પરની પનિહારીને પૂછવા લાગ્યો :
                                                    પાદરની પનિહારી પૂછું મારી બેન,
                                                    એજી રે... પનિહારી પૂછું મારી બેન,
                                                    આ કીયા ૨ે નગર ને કોણ રાજવી ? એજી...હોજી.
                                                    ત્યારે પનિહારીએ જવાબ આપ્યો :

                                                </p>
                                                <p className="paragraph-content">
                                                    નગરનું
                                                    શ્રી રામદેવ રામાયણ
                                                    પીંગળગઢ
                                                    ૐ
                                                    નામ
                                                    એજી રે.. નગરનું પીગળગઢ નામ
                                                    રાજ રે પ્રતાપી પઢિયારનાં... એજી...હોજી.
                                                    પીંગલગઢનું નામ સાંભળી રતનો રાજી થઈ ગયો. તે રામદેવજીના પ્રથમ
                                                    થોભાવી તે
                                                    મિત્ર
                                                    આનંદ અનુભવતો નગરમાં આવ્યો. સાંઢિયાને બહાર દરબારગઢમાં પેઠો. પઢિયાર રાજા કચેરી ભરી બેઠો છે. ત્યાં આવી રતનો નમન કરી ઊભા ર
                                                    કંકોતરી
                                                    આજ રામે ૨ે કંકોતરી મોકલી રે, દેજો મારી બેનીને હાથ,
                                                    અને
                                                    દીધા હૈ સંદેશા રતને રામના જી રે, અને રે આપી કંકોતરી હાથો રે હાથ... આજ પઢિયાર૨ાય કંકોત્રી વાંચી અને ક્રોધમાં ધૂંધવાતો બોલ્યો : બાલે ૨ે પીંગલગઢનો રાજવી જી રે, અને રે તુંવરા તોરીલા અપાર, ટૂંકી રે વધુ ને લાંબા પંથડા જી રે. અને નવ પોંચે. રણુંજા મોજાર... આજ પઢિયાર૨ાય પછી કહેવા લાગ્યો : ‘ભાઈ ! એ તો ભગવાનનો બાપ છે. તુંવરા તંબુરિયા ભગત છે. રણસિંહ તંબૂરો લઈને ઘેર ઘેર ભજન ગાવા જતો. મેઘવાળના ઘરે પણ પાટ પરસાદ કરાવતો. આ એનો દીકરો અજમલ પણ તંબૂરિયો ભગત છે. વીસ વરસ પહેલાં કાશીથી દ્વારકા આંટાફેરા કરતો હતો. જો સગુણા પિયર આવતી- જતી હોત તો આ દરબારમાં પણ તંબૂરિયા ભગત પેઠા હોત.’
                                                    રાજાની હામાં હા મેળવતાં પઢિયાર સરદારો બોલ્યા : ‘હવે તો અજમલજીને બે દીકરા છે; આ પરણે છે એ તો પીર છે ! ભગવાનનો અવતાર છે ! પછી અહીંથી રણુંજા કેટલું દૂર છે, એનું ભાન એને ક્યાંથી હોય ? દીકરી પરણાવી મોકલ્યા પછી ખબર લેવા આવ્યા હોય તો ખબર પડે ને કે પીંગલગઢે કેટલા દિવસમાં પહોંચાય છે ?'
                                                    રતનો રાયકો આ ઠઠ્ઠામશ્કરીઓ સાંભળી મનમાં ઝાળઝાળ થવા લાગ્યો પદ્મ

                                                </p>
                                                <p className="paragraph-content">
                                                    સગુણાબહેનને તેડી જવાની હોવાથી ક્રોધ દાબી શાન્ત ઊભો રહ્યો.

                                                    રતના ! તું તો ચીઠીનો ચાકર છે. અજમલે અમને આજે હલકા પાડવા આવો કૂંડો વિચાર કરીને તને મોકલ્યો. એમના મનમાં એમ કે, રાય સલાને મોક્લમે દીકરીને વહેલી તેડાવી ન હોત ? તું સગુણાને તેડવા તો આવ્યો પણ લગ્નમાં પહોંચી શકાશે નહીં, કારણ કે ત્યાં જતાં પંદર દિવસ લાગશે, ત્યાં તો લગ્ન ઊકલી પણ ગયાં હશે. માટે પાછો જા અને અજમલને સંદેશો આપજે કે, પઢિયારની મશ્કરી કરવાનું મૂકી દે, નહીં તો પરિણામ સારું નહીં આવે, અમે કાંઈ તંબૂરા વગાડનાર રાજા નથી કે છાનામાના બેસી રહીશું.'
                                                    રતનો રાયકો સમજી ગયો અને અહંકાર ભરેલા રાયને કંઈ કહેવું તો ખરું એમ વિચારી તે બોલ્યો : ‘હે મહારાજ ! આવાં અભિમાનનાં વચનો મેં ઘણાં સાંભળ્યાં, ઘણી મશ્કરી સહન કરી પણ હવે મારે કહેવું પડે છે કે, હું સગુણાબહેનને તેડવા આવ્યો છું અને તેડીને જ જઈશ !’
                                                    મૂકી દે રાજા ૨ે અભિમાનને જીરે, અને રે વચન વદતાં સાંભળ, અને ધૂન ધણી છે મારો મારો રખેવાળ. આજ૦
                                                    રતનાનાં વચન પઢિયારરાયના હૃદયમાં સોંસરાં નીકળી ગયાં. ક્રોધિત થઇને તે બોલ્યા : ‘તારો રામ રખવાળ છે, તો હવે એને અહીં આવવા દે. મારે જોવો છે'. આમ કહી સરદારો તરફ પ્રપંચી આંખનો ઇશારો કરતાં સરદારોએ ૨તનાને ઘે૨ીને જેલમાં પૂરી દીધો.
                                                    રામદેવજીની કંકોતરી લઈ રતનો આવ્યો છે એવી ખબર દાસીએ સગુણાને કંકોત્રી આપતાં કહી. સ્વપ્નું સાચું પડ્યું જાણી સગુણા હરખાઈને કંકોત્રી વાંચવા
                                                    લાગી.
                                                    [3]
                                                    બેનીએ કંકોત્રી
                                                    અને સજ્યા
                                                    ३
                                                    20 વાંચતાં સોળ શણગાર,
                                                    તરત થયાં રે બેની સાબદાં જી રે,
                                                    અને હૈયે હરખનો નહીં પારે ...આજ
                                                    સગુણાએ સોળે શણગાર સજી કંકોત્રી લઈ સાસુ પાસે આવીને કહ્યું :

                                                </p>
                                                <p className="paragraph-content">
                                                    સાસુ રે જેઠાણી નણદી વિનવું જી રે, વીરાને વવાય. અને જવું મારા
                                                    રતનો રાયકો અમને તેડવા આવ્યા જી રે,
                                                    અને પિયર પરણે રે રામદેવ ભાઈ...
                                                    કંકોતરી જોઈ સગુણાની સાસુ આશ્ચર્ય પામી મનમાં કચવાતી બોલી : સાસરિયે પરણે સગો દિયરિયો જી રે, અને પિયરમાં પરણે રામદે' ભાઈ, ટૂંકી રે અવધુમાં નવ પહોંચાય જી રે. અને તુંવરા વેવાર સમજે નવ કાંઈ ...આજ
                                                    વહુજી ! તમને પિયર જવાના કોડ જાગ્યાં છે, પણ તમારાં માતાપિતા વેવારમાં કાંઈ સમજતાં નથી. લગ્ન ઢૂંકડું છે. પીંગલગઢથી રણુંજાનો પંદ૨ દિવસનો લાંબો પંથ છે માટે પિયર જવાનો મમત કરશો નહીં. લગ્નમાં તેડાવવાં જ હતાં તો વહેલાં કેમ ન તેડાવ્યાં ?'
                                                    ‘સાસુજી ! પણ તમે મને વચન આપ્યું છે કે, જો કંકોતરી આવશે તો તને રણુંજે મોકલીશ.’
                                                    ‘વહુજી ! મારું વચન તો સાચું છે પણ વરધુ ઓછી એટલે પહોંચાશે નહીં એ કેમ સમજતાં નથી ? છતાં કાલે વાત.’
                                                    સગુણા હતાશ થઈ પાછી ફરી. શણગાર કાઢી નાખી ઘીનો દીવો કરી, તેની સામે બેસી રામદેવજીની પ્રાર્થના કરવા લાગી.
                                                    ‘હે ભાઈ ! તમો પ્રગટ પીર છો ! તમે અનેકનાં દુઃખ દૂર કર્યાં છે. આ તમારી બહેનને તમારું લગ્ન માણવાના કોડ છે, તો મને છેહ ન દેશો ભાઈ ! મને સહાય કરજો.’ આવી અનેક પ્રાર્થનાઓ કરતી સગુણા દીવા પાસે ઢળી પડી.
                                                    પાકી ચોકી વચ્ચે જેલમાં પુરાયેલો રતનો આકુળવ્યાકુળ થતો વિચારવા લાગ્યો કે, સગુણાબેનને તેડીને રણુંજા પહોંચાશે નહિ તો શું થશે ? એવા અનેક તર્કવિતર્ક કરતો હતો, ત્યાં રતનાને રામદેવજીના શબ્દો યાદ આવ્યા કે, હું તારી પડખે છું. સંકટ પડે તો મને યાદ કરજે. આપેલા વચનને યાદ કરી રતનો પ્રાર્થના કરવા લાગ્યો.

                                                </p>
                                                <p className="paragraph-content">
                                                    જીરે... રત્નો કરે આરાધ, એજી રે રાયકો કરે રે આરાધ
                                                    પીર મીઠી
                                                    નિંદરાયુંમાંથી જાગજો એ... હોજી
                                                    રતનાની આરાધના સાંભળી ચોકિયાતો જેલના દરવાજે ભેગા થયા છે. રતનો આરાધની ધૂનમાં તલ્લીન થયો છે, તે ફરી ફરીને રામદેવજીને પોકાર કરે છે.
                                                    નિંદરા તો નિજારી, નાથ નવ જાજો વચન વિસારી, પધારો તમે નકળંગ નેજાધારી રે હોજી. નકળંગ, નેજાધારી પીર, કરો લીલુડે ઘોડે અસવારી, પધારો તમે નકળંગ નેજાધારી હોજી ...નિંદરા પઢિયારોએ પોત પ્રકાશ્યું, અભિમાની અહંકારી હોજી. પકડી પૂર્યો કેદમાં અને ચોકિયું મૂકી પઢિયારી, પધારો તમે નકળંગ નેજાધારી હોજી ...નિંદરા ભીડ પડી તમે ભાંગો પીરજી, રતનો કહે પુકારી હોજી. પરગટ પરચો દેજો પીરજી, પઢિયારાને પડકારી પધારો તમે નકળંગ, નેજાધારી રે હોજી ...નિંદરા દ્વારકાથી પધાર્યા રણુંજે ‘કેશવ' કરુણાકારી હોજી.
                                                    પધારો તમે નકળંગ નેજાધારી રે હોજી ...નિંદરા
                                                    રતનાનો પોકાર રામદેવજીને સંભળાયો. તેઓ ભરનિદ્રામાંથી ઝબકીને જાગી ઊઠ્યા. ઘોડો તૈયાર કરી પીંગલગઢ જવા માતા મિણલદે પાસે રજા માગવા આવ્યા. મિણલદેએ કહ્યું : ‘બેટા ! તમે પીઠી ભર્યા મીંઢળ બાંધ્યા છો. તમારાથી બહાર
                                                    ન નીકળાય. સ્વપ્નમાં તમને ભ્રમણા થઈ હશે, અત્યારે મધરાત થઈ છે માટે સૂઈ
                                                    જાવ.'
                                                    માતાજીની આજ્ઞા પ્રમાણે રામદેવજી પાછા પોતાના શયનખંડમાં આવ્યા. એ જ સમયે પઢિયાર૨ાયે સ્વપ્નામાં ચમત્કાર અનુભવ્યો.
                                                    આજ ગઢ ૨ પીંગલ કેરા પાદરે જી રે,
                                                    અને ૨ે તાણી તંબુ કર્યો ૨ે પડકાર, તમે પઢિયારો હથિયારો ધરજો જી રે
                                                    અમે આવ્યા રતનાની કરવાને હાર. આજ
                                                    પીંગલગઢનો રાજા ગભરાયો. છતાં અનેક પઢિયારો તૈયાર થઈ યુદ્ધે ચડ્યા.

                                                </p>
                                                <p className="paragraph-content">
                                                    પણ ગભરાટમાં પઢિયારો એકબીજાને જ પરસ્પર રામદેવજી સમજી હું ખૂબ હું
                                                    હું ઘડી
                                                    રાજા ઢોલિયામાંથી પછડાઈ પડયા ! એમની આંખ ખૂલી ગઈ, છતાં બચાવો... બચાવો...ની બૂમ પાડી ઊઠ્યા. રાજાની આ દશા જોઈ રાણી બની ગયાં, આ શું બને ? તેની એમને સમજણ ન પડી. થોડીવાર પછી એ મીંઢળબંધો હાથ મહેલમાં ફરતો દૃષ્ટિએ પડ્યો. રાજારાણી આ ચમત્કાર છે
                                                    લાગ્યાં.
                                                    રાજદરબારમાં મશ્કરી કરનારાઓને પણ એ જ સમયે સપનાં આવ્યા. તેમ પથારીમાંથી ગબડ્યા અને ગેબી હાથ તેમને પકડી પકડીને પછાડવા લાગ્યો મ અવાજ પણ સંભળાયો કે, ‘જોઈ લે ! આ તંબૂરિયા ભગતના દીકરાને ! રતનાની જેલમાં તાળા તૂટી પડ્યાં અને બારણાં ઊઘડી ગયાં ! પહેરેગી બીકના માર્યા ભાગ્યા અને રાજાને ખબર આપી પણ રાજાની દશા તો એમના કરત
                                                    ભૂંડી હતી.
                                                    #_B_
                                                    ‘રામાપીરની જય !' બોલી રતનો જેલની બહાર નીકળ્યો.
                                                    પઢિયારરાયને રાણીએ કહ્યું કે, જે થવાનું હતું એ તો થઈ ગયું, પણ હવે આ વાત છાની રાખજો; નહીં તો સગુણા પિયરમાં જઈ વાત ક૨શે તો તુંવરોના મનમાં અભિમાન આવશે કે વાર્યા ન કરે તે હાર્યા કરે.
                                                    રાજાએ પણ સંમતિ આપતાં કહ્યું : ‘સગુણા ભલે પિયર જાય, આજની રાત આટલું વેઠ્યું તો હવે પછીની રાત તો કેવીર્ય જાય !'
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                </div>
            </div>
            {/* </div>
      </div> */}
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay })} /> */}
            </div>
        </>
    );
};

export default BlogDetails17;
