import ReactAudioPlayer from "react-audio-player";
import styles from "./AudioDetail.module.css";
import { BsBookmarkHeart } from "react-icons/bs";
import Header from "../Header";
import { Link } from "react-router-dom";
import AudioPlayer from "./AudioPlayer";
import Slider from "../Slider/Slider";

export const AudioDetail = (props) => {
  const img1 = "../../assets/audioImg/ramapir.jpeg";
  console.log("audio details...", props.state);
  return (
    <>
     <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
      <Header />

      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id="46"
        className="elementor elementor-46"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="52ebb12"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
          style={{ height: "30rem", marginBottom: "5rem" }}
        >
          <div className="elementor-background-overlay"></div>
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
              data-id="ae9d67e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                  data-id="fcf91b3"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-extended">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                      data-id="93dfaf4"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                          data-id="847c2c5"
                          data-element_type="widget"
                          data-widget_type="spacer.default"
                          style={{ height: "8rem" }}
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-spacer">
                              <div className="elementor-spacer-inner"></div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                          data-id="8fe49e3"
                          data-element_type="widget"
                          data-widget_type="trx_sc_layouts_title.default"
                        >
                          <div className="elementor-widget-container">
                            <div
                              id="trx_sc_layouts_title_515615674"
                              className="sc_layouts_title sc_align_center with_content without_image without_tint"
                            >
                              <div className="sc_layouts_title_content">
                                <div className="sc_layouts_title_title">
                                  {" "}
                                  
                                </div>
                                <div className="sc_layouts_title_breadcrumbs">
                                  <div className="breadcrumbs">
                                      <span className="breadcrumbs_item current">
                                    AudioPlayer
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="w-60 h-80vh bg-black">
        <div className={styles.card1}>
          <img src={img1} className="card-img-top2" alt="Card 2 Image" />
          <div className={styles["card-body"]}>
            <h5 className={styles["card-title"]}>Ramdev Pir </h5>
            {/* <img src={icon} className={styles.icon} /> */}
            {/* <button className={styles.icon1}>
              <BsBookmarkHeart />{" "}
            </button> */}
          </div>
        </div>
      </div>
     
      <AudioPlayer
        state={props.state}
        onPlay={(isPlay) => this.setState({ isPlay })}
      />
    </>
  );
};
