import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";

import './about.css';
import AudioPlayer from "../Audio/AudioPlayer";

const About2 = (props) => {
    const img1 = "/assets/wp-content/uploads/2018/12/post-18-copyright.jpg";
    const img2 = "../../assets/wp-content/uploads/2018/12/post-10-copyright-1024x576.jpg";
    return (
        <>
         <meta name="keywords" content="બાબા રામદેવ , રામદેવજી,  રામદેવ પીર, રામશા પીર, Baba Ramdev, Ramdevji, Ramdev Pir, Ramshapir" />
      
            {/* <div className="body_wrap">
        <div className="page_wrap"> */}


            <Header />

            <div
                data-elementor-type="cpt_layouts"
                data-elementor-id="46"
                className="elementor elementor-46"
            >
                <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-52ebb12  elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                    data-id="52ebb12"
                    data-element_type="section"
                    data-settings='{"background_background":"classic"}'
                    style={{ height: "30rem", marginBottom: "5rem" }}
                >
                    <div className="elementor-background-overlay"></div>
                    <div className="elementor-container elementor-column-gap-extended">
                        <div
                            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ae9d67e sc_inner_width_none sc_layouts_column_icons_position_left"
                            data-id="ae9d67e"
                            data-element_type="column"
                        >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-fcf91b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="fcf91b3"
                                    data-element_type="section"
                                >
                                    <div className="elementor-container elementor-column-gap-extended">
                                        <div
                                            className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-93dfaf4 sc_inner_width_none sc_layouts_column_icons_position_left"
                                            data-id="93dfaf4"
                                            data-element_type="column"
                                        >
                                            <div className="elementor-widget-wrap elementor-element-populated">
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-847c2c5 sc_fly_static elementor-widget elementor-widget-spacer"
                                                    data-id="847c2c5"
                                                    data-element_type="widget"
                                                    data-widget_type="spacer.default"
                                                    style={{ height: "8rem" }}
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div className="elementor-spacer">
                                                            <div className="elementor-spacer-inner"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="sc_layouts_item elementor-element elementor-element-8fe49e3 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title"
                                                    data-id="8fe49e3"
                                                    data-element_type="widget"
                                                    data-widget_type="trx_sc_layouts_title.default"
                                                >
                                                    <div className="elementor-widget-container">
                                                        <div
                                                            id="trx_sc_layouts_title_515615674"
                                                            className="sc_layouts_title sc_align_center with_content without_image without_tint"
                                                        >
                                                            <div className="sc_layouts_title_content">
                                                                <div className="sc_layouts_title_title">
                                                                    {" "}
                                                                    {/* <h1 className="sc_layouts_title_caption">
                                                                        About
                                                                    </h1> */}
                                                                </div>
                                                                <div className="sc_layouts_title_breadcrumbs">
                                                                    <div className="breadcrumbs">

                                                                        <span className="breadcrumbs_item current">
                                                                            About
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="page_content_wrap">
                <div className="content_wrap">
                    <div className="content">
                        <article
                            id="post-327"
                            className="services_single itemscope post-327 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-programs"
                        >
                            <section className="services_page_header">
                                <div className="services_page_featured">
                                    <img
                                        width={1920}
                                        height={1080}
                                        src={img1}

                                        className="attachment-full size-full wp-post-image"
                                        alt="The Happiness Program"
                                        decoding="async"
                                        // srcSet="https://vihara.themerex.net/wp-content/uploads/2018/12/post-18-copyright.jpg 1920w, https://vihara.themerex.net/wp-content/uploads/2018/12/post-18-copyright-300x169.jpg 300w, https://vihara.themerex.net/wp-content/uploads/2018/12/post-18-copyright-1024x576.jpg 1024w, https://vihara.themerex.net/wp-content/uploads/2018/12/post-18-copyright-768x432.jpg 768w, https://vihara.themerex.net/wp-content/uploads/2018/12/post-18-copyright-1536x864.jpg 1536w, https://vihara.themerex.net/wp-content/uploads/2018/12/post-18-copyright-370x208.jpg 370w, https://vihara.themerex.net/wp-content/uploads/2018/12/post-18-copyright-1170x658.jpg 1170w, https://vihara.themerex.net/wp-content/uploads/2018/12/post-18-copyright-760x428.jpg 760w, https://vihara.themerex.net/wp-content/uploads/2018/12/post-18-copyright-270x152.jpg 270w"
                                        sizes="(max-width: 1920px) 100vw, 1920px"
                                    />
                                </div>
                            </section>
                            <section className="services_page_content entry-content">
                                <p>
                                    Lorem ipsum dolor sit amet, ea pri meis accusam. Et vis accusam
                                    rationibus liberavisse, an vix viderer admodum. Atqui docendi
                                    omittam ei has, liber constituam id vim. Eam in dico doming
                                    definiebas. Cum munere impetus et. Ne nam simul oblique alterum, pri
                                    solet omnium id, usu an munere.
                                </p>
                                <h5>Sit eu facer soluta fuisset us magna mazimid</h5>
                                <p>
                                    <img
                                        decoding="async"
                                        loading="lazy"
                                        className="size-medium wp-image-839 alignright"
                                        src={img2}
                                        alt=""
                                        width={300}
                                        height={195}
                                    />
                                    Ne fugit essent persequeris sed. Qui dico dicam sadipscing no. Ius
                                    posse omnes eleifend ne, no sea amet oblique. Mea in wisi utinam
                                    facilisi. Eu omnes nonumes reformidans sit, et eam aperiam
                                    pertinacia.
                                </p>
                                <p>
                                    Te posse nostro labores pri, agam audire eu mei, natum voluptaria an
                                    mel. Ut illud maiestatis nec, vis cu propriae deterruisset. Ea mazim
                                    suavitate ius. Ei lorem instructior sea, populo necessitatibus ut
                                    est. Ne vix voluptua.
                                </p>
                                <p>
                                    Porro deleniti apeirian mea at, nostro referrentur an mei. Wisi
                                    alienum ullamcorper ea duo, aperiri apeirian vel ad. Sit eu facer
                                    soluta fuisset. Ius magna mazim id. In putant consulatu pri, per
                                    persius quaeque perpetua an.Ne fugit essent persequeris sed. Qui
                                    dico dicam sadipscing no.
                                </p>
                                <blockquote>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                        eiusmod tempor incididunt ut labore et dolore magna aliqua tempor
                                        <br />
                                        <cite>Morgan King</cite>
                                    </p>
                                </blockquote>
                            </section>
                            {/* .entry-content */}
                        </article>
                        {/* <div className="trx_addons_tabs services_page_tabs">
                            <ul className="trx_addons_tabs_titles border-title" style={{ borderBottom: 'none' }}>
                                <li data-active="true">
                                    <a href="#services_page_tab_comments">Comments</a>
                                </li>
                                <li>
                                    <a href="#services_page_tab_contacts">Contact Us</a>
                                </li>
                            </ul>
                            <section
                                id="services_page_tab_comments"
                                className="services_page_section services_page_section_comments"
                            >
                                {" "}
                                <section className="comments_wrap">
                                    <div className="comments_form_wrap">
                                        <div className="comments_form">
                                            <div id="respond" className="comment-respond">
                                                <h3
                                                    id="reply-title"
                                                    className="section_title comments_form_title"
                                                >
                                                    Leave a comment{" "}
                                                    <small>
                                                        <a
                                                            rel="nofollow"
                                                            id="cancel-comment-reply-link"
                                                            href="/services/the-happiness-program/#respond"
                                                        >
                                                            Cancel reply
                                                        </a>
                                                    </small>
                                                </h3>
                                                <form
                                                    action="/"
                                                    method="post"
                                                    id="commentform"
                                                    className="comment-form "
                                                    noValidate=""
                                                >
                                                    <div className="comments_field comments_author">
                                                        <label htmlFor="author" className="required">
                                                            Name
                                                        </label>
                                                        <span className="sc_form_field_wrap">
                                                            <input
                                                                id="author"
                                                                name="author"
                                                                type="text"
                                                                placeholder="Your Name *"
                                                                defaultValue=""
                                                                aria-required="true"
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="comments_field comments_email">
                                                        <label htmlFor="email" className="required">
                                                            E-mail
                                                        </label>
                                                        <span className="sc_form_field_wrap">
                                                            <input
                                                                id="email"
                                                                name="email"
                                                                type="text"
                                                                placeholder="Your E-mail *"
                                                                defaultValue=""
                                                                aria-required="true"
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="comments_field comments_comment">
                                                        <label htmlFor="comment" className="required">
                                                            Comment
                                                        </label>
                                                        <span className="sc_form_field_wrap">
                                                            <textarea
                                                                id="comment"
                                                                name="comment"
                                                                placeholder="Your comment *"
                                                                aria-required="true"
                                                                defaultValue={""}
                                                            />
                                                        </span>
                                                    </div>
                                                    <p
                                                        className="wpgdprc-checkbox
comment-form-wpgdprc"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            name="wpgdprc"
                                                            id="wpgdprc"
                                                            defaultValue={1}
                                                        />
                                                        <label htmlFor="wpgdprc">
                                                            By using this form you agree with the storage and
                                                            handling of your data by this website.
                                                            <abbr
                                                                className="wpgdprc-required"
                                                                title="You need to accept this checkbox."
                                                            >
                                                                *
                                                            </abbr>
                                                        </label>
                                                    </p>
                                                    <p className="form-submit">
                                                        <input
                                                            name="submit"
                                                            type="submit"
                                                            id="send_comment"
                                                            className="submit"
                                                            defaultValue="Leave a Comment"
                                                        />{" "}
                                                        <input
                                                            type="hidden"
                                                            name="comment_post_ID"
                                                            defaultValue={327}
                                                            id="comment_post_ID"
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name="comment_parent"
                                                            id="comment_parent"
                                                            defaultValue={0}
                                                        />
                                                    </p>
                                                </form>{" "}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </section>
                            <section
                                id="services_page_tab_contacts"
                                className="services_page_section services_page_section_contacts"
                            >
                                <div
                                    className="wpcf7 no-js"
                                    id="wpcf7-f82-p327-o1"
                                    lang="en-US"
                                    dir="ltr"
                                >
                                    <div className="screen-reader-response">
                                        <p role="status" aria-live="polite" aria-atomic="true" /> <ul />
                                    </div>
                                    <form
                                        action="/services/the-happiness-program/#wpcf7-f82-p327-o1"
                                        method="post"
                                        className="wpcf7-form init"
                                        aria-label="Contact form"
                                        noValidate="novalidate"
                                        data-status="init"
                                    >
                                        <div style={{ display: "none" }}>
                                            <input type="hidden" name="_wpcf7" defaultValue={82} />
                                            <input
                                                type="hidden"
                                                name="_wpcf7_version"
                                                defaultValue="5.7.7"
                                            />
                                            <input
                                                type="hidden"
                                                name="_wpcf7_locale"
                                                defaultValue="en_US"
                                            />
                                            <input
                                                type="hidden"
                                                name="_wpcf7_unit_tag"
                                                defaultValue="wpcf7-f82-p327-o1"
                                            />
                                            <input
                                                type="hidden"
                                                name="_wpcf7_container_post"
                                                defaultValue={327}
                                            />
                                            <input
                                                type="hidden"
                                                name="_wpcf7_posted_data_hash"
                                                defaultValue=""
                                            />
                                        </div>
                                        <div className="elementor-column-gap-extended">
                                            <div className="elementor-row">
                                                <div className="elementor-column elementor-col-50">
                                                    <div className="elementor-column-wrap elementor-element-populated">
                                                        <p>
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-name"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    placeholder="Name*"
                                                                    defaultValue=""
                                                                    type="text"
                                                                    name="your-name"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-50">
                                                    <div className="elementor-column-wrap elementor-element-populated">
                                                        <p>
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-email"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    placeholder="Email*"
                                                                    defaultValue=""
                                                                    type="email"
                                                                    name="your-email"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="elementor-column-gap-extended">
                                            <div className="elementor-row">
                                                <div className="elementor-column elementor-col-100">
                                                    <div className="elementor-column-wrap elementor-element-populated">
                                                        <p>
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-message"
                                                            >
                                                                <textarea
                                                                    cols={40}
                                                                    rows={10}
                                                                    className="wpcf7-form-control wpcf7-textarea"
                                                                    aria-invalid="false"
                                                                    placeholder="Message"
                                                                    name="your-message"
                                                                    defaultValue={
                                                                        "Hi.\nI'd like this service 'The Happiness Program'.\nPlease, get in touch with me."
                                                                    }
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text">
                                            <p>
                                                <span
                                                    className="wpcf7-form-control-wrap"
                                                    data-name="acceptance-486"
                                                >
                                                    <span className="wpcf7-form-control wpcf7-acceptance">
                                                        <span className="wpcf7-list-item">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    name="acceptance-486"
                                                                    defaultValue={1}
                                                                    aria-invalid="false"
                                                                />
                                                                <span className="wpcf7-list-item-label">
                                                                    I agree that my submitted data is being collected
                                                                    and stored.
                                                                </span>
                                                            </label>
                                                        </span>
                                                    </span>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="text">
                                            <p>
                                                <input
                                                    className="wpcf7-form-control has-spinner wpcf7-submit"
                                                    type="submit"
                                                    defaultValue="Send message"
                                                />
                                            </p>
                                        </div>
                                        <div className="wpcf7-response-output" aria-hidden="true" />
                                    </form>
                                </div>
                            </section>
                        </div> */}
                    </div>
                </div>
            </div>
            <div>
                <Footer />
                {/* <AudioPlayer state={props.state} onPlay={(isPlay) => this.setState({ isPlay }) } /> */}
            </div>
        </>
    );
};

export default About2;
